import classNames from 'classnames'
import { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import style from './AccountPages.module.css'
import InputField from '../../common/forms/InputField'
import Button from '../../common/forms/Button'
import axios from 'axios'
import { Routes } from '../../app/helpers/RestApiRoutes'

function RecoverPasswordPage() {
  let [searchParams] = useSearchParams()
  let navigate = useNavigate()

  let [code] = useState(searchParams.get('code'))
  let [email] = useState(searchParams.get('email'))
  let [password, setPassword] = useState('')
  let [error, setError] = useState('')
  let [message, setMessage] = useState('')
  let [loading, setLoading] = useState(false)

  async function DoChangePassword() {
    setError('')
    setMessage('')
    setLoading(true)

    try {
      await axios.post(Routes.ResetPassword(), {
        code: code as string,
        email: email as string,
        newPassword: password,
      })

      setMessage('Your password has been updated!')
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    } catch (error) {
      setError(
        'Something went wrong! Please try again or contact us on our discord.',
      )
    }
  }

  return (
    <div
      className={classNames(
        style.bg,
        'flex justify-center items-center w-screen h-screen',
      )}
    >
      <div
        className={classNames(
          { [style.bgMaskPrimary]: true },
          'absolute w-screen h-screen z-0',
        )}
      />
      <div className="container mx-auto px-4 xl:px-36 z-10">
        <div
          className={classNames(
            'w-full flex bg-background-500 rounded-2xl text-white',
          )}
        >
          <div className="flex flex-col w-full justify-center py-6 xl:py-12 px-5">
            <h1 className="text-xl">Reset your password</h1>

            {!code || !email ? (
              <div className="flex flex-col w-full justify-center py-6 xl:py-12 text-steel">
                Something went wrong, please click the link in your email again.
                <Link to="/login">
                  <Button
                    className="mt-5 w-full"
                    color="primary"
                    trackingEventName={
                      'change-password-something-wrong-navigation'
                    }
                  >
                    Back to Login
                  </Button>
                </Link>
              </div>
            ) : (
              <>
                <p className="py-3 text-steel">
                  Enter your new password below and click the button to
                  continue;
                </p>
                <InputField
                  placeholder="your new password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />

                {error && (
                  <div className="flex flex-col w-full justify-center py-3 text-red-500">
                    Error: {error}
                  </div>
                )}

                {message && (
                  <div className="flex flex-col w-full justify-center py-3 text-green-500">
                    Success: {message}
                  </div>
                )}

                <Button
                  className="mt-4"
                  color="primary"
                  trackingEventName={'activate-account-click'}
                  loading={loading}
                  onClick={() => DoChangePassword()}
                >
                  Change Password
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecoverPasswordPage
