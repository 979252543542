import { useContext, useState } from 'react'
import {
  SocialBrand,
  SocialBrandsAttributes,
  SocialLink,
} from '../../../api/types'
import SelectField from '../../../common/forms/SelectField'
import InputField from '../../../common/forms/InputField'
import Button from '../../../common/forms/Button'
import classnames from 'classnames'
import { useEffect } from 'react'
import ChevronRight from 'src/common/icons/ChevronRight'
import SectionCard from '../atoms/SectionCard'
import classNames from 'classnames'
import { CurrentUserContext } from '../../../providers/AuthProvider'
import { useUpdateFeed } from '../../../app/hooks/UseUpdateFeed'
import useGetFeed from '../../../app/hooks/useGetFeed'

const defaultMessages: Record<
  SocialBrand,
  { message: string; error: boolean }
> = {
  [SocialBrand.TWITTER]: {
    message: '',
    error: false,
  },
  [SocialBrand.TWITCH]: {
    message: '',
    error: false,
  },
  [SocialBrand.STEAM]: {
    message: '',
    error: false,
  },
  [SocialBrand.XBOX]: {
    message: '',
    error: false,
  },
  [SocialBrand.PLAYSTATION]: {
    message: '',
    error: false,
  },
  [SocialBrand.NINTENDOSWITCH]: {
    message: '',
    error: false,
  },
  [SocialBrand.NINTENDO3DS]: {
    message: '',
    error: false,
  },
  [SocialBrand.STADIA]: {
    message: '',
    error: false,
  },
  [SocialBrand.GOG]: {
    message: '',
    error: false,
  },
  [SocialBrand.EPIC]: {
    message: '',
    error: false,
  },
  [SocialBrand.EA]: {
    message: '',
    error: false,
  },
  [SocialBrand.UBISOFT]: {
    message: '',
    error: false,
  },
  [SocialBrand.POKEMONGO]: {
    message: '',
    error: false,
  },
  [SocialBrand.INSTAGRAM]: {
    message: '',
    error: false,
  },
  [SocialBrand.STEAMCOMMUNITY]: {
    message: '',
    error: false,
  },
  [SocialBrand.STEAMGAME]: {
    message: '',
    error: false,
  },
  [SocialBrand.WEBSITE]: {
    message: '',
    error: false,
  },
  [SocialBrand.DISCORD]: {
    message: '',
    error: false,
  },
}

interface Props {
  whiteList?: SocialBrand[]
  blackList?: SocialBrand[]
}

function SocialLinksSettingsWidget({
  whiteList,
  blackList,
}: Props) {
  const { user } = useContext(CurrentUserContext)

  const {
    loading,
    error,
    updateFeed,
  } = useUpdateFeed(user?.username ?? '')

  const { data: feed } = useGetFeed(user?.username ?? '')

  const [socialLinks, setSocialLinks] = useState<SocialLink[] | undefined>(feed?.socialPlatforms)

  useEffect(() => {
    if (feed?.feedSocialPlatforms) {
      setSocialLinks(feed.feedSocialPlatforms)
    }
  }, [feed])

  if (whiteList && blackList) {
    throw new Error('Cannot have both whiteList and blackList')
  }

  const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0)
        .toUpperCase() + txt.substr(1)
        .toLowerCase()
    })
  }

  function updateExistingLinkValue(
    link: SocialLink | undefined,
    platform: string,
    value: string,
  ): void {
    if (!link) {
      // @ts-ignore
      setSocialLinks((prev) => [
        ...(prev ?? []),
        {
          name: toTitleCase(platform),
          pinned: false,
          platform: toTitleCase(platform),
          value: value,
        },
      ])
      return
    }

    const links: SocialLink[] = JSON.parse(JSON.stringify(socialLinks))
    const index = links.findIndex((x: SocialLink) => x.id === link?.id)
    if (index !== -1) {
      links[index].value = value
      setSocialLinks(links)
    }
  }

  function updateExistingLinkPinned(
    link: SocialLink | undefined,
    value: string,
  ): void {
    if (!link) {
      console.error('There was no social link')
      return
    }

    const links: SocialLink[] = JSON.parse(JSON.stringify(socialLinks))
    const index = links.findIndex((x: SocialLink) => x.id === link.id)

    console.log({
      links,
      index,
      value,
    })
    if (index !== -1) {
      links[index].pinned = value === 'true'
      setSocialLinks(links)
    }
  }

  async function Save() {
    console.log('saving', socialLinks)

    try {
      void updateFeed({
        socialLinks: JSON.stringify(socialLinks),
      })

      setMessages((prev) => ({
        ...prev,
        [expanded as SocialBrand]: {
          message: 'Saved',
          error: false,
        },
      }))
    } catch (e) {
      setMessages((prev) => ({
        ...prev,
        [expanded as SocialBrand]: {
          message: 'Something went wrong. Please try again.',
          error: true,
        },
      }))
    }
  }

  const DownChevron = () => {
    return (
      <svg
        className='h-6 w-6 text-gray-500'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M19 9l-7 7-7-7'
        />
      </svg>
    )
  }

  const [expanded, _setExpanded] = useState('')
  const setExpanded = (brand: string) => {
    _setExpanded(brand)
    setMessages(defaultMessages)
  }
  const isExpanded = (brand: string) => {
    return expanded === brand
  }

  const socialLinkForPlatform = (platform: string) => {
    return socialLinks?.find(
      (x) => x.platform.toLowerCase() === platform.toLowerCase(),
    )
  }

  const [messages, setMessages] =
    useState<Record<SocialBrand, { message: string; error: boolean }>>(
      defaultMessages,
    )

  return (
    <SectionCard title={'Your social profiles'}>
      {Object.values(SocialBrand)
        .map((socialBrand: string) => (
          <div
            className='w-full relative bg-background-500 overflow-hidden'
            key={socialBrand}
          >
            <div
              className='flex text-xl justify-between shadow-md p-2 cursor-pointer'
              onClick={() =>
                isExpanded(socialBrand)
                  ? setExpanded('')
                  : setExpanded(socialBrand)
              }
            >
              <div className='capitalize flex gap-3'>
                {SocialBrandsAttributes[socialBrand as SocialBrand].icon()}
                {socialBrand.toLowerCase()
                  .replace('_', ' ')}
              </div>
              {isExpanded(socialBrand) ? <DownChevron /> : <ChevronRight />}
            </div>
            <div
              className={classnames(
                'transition-all overflow-hidden border-b border-darkbackground-500',
                {
                  'h-0': !isExpanded(socialBrand),
                  'h-max md:h-40 lg:h-32 p-2': isExpanded(socialBrand),
                },
              )}
            >
              <div className='grid md:grid-cols-4 grid-cols-1 w-full justify-between gap-4 items-center flex-col md:flex-row'>
                <div className='font-bold'>
                  <div className='mt-6'>
                    {SocialBrandsAttributes[socialBrand as SocialBrand].baseURL}
                  </div>
                </div>
                <div className='text-white'>
                  <div className='mb-1 text-sm md:text-base'>
                    {
                      SocialBrandsAttributes[socialBrand as SocialBrand]
                        .settingsTitle
                    }
                  </div>
                  {/* Your <span className="capitalize">{i.toLowerCase()}</span>{' '}
                  profile;{' '} */}
                  {error !== '' && <span className='text-red-500'>{error}</span>}
                  <InputField
                    type='text'
                    value={socialLinkForPlatform(socialBrand)?.value}
                    onChange={(e) =>
                      updateExistingLinkValue(
                        socialLinkForPlatform(socialBrand),
                        socialBrand,
                        e.target.value,
                      )
                    }
                  />
                </div>
                <div className='text-white'>
                  <div className='mb-2 text-sm md:text-base'>
                    Display on your profile?
                  </div>
                  <SelectField
                    options={{
                      false: 'Hidden from profile',
                      true: 'Shown on profile',
                    }}
                    onChange={(value) =>
                      updateExistingLinkPinned(
                        socialLinkForPlatform(socialBrand),
                        value,
                      )
                    }
                    defaultValue={socialLinkForPlatform(socialBrand)?.pinned + ''}
                  />
                </div>
                <div>
                  <Button
                    trackingEventName={'save-changes-to-settings-widget'}
                    color='primary'
                    className='mt-5 w-full'
                    onClick={() => Save()}
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
                <div className='md:col-span-4 col-span-1 text-right'>
                  {messages[socialBrand as SocialBrand].message && (
                    <div
                      className={classNames('text-sm', {
                        'text-red-500':
                        messages[socialBrand as SocialBrand]?.error,
                        'text-green-500':
                          !messages[socialBrand as SocialBrand]?.error,
                      })}
                    >
                      {messages[socialBrand as SocialBrand].message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </SectionCard>
  )
}

export default SocialLinksSettingsWidget
