import { useLocation } from 'react-router-dom'
import { Feed } from 'src/api/types'
import NavSideBarWithOutlet from './NavSideBarWithOutlet'

interface Props {
  blocks: Feed[]
}

export default function WritersBlocksSettingsPanel({ blocks }: Props) {
  const activeRoute = useLocation().pathname

  const routes = blocks.map((block) => ({
    name: block.name,
    path: encodeURIComponent(block.name),
  }))

  return <NavSideBarWithOutlet activeRoute={activeRoute} routes={routes} />
}
