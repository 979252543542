import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import WritersBlockPostCard from '../WritersBlockPostCard'
import { Post } from '../../../api/types'
import WritersBlockSponsoredPost from './WriterBlockSponsoredPost'
import GradientBackgroundContainer from 'src/common/containers/GradientBackgroundContainer'
import Button from 'src/common/forms/Button'
import Image from 'src/common/forms/Image'
import ShardIcon from 'src/common/icons/ShardIcon'
import Spinner from 'src/common/Spinner'
import { AxiosContext } from '../../../providers/AxiosProvider'
import { useBoolean, useDisclosure } from '@chakra-ui/react'
import { APIPaginatedResponse, Routes } from '../../../app/helpers/RestApiRoutes'
import { CurrentWritersBlockContext } from './WritersBlockContext'
import useToggleFollowFeed from '../../../app/hooks/useToggleFollowFeed'
import { InfiniteScroll } from '../../../common/containers/InfiniteScrollingFeed'
import { useCookies } from 'react-cookie'
import config from '../../../config'
import { driver, DriveStep } from 'driver.js'
import 'driver.js/dist/driver.css'
import { AiFillQuestionCircle } from 'react-icons/ai'
import ExclamationCircle from '../../../common/icons/ExclamationCircle'
import RecoverMyFeedModal from '../../../common/modals/RecoverMyFeedModal'

const FeaturedPosts = (props: { feedId: string }) => {
  const client = useContext(AxiosContext)
  const [featuredPostData, setFeaturedPostData] = useState<Post[]>([])
  const [featuredPostLoading, setFeaturedPostLoading] = useBoolean(false)
  const [featuredPostError, setFeaturedPostError] = useState('')
  const GetSponsoredPosts = useCallback(async () => {
    setFeaturedPostLoading.on()
    try {
      const resp = await client.get(
        Routes.GetPosts({
          page: 0,
          pageSize: 1,
          feedId: props.feedId,
          feedType: 'WritersBlock',
          sponsored: true,
          pinned: false,
          archived: false,
        }),
      )
      setFeaturedPostData(resp.data.Payload)
    } catch (e: any) {
      setFeaturedPostError(e.message)
    }
    setFeaturedPostLoading.off()
  }, [client, props.feedId, setFeaturedPostLoading])

  useEffect(() => {
    setFeaturedPostLoading.on()
    void GetSponsoredPosts()
  }, [GetSponsoredPosts, setFeaturedPostLoading])

  if (featuredPostLoading) {
    return (
      <div className='w-full text-center p-5 md:col-span-3' key={0}>
        <Spinner />
      </div>
    )
  }

  if (featuredPostError) {
    return (
      <div className='w-full text-center p-5 md:col-span-3' key={0}>
        <p>Error: {featuredPostError}</p>
      </div>
    )
  }

  if ((featuredPostData ?? []).length === 0 || !featuredPostData) {
    return null
  }

  return (
    <div className='mt-9'>
      <div className='text-3xl md:col-span-3 font-alice'>Featured</div>
      <div className='border-b border-solid border-red-500 w-28 md:col-span-3 mb-9' />

      <WritersBlockSponsoredPost post={featuredPostData[0]!} />
    </div>
  )
}

function WritersBlockPage() {
  const pageSize = 24
  const client = useContext(AxiosContext)
  const {isOpen: recoverIsOpen, onOpen: recoverFeedOnOpen, onClose: recoverFeedOnClose} = useDisclosure()
  const {
    feed,
    getFeedData,
  } = useContext(CurrentWritersBlockContext)
  const [cookies, setCookie,] = useCookies([config.cookies.has_views_writers_block_recovery_tutorial])
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useBoolean(false)
  const [error, setError] = useState('')
  const [data, setData] = useState<APIPaginatedResponse<Post>>({
    hasMore: false,
    total: 0,
    offset: 0,
    pageSize: 0,
    payload: [],
  })

  const {
    toggleFollow,
  } = useToggleFollowFeed(feed.id ?? '')

  const GetFeed = useCallback(
    async (page: number, pageSize: number, feedSlug: string) => {
      setLoading.on()
      setError('')
      try {
        const { data: responseData } = await client.get<
          APIPaginatedResponse<Post>
        >(Routes.GetPostsForFeed(feedSlug, page, pageSize))

        setData((prev) => ({
          ...responseData,
          payload: [...(prev.payload ?? []), ...(responseData.payload ?? [])],
        }))
      } catch (e: any) {
        setError(e.message)
      }
      setLoading.off()
    },
    [client, setData, setLoading],
  )

  const handleSubscribeClick = async () => {
    await toggleFollow()
    await getFeedData()
  }

  useEffect(() => {
    if (!feed.slug) {
      return
    }
    void GetFeed(page, pageSize, feed.slug)
  }, [GetFeed, feed, page])


  // Recover this content - Tutorial Journey
  const recoverTutorialJourney = useMemo(() => {
    let extraSteps: DriveStep[] = []
    if (feed.isOwner) {
      extraSteps = [
        {element: '#create-new-post-button', popover: {title: 'Create a new post', description: 'You can create a post by clicking here!', side: 'right', align: 'start'}},
        {element: '#edit-writers-block-button', popover: {title: 'Edit your Writers Block', description: 'Click here to go to the settings of your writers block!', side: 'right', align: 'start'}}
      ]
    } else {
      extraSteps = [
        {element: '#writers-block-post-card:nth-child(1)', popover: {title: 'View a post!', description: 'Clicking one of the fabulous posts under the recent entries section will take you to the action!',side: 'right', align: 'start'}},
        {element: '#recover-my-feed', popover: {title: 'Wait! This is my feed!', description: 'If you want to claim this feed as your own, throw us a message from here',side: 'right', align: 'start'}}
      ]
    }

    return driver({
      showProgress: true,
      steps: [
        { element: '#writers-block-title-and-stats',
          popover: {
            title: 'Welcome to Writers Blocks',
            description: `Here's a simple animated tutorial. It takes 10 seconds, but you can click off the highlighted area to close the tutorial.`,
            side: "left",
            align: 'start'
          }
        },
        { element: '#doesnotexist',
          popover: {
            title: 'What is a Writers Block?',
            description: 'Introducing "Writer\'s Block" – your ultimate microblogging paradise! 🚀📝 Say goodbye to creative slumps and hello to endless inspiration! 💡✨ "Writer\'s Block" is your go-to platform for all things microblogging, where your thoughts can soar freely in short, snappy bursts of creativity. Whether you\'re a wordsmith extraordinaire or just want to share your daily musings, our user-friendly interface makes it a breeze. Connect with fellow wordsmiths, ignite conversations, and craft your own unique digital presence. So, let your creativity flow like never before with "Writer\'s Block" – where ideas come to life, one post at a time! 🌟📚 #WritersBlock #UnleashYourCreativity',
            side: "bottom",
            align: 'start'
          }
        },
        ...extraSteps,
      ],
      onDestroyed: () => {
        setCookie(config.cookies.has_views_writers_block_recovery_tutorial, "true", { path: '/' })
      }
    })
  }, [feed.isOwner, setCookie])
  useEffect(() => {
    if(cookies.viewed_writers_block_recovery_journey !== "true") {
      recoverTutorialJourney.drive()
    }
  }, [cookies.viewed_writers_block_recovery_journey, recoverTutorialJourney])

  if (!feed.slug || !feed.id) {
    return <div>Nothing found</div>
  }

  return (
    <GradientBackgroundContainer
      url={feed.bannerImage ?? '/images/writers-blocks/embergn-bedroom.png'}
    >
      {
        <div>
          <div className='container mx-auto px-4 py-16 relative'>
            <div id={'writers-block-title-and-stats'} className='flex md:w-1/2 relative'>
              <div className={'absolute top-0 right-0 text-steel opacity-50 cursor-pointer p-3'} onClick={() => recoverTutorialJourney.drive()}>
                <AiFillQuestionCircle />
              </div>
              <div className='flex-1 flex flex-col text-white'>
                <div className='grid md:grid-cols-5'>
                  <div
                    style={{
                      height: 120,
                      width: 120,
                    }}
                    className='md:col-span-1 col-span-5 '
                  >
                    <Image
                      className='w-full h-full rounded-xl object-cover object-center '
                      alt='feed icon'
                      src={feed.iconImage ?? '#'}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = '/images/games/placeholder.png'
                      }}
                    />
                  </div>
                  <div className='flex flex-col justify-end items-center'>
                    <ShardIcon isActive />
                    <div className='blocks-created text-lg text-primary-500'>
                      {feed.totalPosts}
                    </div>
                    <div className='text-md text-steel'>Entries</div>
                  </div>
                  <div className='flex flex-col justify-end items-center'>
                    <ShardIcon isActive />
                    <div className='blocks-created text-lg text-primary-500'>
                      {feed.totalSubscribers}
                    </div>
                    <div className='text-md text-steel'>Followers</div>
                  </div>
                  <div className='flex flex-col justify-end items-center'>
                    <ShardIcon isActive />
                    <div className='blocks-created text-lg text-primary-500'>
                      {feed.totalShards}
                    </div>
                    <div className='text-md text-steel'>Awards</div>
                  </div>
                  <div className='flex flex-col justify-end items-center'>
                    <ShardIcon isActive />
                    <div className='blocks-created text-lg text-primary-500'>
                      {feed.totalViews}
                    </div>
                    <div className='text-md text-steel'>Total Views</div>
                  </div>
                </div>
                <h1 className='bi-type-underline text-3xl font-light mt-9'>
                  {feed.name}
                </h1>
                <p className='text-steel'>A Block By {feed.owner.name}</p>
                <p className='text-steel my-14'>{feed.description}</p>
                <div>
                  {feed.isOwner ? (
                    <div className='w-full flex gap-3'>
                      <Link to={`/writers-block/${feed.id}/edit`}>
                        <Button
                          trackingEventName={'edit_writers_block'}
                          className='bg-primary-500 text-white'
                        >
                          Edit Block
                        </Button>
                      </Link>
                      <Link to={`/writers-block/${feed.slug}/create-post`}>
                        <Button
                          trackingEventName={'create_new_writers_block_post'}
                          className='bg-primary-500 text-white'
                        >
                          Create new post
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <Button
                      trackingEventName={
                        feed.isFollowing
                          ? 'unsubcribe-from-writers-block'
                          : 'subcribe-to-writers-block'
                      }
                      className='bg-primary-500 text-white'
                      onClick={handleSubscribeClick}
                    >
                      {feed.isFollowing ? 'Unsubcribe' : 'Subscribe'}
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <FeaturedPosts feedId={feed.id} />

            <div className='text-3xl font-alice mt-9 relative'>
              Recent entries
              <div id={'recover-my-feed'} className='absolute top-0 right-0 opacity-50 text-steel cursor-pointer' onClick={() => recoverFeedOnOpen()}>
                <ExclamationCircle />
                <RecoverMyFeedModal feedSlug={feed.slug} isOpen={recoverIsOpen} onOpen={recoverFeedOnOpen} onClose={recoverFeedOnClose}/>
              </div>
            </div>
            <div className='border-b border-solid border-red-500 w-28 md:col-span-3 mb-9' />

            {error !== '' && (
              <div className='w-full text-center p-5 md:col-span-3'>
                <p>Error: {error}</p>
              </div>
            )}

            <InfiniteScroll
              loadMore={() => {
                setLoading.on()
                setPage((prev) => prev + 1)
              }}
              loading={loading}
              hasMore={data.hasMore ?? false}
            >
              <div className='grid md:grid-cols-3 gap-4'>
                {(data.payload ?? []).map((post: Post) => (
                  <WritersBlockPostCard
                    key={post.id}
                    className='col-span-1'
                    post={post}
                    slug={feed.slug ?? ''}
                  />
                ))}

                {loading && (
                  <div>
                    <Spinner />
                  </div>
                )}

                {(data.payload ?? []).length === 0 && (
                  <div className={'text-steel text-center col-span-3'}>
                    There are no posts yet!
                    <br />
                    Check back in a little while
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      }
    </GradientBackgroundContainer>

    // Infinite scrolling to populate this grid
  )
}

export default WritersBlockPage
