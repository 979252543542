import ShardIcon from '../icons/ShardIcon'
import Spinner from '../Spinner'
import { useContext, useEffect, useState } from 'react'
import { ShardApi } from '../../api/shards/api'
import ExclamationCircle from '../icons/ExclamationCircle'
import { AxiosContext } from '../../providers/AxiosProvider'

interface Props {
  postId?: string
  userId?: string
  showIcon?: boolean
}

function FetchShardTotal(query: Promise<number>, callback: (val: number) => void, setError: (value: (((prevState: (string | undefined)) => (string | undefined)) | string)) => void, setLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void) {
  query
    .then(callback)
    .catch((e) => {
      console.warn("Clickable shard failed to collect accurate number of shards")
      setError(e.message)
    })
        .then(() => setLoading(false))
}

/**
 * Displays the total number of shards for a post or user.
 */
export default function ShardStat(props: Props = {showIcon: true}) {
  const [loading, setLoading] = useState(true)
  const [totalShards, setTotalShards] = useState(0)
  const [error, setError] = useState<string | undefined>(undefined)
  const client = useContext(AxiosContext)

  if(props.postId && props.userId) {
    throw new Error("Cannot use both Post ID and User ID. Ensure only one is passed to this component at a time.")
  }

  useEffect(() => {
    if(props.postId) {
      FetchShardTotal(ShardApi.GetShardsForPost(client, props.postId), (val) => {
        console.log("Total shards for post: ", val)
        setTotalShards(val)
      }, setError, setLoading)
    }
    else if(props.userId) {
      FetchShardTotal(ShardApi.GetShardsForUser(client, props.userId), (val) => {
        console.log("Total shards for user: ", val)
        setTotalShards(val)
      }, setError, setLoading)
    }
  }, [client, props])


  const Icon = () => {
    if(props.showIcon) {
      if(error) {
        return <span className="text-red-500"><ExclamationCircle /></span>
      }

      return <ShardIcon />
    }
    return null
  }

  const Total = () => {
    if (loading) {
      return <Spinner />
    }

    return <div>{totalShards}</div>
  }

  return (<div
      style={{ maxHeight: 39 }}
      className='p-3 flex justify-center items-center space-x-3'
    >
      <Icon />
      <Total />
    </div>)
}
