import { format, formatDistance } from 'date-fns'

export function TitleCase(value: string): string {
  if (value.length === 0) return value

  const lowerCaseWords = value
    .trim()
    .replace('_', ' ')
    .replace('-', ' ')
    .toLowerCase()
    .split(' ')
  lowerCaseWords.map((word) => {
    if (word.length > 0) return word.replace(word[0], word[0].toUpperCase())
    else return word
  })
  return lowerCaseWords.join(' ')
}

export function CapitaliseFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function ParseUnixTimestamp(value: number): string {
  return format(new Date(value * 1000), 'yyyy-MM-dd')
}

export function ParseUnixTimestampToDateDistance(value: number): string {
  return formatDistance(new Date(value * 1000), new Date())
}

export function Sanitize(value: string): string {
  return value
  // return sanitizeHtml(value)
}

export function CharLimit(string: string, limit: number) {
  if (!string) {
    return string
  }
  if (string.length > limit) {
    return string.substring(0, limit) + '...'
  } else {
    return string
  }
}

export function StriptNBSP(string: string): string {
  return string.replace(/&nbsp;/g, '')
}

export function StripHTMLTags(string: string): string {
  return StriptNBSP(string)
    .replace(/<br[ /]*>/g, '\n')
    .replace(/<(?:.|\n)*?>/gm, '')
}

export function isValidString(str: string) {
  return str && str.length > 0
}
