import InputField from '../../common/forms/InputField'
import MagnifiyingGlass from '../../common/icons/MagnifyingGlass'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchGrid from './SearchGrid'

function SearchNavElement() {
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()

  return (
    <div className="search flex items-center space-x-3">
      <div className="hidden lg:block">
        <InputField
          type="search"
          placeholder="search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div
        className="cursor-pointer block lg:hidden"
        onClick={() => navigate('/search')}
      >
        <MagnifiyingGlass className="fill-darksteel" />
      </div>
      <div className="lg:block hidden">
        <MagnifiyingGlass className="fill-darksteel" />
      </div>

      <SearchGrid
        searchTerm={searchTerm}
        fixed={true}
        onSelect={() => {
          setSearchTerm('')
        }}
      />
    </div>
  )
}

export default SearchNavElement
