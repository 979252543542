function EALogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.971"
      height="11.657"
      viewBox="0 0 23.971 11.657"
    >
      <g id="形状_1" transform="translate(-26.045 -144.668)">
        <g
          id="Group_1386"
          data-name="Group 1386"
          transform="translate(26.045 144.668)"
        >
          <path
            id="Path_712"
            data-name="Path 712"
            style={{ fill: '#fff', fillRule: 'evenodd' }}
            d="M42.651,144.668,36.748,154h-6.47l1.491-2.3h4.025l1.461-2.325H28.281L26.82,151.7h2.147l-2.922,4.621H38.09l4.562-7.275,1.67,2.654H42.89L41.429,154h4.353l1.461,2.326h2.773Zm-1.938.089H31.2l-1.461,2.326,9.511-.03Z"
            transform="translate(-26.045 -144.668)"
          />
        </g>
      </g>
    </svg>
  )
}

export default EALogo
