import ChangeNSFWConsent from '../organisms/writers-blocks/ChangeNSFWFeedContent'
import ChangeBlockBackground from '../organisms/writers-blocks/ChangeBlockBackground'
import ChangeBlockPicture from '../organisms/writers-blocks/ChangeBlockPicture'
import ChangeFeedPrivacyContent from '../organisms/writers-blocks/ChangeFeedPrivacyContent'
import ChangeWritersBlockDetails from '../organisms/writers-blocks/ChangeWritersBlockDetails'

export default function WritersBlockSettingsWidget() {
  return (
    <>
      <ChangeWritersBlockDetails />

      <ChangeBlockPicture />

      <ChangeBlockBackground />

      <ChangeNSFWConsent />

      <ChangeFeedPrivacyContent />
    </>
  )
}
