import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import {
  GetFeedQuery,
  GetFeedVariables,
  GET_FEED,
} from 'src/api/gql-queries'
import SectionCard from '../../atoms/SectionCard'
import ErrorMessage from '../../molecules/ErrorMessage'
import SelectField from 'src/common/forms/SelectField'
import { useLocation } from 'react-router-dom'
import { Privacy } from 'src/api/types'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'

export function ChangeUserPrivacy() {
  const {
    writersBlocks,
    ClearCache,
  } = useContext(CurrentUserContext)
  const { pathname: location } = useLocation()

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const { updateUser } = useUpdateUser()

  const getWritersBlockByName = () => {
    // Get last part of url
    const url = location.split('/')
    const name = url[url.length - 1]

    return (writersBlocks ?? []).find((block) => block.name === decodeURI(name))
  }

  const { data } = useQuery<GetFeedQuery, GetFeedVariables>(GET_FEED, {
    variables: {
      slug: getWritersBlockByName()?.id as string,
    },
  })

  const [feedPrivacy, setFeedPrivacy] = useState<Privacy>(
    data?.Feed?.privacy ?? Privacy.Open,
  )

  useEffect(() => {
    setFeedPrivacy(data?.Feed?.privacy ?? Privacy.Open)
  }, [data])

  function submitForm(value: string) {
    setFeedPrivacy(value as Privacy)

    updateUser({
      privacy: feedPrivacy,
    })
      .then(() => {
        ClearCache()
        setMessage('Your feed content flag has been updated.')
      })
      .catch(() => {
        setError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  return (
    <SectionCard title='Change feed privacy setting'>
      <div>Change your privacy setting:</div>
      <SelectField
        options={{
          [Privacy.Private]: 'Private: My profile will not be shown to anyone',
          [Privacy.Invite]:
            'Followers: My profile will only be shown to people I follow',
          [Privacy.Open]: 'Public: My profile will be shown to everyone',
        }}
        defaultValue={feedPrivacy + ''}
        onChange={(value) => {
          submitForm(value)
        }}
      />

      <ErrorMessage
        error={error}
        message={message}
        dataTestId={'change-profile-user-nsfw'}
      />
    </SectionCard>
  )
}
