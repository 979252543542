import classNames from 'classnames'
import InputField from 'src/common/forms/InputField'

interface InputFieldWithMessagesProps {
  type: string
  placeholder: string
  value: string
  onChange: (e: any) => void
  dataTestId: string
  message?: string
  error?: boolean
}

export default function InputFieldWithMessage({
  type,
  placeholder,
  value,
  onChange,
  dataTestId,
  message,
  error,
}: InputFieldWithMessagesProps) {
  return (
    <div>
      <InputField
        type={type}
        placeholder={placeholder}
        value={value}
        data-testid={dataTestId}
        onChange={onChange}
      />
      {message && (
        <div
          className={classNames({
            'text-green-500': !error,
            'text-red-500': error,
          })}
          data-testid={`${dataTestId}-success-message`}
        >
          {message}
        </div>
      )}
    </div>
  )
}
