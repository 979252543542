import { useContext, useEffect, useState } from 'react'
import SectionCard from '../../atoms/SectionCard'
import ErrorMessage from '../../molecules/ErrorMessage'
import SelectField from 'src/common/forms/SelectField'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'

export function ChangeNSFWConsent() {
  const {
    user,
    ClearCache,
  } = useContext(CurrentUserContext)

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const [userProfileNSFWSetting, setUserProfileNSFWSetting] = useState<string>(
    user?.allowNsfw ? 'Yes' : 'No',
  )

  useEffect(() => {
    const selectionKey = user?.allowNsfw ? 'Yes' : 'No';
    setUserProfileNSFWSetting(selectionKey)
  }, [user])

  const { updateUser } = useUpdateUser()

  function saveChanges(value: string) {
    setUserProfileNSFWSetting(value)
    updateUser({
      allowNSFW: value === 'Yes' ? 'y' : 'n',
    })
      .then(() => {
        ClearCache()
        setMessage('Your content consent has been updated.')
      })
      .catch(() => {
        setError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  return (
    <SectionCard title='Change NSFW Consent'>
      <form>
        <div>
          My User Profile NSFW Content:
        </div>
        <SelectField
          options={{
            'No': 'I DO NOT wish to see NSFW content on my feeds',
            'Yes': 'I opt in to see NSFW content on my feeds',
          }}
          defaultValue={userProfileNSFWSetting}
          onChange={(value) => {
            saveChanges(value)
          }}
        />

        <ErrorMessage
          error={error}
          message={message}
          dataTestId={'change-profile-feed-nsfw'}
        />
      </form>
    </SectionCard>
  )
}
