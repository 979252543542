interface Props extends React.HtmlHTMLAttributes<any> {}

function SpeechBubbleWithExclaimationPoint(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.524"
      height="26.525"
      viewBox="0 0 26.524 26.525"
    >
      <path
        id="Icon_material-feedback"
        data-name="Icon material-feedback"
        {...props}
        d="M28.039,4.167H6.819A2.649,2.649,0,0,0,4.18,6.819L4.167,30.692l5.3-5.3H28.039a2.66,2.66,0,0,0,2.652-2.652V6.819a2.66,2.66,0,0,0-2.652-2.652ZM18.755,20.082H16.1V17.429h2.652Zm0-5.3H16.1v-5.3h2.652Z"
        transform="translate(-4.167 -4.167)"
      />
    </svg>
  )
}

export default SpeechBubbleWithExclaimationPoint
