import { CurrentUserContext } from '../../../providers/AuthProvider'
import { useContext } from 'react'

export default function ProfilePictureWithBanner() {
  const {user} = useContext(CurrentUserContext)

  return (
    <div>
      <div className="h-24 w-24 overflow-visible relative m-3">
        <img className="h-24 w-24 absolute" src={user?.profilePicture} alt={user?.username}/>
        <div className='absolute w-full h-full'>
          <img className="absolute" style={{transform: "scale(1.2)"}} alt={user?.username + ' banner'}
               src={"/images/banners/shard-profile-banner.png"} />
        </div>
      </div>
    </div>
  )
}
