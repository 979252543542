import classnames from 'classnames'
import React, { useState } from 'react'
import { ImageListType, ImageType } from 'react-images-uploading'
import Button from '../forms/Button'
import FileField from '../forms/FileField'
import InputField from '../forms/InputField'
import ToggleSwitch from '../forms/ToggleSwitch'

interface Props {
  placeholder?: string
  buttonContent?: string
  maxLength?: number
  onClick?: (onClickEvent: PostCreateEvent) => void
  showNSFW?: boolean
  allowImages?: boolean
}

export interface PostCreateEvent {
  content: string
  title?: string
  nsfw: boolean
  image?: ImageType
  to?: string
  existingPostId?: string
}

function ShortPostEditor({
  placeholder = 'Hello, World!',
  buttonContent = 'Post',
  maxLength = 500,
  onClick,
  showNSFW = true,
  allowImages = true,
}: Props) {
  const [textContent, setTextContent] = useState('')
  const [nsfw, setNSFW] = useState(false)
  const [files, setFiles] = useState<ImageListType>([])

  const handleChange = (event: any) => {
    // Ensure we don't blow past the max length
    const newTextContent = event.target.value.substring(0, maxLength!)
    setTextContent(newTextContent)
  }

  const handleSubmit = () => {
    if (onClick) {
      onClick({
        content: textContent,
        nsfw,
        image: (files)[0],
      })
      setTextContent('')
      setFiles([])
    }
  }

  return (
    <div className="p-3 bg-background-500 rounded-xl">
      <div className='relative w-full'>
        <InputField
          type="text"
          value={textContent}
          placeholder={placeholder}
          onChange={handleChange}
        />

        {/* <TextualCommandDrawer rawTextInput={textContent} setTextCallback={handleChange}/> */}
      </div>

      {allowImages && (
        <div
          className={classnames(
            'transition-all overflow-hidden items-center w-full',
            {
              'h-0 py-0': textContent.length === 0,
              'h-min py-3': textContent.length > 0,
            },
          )}
        >
          <FileField
            showRemoveAll={false}
            showSubmit={false}
            withPreview={true}
            onChange={(e) => setFiles(e)}
          />
        </div>
      )}

      {showNSFW && (
        <div
          className={classnames(
            'transition-all overflow-hidden flex items-center space-x-3 px-3',
            {
              'h-0 py-0': textContent.length === 0,
              'h-12 py-3': textContent.length > 0,
            },
          )}
        >
          <div>This post contains adult content </div>
          <ToggleSwitch
            label={''}
            disabled={false}
            defaultChecked={nsfw}
            onChange={(e) => setNSFW(!!e.currentTarget.value)}
          />
        </div>
      )}

      <div className="flex justify-end items-center space-x-3 pt-3">
        <div>
          {textContent.length} / {maxLength}
        </div>
        <Button
          trackingEventName={'short-post-editor-submit-post'}
          onClick={handleSubmit}
          className="px-12 py-3 text-base font-medium"
        >
          {buttonContent}
        </Button>
      </div>
    </div>
  )
}

export default ShortPostEditor
