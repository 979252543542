import { useCookies } from 'react-cookie'
import {
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  ApolloLink, concat, ApolloClient,
} from '@apollo/client'
import { Navigate, Outlet } from 'react-router-dom'
import NavBar from '../common/NavBar'
import config from '../config'
import React, { useMemo } from 'react'
import RouteChangeTracker from '../app/RouteChangeTracker'
import omitDeep from 'omit-deep-lodash'
import { AuthProvider } from '../providers/AuthProvider'
import { AxiosProvider } from '../providers/AxiosProvider'
import { MoreUserDetailRequiredModal } from '../common/modals/MoreUserDetailRequiredModal'

const dedupe = (array: any[]) => {
  const seen = new Set()
  const results = array.filter((item) => {
    const k = item['__ref']
    return seen.has(k) ? false : !!seen.add(k)
  })

  return results
}

let previousWindowLocation = window.location.pathname
const mergePaginatedResult = (existing = { items: [] }, incoming: any) => {
  if (previousWindowLocation !== window.location.pathname) {
    previousWindowLocation = window.location.pathname
    return incoming
  }

  return {
    ...incoming,
    items: dedupe([...existing.items, ...incoming.items]),
  }
}

const cache = new InMemoryCache({
  // These are used to tell the cache which data is needed to be kept in memory.
  typePolicies: {
    Query: {
      fields: {
        DashboardFeed: {
          keyArgs: false,
          merge: mergePaginatedResult,
        },
        GetPostsForFeed: {
          keyArgs: false,
          merge: mergePaginatedResult,
        },
        games: {
          keyArgs: false,
          merge: mergePaginatedResult,
        },
        Feeds: {
          keyArgs: false,
          merge: mergePaginatedResult,
        },
      },
    },
  },
})

export function MainLayout() {
  // const [client, setClient] = useState<ApolloClient<any> | undefined>()
  const [cookies] = useCookies([config.cookies.auth_token])

  const gqlClient = useMemo(() => {
    // Apollo Client
    const httpLink = new HttpLink({
      uri: config.graphqlAPI.url,
    })

    const authMiddleware = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = omitDeep(operation.variables, '__typename')
      }
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: cookies.auth_token ? `Bearer ${cookies.auth_token}` : null,
        },
      }))

      return forward(operation)
    })

    const _client = new ApolloClient({
      cache,
      link: concat(authMiddleware, httpLink),
      connectToDevTools: true,
    })
    return _client
  }, [cookies])



  // if (!client) {
  //   return (
  //     <div>
  //       <Spinner />
  //     </div>
  //   )
  // }

  return cookies.auth_token ? (
    <AxiosProvider>
      <ApolloProvider client={gqlClient as any}>
        <AuthProvider>
          <RouteChangeTracker />
          <MoreUserDetailRequiredModal />
          <NavBar>
            <Outlet />
          </NavBar>
        </AuthProvider>
      </ApolloProvider>
    </AxiosProvider>
  ) : (
    <Navigate to='/about' />
  )
}

export default MainLayout
