import { gql } from '@apollo/client/core'
import { SubscriptionTier, ProfileModule, Post } from '../types'
import { FollowedGame } from '../types/game/followed-game.type'
import { StandardisedResponse } from '../types/StandardisedResponse.type'
import { LoginAttempt } from '../types/users/login-attempt.type'

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    DeleteUser(id: $id)
  }
`

export interface DeleteUserQuery {
  DeleteUser: boolean
}

export interface DeleteUserVariables {
  id: string
}

export const CHECK_LOCAL_USER_SUBSCRIPTION = gql`
  query CheckLocalUserSubscription {
    User(username: null) {
      Subscription
    }
  }
`

export interface CheckLocalUserSubscriptionQuery {
  User: { Subscription: SubscriptionTier }
}

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $newPassword: String!
    $username: String!
    $code: String
  ) {
    ChangePassword(newPassword: $newPassword, username: $username, code: $code)
  }
`

export interface ChangePasswordQuery {
  ChangePassword: boolean
}

export interface ChangePasswordVariables {
  newPassword: string
  username: string
  code?: string
}

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: String!
    $username: String
    $email: String
    $confirmed: Boolean
    $role: String
    $privacy: Float
    $banner: String
    $profilePicture: String
    $description: String
    $country: String
    $tagline: String
    $dob: String
    $updates: Boolean
    $allowNsfw: Boolean
    $profileModules: [ProfileModuleInput!]
    $followedGames: [FollowedGameInput!]
  ) {
    UpdateUser(
      id: $id
      username: $username
      email: $email
      confirmed: $confirmed
      role: $role
      privacy: $privacy
      banner: $banner
      profilePicture: $profilePicture
      description: $description
      country: $country
      tagline: $tagline
      dob: $dob
      updates: $updates
      allowNsfw: $allowNsfw
      profileModules: $profileModules
      followedGames: $followedGames
    )
  }
`

export interface UpdateUserQuery {
  UpdateUser: string
}

export interface UpdateUserVariables {
  id: string
  username?: string
  email?: string
  confirmed?: boolean
  role?: string
  privacy?: string // NB: Changed from number to string to fix compilation for new api
  banner?: string
  profilePicture?: string
  description?: string
  country?: string
  tagline?: string
  dob?: string
  updates?: boolean
  allowNsfw?: boolean
  profileModules?: ProfileModule[]
  followedGames?: FollowedGame[]
}

export const GET_USERS = gql`
  query GetUsers($limit: Float, $search: String, $page: Float) {
    Users(limit: $limit, search: $search, page: $page) {
      _id
      username
      email
      passwordDigest
      confirmed
      privacy
      banner
      profilePicture
      description
      country
      tagline
      dob
      updates
      allowNsfw
      role {
        name
      }
      previousUsernames {
        username
        updatedAt
      }
      achievements {
        name
        description
      }
      followers {
        username
      }
      profileModules {
        module
      }
    }
  }
`

export interface GetUsersQuery {
  Users: Post[]
}

export interface GetUsersVariables {
  limit: number
  search: string
  page: number
}

export const GET_USER = gql`
  query GetUser($username: String) {
    User(username: $username) {
      _id
      username
      privacy
      banner
      profilePicture
      description
      country
      tagline
      dob
      updates
      allowNsfw
      Subscription
      verified
      followedGames {
        IGDBGameId
        favorite
      }
      role {
        name
      }
      previousUsernames {
        username
        updatedAt
      }
      achievements {
        name
        description
      }
      followers {
        _id
        username
        profilePicture
      }
      profileModules {
        module
        position
      }
      TotalShards
      TotalFollowers
      userProfileFeed {
        _id
        createdBy
        name
        description
        website
        category
        coverImage
        iconImage
        bannerImage
        location
        events {
          name
          description
          start
          end
          allDay
          singleDay
          timeszone
          repeat
          repeatEndDate
          repeatEndTimes
          title
          location
          frequency
        }
        socialPlatforms {
          _id
          name
          platform
          value
          pinned
        }
        featured
        feedType
        totalViews
        archived
        privacy
        nsfw
        tags
        IsSubscribed
        IsOwner
      }
      PopulatedFollowedGames {
        artwork
        title
        id
      }
    }
  }
`

export interface GetUserQuery {
  User: Post
}

export interface GetUserVariables {
  username?: string
}

export const TOGGLE_FOLLOW = gql`
  mutation toggleFollow($feedId: String!, $userId: String) {
    ToggleFollow(feedId: $feedId, userId: $userId)
  }
`

export interface ToggleFollowQuery {
  ToggleFollow: boolean
}

export interface ToggleFollowVariables {
  feedId: string
  userId?: string
}

export const CREATE_USER = gql`
  mutation CreateUser(
    $username: String
    $email: String
    $password: String
    $marketingUpdates: Boolean
    $dob: String!
    $code: String!
  ) {
    CreateUser(
      username: $username
      email: $email
      password: $password
      marketingUpdates: $marketingUpdates
      dob: $dob
      code: $code
    ) {
      username
      email
    }
  }
`

export interface CreateUserQuery {
  CreateUser: Post
}

export interface CreateUserVariables {
  username: string
  email: string
  password: string
  marketingUpdates: boolean
  dob: string
  code: string
}

export const ACTIVATE_USER = gql`
  mutation ActivateUser($code: String!) {
    ActivateUser(code: $code)
  }
`

export interface ActivateUserQuery {
  ActivateUser: Post
}

export interface ActivateUserVariables {
  code: string
}

export const LOGIN_USER = gql`
  query Login($email: String!, $password: String!) {
    Login(email: $email, password: $password) {
      error
      reason
      success
      token
    }
  }
`

export interface LoginVariables {
  email: string
  password: string
}

export interface LoginQuery {
  Login: LoginAttempt
}

export const FORGOT_PASSWORD = gql`
  query ForgotPassword($email: String!) {
    ForgotPassword(email: $email) {
      message
      success
    }
  }
`

export interface ForgotPasswordVariables {
  email: string
}

export interface ForgotPasswordQuery {
  ForgotPassword: StandardisedResponse
}

