import React, { useMemo } from 'react'
import { CharLimit, StripHTMLTags } from '../app/helpers/string-helpers'

const urlRegex = /(https?:\/\/[^\s]+)/g;
export const TextPreprocessor = ({ text, stripHTML = true, charLimit }: { text: string, stripHTML?: boolean, charLimit?: number }) => {
  const parts = useMemo(() => {
    let originalText = text
    let newText = text
    if (stripHTML) {
      originalText = StripHTMLTags(originalText)
      newText = StripHTMLTags(newText)
    }

    if (charLimit) {
      newText = CharLimit(newText, charLimit)
    }

    // zip original text and new text together into one object
    const originalParts = originalText.split(urlRegex)
    const newParts = newText.split(urlRegex)

    return originalParts.map((part, index) => {
      return {
        original: part,
        new: newParts[index],
      }
    })
  }, [charLimit, stripHTML, text]);


  return (
    <div>
      {parts.map(({ new: part, original }, index) => {
        if (part.match(urlRegex)) {
          // TODO: Bring these styles back up the chain
          return (
            <a key={index} href={original} target="_blank" rel="noopener noreferrer" className={'text-primary-200 hover:text-primary-500'}>
              {part}
            </a>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    </div>
  );
};
