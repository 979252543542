function StadiaLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.066"
      height="18.711"
      viewBox="0 0 28.066 18.711"
    >
      <path
        id="google-stadia-1"
        style={{ fill: '#fff' }}
        d="M.234,5.459A.564.564,0,0,0,0,5.915v0a.574.574,0,0,0,.047.222l2.69,6.091a.56.56,0,0,0,.7.3c1.759-.627,7.85-2.61,11.793-1.544A14.923,14.923,0,0,0,7.7,14.025a.56.56,0,0,0-.164.669c.29.655.872,1.974,1.2,2.718L9.2,18.5a.352.352,0,0,0,.618.051,7.337,7.337,0,0,1,3.593-2.433,20.55,20.55,0,0,1,4.3-1.226,18.627,18.627,0,0,1,4.758-.2.566.566,0,0,0,.589-.393l1.216-3.878a.561.561,0,0,0-.211-.62c-1.352-.982-6.713-4.273-16.139-2.952,0,0,8.046-4.617,18.256.458a.566.566,0,0,0,.788-.337l1.069-3.42a.644.644,0,0,0,.023-.164V3.378a.555.555,0,0,0-.285-.48A24.309,24.309,0,0,0,16.193,0,27.1,27.1,0,0,0,.234,5.459"
      />
    </svg>
  )
}

export default StadiaLogo
