import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useNavigate } from 'react-router-dom'
import config from 'src/config'
import EmberLogo from '../../common/EmberLogo'
import Footer from '../../common/Footer'
import Button from '../../common/forms/Button'
import BurgerMenuIcon from '../../common/icons/BurgerMenuIcon'

function AboutPage() {
  const [cookies] = useCookies([config.cookies.auth_token])
  const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false)
  const toggleMobileOptions = () => {
    setShowMobileOptions(!showMobileOptions)
  }
  const navigate = useNavigate()

  useEffect(() => {
    if (cookies.auth_token) {
      navigate('/dashboard')
    }
  }, [cookies, navigate])

  const bgMask = (
    <div
      className="absolute h-screen w-full"
      style={{ backgroundColor: 'rgba(47, 49, 67, 0.7)' }}
    />
  )

  function backgroundImageSection(
    imagePath: string,
    children: React.ReactNode,
    desktopOnly: boolean = false,
  ) {
    return (
      <section
        className={classNames('h-screen', { 'hidden lg:block': desktopOnly })}
        style={{
          backgroundImage: `url(${imagePath})`,
          backgroundSize: 'cover',
        }}
      >
        {bgMask}
        <div className="container h-full mx-auto relative">{children}</div>
      </section>
    )
  }

  const mobileNav = (
    <div className="lg:hidden flex flex-col w-full bg-slate-800 fixed top-0 p-2 z-50">
      <div className="flex justify-end">
        <button
          className="border-solid border-steel text-steel border rounded p-1"
          onClick={() => toggleMobileOptions()}
        >
          <BurgerMenuIcon />
        </button>
      </div>
      <div
        className={classNames(
          'flex flex-col gap-2 text-lg transition-height ease-in-out duration-500 overflow-hidden',
          { 'h-0': !showMobileOptions, 'h-24': showMobileOptions },
        )}
      >
        <Link className="text-steel hover:text-white" to="/login">
          Login
        </Link>
        <a
          className="text-steel hover:text-white"
          href="https://discord.com/invite/ZMk93b9"
          target={'_blank'}
          rel="noreferrer"
        >
          Discord
        </a>
        <a
          className="text-steel hover:text-white"
          href="mailto:contact@emberapp.gg"
          target={'_blank'}
          rel="noreferrer"
        >
          Contact Us
        </a>
      </div>
    </div>
  )

  const navBar = (
    <div className="hidden lg:block w-full fixed top-0 z-50">
      <div className="flex justify-end text-lg">
        <div className="p-3">
          <Link className="text-steel hover:text-white" to="/login">
            Login
          </Link>
        </div>
        <div className="p-3">
          <a
            className="text-steel hover:text-white"
            href="https://discord.com/invite/ZMk93b9"
            target={'_blank'}
            rel="noreferrer"
          >
            Discord
          </a>
        </div>
        <div className="p-3 mr-3">
          <a
            className="text-steel hover:text-white"
            href="mailto:contact@emberapp.gg"
            target={'_blank'}
            rel="noreferrer"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )

  const WelcomeToEmber = backgroundImageSection(
    'https://ember-assets.s3-eu-west-1.amazonaws.com/brand/backgrounds/E_A_00.jpg',
    <div className="w-100 h-screen flex items-center justify-center flex-col">
      <EmberLogo width={200} />
      <div>
        <h1 className="text-5xl font-bold text-white text-center">
          Welcome to Ember
        </h1>
      </div>
      <div>
        <h2 className="text-3xl mt-5 text-white text-center">
          Our platform brings gamers together
        </h2>
      </div>
    </div>,
  )

  const whatIsEmber = backgroundImageSection(
    '/images/landing-page/Landing-page-2.png',
    <div className="ml-20 text-white max-w-md h-screen flex items-start justify-center space-y-9 flex-col">
      <h2 className="text-3xl">What is Ember?</h2>
      <p>
        Ember is a new social media network that is built for the modern gamer.
        Online communities are the underpinning of an industry worth an
        estimated £180b*. From fandoms to clans. We are bridging the gap so all
        gamers regardless of platform can chat, share, meet, and grow.
      </p>
      <p>
        Read more about Ember at&nbsp;
        <a
          href="https://labs.uk.barclays/gamestech/resource-hub/how-ember-wants-to-unite-gaming-communities"
          target="_blank"
          className="text-red-400 hover:text-red-400"
          rel="noreferrer"
        >
          Barclays
        </a>
      </p>
    </div>,
  )

  type SingleBulletProps = {
    start: string
    middle: string
    end: string
    title: string
    description: string
  }
  const singleBullet = ({
    start,
    middle,
    end,
    title,
    description,
  }: SingleBulletProps) => (
    <div className="flex flex-col space-y-3">
      <div
        className="rounded-full h-16 mh-16 mw-16 w-16"
        style={{
          background: `linear-gradient(180deg, ${start} 0%, ${middle} 37%, ${end} 100%)`,
        }}
      />
      <h3 className="text-3xl text-white">{title}</h3>
      <p className="text-steel">{description}</p>
    </div>
  )

  const bulletPoints = backgroundImageSection(
    '/images/landing-page/Landing-page-3.png',
    <div className="grid grid-cols-4 gap-6 place-content-center h-full mx-20">
      {singleBullet({
        start: '#97DECE',
        middle: '#4EDEBD',
        end: '#2698EE',
        title: 'Join communities',
        description:
          'Using Ember rewards you with ways to personalise your gaming identity.',
      })}
      {singleBullet({
        start: '#E993F6',
        middle: '#E946F2',
        end: '#E24F57',
        title: 'Express your avatar',
        description:
          'We host communities dedicated to your favourite games and content creators.',
      })}
      {singleBullet({
        start: '#9BC8D7',
        middle: '#35AFD7',
        end: '#4771EF',
        title: 'Show achievements',
        description:
          'Did you spark a massive discussion? Wear that badge proud.',
      })}
      {singleBullet({
        start: '#C0D8A8',
        middle: '#99D859',
        end: '#3CC5A1',
        title: 'Watch content',
        description:
          'Streaming has become a massive part of gaming. Join in the discussion on your favourite games, or design your own, then build a fan-base for it via Ember.',
      })}
      {singleBullet({
        start: '#ED8D62',
        middle: '#ED5262',
        end: '#9D176B',
        title: 'Find your niche',
        description:
          'Online personas go beyond gaming. They cater to artists, writers, entertainers and content creators.',
      })}
      {singleBullet({
        start: '#CC83EF',
        middle: '#B747EF',
        end: '#6635D7',
        title: 'Integrate Platforms',
        description:
          'We pull the best parts of existing platforms. Including achievements, live streams and galleries.',
      })}
      {singleBullet({
        start: '#F6A43E',
        middle: '#F6823F',
        end: '#ED5262',
        title: 'Merge your profiles',
        description:
          'Want your friends to easily know which consoles you play? - No problem.',
      })}
      {singleBullet({
        start: '#77A8D9',
        middle: '#777CD9',
        end: '#6E35D7',
        title: 'Find events',
        description:
          'We provide the tools to organise real-life events, from humble meet-ups to fully fledged conventions.',
      })}
    </div>,
    true,
  )

  const signUpForBeta = backgroundImageSection(
    '/images/landing-page/Landing-page-4.png',
    <div className="text-white h-screen flex items-end justify-center flex-col">
      <div className="w-72 lg:w-96 space-y-9">
        <h2 className="text-3xl lg:text-5xl">Sign up for BETA</h2>
        <p>
          Ember  is currently in development, we're an indie team working hard to  deliver our vision for Ember, If you would like to
          register and help us build ember, please register below.
        </p>
        <div>
          <Link className="text-steel hover:text-white" to="/login">
            <Button trackingEventName={'about-page-register-button'}>
              Get started
            </Button>
          </Link>
        </div>
      </div>
    </div>,
  )

  return (
    <main>
      {mobileNav}
      {navBar}

      {WelcomeToEmber}

      {whatIsEmber}

      {bulletPoints}

      {signUpForBeta}

      <Footer />
    </main>
  )
}

export default AboutPage
