

const config = {
  app: {
    version: process.env.RELEASE_VERSION || process.env.SHORT_SHA || 'local',
  },
  graphqlAPI: {
    url:
      process.env.REACT_APP_EMBER_GRAPHQL_URL ??
      'http://localhost:3333/graphql',
    apikey: process.env.REACT_APP_EMBER_GRAPHQL_API_KEY,
  },
  restAPI: {
    url: process.env.REACT_APP_EMBER_REST_URL ?? 'http://localhost:3333/api/v1',
    apikey: process.env.REACT_APP_EMBER_GRAPHQL_API_KEY,
  },
  googleAnalytics: {
    trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID ?? 'G-X5RVMVW8YF',
  },
  cookies: {
    auth_token: 'auth_token',
    refresh_token: 'refresh_token',
    has_views_writers_block_recovery_tutorial: 'viewed_writers_block_recovery_journey',

  },
  stripe: {
    publicKey:
      process.env.STRIPE_PUBLIC_KEY ??
      'pk_test_51Im380JN2ZegEJXu5zv3gDndlpjXZYX5TeZV9E3VGdH7i7fjBNKxJqY1gdXMEKMkhIZssFG8SV8iAFysFThqGJyv008GGOCD4T',
  },
}

export default config
