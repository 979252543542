interface Props extends React.PropsWithChildren<any> {
  url: string
}

function GradientBackgroundContainer({ children, url }: Props) {
  return (
    <div className="w-full bg-darkbackground-500 text-white overflow-hidden">
      <div
        className="absolute top"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(25, 28, 41, 1), rgba(25, 28, 41, 0.6)), url(${url})`,
          height: '600px',
          width: 'calc(100vw - 10px)', // to prevent hoirzontal scrollbar
          top: '0px',
          left: '0px',
          opacity: '0.5',
          backgroundSize: 'cover',
          backgroundPosition: '50% 0%',
        }}
      />
      {children}
    </div>
  )
}

export default GradientBackgroundContainer
