import gql from 'graphql-tag';

export const GET_TOTAL_FOLLOWERS = gql`
  query GetTotalFollowers {
    User {
      totalFollowers
    }
  }
`;

export interface GetTotalFollowersQuery {
  User: { totalFollowers: number };
}
