import config from '../../config'
import { useCookies } from 'react-cookie'

export default function useLogout() {
  const [, , removeCookie] = useCookies([config.cookies.auth_token])

  function Logout() {
    console.log('removing cookie', config.cookies.auth_token)
    removeCookie(config.cookies.auth_token, {
      path: '/',
    })
  }

  return Logout
}
