import {
  ProfileModule as APIProfileModule,
  ProfileModuleType,
  User,
  Feed,
} from '../../api/types'
import Spinner from '../../common/Spinner'
import EmptyModule from './profile-modules/EmptyModule'
import GamesModule from './profile-modules/GamesModules'
import RecentBlock from './profile-modules/RecentBlock'
import SocialLinks from './profile-modules/SocialLinks'

interface Props {
  module: APIProfileModule | null
  userQuery: User
  feedData: Feed
  userId: string
}

function ProfileModule({ module, userQuery, feedData }: Props) {
  const { userProfileFeed, PopulatedFollowedGames } = userQuery
  if (!userProfileFeed) {
    return <Spinner />
  }

  const ResolveModule = () => {
    switch (module?.module) {
      case ProfileModuleType.Games:
        return GamesModule({ games: PopulatedFollowedGames })
      case ProfileModuleType.RecentBlock:
        return RecentBlock({ username: userProfileFeed.name })
      case ProfileModuleType.SocialPlatforms:
        return SocialLinks({ feedData })
      default:
        return EmptyModule()
    }
  }

  return ResolveModule()
}

export default ProfileModule
