import type { Game } from '../../../api/types'
import GameCard from '../../games/GameCard'

interface Props {
  games: Game[]
}

function GamesModule({ games }: Props) {
  const GameGrid = () => {
    if ((games ?? []).length === 0) {
      return (
        <div className="w-full col-span-2 flex items-center justify-center text-center h-64">
          This user is not following any games
        </div>
      )
    }

    // Limit this array to be at most 6 long
    const gamesToShow = games.slice(0, 6)
    return gamesToShow.map((game) => <GameCard key={game.id} game={game} />)
  }

  return (
    <div className="grid grid-cols-2 gap-4 mt-3 p-3 bg-background-500 rounded-xl">
      <div className="text-white pb-3 text-2xl font-medium col-span-2">
        Games
      </div>
      {GameGrid()}
    </div>
  )
}

export default GamesModule
