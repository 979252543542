import { PostCreateEvent } from 'src/common/command-palette/ShortPostEditor'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../helpers/RestApiRoutes'
import { Post } from '../../api/types'
import { useBoolean } from '@chakra-ui/react'
import { nanoid } from 'nanoid'

interface CreatePost {
  loading: boolean
  error: string
  create: (event: PostCreateEvent) => Promise<Post>
}

export default function useCreatePost(props: {
  feedId: string
}): CreatePost {
  const { user } = useContext(CurrentUserContext)
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean(false)
  const [error, setError] = useState('')

  const create = async ({ content, image, nsfw, title, to, existingPostId }: PostCreateEvent) => {
    setError('')
    if (!user) {
      setError('User not logged in. Cannot create post without a user.')
      throw new Error('User not logged in. Cannot create post without a user.')
    }

    if (props.feedId === '') {
      setError('Feed ID not set. Cannot create post without a feed ID.')
      throw new Error('Feed ID not set. Cannot create post without a feed ID.')
    }

    setLoading.on()

    // find any text prefixed with a # and append it to a tags array
    const matchedTags = content.match(/#[^\s]+/g) || []
    const tagString = matchedTags.map((tag) => tag.substring(1)).join(',')

    var f = new FormData();
    f.append("content", content)
    f.append("nsfw", nsfw ? "true" : "false")
    f.append("feedId", props.feedId)
    if (title) f.append("title", title)
    if (tagString.length > 0) f.append("tags", tagString)
    if (false) f.append("sharedPost", "") // TODO pass along post for sharing posts
    if (to && to !== '') f.append("postIdToReplyTo", to) // Post in which we are replying to

    if (image) {
      f.append("image", image.file as Blob, image.file?.name ?? nanoid()) // TODO: test uploading images, suspect this will break as there path requirements will change to post.<memberVariableName>
    }

    try {
      let data: Post;

      // If we have an existing post id, we are editing a post
      if (existingPostId) {
        f.append("postId", existingPostId)
        const res = await client.put<Post>(Routes.CreatePost(), f, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        data = res.data

      } else {
        // Otherwise we are creating a new post
        const res = await client.post<Post>(Routes.CreatePost(), f, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        data = res.data
      }

      return data
    } catch (e: any) {
      setError(e.message)
      throw e;
    } finally {
      setLoading.off()
    }
  }

  return {
    loading,
    error,
    create
  }
}
