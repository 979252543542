import React from 'react'
import Spinner from 'src/common/Spinner'
import { useTrendingTags } from '../../app/hooks/useTrendingTags'

export default function TrendingOnEmber() {
  const {loading, tags} = useTrendingTags("global")

  if (loading) {
    return (
      <div className="bg-background-500 p-5 rounded-3xl flex flex-col text-white font-semibold">
        <div className="text-2xl font-semibold pb-3">Trending</div>
        <hr className="border-t-darkbackground-500" />
        <div className="text-center my-3">
          <Spinner />
        </div>
      </div>
    )
  }

  if (!tags || !tags.length) {
    return (
      <div className="bg-background-500 p-5 rounded-3xl flex flex-col text-white font-semibold">
        <div className="text-2xl font-semibold pb-3">Trending</div>
        <hr className="border-t-darkbackground-500" />
        <div className="text-center my-3">No trending tags at the moment</div>
      </div>
    )
  }

  return (
    <div className="bg-background-500 px-5 pt-5 rounded-3xl flex flex-col text-white font-semibold">
      <div className="text-2xl font-semibold pb-3">Trending</div>
      {tags.map((tag: string, index: number) => (
        <React.Fragment key={index}>
          <hr className="border-t-darkbackground-500" />

          <div className="py-4">
            <span className="text-red-500 text-2xl pr-3">#</span>
            <span>{tag}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
