function InputField({ ...props }: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className="flex flex-col justify-center items-center w-full">
      <input
        className="w-full
        transition
        ease-in-out
        text-lg
        font-normal
        h-12
        p-2
        rounded-lg
      bg-darkbackground-500 
      placeholder:text-gray-600
      text-steel
        focus:outline-none
        border-2
        border-transparent 
        focus:border-2
        focus:border-primary"
        {...props}
      />
    </div>
  )
}

export default InputField
