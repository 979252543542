import classnames from 'classnames'
import Button from './forms/Button'
import CloseCrossButton from './forms/CloseCrossButton'

interface Props extends React.PropsWithChildren<any> {
  title: string
  isOpen: boolean
  confirmText?: string
  cancelText?: string
  showApprove?: boolean
  showCancel?: boolean
  onConfirm?: () => void
  onClose?: () => void
  onCancel?: () => void
}

function Modal({
  children,
  onConfirm,
  onClose,
  onCancel,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  showApprove = true,
  showCancel = true,
  title,
  isOpen,
}: Props) {
  if (!isOpen) return null
  return (
    // <!-- Main modal -->

    <div
      id="defaultModal"
      data-testid="default-modal"
      tabIndex={-1}
      aria-hidden="true"
      role={'dialog'}
      className={classnames(
        'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  w-full md:inset-0 h-modal md:h-full flex justify-center items-center bg-background-500 bg-opacity-80 ',
      )}
    >
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto ">
        {/* <!-- Modal content --> */}
        <div className="relative bg-foreground-500 rounded-lg shadow">
          {/* <!-- Modal header --> */}
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {title}
            </h3>
            <CloseCrossButton onClick={onClose} />
          </div>
          {/* <!-- Modal body --> */}
          <div
            className="p-6 space-y-6 h-3/4"
            style={{
              maxHeight: '60vh',
            }}
          >
            {children}
          </div>
          {/* <!-- Modal footer --> */}
          <div className="flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            {showApprove && (
              <Button
                trackingEventName={'modal-confirm'}
                data-modal-toggle="defaultModal"
                color="primary"
                data-testid="modal-confirm-button"
                onClick={() => {
                  onConfirm && onConfirm()
                  onClose && onClose()
                }}
              >
                {confirmText}
              </Button>
            )}

            {showCancel && (
              <Button
                trackingEventName={'modal-cancel'}
                color="danger"
                type="button"
                data-testid="modal-cancel-button"
                onClick={() => {
                  onCancel && onCancel()
                  onClose && onClose()
                }}
              >
                {cancelText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
