import { Link } from 'react-router-dom'
import { Feed } from '../../api/types'
import ShardIcon from '../../common/icons/ShardIcon'
import Image from '../../common/forms/Image'

interface Props {
  feed: Feed
}
function WritersBlockFeedCard({ feed }: Props) {
  return (
    <Link
      to={`/writers-block/${feed.slug}`}
      className="col-span-1 auto-rows-max"
    >
      <div className="mt-16" style={{ height: 'calc(100% - 4rem)' }}>
        <div className="bg-background-500 px-5 rounded-lg h-full">
          <div style={{ maxWidth: 120 }} className="absolute">
            <Image
              src={feed.iconImage ?? '#'}
              alt={`${feed.name}'s icon`}
              className="w-full rounded-lg object-cover object-center -mt-16"
              style={{ width: 120, height: 120 }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/images/games/placeholder.png'
              }}
            />
          </div>
          <div className="w-100 flex flex-col pt-16 h-full">
            <div className="text-white text-lg mt-2">{feed.name}</div>
            <div className="text-cyan-400 text-sm mb-4">
              {feed.tags === '' ? 'General' : feed.tags}
            </div>
            <div className="text-gray-500 mb-8">{feed.description}</div>
            <div className="flex space-x-3 mb-3 mt-auto items-center">
              <ShardIcon /> <div>{feed.totalShards}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default WritersBlockFeedCard
