interface Props extends React.HtmlHTMLAttributes<any> {}

function StickyNote(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.774"
      height="22.775"
      viewBox="0 0 22.774 22.775"
    >
      <path
        id="Path_677"
        data-name="Path 677"
        {...props}
        d="M23.244,3H5.531A2.53,2.53,0,0,0,3,5.531V23.244a2.53,2.53,0,0,0,2.531,2.531H23.244a2.538,2.538,0,0,0,2.531-2.531V5.531A2.53,2.53,0,0,0,23.244,3Zm0,20.244H5.531V8.061H23.244Zm-2.531-8.857H8.061V11.857H20.714Zm-5.061,5.061H8.061V16.918h7.592Z"
        transform="translate(-3 -3)"
      />
    </svg>
  )
}

export default StickyNote
