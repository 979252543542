import { Feed } from '../../api/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

interface FindFeeds {
  loading: boolean
  total: number
  page: number
  setPage: (n: number) => void
  pageSize: number
  setPageSize: (n: number) => void
  hasMore: boolean
  items: Feed[]
  Search: (page: number, pageSize: number, archived: boolean, privacy: string, userId: string) => Promise<void>
}

export default function useFindFeeds(feedType: string): FindFeeds {
  const client = useContext(AxiosContext)
  const toast = useToast()
  const [loading, setLoading] = useBoolean()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [response, setResponse] = useState<APIPaginatedResponse<Feed>>({
    hasMore: false,
    offset: 0,
    pageSize: 0,
    payload: [],
    total: 0
  })
  const [lastArgs, setLastArgs] = useState<[boolean, string, string]>([false, "", ""])

  const SearchForFeeds = useCallback(async (page: number, pageSize: number, archived: boolean, privacy: string, userId: string) => {
    setLastArgs([archived, privacy, userId])
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<Feed>>(Routes.GetFeeds(page, pageSize, feedType, archived, privacy, userId))
      if (data) {
        setResponse(data)
      }
    } catch (e) {
      toast({
        title: 'Search failed',
        description: 'Something went wrong while searching for feeds',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading.off()
  }, [client, feedType, setLoading, toast])

  // Leaving out last args as we don't want an infinite re-run
  useEffect(() => {
    void SearchForFeeds(page, pageSize, ...lastArgs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchForFeeds, page, pageSize])

  return {
    Search: SearchForFeeds,
    hasMore: response.hasMore,
    items: response.payload ?? [],
    total: response.total,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize
  }
}