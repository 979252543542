import ShortPostEditor, {
  PostCreateEvent,
} from '../../common/command-palette/ShortPostEditor'
import { useNavigate } from 'react-router-dom'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useContext } from 'react'
import useCreatePost from '../../app/hooks/useCreatePost'

/**
 * Compose Page is a mobile only page where the full screen is a short post editor
 */
function ComposePage() {
  const { user } = useContext(CurrentUserContext)
  const { create: createPost } = useCreatePost({
    feedId: user?.userProfileFeed?.id ?? '',
  })
  const navigate = useNavigate()

  const PostOnFeed = async ({
    content,
    image,
    nsfw,
  }: PostCreateEvent) => {
    if (!user || !user.userProfileFeed?.slug) {
      return
    }

    await createPost({
      content: content,
      nsfw: nsfw,
      image: image,
    })

    navigate(`/profile/${user.username}/feed`)
  }

  return <ShortPostEditor onClick={PostOnFeed} />
}

export default ComposePage
