export default function SparkleIcon(props: React.HtmlHTMLAttributes<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.637"
      height="20.637"
      viewBox="0 0 20.637 20.637"
      {...props}
    >
      <g
        id="Group_1410"
        data-name="Group 1410"
        transform="translate(-8.18 -8.068)"
      >
        <path
          id="Path_449"
          data-name="Path 449"
          d="M98.88,93.388a4.575,4.575,0,0,1-3.245-1.333,4.508,4.508,0,0,1-1.342-3.222.6.6,0,1,0-1.209,0,4.508,4.508,0,0,1-1.342,3.222A4.574,4.574,0,0,1,88.5,93.388a.6.6,0,1,0,0,1.209,4.583,4.583,0,0,1,4.587,4.568.6.6,0,1,0,1.209,0A4.582,4.582,0,0,1,98.88,94.6a.6.6,0,1,0,0-1.209Z"
          transform="translate(-70.668 -71.065)"
          fill="currentcolor"
        />
        <path
          id="Path_450"
          data-name="Path 450"
          d="M16.452,62.265a3.221,3.221,0,0,1-3.229-3.206.6.6,0,1,0-1.209,0,3.221,3.221,0,0,1-3.229,3.206.6.6,0,1,0,0,1.209,3.226,3.226,0,0,1,3.229,3.216.6.6,0,1,0,1.209,0,3.226,3.226,0,0,1,3.229-3.216.6.6,0,1,0,0-1.209Z"
          transform="translate(0 -44.669)"
          fill="currentcolor"
        />
        <path
          id="Path_451"
          data-name="Path 451"
          d="M85.024,12.246A2.369,2.369,0,0,1,87.4,14.6a.6.6,0,1,0,1.209,0,2.372,2.372,0,0,1,2.375-2.364.6.6,0,1,0,0-1.209,2.369,2.369,0,0,1-2.375-2.357.6.6,0,1,0-1.209,0,2.372,2.372,0,0,1-2.375,2.364.6.6,0,1,0,0,1.209Z"
          transform="translate(-67.588 0)"
          fill="currentcolor"
        />
      </g>
    </svg>
  )
}
