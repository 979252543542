import { Post } from '../../api/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

interface GetPosts {
  loading: boolean
  total: number
  page: number
  setPage: (n: number) => void
  pageSize: number
  setPageSize: (n: number) => void
  hasMore: boolean
  items: Post[]
  GetReplies: (postId: string, page: number, pageSize: number) => Promise<void>
}

export default function useGetReplies(postId: string): GetPosts {
  const client = useContext(AxiosContext)
  const toast = useToast()
  const [loading, setLoading] = useBoolean()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [response, setResponse] = useState<APIPaginatedResponse<Post>>({
    hasMore: false,
    offset: 0,
    pageSize: 0,
    payload: [],
    total: 0
  })


  const GetReplies = useCallback(async (postId: string, page: number, pageSize: number) => {
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<Post>>(Routes.GetRepliesForPost(postId, page, pageSize))
      setResponse(data)
    } catch (e: any) {
      toast({
        title: 'Getting replies failed',
        description: 'Something went wrong while trying to get replies for post',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading.off()
  }, [client, setLoading, toast])

  // Leaving out last args as we don't want an infinite re-run
  useEffect(() => {
    void GetReplies(postId, page, pageSize)
  }, [GetReplies, postId, page, pageSize])

  return {
    GetReplies: GetReplies,
    hasMore: response.hasMore,
    items: response.payload ?? [],
    total: response.total,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize
  }
}