import classnames from 'classnames'
import { useState } from 'react'

interface Props {
  options: string[]
  value: string
  onChange: (value: string) => void
  className?: string
}

function LinearPicker({ options, value, onChange, className }: Props) {
  const [selected, setSelected] = useState(value)

  return (
    <div className={`bg-background-500 flex rounded-lg p-2 ${className}`}>
      {options.map((option) => (
        <div
          key={option}
          className={classnames(
            'flex-1 text-center rounded-lg py-4 text-xl transition-all ease-in-out duration-200 capitalize cursor-pointer',
            {
              'text-white': option === selected,
              'bg-primary-500': option === selected,
            },
          )}
          onClick={() => {
            setSelected(option)
            onChange(option)
          }}
        >
          {option}
        </div>
      ))}
    </div>
  )
}

export default LinearPicker
