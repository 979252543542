import classNames from 'classnames'
import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../common/forms/Button'
import Image from '../../common/forms/Image'
import InputField from '../../common/forms/InputField'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useGetConversation } from '../../app/hooks/UseGetConversation'
import { useGetMessages } from '../../app/hooks/UseGetMessages'
import { useCreateMessage } from '../../app/hooks/UseCreateMessage'

function MessagingConversationPage() {
  const { conversation: conversationId } = useParams()
  const localUser = useContext(CurrentUserContext)
  const [currentMessage, setCurrentMessage] = useState<string>('')

  const { conversation, error: conversationError, loading: conversationLoading} = useGetConversation(conversationId!);
  const { getMessages, messages, error: messageError, loading: messsagesLoading } = useGetMessages(conversationId!);
  const { sendMessage } = useCreateMessage(conversationId!)

  const bottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [conversation])

  if (conversationId === '') {
    return <div>No conversation selected</div>
  }

  if (conversationError || messageError) {
    return (
      <div className="text-steel text-center">
        <div>Something went wrong</div>
        <div>{messageError || conversationError}</div>
      </div>
    )
  }

  if (conversationLoading || messsagesLoading) {
    return <div className="text-steel text-center">Loading...</div>
  }

  async function SendMessage() {
    if (currentMessage.trim() === '') {
      return
    }

    await sendMessage(currentMessage)
    setCurrentMessage('')
    void getMessages(conversationId!)
  }

  return (
    <div
      className="h-full relative overflow-y-scroll"
      style={{ maxHeight: 'calc(100vh - 72px)' }}
    >
      <div className="flex flex-col gap-3  pb-20 lg:pb-0">
        {messages.total === 0 && (
          <div className="text-steel text-center">
            Be the first to send a message!
          </div>
        )}
        {(messages.payload ?? []).map((message, index) => {
          const wasLocalUser = message.senderId === localUser.user?.id

          return (
            <div
              key={index}
              className={classNames('flex gap-3', {
                'flex-row': wasLocalUser,
                'flex-row-reverse': !wasLocalUser,
              })}
              ref={bottomRef}
            >
              <Image
                src={message.sender?.profilePicture ?? '#'}
                height={48}
                width={48}
                className="rounded-full object-cover object-center self-start max-h-12 h-12"
                style={{ minWidth: '48px' }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = '/images/games/placeholder.png'
                }}
              />
              <div
                className={classNames('text-white p-3 rounded-xl break-all', {
                  'bg-primary-500': !wasLocalUser,
                  'bg-background-400': wasLocalUser,
                })}
                style={{ hyphens: 'auto' }}
              >
                {message.message}
              </div>
            </div>
          )
        })}
      </div>
      <div className="fixed lg:absolute bottom-0 left-0 right-0 flex bg-background-500 p-3 rounded-xl gap-3">
        <InputField
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.currentTarget.value)}
          placeholder="Your message..."
        />
        <Button
          trackingEventName={'message-conversation-page-send-message'}
          color="primary"
          onClick={SendMessage}
        >
          +
        </Button>
      </div>
    </div>
  )
}

export default MessagingConversationPage
