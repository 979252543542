import classNames from 'classnames'

interface Props {
  src: () => JSX.Element
  active: boolean
  onClick: () => void
}

export default function PictureButton({ src, active = false, onClick }: Props) {
  return (
    <div
      className={classNames(
        'cursor-pointer bg-darkbackground-500 rounded-xl border-4',
        {
          'border-darkbackground-500': !active,
          'border-primary-500': active,
        },
      )}
      onClick={onClick}
      data-testid="picture-button"
    >
      <div className="w-40 h-40 flex justify-center items-center ">
        {src() as any as React.ReactNode}
      </div>
    </div>
  )
}
