import Spinner from 'src/common/Spinner'
import WritersBlocksSettingsPanel from '../templates/WritersBlocksSettingsPanel'
import { CurrentUserContext } from '../../../providers/AuthProvider'
import { useContext } from 'react'

export default function WritersBlockSettings() {
  const { writersBlocks } = useContext(CurrentUserContext)
  if (!writersBlocks)
    return (
      <div className='w-full text-center'>
        <Spinner />
      </div>
    )

  return <WritersBlocksSettingsPanel blocks={writersBlocks ?? []} />
}
