import { useEffect } from 'react'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

function RouteChangeTracker() {
    let location = useLocation();
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
        // TODO: verify that this is working correctly
        ReactGA.gtag('config', 'GA_MEASUREMENT_ID', { page_path: location.pathname });
    }, [location]);

    return <div></div>;
}

export default RouteChangeTracker;