import { useCallback, useContext, useEffect, useState } from 'react'
import { Routes } from '../helpers/RestApiRoutes'
import { useBoolean } from '@chakra-ui/react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Feed } from '../../api/types'

export default function useGetFeed(slug: string) {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState('')
  const [data, setData] = useState<Feed | undefined>()

  const getFeed = useCallback(async () => {
    if (slug === '') {
      console.warn('trying to get feed with empty slug')
      return
    }

    setLoading.on()
    setData(undefined)
    setError('')
    try {
      const { data } = await client.get(Routes.GetFeed(slug.toLowerCase()))
      if (data) {
        setData(data)
      }
    } catch (e: any) {
      setError(e.message)
    }
    setLoading.off()
  }, [client, setLoading, slug])

  useEffect(() => {
    void getFeed()
  }, [getFeed, slug])

  return {
    loading,
    data,
    getFeed,
    error,
  }
}
