function Nintendo3DSLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.073"
      height="24.232"
      viewBox="0 0 14.073 24.232"
    >
      <g id="nintendo-3ds" transform="translate(-116.401 -0.2)">
        <path
          id="path171"
          style={{ fill: '#fff' }}
          d="M118.927,15.6a2.417,2.417,0,0,0-2.415,2.415v6.579a2.417,2.417,0,0,0,2.415,2.415h9.243a2.417,2.417,0,0,0,2.415-2.415V18.015a2.417,2.417,0,0,0-2.415-2.415Zm.083,2h9.077a.537.537,0,0,1,.5.5v6.578a.537.537,0,0,1-.5.5h-9.077a.537.537,0,0,1-.5-.5V18.1A.537.537,0,0,1,119.011,17.6Z"
          transform="translate(-0.111 -2.576)"
        />
        <path
          id="path173"
          style={{ fill: '#fff' }}
          d="M118.927.2a2.417,2.417,0,0,0-2.415,2.415V9.277a2.417,2.417,0,0,0,2.415,2.415h9.243a2.417,2.417,0,0,0,2.415-2.415V2.615A2.417,2.417,0,0,0,128.171.2Zm.083,2h9.077a.537.537,0,0,1,.5.5V9.277a.537.537,0,0,1-.5.5h-9.077a.537.537,0,0,1-.5-.5V2.7A.537.537,0,0,1,119.011,2.2Z"
          transform="translate(-0.111 0)"
        />
      </g>
    </svg>
  )
}

export default Nintendo3DSLogo
