import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../../providers/AxiosProvider'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center, Flex, Stack,
  Stat,
  StatLabel,
  StatNumber,
  useBoolean,
  Wrap,
} from '@chakra-ui/react'
import { Routes } from '../../../app/helpers/RestApiRoutes'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { Link } from 'react-router-dom'
import Button from '../../../common/forms/Button'

interface DateSeries {
  month: string,
  total: number
}

interface DashboardFacts<T> {
  string: string,
  label: string,
  type: string,
  value: T
}

export default function AdminDashboard() {
  const client = useContext(AxiosContext)

  const [data, setData] = useState<DashboardFacts<any>[]>([])
  //TODO: loading state
  const [, setLoading] = useBoolean()

  const getDashboardFacts = useCallback(async () => {
    setLoading.on()
    try {
      const { data } = await client.get<DashboardFacts<any>[]>(Routes.GetAdminDashboard())
      setData(data)
    } catch (e) {
      console.error(e)
    }
    setLoading.off()
  }, [client, setLoading])

  useEffect(() => {
    void getDashboardFacts()
  }, [getDashboardFacts])

  const NumericValues = useCallback(() => {
    return <>
      {data
        .filter((i: DashboardFacts<number>) => i.type === 'number')
        .map((item: DashboardFacts<number>) => {
          return (
            <Card key={item.string} w={150}>
              <CardBody>
                <Stat>
                  <StatLabel>
                    <Center>{item.label}</Center>
                  </StatLabel>
                  <StatNumber>
                    <Center>{item.value}</Center>
                  </StatNumber>
                </Stat>
              </CardBody>
            </Card>
          )
        })}
    </>
  }, [data])

  const ChartValues = useCallback(() => {
    return <>
      {data
        .filter((i: DashboardFacts<any>) => i.type === 'histogram')
        .map((item: DashboardFacts<DateSeries[]>) => {
          return (
            <Card key={item.string}>
              <CardHeader>
                {item.label}
              </CardHeader>
              <CardBody>
                <BarChart data={item.value} width={400} height={250}>
                  <Bar dataKey='total' fill='#8884d8' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
                  <XAxis dataKey={'month'} />
                  <YAxis />
                  <Tooltip />
                </BarChart>
              </CardBody>
            </Card>
          )
        })}
    </>
  }, [data])

  return (
    <div className={'container mx-auto mt-4'}>
      <Flex gap={3}>
        <Stack w={300}>
          <Box>
            <Link to={'/admin/manage/users'}>
              <Button className={'w-full'} trackingEventName={'navigate-to-manage-user'}>
                Manage Users
              </Button>
            </Link>
          </Box>
          <Box>
            <Link to={'/admin/manage/posts'}>
              <Button className={'w-full'} trackingEventName={'navigate-to-manage-posts'}>
                Manage Posts
              </Button>
            </Link>
          </Box>
        </Stack>

        <Wrap gap={4}>
          <NumericValues />
          <ChartValues />
        </Wrap>
      </Flex>
    </div>
  )
}