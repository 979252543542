import { useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import {
  GetFeedQuery,
  GetFeedVariables,
  GET_FEED,
  UpdateFeedQuery,
  UpdateFeedVariables,
  UPDATE_FEED,
} from 'src/api/gql-queries'
import SectionCard from '../../atoms/SectionCard'
import ErrorMessage from '../../molecules/ErrorMessage'
import SelectField from 'src/common/forms/SelectField'
import { Navigate, useLocation } from 'react-router-dom'
import SkeletonCard from '../../molecules/SkeletonCard'
import { CurrentUserContext } from '../../../../providers/AuthProvider'

export default function ChangeNSFWFeedContent() {
  const { writersBlocks } = useContext(CurrentUserContext)
  const { pathname: location } = useLocation()

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const [updateFeed] = useMutation<UpdateFeedQuery, UpdateFeedVariables>(
    UPDATE_FEED,
  )

  const getWritersBlockByName = () => {
    // Get last part of url
    const url = location.split('/')
    const name = url[url.length - 1]

    return (writersBlocks ?? []).find((block) => block.name === decodeURI(name))
  }

  const { data, loading, refetch } = useQuery<GetFeedQuery, GetFeedVariables>(
    GET_FEED,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: {
        slug: getWritersBlockByName()?.id ?? '',
      },
    },
  )

  const [feedNSFW, setFeedNSFW] = useState<string>(
    data?.Feed?.nsfw ? 'Yes' : 'No',
  )

  useEffect(() => {
    setFeedNSFW(data?.Feed?.nsfw ? 'Yes' : 'No')
  }, [data])

  async function submitForm(value: string) {
    setFeedNSFW(value)

    try {
      await updateFeed({
        variables: {
          id: data?.Feed.id ?? '',
          nsfw: value === 'Yes',
        },
      })

      refetch()
      setMessage('Your feed content flag has been updated.')
    } catch (e) {
      setError(true)
      setMessage('Something went wrong. Please try again later.')
    }
  }

  // There was no communties, so there should be no form
  if (!writersBlocks) return null

  // We're loading so we should display the intent
  if (loading) return <SkeletonCard />

  // there's no WritersBlock with that name, we should get out of here
  if (!getWritersBlockByName())
    return <Navigate to="/settings/writers-blocks" />

  return (
    <SectionCard title="Change NSFW Profile Content">
      <div>Declare the feed's NSFW Content:</div>

      <SelectField
        options={{
          No: 'My profile does NOT contain NSFW content',
          Yes: 'My profile contains NSFW content',
        }}
        defaultValue={feedNSFW}
        onChange={(value) => {
          setFeedNSFW(value)
          submitForm(value)
        }}
      />

      <ErrorMessage
        error={error}
        message={message}
        dataTestId={'change-profile-feed-nsfw'}
      />
    </SectionCard>
  )
}
