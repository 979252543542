import { useContext, useEffect, useState } from 'react'
import Button from 'src/common/forms/Button'
import SectionCard from '../../atoms/SectionCard'
import InputFieldWithMessage from '../../molecules/InputFieldWithMessage'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'

type FormState = {
  usernameError: boolean
  usernameMessage: string
  emailError: boolean
  emailMessage: string
}

const defaultPasswordState: FormState = {
  usernameError: false,
  usernameMessage: '',
  emailError: false,
  emailMessage: '',
}

export default function ChangePersonalDetailsForm() {
  const {
    user,
  } = useContext(CurrentUserContext)

  const [username, setUsername] = useState(user?.username ?? '')
  const [messages, setMessages] = useState<FormState>(defaultPasswordState)
  // const [email, setEmail] = useState<string>(user?.email ?? '')

  useEffect(() => {
    setUsername(user?.username ?? '')
    // setEmail(user?.email ?? '')
  }, [user])

  const {
    loading,
    updateUser,
  } = useUpdateUser()

  function onSubmit(e: any) {
    e.preventDefault()
    setMessages(defaultPasswordState)

    const trimedUsername = username.trim() === '' ? undefined : username.trim()
    // const trimedEmail = email.trim() === '' ? undefined : email.trim()

    // Reset state to default
    setMessages(defaultPasswordState)
    updateUser({
      username: trimedUsername,
    })

    setMessages({
      ...messages,
      // emailMessage: trimedEmail ? 'Email updated successfully' : '',
      usernameMessage: trimedUsername ? 'Username updated successfully' : '',
    })
  }

  return (
    <SectionCard title={'Change your personal details'}>
      <form onSubmit={onSubmit}>
        <div className='mt-3'>
          <div>Username</div>
          <InputFieldWithMessage
            type='text'
            placeholder='CoolUsername'
            value={username}
            dataTestId='input-text-username'
            onChange={(e) => setUsername(e.currentTarget.value)}
            error={messages.usernameError}
            message={messages.usernameMessage}
          />
        </div>

        {/* <div className="mt-3"> */}
        {/*   <div> */}
        {/*     Email{' '} */}
        {/*     <small className="text-gray-600"> */}
        {/*       - for data protection we do not send this to the client */}
        {/*     </small> */}
        {/*   </div> */}
        {/*   <InputFieldWithMessage */}
        {/*     type="email" */}
        {/*     placeholder="Joe.Blogs@email.com" */}
        {/*     value={email} */}
        {/*     dataTestId="input-text-email" */}
        {/*     onChange={(e) => setEmail(e.currentTarget.value)} */}
        {/*     error={messages.emailError} */}
        {/*     message={messages.emailMessage} */}
        {/*   /> */}
        {/* </div> */}

        <Button
          type='submit'
          trackingEventName={'settings-page-update-user-information'}
          size='md'
          className='p-3 bg-primary-500 text-white mt-3'
          data-testid='update-basic-info-save-button'
          loading={loading}
          // class:disabled={() => !HasChangedBasicInfo()}
        >
          Save Changes
        </Button>
      </form>
    </SectionCard>
  )
}
