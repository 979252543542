import { useOutletContext } from 'react-router-dom'
import { Game } from '../../api/types'
import GameCard from '../games/GameCard'
import { ProfilePageContextType } from './ProfilePage'


function ProfilePageGames() {
  const { userData } = useOutletContext<ProfilePageContextType>()



  const DisplayGames = () => {
    if (
      !userData?.games ||
      userData.games.length === 0
    ) {
      return (
        <div className="w-full text-center text-2xl">
          This user is not following any games
        </div>
      )
    }

    return (
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-3">
        {userData.games.map(
          (game: Game) => {
            return <GameCard game={game} key={game.id} />
          },
        )}
      </div>
    )
  }

  return (
    <div className="p-3">
      {(
        DisplayGames()
      )}
    </div>
  )
}

export default ProfilePageGames
