import { FeedType, Post } from '../../../api/types'
import { useQuery } from '@apollo/client'
import {
  FeedByOwnerQuery,
  FeedByOwnerVariables,
  FEED_BY_OWNER,
  GetRecentPostsForFeedQuery,
  GetRecentPostsForFeedVariables,
  GET_RECENT_POSTS_FOR_FEED,
} from '../../../api/gql-queries'
import Spinner from '../../../common/Spinner'
import { CharLimit, StripHTMLTags } from '../../../app/helpers/string-helpers'
import Image from '../../../common/forms/Image'

interface Props {
  username: string
}

function RecentBlock({ username }: Props) {
  const { loading: loadingFeed, data: dataFeed } = useQuery<
    FeedByOwnerQuery,
    FeedByOwnerVariables
  >(FEED_BY_OWNER, {
    variables: {
      feedType: FeedType.WritersBlock,
      username,
    },
  })

  const { loading, data } = useQuery<
    GetRecentPostsForFeedQuery,
    GetRecentPostsForFeedVariables
  >(GET_RECENT_POSTS_FOR_FEED, {
    variables: {
      id: (dataFeed?.FeedByOwner.items[0] ?? {}).id ?? '',
      quantity: 1,
    },
  })

  return (
    <div className="profile-module px-5 py-3 mt-3 bg-background-500 rounded-xl">
      <div className="text-white text-xl font-medium py-2 px-3 mb-3 rounded-xl bg-foreground-500">
        Recent Block
      </div>
      {loadingFeed || loading ? (
        <div className="w-full text-center">
          <Spinner />
        </div>
      ) : (
        data?.RecentPostsForFeed.items.map((post: Post, index) => {
          return (
            <div className="w-full" key={index}>
              <Image
                src={post.image}
                alt={post.title}
                className="rounded-lg mx-auto"
              />

              <div className="flex flex-col space-y-3 pt-3">
                <div className="text-white">{post.title}</div>
                <div className="text-xs text-darksteel">
                  {CharLimit(StripHTMLTags(post.content), 120)}
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

export default RecentBlock
