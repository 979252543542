import { format, parseISO } from 'date-fns'
import { Link, useNavigate, useParams } from 'react-router-dom'
import GradientBackgroundContainer from '../../common/containers/GradientBackgroundContainer'
import Button from '../../common/forms/Button'
import Image from '../../common/forms/Image'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Routes } from '../../app/helpers/RestApiRoutes'
import { Game } from '../../api/types'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'

function GamesPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const client = useContext(AxiosContext)
  const [error, setError] = useState('')
  const [game, setGame] = useState<Game>()
  const [loading, setLoading] = useBoolean()
  const toast = useToast()

  if (!id) {
    navigate('/games')
  }

  useEffect(() => {
    setError('')
    setLoading.on()
    client.get<Game>(Routes.GetGame(id!))
      .then((res) => {
        setGame(res.data)
      })
      .catch((e) => {
        setError(e.message)
      })
      .then(() => {
        setLoading.off()
      })
  }, [client, id, setLoading])

  const IsFollowing = useMemo(() => {
    return game?.IsFollowing ?? false
  }, [game]);

  const followGame = async () => {
    try {
      const res = await client.post(Routes.ToggleFollowGame(id!))
      setGame(res.data)
    } catch (e) {
      toast({
        title: 'Failed to update your profile',
        description: 'Something went wrong while trying to update your following status',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  //   if (IsFollowing()) {
  //     await updateUser({
  //       variables: {
  //         id: user?.id as string,
  //         followedGames: user?.followedGames?.filter(
  //           (f) => f.IGDBGameId !== id,
  //         ),
  //       },
  //     })
  //   } else {
  //     await updateUser({
  //       variables: {
  //         id: user?.id as string,
  //         followedGames: [
  //           ...(user?.PopulatedFollowedGames.map((i) => ({
  //             IGDBGameId: i.id,
  //             favorite: true,
  //           })) || []),
  //           {
  //             IGDBGameId: game.id,
  //             favorite: true,
  //           },
  //         ],
  //       },
  //     })
  //   }
  //
  //   window.location.reload()
  }

  function HumanifyDate(date: string, includeDate = false) {
    ///2018-01-01T00:00:00.000Z
    const datefnDate = parseISO(date)
    return format(datefnDate, includeDate ? 'do MMMM yyyy' : 'yyyy')
  }

  const pageContent = () => {
    if (loading) {
      return <div>Loading...</div>
    }
    if (error) {
      return <div>Error: {error}</div>
    }
    if (game) {
      return (
        <GradientBackgroundContainer
          url={game?.banner.String || '/images/games/placeholder.png'}
        >
          <div className='container mx-auto px-4 py-16 relative'>
            <Link to='/games'>
              <div className='back-link text-purple-500 hover:text-purple-600 transition ease-in'>
                &lt; Go Back
              </div>
            </Link>

            <div className='bg-background-500 rounded-3xl mt-16 p-8 drop-shadow-xl lg:flex block lg:space-x-16'>
              <div className='md:w-1/4 md:block flex flex-col'>
                {/* Square Game Icon */}
                <div className='md:h-64 md:w-64 w-full bg-foreground rounded-lg mb-5'>
                  <Image
                    className='h-full object-cover object-center rounded-lg mx-auto'
                    src={game.artwork.String}
                    alt={`${game.title}'s artwork`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = '/images/games/placeholder.png'
                    }}
                  />
                </div>

                <h1 className='text-white text-3xl font-semibold'>
                  {game.title}
                </h1>
                <p className='text-2xl font-semibold mt-2'>
                  {HumanifyDate(game.release_date)}
                </p>
                <p className='mt-6'>
                  <strong>
                    Genre: <br />{' '}
                  </strong>{' '}
                  {(game.genres ?? []).map((i) => i.name)
                    .join(',')}
                </p>
                <p className='mt-6'>
                  <strong>
                    Platforms: <br />{' '}
                  </strong>{' '}
                  {(game.platforms ?? []).map((i) => i.name)
                    .join(',')}
                </p>
              </div>

              <div className='px-3 lg:mt-6 mt-5 text-steel w-full relative'>
                <h1 className='text-white text-3xl font-semibold'>About</h1>
                <p className='mt-5 break-all'>{game.description.String}</p>

                <div className='mt-5 bottom-0 right-0'>
                  <Button
                    onClick={followGame}
                    color={IsFollowing ? 'danger' : 'primary'}
                    trackingEventName={
                      IsFollowing
                        ? 'games-page-unfollow'
                        : 'games-page-follow'
                    }
                  >
                    {IsFollowing ? 'Unfollow' : 'Follow'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </GradientBackgroundContainer>
      )
    }
  }

  return (
    <div className='bg-darkbackground-500 text-white w-full'>
      {pageContent()}
    </div>
  )
}

export default GamesPage
