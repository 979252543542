import Image from '../../../common/forms/Image'

function EmptyModule() {
  return (
    <div className="profile-module px-5 py-3 mt-3 bg-background-500 rounded-xl">
      <div className="text-white pb-3 text-2xl font-medium">Social Links</div>
      <Image
        className="rounded-xl w-full"
        src="https://picsum.photos/344/266"
        alt="placeholder"
      />
      <div>Add Content to this module</div>
      <div>Add content to this module to personalise your ember profile.</div>
    </div>
  )
}

export default EmptyModule
