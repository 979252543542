import React, { useCallback, useEffect } from 'react'
import { Post, Reply } from 'src/api/types'
import PostCard from '../posts/PostCard'
import ShortPostEditor, {
  PostCreateEvent,
} from '../command-palette/ShortPostEditor'
import Spinner from '../Spinner'

interface Props {
  displayShortPostEditor?: boolean
  hasMore: boolean
  items: Post[]
  loader?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  loading: boolean
  pageStart: number
  loadMore: () => void
  shortPostEditorOnClick?: (event: PostCreateEvent) => void
  onReply?: (reply: Reply) => void
}

const debounce = (fn: (...args: any[]) => unknown, delay: number) => {
  let timeoutId: any
  return function (...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(null, args), delay)
  }
}

export function InfiniteScroll({
  children,
  loadMore,
  hasMore,
  loading,
}: React.PropsWithChildren<{
  loadMore: () => void
  loading: boolean
  hasMore: boolean
}>) {
  const handleScroll = useCallback(() => {
    console.log({
      hasMore: hasMore,
      'window.innerHeight': window.innerHeight,
      'document.documentElement.scrollTop': document.documentElement.scrollTop,
      'document.documentElement.offsetHeight':
        document.documentElement.offsetHeight,
    })
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading ||
      !hasMore
    ) {
      return
    }
    console.log('Loading more posts...')
    void loadMore()
  }, [hasMore, loadMore, loading])

  // useEffect(() => {
  //   // Load initial set of posts
  //   void loadMore()
  // }, [loadMore])

  useEffect(() => {
    // Bind the scroll event listener
    const debouncedHandler = debounce(handleScroll, 100)
    window.addEventListener('scroll', debouncedHandler)
    return () => window.removeEventListener('scroll', debouncedHandler)
  }, [handleScroll, loadMore])

  return (
    <>
      {children}
      {loading && (
        <div key={'dashboard-feed-spinner'} className="w-full py-5 text-center">
          <Spinner />
        </div>
      )}
      {!hasMore && (
        <div className="w-full py-5 text-center">
          <p className="text-gray-500">No more posts</p>
        </div>
      )}
    </>
  )
}

export default function InfiniteScrollingFeed({
  displayShortPostEditor,
  items,
  loading,
  loadMore,
  hasMore,
  shortPostEditorOnClick,
}: Props) {
  // const defaultLoader = (
  //   <div key={'dashboard-feed-spinner'} className="w-full py-5 text-center">
  //     <Spinner />
  //   </div>
  // )

  return (
    <>
      {displayShortPostEditor && (
        <ShortPostEditor onClick={shortPostEditorOnClick} />
      )}

      <InfiniteScroll loadMore={loadMore} loading={loading} hasMore={hasMore}>
        {(items ?? []).map((post, index) => (
          <PostCard key={index} post={post} />
        ))}
        {(items ?? []).length === 0 && (
          <div className="w-full py-5 text-center">
            <p className="text-gray-500">No posts yet</p>
          </div>
        )}
      </InfiniteScroll>
    </>
  )
}
