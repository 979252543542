import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { User } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'
import { ImageType } from 'react-images-uploading'
import { CurrentUserContext } from '../../providers/AuthProvider'

interface UpdateUserPayload {
  username?: string
  email?: string
  password?: string
  changePassword?: string
  profilePicture?: ImageType
  bannerImage?: ImageType
  description?: string
  country?: string
  tagline?: string
  dob?: string
  marketingConsent?: string
  allowNSFW?: string
  privacy?: string
}

export function useUpdateUser() {
  const { ClearCache } = useContext(CurrentUserContext)
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState('')
  const toast = useToast()

  const updateUser = useCallback(async (payload: UpdateUserPayload) => {
    setLoading.on()

    const formdata = new FormData()
    // Append all payload attributes into form data for API
    for (const payloadKey in payload) {
      if (['profilePicture', 'bannerImage'].includes(payloadKey)) {
        //TODO: Readability improvements
        const attr = (payload as any)[payloadKey] as ImageType
        if (!attr.file) {
          throw new Error(`failed add ${payloadKey} to update user request. Image Object did not have attribute file.`)
        }

        formdata.append(payloadKey, attr!.file as Blob, attr.file.name)
        continue
      }

      //TODO: Readability improvements
      formdata.append(payloadKey, (payload as any)[payloadKey] as string)
    }

    try {
      await client.put<APIPaginatedResponse<User>>(Routes.UpdateUser(), formdata)
      ClearCache()
    } catch (e: any) {
      toast({
        title: 'Failed to update user',
        description: 'Something went wrong while trying to update the user',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [ClearCache, client, setLoading, toast])

  return {
    loading,
    updateUser,
    error,
  }
}