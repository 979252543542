import NavigationSideBar from '../../molecules/NavigationSideBar'
/**
 * @params routes - The routes to display in the sidebar
 * @params activeRoute - The route that is currently active
 */
interface Props {
  routes: { name: string; path: string }[]
  activeRoute: string
}
export default function DesktopSideNav({ routes, activeRoute }: Props) {
  return (
    <>
      <div data-testid="nav-side-bar">
        <NavigationSideBar
          childRoutes={routes}
          activeRoute={activeRoute}
          onChange={() => {}}
        />
      </div>
    </>
  )
}
