import { useContext, useState } from 'react'
import SectionCard from '../../atoms/SectionCard'
import Image from 'src/common/forms/Image'
import FileField from 'src/common/forms/FileField'
import ErrorMessage from '../../molecules/ErrorMessage'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'

export function ChangeProfilePicture() {
  const { user, ClearCache } = useContext(CurrentUserContext)

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const {
    updateUser,
  } = useUpdateUser()

  const uploadProfilePicture = async (files: File[]) => {
    setError(false)
    setMessage('')

    if (!files[0]) return

    updateUser({
        profilePicture: files[0],
    })
      .then(() => {
          ClearCache()
          setMessage('Successfully uploaded your new profile image image.')
      })
      .catch(() => {
        setError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  return (
    <SectionCard title="Change Profile Picture">
      <div>
        <div className="text-lg font-medium">Profile Picture</div>
        <div className="flex space-x-3 items-center">
          <div className="bg-darkbackground-500 rounded-lg p-3">
            <Image
              src={user?.profilePicture}
              width={120}
              height={120}
              className="rounded-lg"
            />
          </div>
          <div className="flex-1 flex-col">
            <div className="text-sm text-gray-500 pb-3">
                Your profile picture will be visible to everyone on Ember. For best results, use a square image of 208x208px.
            </div>
            <FileField
              label="Click here or Drop to upload a profile picture"
              onSubmit={uploadProfilePicture}
            />
          </div>
        </div>
        <div className="text-center">
          <ErrorMessage
            error={error}
            message={message}
            dataTestId={'profile-picture'}
          />
        </div>
      </div>
    </SectionCard>
  )
}
