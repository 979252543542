import { useContext, useEffect, useState } from 'react'
import SectionCard from '../../atoms/SectionCard'
import ErrorMessage from '../../molecules/ErrorMessage'
import SelectField from 'src/common/forms/SelectField'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateFeed } from '../../../../app/hooks/UseUpdateFeed'
import useGetFeed from '../../../../app/hooks/useGetFeed'

export function ChangeNSFWProfileContent() {
  const {
    user,
    ClearCache,
  } = useContext(CurrentUserContext)

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const { data: feed } = useGetFeed(user?.username ?? '')
  const { updateFeed } = useUpdateFeed(user?.username ?? '')

  const [userProfileFeedNSFW, setUserProfileFeedNSFW] = useState<string>('No')

  useEffect(() => {
    const selectionKey = feed?.nsfw ? 'Yes' : 'No'
    setUserProfileFeedNSFW(selectionKey)
  }, [feed])

  function saveChanges(value: string) {
    setUserProfileFeedNSFW(value)
    updateFeed({
      nsfw: value === 'Yes' ? 'y' : 'n',
    })
      .then(() => {
        ClearCache()
        setMessage('Your profile feed content flag has been updated.')
      })
      .catch(() => {
        setError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  return (
    <SectionCard title='Change NSFW Profile Content'>
      <div>My User Profile NSFW Content:</div>
      <SelectField
        options={{
          No: 'My profile does NOT contain NSFW content',
          Yes: 'My profile contains NSFW content',
        }}
        defaultValue={userProfileFeedNSFW}
        onChange={(value) => {
          saveChanges(value)
        }}
      />

      <ErrorMessage
        error={error}
        message={message}
        dataTestId={'change-profile-feed-nsfw'}
      />
    </SectionCard>
  )
}
