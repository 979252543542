import { Navigate, RouteObject } from 'react-router-dom'
import WritersBlockPostPage from './pages/WritersBlockPostPage'
import WritersBlockCreatePostPage from './pages/WritersBlockCreatePostPage'
import WritersBlockPage from './pages/WritersBlockPage'
import WritersBlockIndex from './pages/WritersBlockIndex'
import WritersBlockCreateBlockPage from './pages/WritersBlockCreateBlockPage'
import WritersBlockContext from './pages/WritersBlockContext'
import WritersBlockPostContext from './pages/WritersBlockPostContext'

export const WritersBlockRoutes: RouteObject = {
  path: '/writers-block',
  children: [
    {
      path: '',
      index: true,
      caseSensitive: false,
      element: <WritersBlockIndex />,
    },
    {
      path: 'create',
      element: <WritersBlockCreateBlockPage />,
    },
    {
      path: ':id',
      element: <WritersBlockContext />,
      children: [
        {
          path: '',
          index: true,
          element: <WritersBlockPage />,
        },
        {
          path: 'edit',
          element: <WritersBlockCreateBlockPage isEditing={true} />,
        },
        {
          path: 'create-post',
          element: <WritersBlockCreatePostPage />,
        },
        {
          path: 'posts/:postId',
          element: <WritersBlockPostContext />,
          children: [
            {
              path: '',
              index: true,
              element: <WritersBlockPostPage />,
            },
            {
              path: 'edit',
              element: <WritersBlockCreatePostPage isEditing={true} />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to='/writers-blocks' />,
    },
  ],
}