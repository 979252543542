function PSNLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.948"
      height="23.178"
      viewBox="0 0 29.948 23.178"
    >
      <g id="playstation-logotype" transform="translate(0 -12.063)">
        <g
          id="Group_1382"
          data-name="Group 1382"
          transform="translate(0 12.063)"
        >
          <path
            id="Path_708"
            data-name="Path 708"
            style={{ fill: '#fff' }}
            d="M3.284,61.448l-1.01.393C.759,62.486-.027,63.16,0,63.749c.084.87,1.066,1.515,2.862,1.992a17.4,17.4,0,0,0,7.127.393V63.693h0l-1.936.73A3.923,3.923,0,0,1,5,64.535c-.309-.2-.449-.673.393-.982l1.066-.393L9.991,61.9V59.063l-.9.309Z"
            transform="translate(0 -45.874)"
          />
          <path
            id="Path_709"
            data-name="Path 709"
            style={{ fill: '#fff' }}
            d="M47.469,17.787v7.268c1.515.73,2.862.786,3.872.028,1.038-.73,1.6-1.992,1.6-3.9a6.781,6.781,0,0,0-1.263-4.518A8.528,8.528,0,0,0,47.413,14c-2.582-.842-4.77-1.571-6.51-1.936V33.81l4.686,1.431V17.17C45.561,16.076,47.469,16.16,47.469,17.787Z"
            transform="translate(-29.425 -12.063)"
          />
          <path
            id="Path_710"
            data-name="Path 710"
            style={{ fill: '#fff' }}
            d="M74.4,65.539c-.028-.758-.926-1.347-2.525-1.88a14.042,14.042,0,0,0-5.023-.814A18.93,18.93,0,0,0,61.8,63.911v2.778l4.546-1.6a4.961,4.961,0,0,1,2.554-.2c.9.224.842.73-.056,1.038l-1.038.421-6,2.161v2.834l2.806-1.01,6.735-2.385.786-.365C73.757,67,74.486,66.353,74.4,65.539Z"
            transform="translate(-44.461 -48.59)"
          />
        </g>
      </g>
    </svg>
  )
}

export default PSNLogo
