import { useMemo, useState } from 'react'
import Spinner from './Spinner'
import classNames from 'classnames'
import Button from './forms/Button'
import BellFill from './icons/BellFill'
import Image from '../common/forms/Image'
import BinIcon from './icons/BinIcon'
import { useNotifications } from '../app/hooks/useNotifications'

function NotificationDropdown() {
  const [isOpen, setIsOpen] = useState(false)

  // TODO: clear notifications
  // const [clearNotification] = useMutation<
  //   MarkNotificationsAsReadQuery,
  //   MarkNotificationsAsReadVariables
  // >(MARK_NOTIFICATIONS_AS_READ)


  const { loading, notifications } = useNotifications()

  const items = useMemo(() => {
    return (notifications.payload ?? []).filter(n => !n.read)
  }, [notifications])

  function dismissNotification(ids: string[]) {
    console.log(ids)
    // clearNotification({
    //   variables: {
    //     ids: [...ids],
    //   },
    //   onCompleted() {
    //     setNotifications((prev) =>
    //       prev.filter((n) => !ids?.includes(n.id as string)),
    //     )
    //     refetch()
    //   },
    // })
  }

  return (
    <div className="flex space-x-3 items-center select-none text-lg relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-darksteel relative"
      >
        <BellFill />

        {(notifications.payload ?? []).find((i) => !i.read) && (
          <div
            data-testid="notification-pip"
            className="absolute top-0 right-0 bg-red-500 rounded-full w-2 h-2"
          />
        )}
      </button>

      <div
        className={classNames(
          'absolute right-0 top-full bg-background-500 p-3 rounded min-w-[400px] mt-2 max-h-[50vh] overflow-y-scroll border border-[#00000026]',
          { hidden: !isOpen, 'text-center': loading },
        )}
      >
        {loading ? (
          <Spinner />
        ) : notifications.total > 0 ? (
          <div className="flex flex-col gap-2">
            {items.map((notification) => (
                <div
                  className="flex bg-darkbackground-500 rounded gap-4 p-4 items-center"
                  key={notification.id}
                >
                  <Image
                    className="h-12 w-12 rounded-lg"
                    src={notification?.metadata.actor?.profilePicture}
                    alt="notification_image"
                  />
                  <div className="text-sm">{notification.metadata.details}</div>
                  <div className="ml-auto">
                    <button
                      className="text-red-600 p-2"
                      onClick={() =>
                        dismissNotification([notification.id as string])
                      }
                    >
                      <BinIcon />
                    </button>
                  </div>
                </div>
              ))}
            <Button
              trackingEventName={'notification-dropdown-clear-all'}
              size="sm"
              onClick={() =>
                dismissNotification([
                  ...items.map((i) => i.id),
                ])
              }
            >
              Clear notifications
            </Button>
          </div>
        ) : (
          <p className="text-center">There are no unread notifications</p>
        )}
      </div>
    </div>
  )
}

export default NotificationDropdown
