import './ShareIcon.css'
function ShareIcon({ ...props }: React.HTMLAttributes<any>) {
  return (
    <div className="pr-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.694"
        height="17.721"
        viewBox="0 0 18.694 17.721"
      >
        <g id="Group_1224" data-name="Group 1224" transform="translate(1 1)">
          <path
            id="Path_205"
            data-name="Path 205"
            className="cls-1"
            d="M512.283,333v4.174a2.087,2.087,0,0,0,2.086,2.086h12.521a2.086,2.086,0,0,0,2.086-2.086V333"
            transform="translate(-512.283 -323.539)"
          />
          <line
            id="Line_16"
            data-name="Line 16"
            className="cls-1"
            y1="11.5"
            transform="translate(8.347)"
          />
          <path
            id="Path_206"
            data-name="Path 206"
            className="cls-1"
            d="M552.283,264.13l3.13-3.13,3.13,3.13"
            transform="translate(-547.066 -260.93)"
          />
        </g>
      </svg>
    </div>
  )
}

export default ShareIcon
