import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Conversation } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

export function useGetConversations() {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [conversations, setConversations] = useState<APIPaginatedResponse<Conversation>>({
    hasMore: false,
    offset: 0,
    pageSize: 0,
    payload: [],
    total: 0
  })
  const [error, setError] = useState('')
  const toast = useToast()

  const getConversations = useCallback(async (page: number, pageSize: number) => {
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<Conversation>>(Routes.GetConversations(page, pageSize))
      if (data) {
        setConversations(data)
      }
    } catch (e: any) {
      toast({
        title: 'Failed to load conversation',
        description: 'Something went wrong while trying to load the conversation',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, setLoading, toast])

  useEffect(() => {
    void getConversations(page, pageSize)
  }, [getConversations, page, pageSize])

  return {
    loading,
    conversations,
    error,
    getConversations,
    setPage,
    setPageSize,
  }
}