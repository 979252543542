import { AxiosInstance } from 'axios'
import config from '../../config'

export namespace ShardApi {
  type ShardsForPostBody = { total: number; postId: string }

  export function GetShardsForPost(
    client: AxiosInstance,
    id: string,
  ): Promise<number> {
    return client
      .get<ShardsForPostBody>(
        `${config.restAPI.url}/posts/total/${id}`,
      )
      .then((res) => res.data.total)
  }

  type ShardsForUserBody = { total: number; userId: string }

  export function GetShardsForUser(
    client: AxiosInstance,
    id: string,
  ): Promise<number> {
    return client
      .get<ShardsForUserBody>(
        `${config.restAPI.url}/user/shard/total/${id}`,
      )
      .then((res) => res.data.total)
  }

  type AwardedForPostBody = { awarded: boolean; postId: string }

  export function GetAwardedForPost(
    client: AxiosInstance,
    postId: string,
    userId: string,
  ): Promise<boolean> {
    return client
      .get<AwardedForPostBody>(
        `${config.restAPI.url}/posts/awarded/${postId}/${userId}`,
      )
      .then((res) => res.data.awarded)
  }

  export function AwardShard(
    client: AxiosInstance,
    postId: string,
  ): Promise<boolean> {
    return client.post(
      `${config.restAPI.url}/award-shard?post_id=${postId}&shard=1`,
    )
  }
}
