import { Feed } from '../../api/types'
import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Routes } from '../helpers/RestApiRoutes'
import { ImageListType } from 'react-images-uploading'
import { CurrentUserContext } from '../../providers/AuthProvider'

interface CreateFeedProps {
  name: string,
  description: string,
  iconImage?: ImageListType,
  bannerImage?: ImageListType,
  coverImage?: ImageListType,
  feedType: string,
  privacy: string,
  tags: string,
  nsfw: boolean,
  website: string,
  metadata: any,
}

type callback = (props: CreateFeedProps) => Promise<Feed | undefined>

interface CreateFeed {
  loading: boolean
  error: string | undefined
  Create: callback
}

export default function useCreateFeed(args: { useToast: boolean }): CreateFeed {
  const { user } = useContext(CurrentUserContext)
  const client = useContext(AxiosContext)
  const toast = useToast()
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState<string | undefined>('')

  const CreateFeed = useCallback<callback>(async (props: CreateFeedProps) => {
    if (!user) {
      console.error('User not logged in. Cannot create feed without a user.')
      return
    }

    // do something with tags if required?
    // const tagString = tags.map((tag) => tag.substring(1)).join(',')
    const tagString = props.tags

    var f = new FormData()
    f.append('name', props.name)
    f.append('description', props.description)
    f.append('feedType', props.feedType)
    f.append('privacy', props.privacy)
    f.append('tags', tagString)
    f.append('website', props.website)
    f.append('metadata', JSON.stringify(props.metadata))
    f.append('nsfw', props.nsfw ? "true" : "false")

    if ((props?.iconImage?.length ?? 0) > 0) {
      f.append('iconImage', props.iconImage![0].file as Blob, props.iconImage![0].file!.name)
    }
    if ((props?.bannerImage?.length ?? 0) > 0) {
      f.append('bannerImage', props.bannerImage![0].file as Blob, props.bannerImage![0].file!.name)
    }
    if ((props?.coverImage?.length ?? 0) > 0) {
      f.append('coverImage', props.coverImage![0].file as Blob, props.bannerImage![0].file!.name)
    }

    setLoading.on()
    try {
      const { data } = await client.post<Feed>(Routes.CreateFeed(), f, { headers: { 'Content-Type': 'multipart/form-data' } })
      console.log('response from create feed', data)
      return data
    } catch (e: any) {
      setError(e.message)
      args.useToast && toast({
        title: 'Failed to create your new feed',
        description: 'Something went wrong while creating your feed',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading.off()
  }, [args, client, setLoading, toast, user])

  return {
    Create: CreateFeed,
    error: error,
    loading: loading,
  }
}