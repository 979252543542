export enum PostReportType {
    NSFW,
    VIOLENCE,
    HARASSMENT,
    SPAM,
    HATE,
    POLITICAL,
    TERRORISM,
    IP_THEFT,
    FAKE_ACCOUNT,
    OTHER,
}