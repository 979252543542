import { Link, useOutletContext } from 'react-router-dom'
import ProfileSocialLink from './ProfileSocialLink'
import { ProfilePageContextType } from './ProfilePage'
import { SocialBrand } from '../../api/types'
import { useContext, useMemo } from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import { CurrentUserContext } from '../../providers/AuthProvider'

const EditButton = ({ link }: { link: string }) => (
    <Link to={link} className={'text-foreground-300 text-lg rounded-full overflow-hidden'}>
        <AiOutlineEdit />
    </Link>
  )

function ProfilePageAbout() {
  const { userId, userData } = useOutletContext<ProfilePageContextType>()
  const currentUser = useContext(CurrentUserContext)
  const isOwner = useMemo(() => Boolean((userId ?? '-') === currentUser.user?.id), [userId, currentUser])

  return (
    <div className="lg:grid grid-cols-2 gap-3 p-3 pt-4" data-testid="profile-page-about">
      <div className="bg-background-500 rounded-xl p-3 mb-3 min-h-[307px] relative">
        {isOwner && <div className={'absolute right-3 top-3'}><EditButton link="/settings/profile#profile"  /></div>}
        {userData?.description}
      </div>
      <div>
        <div className="bg-background-500 p-3 mb-3 rounded-xl min-h-[307px] relative">
          {isOwner && <div className={'absolute right-3 top-3'}><EditButton link="/settings/profile#social-links"  /></div>}

          <h2 className="text-2xl">Find me here</h2>

          {(userData?.userProfileFeed?.socialPlatforms?.length ?? 0) === 0 &&
            <div className="text-center p-3">No social links</div>
          }
          {userData?.userProfileFeed?.socialPlatforms?.map((platform, index) => (
            <ProfileSocialLink key={index} platform={platform.platform as SocialBrand} value={platform.value} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProfilePageAbout
