import { useMutation } from '@apollo/client'
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'
import {
  CreateNewConversationMutation,
  CreateNewConversationVariables,
  CREATE_NEW_CONVERSATION,
} from '../../api/gql-queries'
import Spinner from '../../common/Spinner'
import ProfileModule from './ProfileModule'
import Image from '../../common/forms/Image'
import { Feed, ProfileModulePosition, User } from '../../api/types'
import Button from '../../common/forms/Button'
import classNames from 'classnames'
import PersonFill from '../../common/icons/PersonFill'
import SelectField from '../../common/forms/SelectField'
import VerifiedUserMark from 'src/common/VerifiedUserMark'
import ShardStat from '../../common/posts/ShardStat'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useBoolean } from '@chakra-ui/react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../../app/helpers/RestApiRoutes'
import useToggleFollowFeed from '../../app/hooks/useToggleFollowFeed'

interface bonusContent {
  // TODO: structure this request properly
  feeds: Feed[]
  brands: any[]
  games: any[]
}

export interface ProfilePageContextType {
  feedId: string
  userId: string | undefined
  userData: (User & bonusContent) | undefined // TODO: Proper type for this
  feedData: Feed
  profileModuleMetadata: any
}

const ProfileStatistics = ({
  user,
  feed,
}: { feed: Feed; user: User }) => (
  <div className='profile-info-stats flex justify-around font-light'>
    <div className='profile-info-stat profile-shards flex flex-col items-center justify-between space-y-1'>
      <div className='mb-2 h-7 w-7'>
        <Image src={'/images/icons/complexshard.png'} alt='shardIcon' />
      </div>
      <ShardStat userId={user?.id} showIcon={false} />
      <div>Shards</div>
    </div>

    <div className='profile-info-stat profile-followers flex flex-col items-center justify-between space-y-1'>
      <PersonFill className='h-8 fill-primary-500' />
      <div>{feed.totalSubscribers}</div>
      <div>Followers</div>
    </div>
  </div>
)

const TabLink = ({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) => {
  let resolved = useResolvedPath(to)
  let match = useMatch({
    path: resolved.pathname,
    end: true,
  })
  return (
    <Link
      to={to}
      className={classNames(
        'rounded-lg flex-1 text-center py-3 px-5 transition-colors',
        {
          'bg-primary-500 text-white hover:bg-primary-500 hover:text-white cursor-default ':
          match,
          'bg-background-500 text-steel hover:bg-background-700 hover:text-steel':
            !match,
        },
      )}
    >
      {children}
    </Link>
  )
}

const profilePageSelectOptions = {
  '/profile/%username%/feed': 'Feed',
  '/profile/%username%/about': 'About',
  // '/profile/%username%/communities': 'Communities',
  '/profile/%username%/writers-blocks': 'Writers Blocks',
  '/profile/%username%/games': 'Games',
}

/**
 * Function to display the buttons on the sidebar. Will display settings for owner of this page.
 * Otherwise, will display a follow and message button.
 */
function ProfileActionButtons(props: {
  userData: User
  isTogglingFollow: boolean
  userIsFollowingFeed: boolean
  onToggleFollowClick: () => void
  onCreateConversationClick: () => void
}) {
  const {
    userData,
    isTogglingFollow,
    userIsFollowingFeed,
    onToggleFollowClick,
    onCreateConversationClick,
  } = props
  const { user } = useContext(CurrentUserContext)

  if (userData?.username === user?.username) {
    return (
      <Link to='/settings'>
        <Button
          trackingEventName={'profile-page-edit-profile'}
          className='w-full'
        >
          Edit profile
        </Button>
      </Link>
    )
  }

  return (
    <div className='grid gap-3 grid-cols-2'>
      <Button
        trackingEventName={
          userIsFollowingFeed
            ? 'profile-page-unfollow-user'
            : 'profile-page-follow-user'
        }
        className='w-full'
        onClick={() => onToggleFollowClick()}
        color={userIsFollowingFeed ? 'danger' : 'primary'}
        loading={isTogglingFollow}
        disabled={isTogglingFollow}
      >
        {userIsFollowingFeed ? 'Unfollow' : 'Follow'}
      </Button>

      <Button
        trackingEventName={'create-conversation'}
        className='w-full'
        onClick={() => onCreateConversationClick()}
        color={'info'}
        loading={isTogglingFollow}
        disabled={isTogglingFollow}
      >
        Message
      </Button>
    </div>
  )
}

function ProfilePage() {
  const { user } = useContext(CurrentUserContext)
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const username = id?.toLowerCase() ?? user?.username ?? 'unknown'

  const [loading, setLoading] = useBoolean(false)
  const [userData, setUserData] = useState<User | undefined>(undefined)
  const [feedData, setFeedData] = useState<Feed | undefined>(undefined)
  const client = useContext(AxiosContext)

  const getUserData = useCallback(async () => {
    let resp = await client.get(Routes.GetUser(username.toLowerCase()))
    setUserData(resp.data)

    resp = await client.get(Routes.GetFeed(username.toLowerCase()))
    setFeedData(resp.data)
    setLoading.off()
  }, [client, setLoading, username])

  const {
    toggleFollow,
    loading: isTogglingFollow,
  } = useToggleFollowFeed(userData?.userProfileFeed?.id ?? '')

  useEffect(() => {
    setLoading.on()

    void getUserData()
  }, [client, getUserData, setLoading, username])

  const metadata = useMemo<any>(() => {
    return JSON.parse(userData?.userProfileFeed?.metadata ?? '{}')
  }, [userData])

  // TODO: replace this with a REST query
  const [createConversation] = useMutation<
    CreateNewConversationMutation,
    CreateNewConversationVariables
  >(CREATE_NEW_CONVERSATION, {
    variables: {
      usernames: [username],
    },
    onCompleted(newConversationData) {
      if (
        newConversationData.CreateNewConversation &&
        newConversationData.CreateNewConversation.id
      ) {
        navigate(`/messaging/${newConversationData.CreateNewConversation.id}`)
      } else {
        console.error(
          'There was an issue creating a new conversation: No id was returned',
        )
      }
    },
  })

  const userIsFollowingFeed = useMemo(() => {
    return feedData?.isFollowing ?? false
  }, [feedData?.isFollowing])

  const ToggleFollowUser = useCallback(async () => {
    await toggleFollow()
    await getUserData()
  }, [getUserData, toggleFollow])

  const goToSelectedPage = useCallback(
    (selectedPage: string) => {
      const path = selectedPage.replace(/%username%/, username)
      navigate(path)
    },
    [navigate, username],
  )

  // While this works. There has to be a better way to do this.
  const currentlySelectedPage = useMemo(() => {
    let currentPath = pathname
    let selectedPage = Object.keys(profilePageSelectOptions)
      .map((key) => key.replace(/%username%/, username))
      .find((key) => key.includes(currentPath))
    return selectedPage?.replace(username, '%username%') ?? ''
  }, [pathname, username])

  // Loading state for profile page
  if (loading) {
    return (
      <div className='w-full bg-darkbackground-500 text-center'>
        <Spinner />
      </div>
    )
  }

  // Error state for missing user data from api
  if (!userData || !feedData) {
    return (
      <div className={'container mx-auto text-center'}>
        Something went wrong trying to load the user profile
      </div>
    )
  }

  return (
    <div className='w-full bg-darkbackground-500 '>
      <div className='container mx-auto'>
        <div className='banner w-full relative'>
          <Image
            className='w-full rounded-xl lg:h-72 lg:max-h-72 h-36 max-h-36 object-cover object-center z-0'
            src={userData?.banner ?? '/images/ember-background-ea.jpg'}
            alt={`${userData?.username} profile banner`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/images/games/placeholder.png'
            }}
          />
          {/* Hidden on screens tablet and smaller */}
          <div className='hidden lg:block banner-overlay absolute bottom-0 w-full pr-2 pb-2 text-lg '>
            <div className='grid grid-cols-4 w-full'>
              <div className='col-start-2 col-span-3 w-full bg-background-500 flex rounded-xl space-x-2 p-2'>
                <TabLink to={`/profile/${username}/feed`}>Feed</TabLink>
                <TabLink to={`/profile/${username}/about`}>About</TabLink>
                {/* <TabLink to={`/profile/${username}/communities`}> */}
                {/*   Communities */}
                {/* </TabLink> */}
                <TabLink to={`/profile/${username}/writers-blocks`}>
                  Writers Blocks
                </TabLink>
                <TabLink to={`/profile/${username}/games`}>Games</TabLink>
              </div>
            </div>
          </div>
        </div>

        <div className='profile-body relative grid grid-cols-4'>
          <div className='lg:col-span-1 col-span-4'>
            <div className='profile-info lg:text-center lg:-mt-40 md:-mt-7 mt-2 relative lg:pt-44 md:pt-24 pt-14 flex flex-col'>
              <div
                className='profile-picture absolute top-0 left-0 flex justify-center items-end w-full px-5'
                style={{
                  maxHeight: 'min(205px, 20vw)',
                }}
              >
                <div className='mx-auto rounded-lg lg:h-52 h-32 lg:w-52 w-32 relative'>
                  <Image
                    src={userData?.profilePicture ?? '#'}
                    alt='profile icon'
                    className='lg:h-52 h-32 lg:w-52 w-32 z-10 relative rounded-lg'
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = '/images/games/placeholder.png'
                    }}
                  />
                </div>

                <div className='block lg:hidden flex-1'>
                  <ProfileStatistics user={userData} feed={feedData} />
                </div>
              </div>

              <div className='lg:bg-background-500 relative rounded-xl pt-8 pb-3'>
                {userData.verified && (
                  <VerifiedUserMark className='absolute top-3 right-3 text-primary-500' />
                )}

                <div className='profile-info-content px-5 py-3 grid lg:block'>
                  <div className='profile-info-name text-2xl text-steel font-medium capitalize'>
                    {userData?.username}
                  </div>

                  <div className='profile-info-tagline py-3 text-gray-600'>
                    {userData?.tagline}
                  </div>

                  <div className='hidden lg:block'>
                    <ProfileStatistics user={userData} feed={feedData} />
                  </div>
                </div>

                <div className='profile-info-actions mt-3 px-3'>
                  <ProfileActionButtons
                    isTogglingFollow={isTogglingFollow}
                    userIsFollowingFeed={userIsFollowingFeed}
                    userData={userData}
                    onToggleFollowClick={ToggleFollowUser}
                    onCreateConversationClick={createConversation}
                  />
                </div>
              </div>
            </div>

            <div className='hidden lg:block'>
              <ProfileModule
                module={(metadata?.profileModules ?? []).find((i: any) => i.position === ProfileModulePosition.bottomLeft)}
                userId={userData?.id ?? ''}
                userQuery={userData}
                feedData={feedData}
              />
            </div>
          </div>

          <div className='lg:col-span-3 col-span-4'>
            <div className='block lg:hidden m-3 p-3 rounded-lg bg-background-500'>
              <SelectField
                options={profilePageSelectOptions}
                onChange={goToSelectedPage}
                defaultValue={currentlySelectedPage}
              />
            </div>

            <Outlet
              context={
                {
                  feedId: username,
                  userId: userData?.id,
                  userData: userData,
                  feedData: feedData,
                  profileModuleMetadata: metadata,
                } as ProfilePageContextType
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
