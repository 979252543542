import { Post } from '../../api/types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

type SearchProps = {
  page: number,
  pageSize: number,
  feedType: string,
  feedId: string,
  archived: boolean,
  privacy: boolean,
  pinned: boolean,
  sponsored: boolean
}

interface FindPosts {
  loading: boolean
  total: number
  page: number
  setPage: (n: number) => void
  pageSize: number
  setPageSize: (n: number) => void
  hasMore: boolean
  items: Post[]
  Search: (p: SearchProps) => Promise<void>
}

export default function useFindPosts(): FindPosts {
  const client = useContext(AxiosContext)
  const toast = useToast()
  const [loading, setLoading] = useBoolean()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [response, setResponse] = useState<APIPaginatedResponse<Post>>({
    hasMore: false,
    offset: 0,
    pageSize: 0,
    payload: [],
    total: 0,
  })
  const [lastArgs, setLastArgs] = useState<Pick<SearchProps, "feedType" | "feedId" | "archived" | "privacy" | "pinned" | "sponsored">>({
    feedId: '',
    feedType: '',
    archived: false,
    privacy: false,
    pinned: false,
    sponsored: false,
  })

  const SearchForPosts = useCallback(async (props: SearchProps) => {
    setLastArgs({feedType: props.feedType, feedId: props.feedId, archived: props.archived, privacy: props.privacy, pinned: props.pinned, sponsored: props.sponsored})
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<Post>>(Routes.GetPosts({
        page,
        pageSize,
        feedType: props.feedType,
        feedId: props.feedId,
        archived: props.archived,
        pinned: props.pinned,
        sponsored: props.sponsored,
      }))
      if (data) {
        setResponse(data)
      }
    } catch (e) {
      console.error(e)
      toast({
        title: 'Search for posts failed',
        description: 'Something went wrong while searching for posts',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading.off()
  }, [client, page, pageSize, setLoading, toast])

  // Leaving out last args as we don't want an infinite re-run
  useEffect(() => {
    void SearchForPosts({
      page,
      pageSize,
      ...lastArgs,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchForPosts, page, pageSize])

  return {
    Search: SearchForPosts,
    hasMore: response.hasMore,
    items: response.payload ?? [],
    total: response.total,
    loading,
    page,
    setPage,
    pageSize,
    setPageSize,
  }
}