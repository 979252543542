import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Feed, Game, Post, User } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

export interface SearchResultResponse {
  users: APIPaginatedResponse<User>
  games: APIPaginatedResponse<Game>
  posts: APIPaginatedResponse<Post>
  writersBlocks: APIPaginatedResponse<Feed>
}

export function useSearch() {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [results, setResults] = useState<SearchResultResponse>()
  const [error, setError] = useState('')
  const toast = useToast()

  const search = useCallback(async (search: string) => {
    setLoading.on()
    try {
      const { data } = await client.get<SearchResultResponse>(Routes.Search(search))
      if (data) {
        setResults({
          users: data.users ?? { total: 0, payload: [], hasMore: false, pageSize: -1, page: -1 },
          games: data.games ?? { total: 0, payload: [], hasMore: false, pageSize: -1, page: -1 },
          posts: data.posts ?? { total: 0, payload: [], hasMore: false, pageSize: -1, page: -1 },
          writersBlocks: data.writersBlocks ?? { total: 0, payload: [], hasMore: false, pageSize: -1, page: -1 },
        })
      }
    } catch (e: any) {
      toast({
        title: 'Failed to load messages for conversation',
        description: 'Something went wrong while trying to load the messages for the conversation',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, setLoading, toast])

  return {
    loading,
    search,
    error,
    results,
  }
}