import React, { useMemo } from 'react'
import Button from '../../common/forms/Button'
import Spinner from '../../common/Spinner'
import Image from '../../common/forms/Image'
import { useNotifications } from '../../app/hooks/useNotifications'
import BinIcon from '../../common/icons/BinIcon'

function NotificationsPage() {

  const {
    loading,
    notifications,
  } = useNotifications()

  const items = useMemo(() => {
    return (notifications.payload ?? []).filter(n => !n.read)
  }, [notifications])

  // TODO: marking notifications as read
  // const [clearNotification, { loading: clearNotificationInflight }] =
  //   useMutation<MarkNotificationsAsReadQuery, MarkNotificationsAsReadVariables>(
  //     MARK_NOTIFICATIONS_AS_READ,
  //   )

  function dismissNotification(ids: string[]) {
    // clearNotification({
    //   variables: {
    //     ids: [...ids],
    //   },
    //   onCompleted() {
    //     setNotifications((prev) =>
    //       prev.filter((n) => !ids?.includes(n.id as string)),
    //     )
    //     refetch()
    //   },
    // })
  }

  return (
    <div className='flex space-x-3 items-center select-none text-lg relative text-center m-3 bg-background-500 p-3 rounded-xl'>
      {loading ? (
        <Spinner />
      ) : notifications.total > 0 ? (
        <div className='flex w-full flex-col gap-2'>
          {items.map((notification) => (
            <div
              className='flex bg-darkbackground-500 rounded gap-4 p-4 items-center'
              key={notification.id}
            >
              <Image
                className='h-12 w-12 rounded-lg'
                src={notification?.metadata.actor?.profilePicture}
                alt='notification_image'
              />
              <div className='text-sm'>{notification.metadata.details}</div>
              <div className='ml-auto'>
                <button
                  className='text-red-600 p-2'
                  onClick={() =>
                    dismissNotification([notification.id])
                  }
                >
                  <BinIcon />
                </button>
              </div>
            </div>
          ))}
          <Button
            trackingEventName={'notification-page-clear-notifications'}
            size='sm'
            loading={loading}
            onClick={() =>
              dismissNotification([
                ...items.map((i) => i.id),
              ])
            }
          >
            Clear notifications
          </Button>
        </div>
      ) : (
        <div className='text-center'>There are no unread notifications</div>
      )}
    </div>
  )
}

export default NotificationsPage
