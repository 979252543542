import React, { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import {
  Flex,
  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { Routes } from '../../app/helpers/RestApiRoutes'
import Button from '../forms/Button'
import Spinner from '../Spinner'

interface Props {
  feedSlug: string
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export default function RecoverMyFeedModal({
  feedSlug,
  isOpen,
  onOpen,
  onClose,
}: Props) {
  const client = useContext(AxiosContext)
  const [isLoading, setLoading] = useState<boolean>()
  const toast = useToast()


  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      await client.post(Routes.AttemptToReclaimFeed(feedSlug))
      toast({
        title: 'Created request to reclaim feed',
        description: 'Our team has received your request and will be in touch shortly.',
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
    } catch (e) {
      toast({
        title: 'Unable to reclaim feed',
        description: 'A request was not created. If you believe this is an error, please contact support.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading(false)
    onClose()
  }, [client, feedSlug, onClose, toast])



  return (
    <Modal data-testid={'required-more-info-modal'} isOpen={isOpen} size={'2xl'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={'70vw'} background={'RGB(37 40 58)'} color={'RGB(153 163 204)'}>
        <ModalHeader>Begin contain reclaim process</ModalHeader>
        <ModalBody>
          <div>
            <p className={'pb-3'}><strong>Important: We kindly ask you to take 2 minutes to read and understand this action.</strong></p>
            <p className={'pb-3'}>If this is your feed and you wish to retain ownership of it, please read the following and then click the 'Reclaim' button.</p>
            <p className={'pb-3'}>By attempting to reclaim this feed, you can transfer the ownership from the current 'brand' to your personal 'brand.'</p>
            <p className={'pb-3'}>A brand is an entity created upon registration that holds content under a license attributed to the owners of the brand. When we transfer the feed to you, you will become responsible for all the content within it.</p>
            <p className={'pb-3'}>Currently, this is a manual process and may take some time before the content ownership is transferred. Rest assured, our team is here to assist, and any misuse of this system will be addressed appropriately.</p>
          </div>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4}>
            <Button color={'primary'} trackingEventName={'abort-attempt-to-recover-feed'} onClick={onClose}>
              <span>Go back</span>
            </Button>
            <Button color={'danger'} trackingEventName={'attempt-to-recover-feed'} onClick={() => handleSave()}>
              {isLoading && <Spinner />}
              {!isLoading && (<span>Reclaim</span>)}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}