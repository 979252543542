interface Props extends React.HtmlHTMLAttributes<any> {}

function EmptySpeechBubble(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.9"
      height="21.058"
      viewBox="0 0 21.9 21.058"
      className="fill-transparent stroke-steel"
      {...props}
    >
      <g
        id="Group_1225"
        data-name="Group 1225"
        transform="translate(0.984 1.034)"
      >
        <path
          id="Path_18"
          data-name="Path 18"
          d="M650.523,2334.94c6.054,3.942,14.754-.857,14.542-8.156-.708-15.84-24.911-9.647-17.969,4.569"
          transform="translate(-645.153 -2317.445)"
        ></path>
        <path
          id="Path_19"
          data-name="Path 19"
          d="M646.788,2337.529c-3.245,6.605-2.636,5.088,3.427,3.588"
          transform="translate(-644.845 -2323.622)"
        ></path>
      </g>
    </svg>
  )
}

export default EmptySpeechBubble
