function ShardBio() {
  return (
    <svg
      id="Group_679"
      data-name="Group 679"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="27.899"
      height="25.577"
      viewBox="0 0 27.899 25.577"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="25.33"
          y1="12.789"
          x2="24.025"
          y2="12.097"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#66d3ff" />
          <stop offset="0.44" stopColor="#6c49e1" />
          <stop offset="1" stopColor="#3b287b" />
        </linearGradient>
        <radialGradient
          id="radial-gradient"
          cx="0.5"
          cy="0.5"
          r="0.375"
          gradientTransform="matrix(2.823, 0, 0, -1, -0.912, 2.104)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6c49e1" />
          <stop offset="1" stopColor="#7332ff" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.997"
          y1="0.011"
          x2="0.032"
          y2="0.951"
          xlinkHref="#linear-gradient"
        />
        <radialGradient
          id="radial-gradient-2"
          cx="0.48"
          cy="0.509"
          r="0.526"
          gradientTransform="matrix(0.814, 0.44, 218.043, -490.64, -1509.159, 3402.796)"
          xlinkHref="#radial-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="0.96"
          y1="-0.02"
          x2="0.021"
          y2="0.94"
          xlinkHref="#linear-gradient"
        />
        <radialGradient
          id="radial-gradient-3"
          r="1.186"
          gradientTransform="matrix(0.312, 0, 0, -1, 0.344, -4.949)"
          xlinkHref="#radial-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="1.044"
          y1="-0.061"
          x2="0.185"
          y2="0.864"
          xlinkHref="#linear-gradient"
        />
        <radialGradient
          id="radial-gradient-4"
          r="0.391"
          gradientTransform="matrix(2.126, 0, 0, -1, -0.563, 1.024)"
          xlinkHref="#radial-gradient"
        />
      </defs>
      <path
        id="Path_496"
        data-name="Path 496"
        d="M19.55,63.447l-1.6-3.728,1.987-6.67L21.631,59.9Z"
        transform="translate(-17.948 -48.178)"
        fill="url(#linear-gradient)"
      />
      <g id="Group_593" data-name="Group 593" transform="translate(0 4.872)">
        <path
          id="Path_497"
          data-name="Path 497"
          d="M19.841,56.97l.687,2.774-.837,1.428-.648-1.509.8-2.693m.091-3.92L17.94,59.72l1.6,3.728L21.623,59.9,19.928,53.05Z"
          transform="translate(-17.94 -53.05)"
          fill="url(#radial-gradient)"
        />
      </g>
      <path
        id="Path_498"
        data-name="Path 498"
        d="M99,59.116l2.616-5.381,11.353-7.285L104.353,57Z"
        transform="translate(-85.069 -42.712)"
        fill="url(#linear-gradient-2)"
      />
      <g
        id="Group_594"
        data-name="Group 594"
        transform="translate(13.931 3.738)"
      >
        <path
          id="Path_499"
          data-name="Path 499"
          d="M108.072,50.817l-4.348,5.327-2.64,1.041,1.332-2.736,5.656-3.63m4.9-4.367-11.353,7.281L99,59.116,104.353,57l8.615-10.554Z"
          transform="translate(-99 -46.45)"
          fill="url(#radial-gradient-2)"
        />
      </g>
      <path
        id="Path_500"
        data-name="Path 500"
        d="M60.17,139.518l5.754-4.238,13.116-1.43-12.057,5.891Z"
        transform="translate(-52.913 -115.092)"
        fill="url(#linear-gradient-3)"
      />
      <g
        id="Group_595"
        data-name="Group 595"
        transform="translate(7.257 18.758)"
      >
        <path
          id="Path_501"
          data-name="Path 501"
          d="M73.284,135.515l-6.53,3.188-3.583-.119,3.14-2.3,6.974-.76m5.761-1.672-13.116,1.43-5.759,4.238,6.812.225Z"
          transform="translate(-60.17 -133.85)"
          fill="url(#radial-gradient-3)"
        />
      </g>
      <path
        id="Path_502"
        data-name="Path 502"
        d="M38.719,50.277,37.55,38.606,49.575,24.7,47.308,42.85Z"
        transform="translate(-34.18 -24.7)"
        fill="url(#linear-gradient-4)"
      />
      <g id="Group_596" data-name="Group 596" transform="translate(3.365 0)">
        <path
          id="Path_503"
          data-name="Path 503"
          d="M48.106,27.946l-1.8,14.382L39.522,48.2,38.6,38.945l9.51-11M49.55,24.7,37.52,38.606l1.17,11.671,8.593-7.428Z"
          transform="translate(-37.52 -24.7)"
          fill="url(#radial-gradient-4)"
        />
      </g>
    </svg>
  )
}

export default ShardBio
