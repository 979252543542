import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { User } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'
import { ImageType } from 'react-images-uploading'
import { CurrentUserContext } from '../../providers/AuthProvider'

interface UpdateUserPayload {
  name?: string
  description?: string
  website?: string
  location?: string
  archived?: string
  nsfw?: string
  iconImage?: ImageType
  bannerImage?: ImageType
  coverImage?: ImageType
  topicMetadata?: string
  metadata?: string
  privacy?: string
  socialLinks?: string
}

export function useUpdateFeed(slug: string) {
  const { ClearCache } = useContext(CurrentUserContext)
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState('')
  const toast = useToast()

  const updateFeed = useCallback(async (payload: UpdateUserPayload) => {
    if (slug === "") {
      console.warn("attempting to update feed with '' as value for slug")
      return
    }
    setLoading.on()

    const formdata = new FormData()
    // Append all payload attributes into form data for API
    for (const payloadKey in payload) {
      if (['iconImage', 'bannerImage', 'coverImage'].includes(payloadKey)) {
        //TODO: Readability improvements
        const attr = (payload as any)[payloadKey] as ImageType
        if (!attr.file) {
          throw new Error(`failed add ${payloadKey} to update user request. Image Object did not have attribute file.`)
        }

        formdata.append(payloadKey, attr!.file as Blob, attr.file.name)
        continue
      }

      //TODO: Readability improvements
      formdata.append(payloadKey, (payload as any)[payloadKey] as string)
    }

    try {
      await client.put<APIPaginatedResponse<User>>(Routes.UpdateFeed(slug.toLowerCase()), formdata)
      ClearCache()
    } catch (e: any) {
      toast({
        title: 'Failed to update feed',
        description: 'Something went wrong while trying to update the feed',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [ClearCache, client, setLoading, slug, toast])

  return {
    loading,
    updateFeed,
    error,
  }
}