import React from 'react'
import classNames from 'classnames'
import Button from '../../common/forms/Button'
import Image from '../../common/forms/Image'
import InputField from '../../common/forms/InputField'
import Modal from '../../common/Modal'
import Spinner from '../../common/Spinner'
import SearchAheadField from 'src/common/forms/SearchAheadField'
import { useCallback, useContext, useRef, useState } from 'react'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import { Message, User } from '../../api/types'
import { ParseUnixTimestampToDateDistance } from '../../app/helpers/string-helpers'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useGetConversations } from '../../app/hooks/UseGetConversations'
import { useCreateConversation } from '../../app/hooks/UseCreateConversation'
import { useFindUsers } from '../../app/hooks/UseFindUsers'

const abortController = new AbortController()

function MessagingPage() {
  const { conversation: conversationId } = useParams()
  const navigate = useNavigate()
  const { user } = useContext(CurrentUserContext)
  const [newConversationModalOpen, setNewConversationModalOpen] =
    useState(false)
  const [, setMessageFilter] = useState<string>('')
  const [, setCurrentUsername] = useState<string>('')
  const [selectedConversation] = useState<string>(conversationId ?? '')
  const [usernames, setUsernames] = useState<string[]>([])

  const { createConversation, loading: createConversationLoading } = useCreateConversation()

  const {
    loading,
    conversations,
  } = useGetConversations()

  async function handleCreateConversationConfirm() {
    const conversation = await createConversation(usernames)
    if (!conversation) {
      return
    }

    setUsernames([])
    setCurrentUsername('')
    setNewConversationModalOpen(false)
    navigate(`/messaging/${conversation.id}`)
  }

  // Search ahead functions and state
  const [, setSearchAheadValue] = useState('')
  const { loading: searchForUsersLoading, users, findUsers, error: searchForErrorMessage } = useFindUsers()

  async function handleChangeSearchValue(value: string) {
    setSearchAheadValue(value)
    await new Promise((resolve) => setTimeout(resolve, 5))

    abortController.abort()
    await new Promise((resolve) => setTimeout(resolve, 5))

    if (value.length > 0 && !searchForUsersLoading) {
      console.log('searching for value', value)
      void findUsers("", value, "")
    }
  }

  function getUsersForSearch() {
    if (users) {
      return (users.payload ?? []).map((user) => ({
        value: user.username,
        image: user.profilePicture,
      }))
    }
    return []
  }

  const ConversationInnerNode = useCallback((receipients: User[], lastMessage: Message) => {
    // First recipient who isn't the current user
    let receipient = receipients.find(
      (recipient) => recipient.username !== user?.username,
    )

    if (!receipient) {
      // This is very likely a conversation with yourself
      receipient = receipients[0]
    }

    return (
      <div className='flex gap-3 bg-background-500 hover:bg-background-600 transition-colors rounded-xl p-2'>
        <Image
          src={receipient?.profilePicture}
          alt={receipient?.username + ' profile picture'}
          className='rounded-full max-h-16'
          style={{
            height: 64,
            width: 64,
          }}
        />
        <div className='flex flex-col flex-grow justify-around'>
          <div className='flex justify-between'>
            <div className='text-white'>{receipient?.username}</div>
            <div className='text-white text-sm'>
              {(lastMessage?.createdAt
                  ? ParseUnixTimestampToDateDistance(
                    +lastMessage?.createdAt / 1000,
                  )
                  : 'moments ago'
              ).toString()}
            </div>
          </div>
          <div className='text-steel'>
            {lastMessage
              ? lastMessage.message.length > 24
                ? lastMessage?.message.substring(0, 18) + '...'
                : lastMessage?.message
              : 'No messages sent'}
          </div>
        </div>
      </div>
    )
  }, [user?.username])

  const ListConversations = useCallback<() => JSX.Element>(() => {
    if (conversations.total === 0) {
      return <div className='text-center'>You have no active conversations</div>
    }

    // filter list of conversations on conversation title and recipients username
    // const filteredConversations = conversations.payload.filter((conversation) => {
    //   if (messageFilter.length === 0) {
    //     return true
    //   }
    //
    //   const conversationNameIncludesFilter = (conversation.name ?? '')
    //     .toLowerCase()
    //     .includes(messageFilter.toLowerCase())
    //   const conversationRecipientsIncludesFilter = conversation.participants.some(
    //     (recipient) =>
    //       (recipient.username ?? '')
    //         .toLowerCase()
    //         .includes(messageFilter.toLowerCase()),
    //   )
    //   console.log({
    //     conversationRecipientsIncludesFilter,
    //     conversationNameIncludesFilter,
    //   })
    //   return (
    //     conversationNameIncludesFilter || conversationRecipientsIncludesFilter
    //   )
    // })
    const defaultEmptyMessage = {
      archived: false,
      message: '',
      readAt: '',
      senderId: '',
    } // TODO: return the 'last message' with the conversation

    return (
      <React.Fragment>
        {(conversations.payload ?? []).map((conversation, index) => {
          // const totalMessages = conversation.messages.length
          // const lastMessage = conversation.messages[totalMessages - 1]
          return (
            <div key={index}>
              <Link
                to={`/messaging/${conversation.id}`}
                className='hidden lg:block'
              >
                {ConversationInnerNode(conversation.participants, defaultEmptyMessage)}
              </Link>
              <Link
                to={`/mobile/messaging/${conversation.id}`}
                className='block lg:hidden'
              >
                {ConversationInnerNode(conversation.participants, defaultEmptyMessage)}
              </Link>
            </div>
          )
        })}
      </React.Fragment>
    )

  }, [ConversationInnerNode, conversations])

  const handleChangeSearchValueReference = useRef((value: string) => {
    console.log('onChange', value)
    void handleChangeSearchValue(value)
  })

  return (
    <div className='container mx-auto my-3'>
      <div
        className='bg-background-500 rounded-xl p-3'
        style={{ height: 'calc(100vh - 99px)' }}
      >
        <div className='grid lg:grid-cols-4 h-full'>
          <div id='chat-controls' className='flex flex-col gap-3'>
            <div className='flex justify-between items-center'>
              <div>Active Chats</div>

              {/* Create Conversation Button */}
              <div>
                <Button
                  trackingEventName={'messaging-page-start-new-conversation'}
                  color='primary'
                  onClick={() =>
                    setNewConversationModalOpen(!newConversationModalOpen)
                  }
                  data-testid='start-new-conversation-button'
                >
                  +
                </Button>

                <Modal
                  title={'Create New Convesation'}
                  isOpen={newConversationModalOpen}
                  onCancel={() => {
                    setUsernames([])
                    setCurrentUsername('')
                    setNewConversationModalOpen(false)
                  }}
                  onClose={() => {
                    setUsernames([])
                    setCurrentUsername('')
                    setNewConversationModalOpen(false)
                  }}
                  onConfirm={() => {
                    void handleCreateConversationConfirm()
                  }}
                >
                  <div className='flex gap-3'>
                    <SearchAheadField
                      items={getUsersForSearch()}
                      isLoading={searchForUsersLoading}
                      errorMessage={searchForErrorMessage ?? ''}
                      showImages={true}
                      placeholder='Search for a user'
                      onChange={handleChangeSearchValueReference.current}
                      onConfirm={(value) => {
                        setSearchAheadValue('')
                        if (value) setUsernames([...usernames, value.value])
                      }}
                    />
                  </div>
                  <div className='flex'>
                    {usernames.map((username) => (
                      <div
                        className='rounded-full flex gap-3 bg-steel px-3 py-2 text-black'
                        key={username}
                      >
                        {username}
                        <button
                          id='dismiss-username px-2'
                          onClick={() =>
                            setUsernames(
                              usernames.filter((i) => i !== username),
                            )
                          }
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                  <div>{createConversationLoading && <Spinner />}</div>
                </Modal>
              </div>
              {/* End of Create Conversation Button */}
            </div>

            <InputField
              placeholder='Search...'
              type='search'
              onChange={(e) => setMessageFilter(e.currentTarget.value)}
            />
            <div
              id='contacts'
              className='flex flex-col gap-3 grow overflow-y-scroll'
              style={{ maxHeight: 'calc(100vh - 72px - 48px - 24px - 5.5rem)' }}
            >
              {loading ? <Spinner /> : <ListConversations />}
            </div>
          </div>

          <div
            className={classNames(
              'hidden lg:block col-span-3 bg-darkbackground-500 mx-3 p-3 rounded-lg h-full',
              {
                'pointer-events-none': newConversationModalOpen,
              },
            )}
          >
            <Outlet
              context={{
                selectedConversation,
                conversations,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessagingPage
