import { useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Button from '../../common/forms/Button'
import InputField from '../../common/forms/InputField'
import SelectField from '../../common/forms/SelectField'
import {
  SubmitStartupDetailsMutation,
  SubmitStartupDetailsVariables,
  SUBMIT_STARTUP_DETAILS,
} from '../../api/gql-queries/launchpad.queries'

function LaunchPadDetailsPage() {
  const { code } = useParams()

  const [idea, setIdea] = useState('')
  const [developmentState, setDevelopmentStage] = useState('n/a')
  const [location, setLocation] = useState('')
  const [funding, setFunding] = useState('n/a')
  const [success, setSuccess] = useState(false)

  const [submitStartupDetails, { loading, error }] = useMutation<
    SubmitStartupDetailsMutation,
    SubmitStartupDetailsVariables
  >(SUBMIT_STARTUP_DETAILS, {
    onCompleted: () => {
      setSuccess(true)
    },
  })

  if (!code) {
    return <Navigate replace to={'/launchpad'} />
  }

  async function Save() {
    if (funding === 'n/a') {
      return
    }

    if (developmentState === 'n/a') {
      return
    }

    await submitStartupDetails({
      variables: {
        code: code as string,
        funding: funding === 'yes',
        idea: idea,
        location: location,
        stage: developmentState,
      },
    })
  }

  return (
    <div>
      <div className="background-image">
        <img src="" alt="" />
      </div>

      <div className="md:w-1/2 w-full mx-auto pt-5 px-3">
        {!success && (
          <div className="bg-background-500 rounded-xl p-5">
            <h1 className="text-3xl text-white">Help us help you</h1>

            <p className="text-lg text-white">
              In order to help you better we need some more details from you
            </p>

            <div className="pb-9">
              <p>What is your idea?</p>
              <InputField
                value={idea}
                onChange={(e) => setIdea(e.target.value)}
              />
            </div>

            <div className="pb-9">
              <p>Have you received funding?</p>
              <SelectField
                options={{
                  'n/a': 'Please select an option',
                  yes: 'Yes, i have received funding',
                  no: 'No, i have not received funding',
                }}
                defaultValue={funding}
                onChange={(e) => setFunding(e)}
              />
            </div>

            <div className="pb-9">
              <p>Where are you based?</p>
              <InputField
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            <div className="pb-9">
              <p>What stage of development are you at?</p>
              <SelectField
                options={{
                  'n/a': 'Please select an option',
                  concept: 'Concept planning',
                  prototyping: 'Prototyping phase',
                  active: 'Active development',
                  'early-access': 'Early Access',
                  'self-published': 'Self Published',
                  other: 'Other',
                }}
                defaultValue={developmentState}
                onChange={(e) => setDevelopmentStage(e)}
              />
            </div>

            {error && (
              <div className="pb-9 text-red-500">
                <p>{error?.message ?? 'Something went wrong'}</p>
              </div>
            )}

            <div>
              <Button
                trackingEventName={'launchpad-page-details-submit-more-details'}
                color="primary"
                onClick={() => Save()}
                loading={loading}
                className="w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        )}

        {success && (
          <div className="bg-background-500 rounded-xl p-5">
            <h1 className="text-3xl text-white">Thank you</h1>
            <p className="text-lg text-white">
              We have received your details and will be in touch shortly
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default LaunchPadDetailsPage
