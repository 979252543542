import classnames from 'classnames'
import { Link } from 'react-router-dom'
import ChevronRight from '../../../common/icons/ChevronRight'

/**
 * @param activeRoute - The route that is currently active
 * @param name - The name to render on the DOM
 * @param path - The path to direct the client to upon clicking
 */
interface Props {
  activeRoute: string
  name: string
  path: string
  onClick: () => void
}

export default function NavigationSideBarItem({
  name,
  path,
  activeRoute,
  onClick,
}: Props) {
  return (
    <Link
      to={path}
      onClick={onClick}
      className={classnames(
        'text-lg mb-3 rounded-lg text-center py-3 px-5 bg-background-500 border-b border-background-600 capitalize transition-all duration-200 ease-in-out relative',
        {
          'text-white bg-primary-500': activeRoute.includes(path),
          'text-steel hover:text-white': !activeRoute.includes(path),
        },
      )}
      data-testid="navigation-sidebar-item"
    >
      <div>{name.replace('-', ' ')}</div>
      <div className="absolute top-0 bottom-0 right-3 flex flex-col justify-center">
        <ChevronRight />
      </div>
    </Link>
  )
}
