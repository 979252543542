export default function WebsiteLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.575"
      height="21.575"
      viewBox="0 0 21.575 21.575"
    >
      <g
        id="Group_1411"
        data-name="Group 1411"
        transform="translate(-1678.953 -815.543)"
      >
        <g id="link" transform="translate(1678.953 815.543)">
          <path
            id="Path_736"
            data-name="Path 736"
            style={{ fill: 'white' }}
            d="M16.138,14.545,17.989,16.4l3.7-3.7a5.235,5.235,0,0,0-7.4-7.4l-3.7,3.7,1.851,1.851,3.7-3.7a2.617,2.617,0,1,1,3.7,3.7Z"
            transform="translate(-1.649 -3.757)"
          />
          <path
            id="Path_737"
            data-name="Path 737"
            style={{ fill: 'white' }}
            d="M14.545,16.138,16.4,17.989l-3.7,3.7a5.235,5.235,0,0,1-7.4-7.4l3.7-3.7,1.851,1.851-3.7,3.7a2.618,2.618,0,1,0,3.7,3.7Z"
            transform="translate(-3.757 -1.649)"
          />
          <path
            id="Path_738"
            data-name="Path 738"
            style={{ fill: 'white' }}
            d="M16.666,11.113a1.309,1.309,0,0,0-1.851-1.851L9.262,14.814a1.309,1.309,0,0,0,1.851,1.851Z"
            transform="translate(-2.176 -2.176)"
          />
        </g>
      </g>
    </svg>
  )
}
