import gql from 'graphql-tag'
import { RegistrationKey } from '../types'

export const REGISTRATION_KEYS = gql`
  query GetRegistrationKeys(
    $type: String!
    $order: String!
    $limit: Float!
    $searchTags: [String!]!
    $page: Float!
  ) {
    RegistrationKeys(
      type: $type
      order: $order
      limit: $limit
      searchTags: $searchTags
      page: $page
    ) {
      name
      description
      usages
      keyType
      value
    }
  }
`

export interface RegistrationKeysQuery {
  RegistrationKeys: RegistrationKey[]
}
export interface RegistrationKeysVariables {
  type: string
  order: string
  limit: number
  searchTags: string[]
  page: number
}

export const REGISTRATION_KEYS_BY_TYPE = gql`
  query GetRegistrationKeysByType($type: KeyType!) {
    RegistrationKeyByType(type: $type) {
      name
      description
      usages
      keyType
      value
    }
  }
`

export interface RegistrationKeysByTypeQuery {
  RegistrationKeyByType: [RegistrationKey]
}

export interface RegistrationKeysByTypeVariables {
  type: KeyType
}

export const REGISTRATION_KEY = gql`
  query GetRegistrationKey($id: String!) {
    RegistrationKey(id: $id) {
      name
      description
      usages
      keyType
      value
    }
  }
`

export interface RegistrationKeyQuery {
  RegistrationKey: RegistrationKey
}

export interface RegistrationKeyVariables {
  id: string
}

export const VALIDATE_REGISTRATION_KEY = gql`
  query ValidateRegistrationKey($value: String!) {
    ValidateKey(value: $value)
  }
`

export interface ValidateKeyQuery {
  ValidateKey: boolean
}

export interface ValidateKeyVariables {
  value: string
}
