import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Routes } from '../../../app/helpers/RestApiRoutes'
import { useBoolean } from '@chakra-ui/react'
import { User, Post } from '../../../api/types'
import { AxiosContext } from '../../../providers/AxiosProvider'
import { Outlet, useParams } from 'react-router-dom'
import Spinner from '../../../common/Spinner'

const defaultPost: Post = {
  archived: false,
  metadata: '{}',
  author: {} as User,
  content: '',
  image: '',
  locked: false,
  nsfw: false,
  slug: '',
  pinned: false,
  reports: [],
  sponsored: false,
  tags: [],
  timesShared: 0,
  title: '',
  views: '',
  feedId: '',
  xp: 0,
  totalReports: 0,
}

export const CurrentWritersBlockPostContext = createContext<Post>(defaultPost)
export default function WritersBlockPostContext() {
  const { postId } = useParams()
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean(false)
  const [postData, setPostData] = useState<Post>(defaultPost)

  const getUserData = useCallback(async () => {
    if (!postId) {
      console.error('unable to find ID from the path parameter')
      return
    }

    const resp = await client.get(Routes.GetPost(postId))
    setPostData(resp.data)
    setLoading.off()
  }, [client, setLoading, postId])

  useEffect(() => {
    setLoading.on()

    void getUserData()
  }, [client, getUserData, setLoading])

  if (loading) {
    return <Spinner />
  }

  return (
    <CurrentWritersBlockPostContext.Provider value={postData}>
      <Outlet />
    </CurrentWritersBlockPostContext.Provider>
  )
}
