import classnames from 'classnames'

interface Props {
  isActive?: boolean
  isSolid?: boolean
}
function ShardIcon({ isActive, isSolid }: Props) {
  /* TODO: Apply an 'active' style to the shard icon. Purple when active */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.15"
      height="27.918"
      viewBox="0 0 18.15 27.918"
      className={classnames('', {
        'fill-transparent': !isSolid,
        'fill-steel hover:fill-gray-700': isSolid && !isActive,
        'stroke-steel hover:stroke-gray-700': !isActive,
        'fill-primary-500 hover:fill-primary-700': isSolid && isActive,
        'stroke-primary-500 hover:stroke-primary-700': isActive,
      })}
    >
      <path
        id="Path_5"
        data-name="Path 5"
        d="M524.863,2312.385h0l-8.075,13.712,8.075,12.206,8.075-12.206Z"
        transform="translate(-515.788 -2311.385)"
      />
    </svg>
  )
}

export default ShardIcon
