import { useState } from 'react'
import InputField from '../../common/forms/InputField'
import SearchGrid from '../../features/search/SearchGrid'

function SearchPage() {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div className="w-full p-3 flex flex-col h-full">
      <div className="bg-background-500 p-3 rounded-lg">
        <InputField
          type="search"
          placeholder="search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="mt-3 p-3 bg-background-500 flex-grow rounded-lg">
        <SearchGrid
          searchTerm={searchTerm}
          fixed={false}
          onSelect={() => {
            setSearchTerm('')
          }}
        />
      </div>
    </div>
  )
}

export default SearchPage
