import { useNavigate } from 'react-router-dom'
import { SubscriptionTier } from '../../api/types'
import Button from '../../common/forms/Button'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useContext, useMemo } from 'react'

function MySubscriptionPage() {
  const { user } = useContext(CurrentUserContext)
  const navigate = useNavigate()

  const userSubscription = useMemo(() => {
    if (!user) {
      return SubscriptionTier.None
    }

    if (!user.subscriptionTier) return SubscriptionTier.Error

    return user.subscriptionTier
  }, [user])

  if (!user) {
    return null
  }

  const SubscriptionMessage = () => {
    if (userSubscription === SubscriptionTier.Error) {
      return (
        <div className="pt-5">
          We are currently unable to check your subscription status, please come
          back later.
        </div>
      )
    }

    if (userSubscription === SubscriptionTier.None) {
      return (
        <div className="pt-5">
          <div className="pb-5">
            You do not currently have a subscription with ember
          </div>
          <Button
            trackingEventName={'my-subscription-page-compare-subscription'}
            onClick={() => navigate('/settings/subscribe')}
          >
            Compare plans
          </Button>
        </div>
      )
    }

    return (
      <div>
        {!userSubscription && (
          <div className={'bg-red-400 text-white bg-opacity-60 p-3 rounded-lg m-3'}>
            Error: Something went wrong and your subscription could not be loaded from the server
          </div>
        )}

        <p className="py-12">
          You have an Ember{' '}
          <span className="text-primary-500 font-semibold">
            {userSubscription}
          </span>{' '}
          subscription
        </p>
        <p className="pb-5">
          If you have any questions about your subscription, please contact us
          at <a href="mailto:contact@emberapp.gg">contact@emberapp.gg</a>.
        </p>
        <p className="pb-5">
          We will be happy to help you If you have an existing subcription and
          wish to cancel. Please get in touch. We are currently working hard to
          make this process as easy as possible.
        </p>

        <div className="flex w-full justify-center ">
          <Button
            trackingEventName={'my-subscription-go-to-dashboard'}
            color="primary"
            className="w-1/2"
            onClick={() => navigate('/dashboard')}
          >
            Go Back
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div
      className="w-full min-h-screen pt-5 text-lg bg-no-repeat bg-cover"
      style={{ backgroundImage: 'url("/images/ember-background-ea.jpg")' }}
    >
      <div className="container mx-auto ">
        <div className="bg-background-500 bg-opacity-90 rounded-lg p-7">
          <div className="text-3xl">My Subscriptions</div>
          <div>
            <SubscriptionMessage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MySubscriptionPage
