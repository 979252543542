import { useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import {
  GetFeedQuery,
  GetFeedVariables,
  GET_FEED,
  UpdateFeedQuery,
  UpdateFeedVariables,
  UPDATE_FEED,
} from 'src/api/gql-queries'
import SectionCard from '../../atoms/SectionCard'
import Image from 'src/common/forms/Image'
import FileField from 'src/common/forms/FileField'
import { useUploadImage } from 'src/app/hooks/useUploadImage'
import ErrorMessage from '../../molecules/ErrorMessage'
import { Navigate, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import SkeletonCard from '../../molecules/SkeletonCard'
import { CurrentUserContext } from '../../../../providers/AuthProvider'

export default function ChangeBlockPicture() {
  const { ClearCache, writersBlocks } = useContext(CurrentUserContext)
  const uploader = useUploadImage()
  const { pathname: location } = useLocation()

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const [updateFeedMuataion] = useMutation<
    UpdateFeedQuery,
    UpdateFeedVariables
  >(UPDATE_FEED)

  const getWritersBlockByName = () => {
    // Get last part of url
    const url = location.split('/')
    const name = url[url.length - 1]

    return (writersBlocks ?? []).find((block) => block.name === decodeURI(name))
  }

  const { data, loading } = useQuery<GetFeedQuery, GetFeedVariables>(GET_FEED, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      slug: getWritersBlockByName()?.id ?? '',
    },
  })

  const uploadBlockPicture = async (files: File[]) => {
    setError(false)
    setMessage('')

    if (!files[0]) return

    const url = await uploader(files[0] as any)
    await updateFeedMuataion({
      variables: {
        id: data?.Feed.id as string,
        iconImage: url || (data?.Feed.iconImage as string),
      },
    })
      .then((res) => {
        if (res.data?.UpdateFeed) {
          ClearCache()
          setMessage('Successfully uploaded your new block image image.')
        }
      })
      .catch(() => {
        setError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  // There was no communties, so there should be no form
  if (!writersBlocks) return null

  // We're loading so we should display the intent
  if (loading) return <SkeletonCard />

  // there's no WritersBlock with that name, we should get out of here
  if (!getWritersBlockByName())
    return <Navigate to="/settings/writers-blocks" />

  return (
    <SectionCard title="Change Block Icon">
      <div>
        <div className="flex space-x-3 items-center">
          <div className="bg-darkbackground-500 rounded-lg p-3">
            <Image
              src={data?.Feed.iconImage}
              style={{ height: 120, width: 120 }}
              className="rounded-lg"
            />
          </div>
          <div className="flex-1">
            <FileField
              label="Click here or Drop to upload a profile picture"
              onSubmit={uploadBlockPicture}
            />
          </div>
        </div>
        <div className="text-center">
          <ErrorMessage
            error={error}
            message={message}
            dataTestId={'block-picture'}
          />
        </div>
      </div>
    </SectionCard>
  )
}
