import React, { useCallback, useContext, useEffect, useState } from 'react'
import { APIPaginatedResponse, Routes } from '../../../app/helpers/RestApiRoutes'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink, Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead, Tr, useToast, Wrap,
} from '@chakra-ui/react'
import { AxiosContext } from '../../../providers/AxiosProvider'
import { User } from '../../../api/types'
import Image from '../../../common/forms/Image'
import Button from '../../../common/forms/Button'

export default function ManageUsersPage() {
  const client = useContext(AxiosContext)
  const toast = useToast()
  const [page, setPage] = useState<number>(0)
  //TODO: search for users by username
  //TODO: change page size
  const [pageSize] = useState<number>(20)
  const [data, setData] = useState<APIPaginatedResponse<User>>({
    pageSize: 0,
    payload: [],
    total: 0,
    offset: 0,
    hasMore: false,
  })

  const getUsers = useCallback(async (page: number, pageSize: number) => {
    try {
      const res = await client.get<APIPaginatedResponse<User>>(Routes.GetUsers(page, pageSize, '', '', 'n', 'n'))
      setData(res.data)
    } catch (e) {
      toast({
        title: 'Failed to get users',
        description: 'Something went wrong trying to get users',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, toast])

  const HandleBlockButtonClick = useCallback(async (user: User) => {
    try {
      const url = user.blocked ? Routes.admin.UnblockUser(user.id!) : Routes.admin.BlockUser(user.id!)
      await client.post(url)
      void getUsers(page, pageSize)
    } catch (e) {
      toast({
        title: 'Failed to block user your profile',
        description: 'Something went wrong while trying to update your following status',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, getUsers, page, pageSize, toast])

  const handleVerifyClick = useCallback(async (user: User) => {
    try {
      await client.post(Routes.admin.VerifyUser(user.id!))
      void getUsers(page, pageSize)
    } catch (e) {
      toast({
        title: 'Failed to block user your profile',
        description: 'Something went wrong while trying to update your following status',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, getUsers, page, pageSize, toast])

  const UserRows = useCallback(() => {
      return (
        <>
          {data.payload?.map(user => {
            return (
              <Tr>
                <Td>
                  <Image
                    src={user.profilePicture} alt='author avatar' className='w-12 h-12 rounded-2xl mr-2'
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = '/images/games/placeholder.png'
                    }} />
                </Td>
                <Td>{user.username}</Td>
                <Td>{user.privacy}</Td>
                <Td>{user.verified ? 'Yes' : 'No'}</Td>
                <Td>{user.blocked ? 'Yes' : 'No'}</Td>
                <Td>
                  <Wrap gap={2}>
                    <Button onClick={() => HandleBlockButtonClick(user)} trackingEventName={'block-user'}>
                      {user.blocked ? 'Unblock' : 'Block'}
                    </Button>
                    <Button onClick={() => handleVerifyClick(user)} trackingEventName={'block-user'}>
                      Verify
                    </Button>
                  </Wrap>
                </Td>
              </Tr>
            )
          })}
        </>
      )
    },
    [HandleBlockButtonClick, data.payload, handleVerifyClick],
  )

  useEffect(() => {
    void getUsers(page, pageSize)
  }, [getUsers, page, pageSize])

  function PreviousPage() {
    setPage((prev) => {
      if (prev - 1 < 0) {
        return 0
      }
      return prev - 1
    })
  }

  function NextPage() {
    setPage((prev) => {
      const highestPage = Math.ceil(data.total / pageSize) - 1
      if (prev + 1 > highestPage) {
        return prev
      }
      return prev + 1
    })
  }

  return (
    <div className={'container mx-auto'}>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href='/admin'>Admin</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>Manage Users</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <TableContainer my={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Username</Th>
              <Th>Privacy</Th>
              <Th>Verified</Th>
              <Th>Blocked</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <UserRows />
          </Tbody>
          <Tfoot>
            <Tr>
              <Th></Th>
              <Th>Username</Th>
              <Th>Privacy</Th>
              <Th>Verified</Th>
              <Th>Blocked</Th>
              <Th>Actions</Th>
            </Tr>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th colSpan={2}>
                <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'}>
                  <Button trackingEventName={'prev-page'} onClick={PreviousPage}>&lt;</Button>
                  {page + 1} / {Math.ceil(data.total / pageSize)}
                  <Button trackingEventName={'next-page'} onClick={NextPage}>&gt;</Button>
                </Flex>
              </Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </div>
  )
}