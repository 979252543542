import { useState } from 'react'
import SparkleIcon from './icons/SparkleIcon'

interface Props extends React.HtmlHTMLAttributes<any> {}
export default function VerifiedUserMark(props: Props) {
  // On hover tooltip
  const [tooltip, setTooltip] = useState(false)

  return (
    <div
      data-testid="verified-user-mark"
      {...props}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      <div className="relative cursor-pointer">
        <SparkleIcon />

        {tooltip && (
          <div className="absolute left-7 -top-3 w-48">
            <div className="bg-background-500 p-2 text-steel shadow-xl border border-background-600 rounded-xl w-auto">
              Verified Ember Official
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
