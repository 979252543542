interface Props extends React.HtmlHTMLAttributes<any> {}

function Controller(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.833"
      height="22.162"
      viewBox="0 0 32.833 22.162"
    >
      <path
        id="google-controller"
        {...props}
        d="M11.8,22.416,6.925,27.341a3.07,3.07,0,0,1-2.052.821A2.873,2.873,0,0,1,2,25.289v-.41L3.642,12.764A7.426,7.426,0,0,1,11.029,6H25.8a7.426,7.426,0,0,1,7.387,6.764l1.642,12.115v.41a2.873,2.873,0,0,1-2.873,2.873,3.07,3.07,0,0,1-2.052-.821l-4.876-4.925H11.8M10.208,9.283v3.283H6.925v1.642h3.283v3.283H11.85V14.208h3.283V12.567H11.85V9.283H10.208m15.6,0a1.231,1.231,0,1,0,1.231,1.231A1.231,1.231,0,0,0,25.8,9.283m-2.873,2.873a1.231,1.231,0,1,0,1.231,1.231,1.231,1.231,0,0,0-1.231-1.231m5.746,0a1.231,1.231,0,1,0,1.231,1.231,1.231,1.231,0,0,0-1.231-1.231M25.8,15.029a1.231,1.231,0,1,0,1.231,1.231A1.231,1.231,0,0,0,25.8,15.029Z"
        transform="translate(-2 -6)"
      />
    </svg>
  )
}

export default Controller
