import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../../app/helpers/RestApiRoutes'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Spinner,
  useDisclosure, useToast,
} from '@chakra-ui/react'
import Button from '../forms/Button'
import InputField from 'src/common/forms/InputField'

export function MoreUserDetailRequiredModal() {
  const client = useContext(AxiosContext)
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure()
  const [, setRequiredData] = useState<string[] | undefined>()
  const [isLoading, setLoading] = useState<boolean>()
  const [dataMap, setDataMap] = useState<Record<string, string>>({})
  const toast = useToast()

  const queryIfRequiredToProvideData = useCallback(async () => {
    try {
      const resp = await client.get(Routes.GetUserRequiredToProvideMoreDetails())

      setRequiredData(resp.data?.required_details)
      if (resp.data?.required_details?.length > 0) {
        console.log('calling on open')
        onOpen()
      }
    } catch (e) {
      console.error('Request to discover missing required data failed', e)
    }
  }, [client, onOpen])

  useEffect(() => {
    void queryIfRequiredToProvideData()
  }, [queryIfRequiredToProvideData])

  const handleSave = useCallback(async () => {
    setLoading(true)
    try {
      await client.put(Routes.UpdateUser(), dataMap)
      toast({
        title: 'Successfully updated',
        description: 'Thanks for taking the time to keep our records up to date!',
        status: 'success',
        isClosable: true,
        duration: 2000,
      })
    } catch (e) {
      toast({
        title: 'Failed to load total followers',
        description: 'Something went wrong while loading the total number of followers. Please try again later.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading(false)
    onClose()
  }, [client, dataMap, onClose, toast])

  const handleFormChange = useCallback((form: string, value: string) => {
    setDataMap({
      ...dataMap,
      [form]: value,
    })
  }, [dataMap])

  return (
    <Modal data-testid={'required-more-info-modal'} isOpen={isOpen} size={'lg'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={'70vw'} background={'RGB(37 40 58)'} color={'RGB(153 163 204)'}>
        <ModalHeader>We require some more details</ModalHeader>
        <ModalBody>
          <Box>
            <label>
              Full Name
              <InputField type={'text'}
                          onChange={(v) => handleFormChange('fullName', v.currentTarget.value)} />
            </label>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4}>
            <Button trackingEventName={'close-message-report-modal'} onClick={() => handleSave()}>
              {isLoading && <Spinner />}
              {!isLoading && (<span>Save</span>)}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}