import { Link } from 'react-router-dom'
import { Game } from '../../api/types'
import Image from '../../common/forms/Image'

interface Props {
  game: Game
}
function GameCard({ game }: Props) {
  var artwork = game.artwork.String
  return (
    <Link to={'/games/' + game.id}>
      <div className="w-full py-full bg-foreground rounded-lg">
        <Image
          className="rounded-lg object-cover object-center w-full"
          src={artwork}
          alt={`${game.title}'s artwork`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/images/games/placeholder.png'
          }}
        />
      </div>
    </Link>
  )
}

export default GameCard
