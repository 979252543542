import PostReply from './PostReply'
import PostOptions from './PostOptions'
import Image from '../forms/Image'
import ShortPostEditor, {
  PostCreateEvent,
} from '../command-palette/ShortPostEditor'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import WritersBlockPostCard from '../../pages/writers-blocks/WritersBlockPostCard'
import EmptySpeechBubble from '../icons/EmptySpeechBubble'
import ShareIcon from '../icons/ShareIcon'
import ClickableStat from './ClickableStat'
import { Post, FeedType } from '../../api/types'
import './PostCard.css'
import VerifiedUserMark from '../VerifiedUserMark'
import ShardClickableStat from './ShardClickableStat'
import React, { useMemo, useState } from 'react'
import useGetReplies from '../../app/hooks/useGetReplies'
import Spinner from '../Spinner'
import useCreatePost from '../../app/hooks/useCreatePost'
import { TextPreprocessor } from '../TextPreprocessor'
import OpenGraphPreview from '../OpenGraphPreview'

interface Props {
  post: Post
}

function PostCard({ post }: Props) {
  const [expanded, setExpanded] = useState(false)

  const { create: createReply } = useCreatePost({ feedId: post.feed?.id ?? '' })

  const doReply = async ({ content }: PostCreateEvent) => {
    if (!content || !post.id) {
      return
    }

    await createReply({
      nsfw: false,
      to: post.id,
      content: content,
    })

    void GetReplies(post.id, page, pageSize)
  }

  const {
    page,
    pageSize,
    GetReplies,
    items,
    total,
    loading,
  } = useGetReplies(post.id ?? '')

  const contextOfPost = useMemo(() => {
    switch (post.feed?.feedType) {
      case FeedType.Community:
        return (
          <div>
            {post.tags.includes('news')
              ? 'A news post for '
              : 'A community post in '}
            <Link
              to={`/communities/${post.feed?.slug}/popular`}
              className='text-steel hover:text-white font-semibold'
            >
              {post.feed?.name}
            </Link>
          </div>
        )
      case FeedType.Gallery:
        return (
          <div>
            A gallery post by
            <Link
              to={`/profile/${post.author.username}/feed`}
              className='text-steel hover:text-white'
            >
              {post.author.username}
            </Link>{' '}
            in
            <Link
              to={`/writers-block/${post.feed.name}`}
              className='text-steel hover:text-white'
            >
              ${post.feed?.name}
            </Link>
          </div>
        )
      case FeedType.UserProfile:
        return (
          <div>
            On{' '}
            <Link
              to={`/profile/${post.feed?.name}/feed`}
              className='text-steel hover:text-white'
            >
              {post.feed?.name}'s
            </Link>{' '}
            profile
          </div>
        )
      case FeedType.WritersBlock:
        return (
          <div>
            A Writers Block post by
            <Link
              to={`/profile/${post.author.username}/feed`}
              className='text-steel hover:text-white'
            >
              {post.author.username}
            </Link>{' '}
            in
            <Link
              to={`/writers-block/${post.feed.name}`}
              className='text-steel hover:text-white'
            >
              ${post.feed?.name}
            </Link>
          </div>
        )
      default:
        return <div>post.feed?.name</div>
    }
  }, [post])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const postShouldAttributeFeedAsAuthor = useMemo(
    () =>
      post.feed?.feedType === FeedType.WritersBlock ||
      (post.feed?.feedType === FeedType.Community &&
        post.tags.includes('news')),
    [post],
  )

  function PostTop() {
    if (postShouldAttributeFeedAsAuthor) {
      return <NoneUserTopic />
    }

    return (
      <div className='flex space-x-3 items-center mb-5'>
        <div
          className='author-avatar relative profile-background'
          style={{
            maxWidth: 70,
            maxHeight: 70,
          }}
        >
          <Link
            to={`/profile/${post.author.username}/feed`}
            className='relative z-10'
          >
            <Image
              style={{
                height: 70,
                width: 70,
              }}
              src={post.author.profilePicture ?? '#'}
              alt={post.author.username}
              className='rounded-lg'
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/images/games/placeholder.png'
              }}
            />
          </Link>
        </div>
        <div className='flex flex-col flex-1'>
          <div className='flex gap-3'>
            <Link
              to={`/profile/${post.author.username}/feed`}
              className='text-white hover:text-white text-lg font-medium'
            >
              {post.author.username}
            </Link>
            {post.author.verified && (
              <VerifiedUserMark className='text-sm text-center text-primary-500' />
            )}
          </div>
          <div className='text-steel'>
            <small className='text-sm'>{contextOfPost}</small>
          </div>
        </div>
        <div className='self-start'>
          <PostOptions post={post} refresh={() => window.location.reload()} />
        </div>
      </div>
    )
  }

  function NoneUserTopic() {
    let urls: { postUrl: string; feedUrl: string }
    if (post.feed?.feedType === FeedType.Community) {
      urls = {
        postUrl: `/communities/${post.feed?.slug}/news/${post.id}`,
        feedUrl: `/communties/${post.feed?.slug}/news`,
      }
    } else {
      urls = {
        postUrl: `/writers-block/${post.feed?.slug}/posts/${post.id}`,
        feedUrl: `/writers-block/${post.feed?.slug}`,
      }
    }
    return (
      <div>
        <div className='flex items-center mb-3'>
          <Image
            style={{
              height: 70,
              width: 70,
            }}
            src={post.feed?.iconImage ?? '#'}
            alt={post.author.username ?? '#'}
            className='rounded-xl h-16 '
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/images/games/placeholder.png'
            }}
          />
          <div className='flex flex-col justify-center ml-3'>
            <Link to={urls.postUrl}>
              <span
                data-testid={'post-title'}
                className='text-white hover:text-primary-200 transition-colors ease-in-out'
              >
                {post.title}
              </span>
            </Link>
            <div className='text-darksteel'>
              <span className='mr-1'>on</span>
              <Link to={urls.feedUrl}>
                <span className='text-darksteel hover:text-white transition-colors ease-in-out'>
                  {post.feed?.name}
                </span>
              </Link>
            </div>
          </div>
          <div className='flex items-start self-start ml-auto'>
            <PostOptions post={post} refresh={() => window.location.reload()} />
          </div>
        </div>
      </div>
    )
  }

  function PostContent() {
    if (post.tags.includes('news')) {
      return <>
        <TextPreprocessor text={post.content} charLimit={200} />
      </>
    }

    return <>
      <TextPreprocessor text={post.content} />
    </>
  }

  function PostContentWithAttribution() {
    if (postShouldAttributeFeedAsAuthor) {
      return (
        <WritersBlockPostCard
          slug={post.feed?.slug as string}
          post={post}
          showOptions={false}
        />
      )
    }

    return <PostContent />
  }

  const postMetadata = useMemo(() => {
    if (!post || !post.metadata) return {}

    const a = JSON.parse(post?.metadata ?? '{}')
    if (!a['opengraph']) return {}

    return a
  }, [post])

  return (
    <div className='bg-background-500 rounded-3xl p-4 mt-3 text-white text-lg'>
      <PostTop />

      <div
        className={classNames('mb-3 break-words', {
          'text-steel': post.feed?.feedType === FeedType.WritersBlock,
        })}
      >
        <PostContentWithAttribution />
      </div>

      {postMetadata['opengraph'] && (
        <div>
          <OpenGraphPreview
            url={postMetadata['opengraph']['URL']}
            title={postMetadata['opengraph']['Title']}
            description={postMetadata['opengraph']['Description']}
            favicon={postMetadata['opengraph']['Favicon']}
            image={postMetadata['opengraph']['Image']}
          />
        </div>
      )}

      {!postShouldAttributeFeedAsAuthor && post.image && (
        <div className='mb-3'>
          <Image
            src={post.image}
            alt={post.author.username}
            className='rounded-lg mx-auto object-contain'
          />
        </div>
      )}

      {!postShouldAttributeFeedAsAuthor && (
        <div className='grid grid-cols-3 gap-3 place-content-center'>
          <ShardClickableStat post={post} />
          <ClickableStat onClick={toggleExpand}>
            <div
              style={{ maxHeight: 39 }}
              className='p-3 flex justify-center items-center space-x-3'
            >
              <EmptySpeechBubble />
              <div>{loading ? <Spinner /> : total}</div>
            </div>
          </ClickableStat>
          <ClickableStat>
            <div
              style={{ maxHeight: 39 }}
              className='p-3 flex justify-center items-center space-x-3'
            >
              <ShareIcon />
              <div>0</div>
            </div>
          </ClickableStat>
        </div>
      )}



      {expanded && !postShouldAttributeFeedAsAuthor && (
        <div className='grid grid-cols-1 gap-3'>
          <ShortPostEditor
            buttonContent='Reply'
            placeholder='Reply to this post...'
            onClick={doReply}
            showNSFW={false}
          />
          {items &&
            items.map((comment) => (
              <PostReply
                key={comment.id ?? ''}
                comment={comment}
                allowReplies={true}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default PostCard
