import { BaseType } from '../base-type'
import type { PostReport } from './post-report.type'
import type { User } from '../users/user.type'
import type { Feed } from '../feed/feed.type'
import type { Reply } from './reply.type'
import { SubscriptionTier } from '../users/subscription-tier'
import { Privacy } from '../constants/privacy.enum'

export interface Post extends BaseType {
  metadata: string
  totalReports: number
  content: string
  tags: string[]
  image: string
  slug: string
  replies?: Reply[]
  title: string
  pinned: boolean
  reports: PostReport[]
  views: string
  sponsored: boolean
  locked: boolean
  nsfw: boolean
  archived: boolean
  xp: number
  timesShared: number
  sharedPost?: Post
  feed?: Feed
  feedId: string
  author: User
}

export const NewPost: Post = {
  archived: false,
  slug: '',
  metadata: '{}',
  totalReports: 0,
  feedId: '',
  author: {
    PopulatedFollowedGames: [],
    subscriptionTier: SubscriptionTier.None,
    TotalFollowers: 0,
    TotalShards: 0,
    activationCode: '',
    allowNsfw: false,
    banner: '',
    blocked: false,
    confirmed: false,
    country: '',
    description: '',
    dob: '',
    email: '',
    normalisedUsername: '',
    passwordDigest: '',
    pendingDelete: false,
    privacy: Privacy.Open,
    profileModules: [],
    profilePicture: '',
    role: "",
    tagline: '',
    updates: false,
    username: '',
    verified: false,
    xp: 0,
  },
  content: '',
  image: '',
  locked: false,
  nsfw: false,
  pinned: false,
  reports: [],
  sponsored: false,
  tags: [],
  timesShared: 0,
  title: '',
  views: '',
  xp: 0
}
