import PictureButton from '../atoms/PictureButton'

interface Props {
  src: () => JSX.Element
  value: string
  active: boolean
  options: string[]
  onChange: (e: string) => void
}

export default function VisualAssignmentButton({
  src,
  value,
  active,
  options,
  onChange,
}: Props) {
  return (
    <div className="flex justify-center relative overflow-visible" data-testid="visual-assignment-button">
      <PictureButton active={active} onClick={() => {}} src={src} />

      <select className={'bg-primary-500 absolute p-3 rounded-lg -bottom-3 -right-3 text-white'}
              value={value}
              onChange={(e) => onChange(e.currentTarget.value)}>
        {options.map((i) => (<option key={i} value={i}>{i}</option>)
        )}
      </select>
    </div>
  )
}
