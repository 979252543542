import { Link } from 'react-router-dom'

function TermsOfServicePage() {
  const microNavBar = (
    <div className="sticky top-0 right-4 bg-background-500">
      <nav className="p-3 flex justify-end space-x-3">
        <div className="nav-item active">
          <Link
            to="/login"
            className="nav-link text-steel hover:text-darksteel"
          >
            Login/Register
          </Link>
        </div>{' '}
        <div className="nav-item">
          <a
            className="text-steel hover:text-darksteel"
            target={'_blank'}
            rel="noreferrer"
            href="https://discord.com/invite/ZMk93b9"
          >
            Discord
          </a>
        </div>{' '}
        <div className="nav-item dropdown">
          <a
            className="text-steel hover:text-darksteel"
            target={'_blank'}
            rel="noreferrer"
            href="mailto:contact@emberapp.gg"
          >
            Contact Us
          </a>
        </div>
      </nav>
    </div>
  )
  return (
    <div className="bg-darkbackground-500">
      {microNavBar}
      <div className="container mx-auto text-steel pb-5 sm:px-3 px-5">
        <h1 className="text-3xl mt-5">Website Terms of Use</h1>
        <p>
          <span>
            By signing up and accessing this website you accept the below Terms
            of Use in full. Do not continue to use the Ember website if you do
            not accept all of the terms of use stated on this page.
          </span>
        </p>
        <p>
          <span>
            The following terminology applies to these Terms of Use, Privacy
            Policy and Disclaimer Notice and any or all Agreements: “Client”,
            “You” and “Your” refers to you, the person accessing this website
            and accepting the Company’s terms and conditions. “The Company”,
            “Ourselves”, “We”, “Our” “Us” and “Ember”, refers to our Company.
            “Party”, “Parties”, or “Us”, refers to both the Client and
            ourselves, or either the Client or ourselves. The “Service” refers
            to any and all functions and services provided by Ember that you,
            The Client use.
          </span>
        </p>
        <h2 className="text-2xl mt-5">1. Who May Use the Services</h2>
        <p>
          <span>
            You may use the Services only if you agree to form a binding
            contract with Ember and are not a person barred from receiving
            services under the laws of the applicable jurisdiction, for example:
            A registered sex offender. In any case, you must be at least 18+
            years old to use the Services. If you are accepting these Terms and
            using the Services on behalf of a company, organization, government,
            or other legal entity, you represent and warrant that you are
            authorized to do so and have the authority to bind such entity to
            these Terms, in which case the words “you” and “your” as used in
            these Terms shall refer to such entity.
          </span>
        </p>
        <h2 className="text-2xl mt-5">2. Privacy</h2>
        <p>
          <span>
            Our privacy policy describes how we collect &amp; handle the
            information you provide to us when you use our Services. You
            understand that through your use of the Services you consent to the
            collection and use (as set forth in the Privacy Policy) of this
            information.
          </span>
        </p>
        <p>
          <span>
            You can find our privacy policy in full{' '}
            <a href="/private-policy">here</a>
          </span>
        </p>
        <h2 className="text-2xl mt-5">3. Content</h2>
        <p>
          <span>
            You are responsible for your use of the Services and for any Content
            you provide, including compliance with applicable laws, rules, and
            regulations. You should only ever provide Content that you are
            comfortable sharing with others.
          </span>
        </p>
        <p>
          <span>
            Any use or reliance on any Content or materials posted via the
            Services or obtained by you through the Services is at your own
            risk. We do not endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy, or reliability of any Content
            or communications posted via the Services or endorse any opinions
            expressed via the Services. You understand that by using the
            Services, you may be exposed to Content that might be offensive,
            harmful, inaccurate or otherwise inappropriate, or in some cases,
            postings that have been mislabeled or are otherwise deceptive. All
            Content is the sole responsibility of the person who originated such
            Content. We may not monitor or control the Content posted via the
            Services and, we cannot take responsibility for such Content.
          </span>
        </p>
        <ul className="list-disc ml-12 my-3">
          <li>
            NSFW (Not suitable for work) content filters, Ember allows NSFW
            content on the platform, by submitting, posting or displaying
            content on or through the service that is of NSFW nature, you are
            responsible for flagging the content appropriately. Failure to do so
            will result in removal of the content from the service and possibly
            restrictions placed on your account.
          </li>
        </ul>
        <ul className="list-disc ml-12 my-3">
          <li>
            Political content, Ember is not intended for political discourse, by
            submitting, posting or displaying content on or through the service
            that is affiliated with or endorsing a political party, group or
            movement. This content will be removed from the service and
            restrictions may be placed on the account at Ember Media LTD’s
            discretion.
          </li>
        </ul>
        <p>
          <span>
            We reserve the right to remove Content that violates these Terms of
            Use. Including for example, copyright or trademark violations,
            impersonation, unlawful conduct, harassment&nbsp; abusive, tortious,
            threatening, harmful, invasive of another’s privacy, vulgar,
            defamatory, false, intentionally misleading, trade libelous,
            pornographic, obscene, patently offensive, promotes racism, bigotry,
            hatred, or physical harm of any kind against any group or
            individual.
          </span>
        </p>
        <p>
          <span>
            If you believe that your Content has been copied in a way that
            constitutes copyright infringement, please report this by sending
            the below to copyright@emberapp.gg
          </span>
        </p>
        <ul className="list-disc ml-12 my-3">
          <li>
            <span>your physical or electronic signature;</span>
          </li>
          <li>
            <span>
              identification of the copyrighted work(s) that you claim to have
              been infringed;
            </span>
          </li>
          <li>
            <span>
              &nbsp;identification of the material on our services that you
              claim is infringing and that you request us to remove;
            </span>
          </li>
          <li>
            <span>
              sufficient information to permit us to locate such material;
            </span>
          </li>
          <li>
            <span>
              &nbsp;your address, telephone number, and e-mail address;
            </span>
          </li>
          <li>
            <span>
              &nbsp;a statement that you have a good faith belief that use of
              the objectionable material is not authorized by the copyright
              owner, its agent, or under the law; and
            </span>
          </li>
          <li>
            <span>
              a statement that the information in the notification is accurate,
              and under penalty of perjury, that you are either the owner of the
              copyright that has allegedly been infringed or that you are
              authorized to act on behalf of the copyright owner.
            </span>
          </li>
        </ul>
        <p>
          <span>
            You retain your rights to any Content you submit, post or display on
            or through the Services. What’s yours is yours — you own your
            Content (and your incorporated audio, photos and videos are
            considered part of the Content).
          </span>
        </p>
        <p>
          <span>
            By submitting, posting or displaying Content on or through the
            Services, you grant us a worldwide, non-exclusive, royalty-free
            license (with the right to sublicense) to use, copy, reproduce,
            process, adapt, modify, publish, transmit, display and distribute
            such Content in any and all media or distribution methods (now known
            or later developed). This license authorizes us to make your Content
            available to the rest of the world and to let others do the same.
            You agree that this license includes the right for Ember to provide,
            promote, and improve the Services and to make Content submitted to
            or through the Services available to other companies, organizations
            or individuals for the syndication, broadcast, distribution,
            promotion or publication of such Content on other media and
            services, subject to our terms and conditions for such Content use.
            Such additional uses by Ember, or other companies, organizations or
            individuals, may be made with no compensation paid to you with
            respect to the Content that you submit, post, transmit or otherwise
            make available through the Services.
          </span>
        </p>
        <p>
          <span>
            You represent and warrant that you have, or have obtained, all
            rights, licenses, consents, permissions, power and/or authority
            necessary to grant the rights granted herein for any Content that
            you submit, post or display on or through the Services. You agree
            that such Content will not contain material subject to copyright or
            other proprietary rights, unless you have necessary permission or
            are otherwise legally entitled to post the material and to grant
            Ember the license described above.
          </span>
        </p>
        <h2 className="text-2xl mt-5">4. Using The Service</h2>
        <p>
          <span>
            The Service we provide at Ember is always changing and evolving. As
            such, the Services may change from time to time, at our discretion.
            We may stop (permanently or temporarily) providing the Services or
            any features within the Services to you or to users generally. We
            also retain the right to create limits on use and storage at our
            sole discretion at any time. We may also remove or refuse to
            distribute any Content on the Services, suspend or terminate users,
            and reclaim usernames without liability to you.
          </span>
        </p>
        <p>
          <span>
            By signing up and using Ember you agree not to misuse our service,
            upload, transmit, display, or distribute any User Content (I) that
            violates any third-party right or any intellectual property or
            proprietary right; (II) that is unlawful, harassing, abusive,
            tortious, threatening, harmful, invasive of another’s privacy,
            vulgar, defamatory, false, intentionally misleading, trade libelous,
            pornographic, obscene, patently offensive, promotes racism, bigotry,
            hatred, or physical harm of any kind against any group or
            individual; (III) that is harmful to minors in any way; or (IV) that
            is in violation of any law, regulation, or obligations or
            restrictions imposed by any third party.
          </span>
        </p>
        <p>
          <span>
            In consideration for Ember granting you access to and use of the
            Services, you agree that Ember and its third-party providers and
            partners may place advertising on the Services or in connection with
            the display of Content or information from the Services whether
            submitted by you or others.
          </span>
        </p>
        <p>
          <span>
            You may not do any of the following while accessing or using the
            Services: (I) access, tamper with, or use non-public areas of the
            Services, Embers infrastructure systems, or the technical delivery
            systems of Embers providers; (II) probe, scan, or test the
            vulnerability of any system or network or breach or circumvent any
            security or authentication measures; (III) access or search or
            attempt to access or search the Services by any means (automated or
            otherwise) other than through our currently available, published
            interfaces that are provided by Ember (and only pursuant to the
            applicable terms and conditions) (IV) forge any TCP/IP packet header
            or any part of the header information in any email or posting, or in
            any way use the Services to send altered, deceptive or false
            source-identifying information; or (V) interfere with, or disrupt,
            (or attempt to do so), the access of any user, host or network,
            including, without limitation, sending a virus, overloading (DDOS),
            flooding, spamming, mail-bombing the Services, or by scripting the
            creation of Content in such a manner as to interfere with or create
            an undue burden on the Services. We also reserve the right to
            access, read, preserve, and disclose any information as we
            reasonably believe is necessary to (I) satisfy any applicable law,
            regulation, legal process or governmental request, (II) enforce the
            Terms, including investigation of potential violations hereof, (III)
            detect, prevent, or otherwise address fraud, security or technical
            issues, (IV) respond to user support requests, or (V) protect the
            rights, property or safety of Ember, its users and the public. Ember
            does not disclose personally-identifying information to third
            parties except in accordance with our Privacy Policy.
          </span>
        </p>
        <h2 className="text-2xl mt-5">5. Liability</h2>
        <p>
          <span>
            By using the Services you agree that Ember, its parents, affiliates,
            related companies, officers, directors, employees, agents
            representatives, partners and licensors, liability is limited to the
            maximum extent permissible in your country of residence.
          </span>
        </p>
        <h2 className="text-2xl mt-5">6. Ending These Terms</h2>
        <p>
          <span>
            You may end your legal agreement with Ember at any time by
            deactivating your accounts and discontinuing your use of the
            Services. See Embers Privacy Policy for more information on what
            happens to your information after you have deactivated your account.
            We may suspend or terminate your account or cease providing you with
            all or part of the Services at any time for any or no reason,
            including, but not limited to, if we reasonably believe: (I) you
            have violated these Terms (II) you create risk or possible legal
            exposure for us; (III) your account should be removed due to
            unlawful conduct, (IV) your account should be removed due to
            prolonged inactivity; or (V) our provision of the Services to you is
            no longer commercially viable. We will make reasonable efforts to
            notify you by the email address associated with your account or the
            next time you attempt to access your account, depending on the
            circumstances. In all such cases, the Terms shall terminate,
            including, without limitation, your license to use the Services,
            except that the following sections shall continue to apply: II, III,
            V, and VI. If you believe your account was terminated in error you
            can file an appeal by contacting us at: contact@emberapp.gg
          </span>
        </p>
        <h2 className="text-2xl mt-5">7. Changing these Terms</h2>
        <p>
          <span>
            Ember reserves the right to retroactively change the terms that
            govern our relationship with you. Ember will always notify you of
            any pending changes to these terms that impact the rights or
            obligations of any party to these Terms, for example via a service
            notification or an email to the email associated with your account.
            By continuing to access or use the Services after those revisions
            become effective, you agree to be bound by the revised Terms.
          </span>
        </p>
        <p>
          <span>
            In the event that any provision of these Terms is held to be invalid
            or unenforceable, then that provision will be limited or eliminated
            to the minimum extent necessary, and the remaining provisions of
            these Terms will remain in full force and effect. Ember’s failure to
            enforce any right or provision of these Terms will not be deemed a
            waiver of such right or provision.
          </span>
        </p>
        <p>
          <span>
            If you have questions relating to our Terms please contact us:{' '}
            <a href="mailto:terms@emberapp.gg">terms@emberapp.gg</a>
          </span>
        </p>
      </div>
    </div>
  )
}

export default TermsOfServicePage
