import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Message } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

export function useCreateMessage(conversationId: string) {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState('')
  const toast = useToast()

  const sendMessage = useCallback(async (message: string) => {
    setLoading.on()
    try {
      if(message === "" || !message){
        return
      }

      const f = new FormData()
      f.append("message", message)

      await client.post<APIPaginatedResponse<Message>>(Routes.GetMessages(conversationId!, 0, 100), f, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    } catch (e: any) {
      toast({
        title: 'Failed to send messages',
        description: 'Something went wrong while trying to send a message',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, conversationId, setLoading, toast])

  return {
    loading,
    error,
    sendMessage,
  }
}