import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  ProfileModule,
  ProfileModulePosition,
  ProfileModuleType,
} from 'src/api/types'
import Button from 'src/common/forms/Button'
import SectionCard from '../../atoms/SectionCard'
import VisualAssignmentButton from '../../molecules/VisualAssignmentButton'
import BottomLeftIcon from '../../atoms/BottomLeftIcon'
import TopRightIcon from '../../atoms/TopRightIcon'
import BottomRightIcon from '../../atoms/BottomRightIcon'
import ErrorMessage from '../../molecules/ErrorMessage'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateFeed } from '../../../../app/hooks/UseUpdateFeed'
import useGetFeed from '../../../../app/hooks/useGetFeed'

export default function ChangeProfileModules() {
  const {
    user,
    ClearCache,
  } = useContext(CurrentUserContext)
  const {
    data: feed,
  } = useGetFeed(user?.username ?? '')
  const {
    updateFeed,
  } = useUpdateFeed(user?.username ?? '')

  // Get list of profile modules
  const profileModuleNames = Object.keys(ProfileModuleType)

  const [modules, setModules] = useState<ProfileModule[]>([])
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    const metadata = JSON.parse(feed?.metadata ?? '{"profileModules": []}')
    setModules(metadata.profileModules)
  }, [feed])

  const GetModuleForPosition = useCallback((mods: ProfileModule[], position: ProfileModulePosition) => {
    if (!mods || mods.length === 0) {
      return ''
    }
    const module = mods.find((module) => module.position === position)
    return module?.module.toString()
      .replace(/_/g, ' ') ?? ''
  }, [])

  async function SaveChanges() {
    setMessage('')
    setError(false)

    try {
      await updateFeed({
        metadata: JSON.stringify({ profileModules: modules }),
      })

      ClearCache()
      setMessage('Successfully updated profile modules')
    } catch (e) {
      setError(true)
      setMessage('Something went wrong. Please try again.')
    }
  }

  const setModuleToPosition = useCallback((
    position: ProfileModulePosition,
    module: string,
  ) => {
    // Check modules for this existing module in another position
    const newModule = {
      position,
      module: module as ProfileModuleType,
    }

    let copiedModules = [
      ...(modules ?? []).filter((i) => i.position !== position),
      newModule,
    ]
    setModules(copiedModules as any)
  }, [modules])

  const IconForPositionMap: Record<ProfileModulePosition, () => JSX.Element> = useMemo(() => ({
    [ProfileModulePosition.bottomLeft]: BottomLeftIcon,
    [ProfileModulePosition.topRight]: TopRightIcon,
    [ProfileModulePosition.bottomRight]: BottomRightIcon,
  }), [])

  return (
    <SectionCard title='Set profile modules'>
      <div>
        Select a module then click on the desired position to place it. Click
        save to commit your changes.
      </div>

      <div className='flex w-full justify-around'>
        {Object.values(ProfileModulePosition)
          .map((position) => (
            <VisualAssignmentButton
              key={position}
              active={false}
              src={IconForPositionMap[position]}
              value={GetModuleForPosition(modules, position)}
              options={profileModuleNames.filter((i) => i !== 'Unknown')}
              onChange={(e: string) => {
                setModuleToPosition(position, e)
              }}
            />
          ))}
      </div>

      <ErrorMessage
        dataTestId='change-profile-module'
        error={error}
        message={message}
      />

      <Button
        type='submit'
        trackingEventName={'save-profile-module-changes'}
        size='md'
        className='p-3 bg-primary-500 text-white mt-3'
        data-testid='update-profile-modules-button'
        onClick={SaveChanges}
      >
        Save Changes
      </Button>
    </SectionCard>
  )
}
