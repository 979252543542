import ProfileModule from './ProfileModule'
import { useOutletContext } from 'react-router-dom'
import Spinner from '../../common/Spinner'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PostCard from '../../common/posts/PostCard'
import { Post, ProfileModulePosition } from '../../api/types'
import ShortPostEditor, { PostCreateEvent } from '../../common/command-palette/ShortPostEditor'
import useCreatePost from 'src/app/hooks/useCreatePost'
import { Box, useBoolean } from '@chakra-ui/react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { APIPaginatedResponse, Routes } from '../../app/helpers/RestApiRoutes'
import { ProfilePageContextType } from './ProfilePage'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import React from 'react'

const defaultPaginatedResponse = {
  hasMore: false,
  total: 0,
  offset: 0,
  pageSize: 0,
  payload: [],
}

function ProfilePageFeed() {
  const {
    feedId,
    userData,
    profileModuleMetadata,
    feedData,
  } = useOutletContext<ProfilePageContextType>()
  const client = useContext(AxiosContext)

  const pageSize = 10

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useBoolean(false)
  const [data, setPosts] = useState<APIPaginatedResponse<Post>>(defaultPaginatedResponse)
  const [error, setError] = useState('')

  const { create: createPost } = useCreatePost({
    feedId: userData?.userProfileFeed?.id!,
  })

  const getPosts = useCallback(async (feedId: string, page: number, pageSize: number) => {
    setLoading.on()
    try {
      const resp = await client.get(Routes.GetPostsForFeed(feedId, page, pageSize))
      setPosts((prev) => {
        return {
          ...resp.data,
          payload: [...(prev.payload ?? []), ...(resp.data.payload ?? [])],
        }
      })
    } catch (e: any) {
      setError(e.message)
      console.log('setting error message', {e, error})
    }
    setLoading.off()
  }, [client, error, setLoading])
  
  const loadMore = useCallback( () => {
    void getPosts(feedId, page + 1, pageSize)
    setPage((prev) => prev + 1)
  }, [feedId, getPosts, page])

  // First time loading
  useEffect(() => {
    console.log('"first time" loading', {feedId, page, pageSize, loading, data, error})
    void getPosts(feedId, page, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const PostOnFeed = useCallback(async (e: PostCreateEvent) => {
    await createPost(e)
    setPage(0)
    setPosts(defaultPaginatedResponse)
  }, [createPost])

  const topRightProfileModule = useMemo(() => {
    const profileModules = profileModuleMetadata?.profileModules ?? []
    if (profileModules.length > 0) {
      return profileModules.filter(
        (i: any) => i.position === ProfileModulePosition.topRight,
      )[0]
    }
    return null
  }, [profileModuleMetadata])

  const bottomRightProfileModule = useMemo(() => {
    const profileModules = profileModuleMetadata?.profileModules ?? []
    if (profileModules.length > 0) {
      return profileModules.filter(
        (i: any) => i.position === ProfileModulePosition.bottomRight,
      )[0]
    }
    return null
  }, [profileModuleMetadata])

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: data.hasMore,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: '0px 0px 300px 0px',
  })

  return (
    <div className='lg:grid grid-cols-3 max-w-full'>
      <div className='feed-content lg:col-span-2 p-3'>
        <ShortPostEditor onClick={PostOnFeed} />

        <div>
          {(data?.payload ?? []).map((post: Post, index) => (
            <PostCard key={index} post={post} />
          ))}

          {(loading || data.hasMore) && (
            <div className='w-full text-center p-5' key={0} ref={sentryRef}>
              <Spinner />
            </div>
          )}

          {(!loading && data.hasMore) && (
            <Box>
              This user has not posted anything yet.
            </Box>)}
        </div>
      </div>

      {userData && <div className='flex-col hidden lg:flex'>
        <ProfileModule userQuery={userData} feedData={feedData} userId={userData?.id ?? ''} module={topRightProfileModule} />
        <ProfileModule userQuery={userData} feedData={feedData} userId={userData.id ?? ''} module={bottomRightProfileModule} />
      </div>}
    </div>
  )
}

export default React.memo(ProfilePageFeed)
