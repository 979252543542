import { useContext, useState } from 'react'
import InputField from '../../../common/forms/InputField'

import Spinner from '../../../common/Spinner'
import { Feed, FeedType, Privacy } from '../../../api/types'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectField from '../../../common/forms/SelectField'
import FileField, { FileFieldValue } from '../../../common/forms/FileField'
import Image from '../../../common/forms/Image'
import Button from '../../../common/forms/Button'
import { useEffect } from 'react'
import useCreateFeed from '../../../app/hooks/useCreateFeeds'
import { Routes } from '../../../app/helpers/RestApiRoutes'
import { AxiosContext } from '../../../providers/AxiosProvider'

interface Props {
  isEditing?: boolean
}

function WritersBlockCreateBlockPage({ isEditing = false }: Props) {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState<FileFieldValue>([])
  const [banner, setBanner] = useState<FileFieldValue>([])
  const [website, setWebsite] = useState('')
  const [tags, setTags] = useState('')
  const [category, setCategory] = useState('')
  const [nsfw, setNsfw] = useState(false)
  const [privacy, setPrivacy] = useState(Privacy.Open)

  const {
    Create,
    loading,
    error,
  } = useCreateFeed({ useToast: true })
  const [validationError, setValidationError] = useState('')

  // Editing mode
  const { id } = useParams()
  const [writersBlock, setWritersBlock] = useState<Feed | undefined>(undefined)
  const client = useContext(AxiosContext)

  useEffect(() => {
    if (id && isEditing) {
      client.get<Feed>(Routes.GetFeed(id.toLowerCase()))
        .then(({ data }) => {
          setTitle(data.name)
          setDescription(data.description)
          setWebsite(data.website)
          setTags(data.tags)
          setCategory(data.category.join(', '))
          setNsfw(data.nsfw)
          setWritersBlock(data)
          setPrivacy(data.privacy)
        })
    }
  }, [id, isEditing, client])

  const CreateBlock = async () => {
    //validate all fields
    if (!title || title === '') {
      setValidationError('Title is required')
      return
    }

    if (!description || description === '') {
      setValidationError('Description is required')
      return
    }

    if (!isEditing && (!image || image.length === 0)) {
      setValidationError('Image is required')
      return
    }

    if (!isEditing && (!banner || banner.length === 0)) {
      setValidationError('Banner is required')
      return
    }

    //create block
    if (isEditing) {
      if (!writersBlock) throw new Error('writersBlock is undefined')

      // TODO: update feeds
      // await updateFeedMuataion({
      //   variables: {
      //     id: writersBlock.id as string,
      //     iconImage: imageUrl || (writersBlock?.iconImage as string),
      //     bannerImage: bannerUrl || (writersBlock?.bannerImage as string),
      //     description,
      //     name: title,
      //     nsfw,
      //     tags: tags.split(',').map((tag) => tag.trim()),
      //     website,
      //     category: category.split(',').map((category) => category.trim()),
      //     location: '',
      //     privacy,
      //   },
      // })
      //   .then((result) => {
      //     console.log('success')
      //     navigate('/writers-block/' + result.data?.UpdateFeed.id)
      //   })
      //   .catch((error) => {
      //     setValidationError(error.message)
      //   })
    } else {
      const feed = await Create({
        iconImage: image,
        bannerImage: banner,
        description,
        name: title,
        nsfw,
        tags: tags,
        website,
        feedType: FeedType.WritersBlock,
        // category: category.split(',') // TODO: allow feed categories on creation
        //   .map((category) => category.trim()),
        // location: '',
        privacy: privacy,
        coverImage: undefined, // intentionally left blank for Writers Blocks
        metadata: {} // intentionally left empty for Writers Blocks
      })
      if (feed) {
        console.log('success')
        navigate('/writers-block/' + feed.slug)
      }
    }
  }

  const onIconChange = (e: FileFieldValue) => {
    setImage(e)
  }

  const onBannerChange = (e: FileFieldValue) => {
    setBanner(e)
  }

  if (isEditing && !writersBlock) {
    return (
      <div className='w-full'>
        <Spinner />
      </div>
    )
  }

  return (
    <div className='w-full bg-darkbackground-500 p-3'>
      <div className='container flex flex-col mx-auto bg-background-500 p-5 rounded-xl space-y-5 text-white'>
        <div className='text-3xl font-alice'>
          {isEditing
            ? `Editing; ${writersBlock?.name}`
            : 'Creating a new Writers Block'}
        </div>

        <div>
          <div>Title</div>
          <InputField
            type='text'
            placeholder='My awesome blog'
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
        </div>

        <div>
          <div>NSFW</div>
          <SelectField
            options={{
              no: 'No',
              yes: 'Yes',
            }}
            onChange={(e) => setNsfw(e === 'yes')}
          />
        </div>

        <div>
          <div>Privacy</div>
          <SelectField
            options={{
              [Privacy.Open]: 'Open: Anyone can see this',
              [Privacy.Invite]: 'Invite: Only people you invite can see this',
              [Privacy.Private]: 'Private: Only you can see this',
            }}
            selected={privacy + ''}
            onChange={(e) => {
              setPrivacy(e as Privacy)
            }}
          />
        </div>

        <div>
          <div>Description</div>
          <InputField
            type='text'
            placeholder='Description of my blog'
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>

        <div>
          <div>Website</div>
          <InputField
            type='text'
            placeholder='Optional: https://my-site.com'
            value={website}
            onChange={(e) => setWebsite(e.currentTarget.value)}
          />
        </div>

        {/* Categories */}
        <div>
          <div>Categories</div>
          <InputField
            type='text'
            placeholder='Optional: General, Gaming'
            value={category}
            onChange={(e) => setCategory(e.currentTarget.value)}
          />
        </div>

        <div>
          <div>Icon Image</div>
          <div className='flex space-x-3 items-center'>
            {isEditing && (
              <div className='bg-darkbackground-500 rounded-lg p-3'>
                <Image
                  src={writersBlock?.iconImage}
                  width={360}
                  height={120}
                  className='rounded-lg'
                />
              </div>
            )}
            <div className='flex-1'>
              <FileField
                label='Click here to Drop upload a writers block icon image'
                onChange={onIconChange}
                showSubmit={false}
              />
            </div>
          </div>
        </div>

        <div>
          <div>Banner Image</div>
          <div className='flex space-x-3 items-center'>
            {isEditing && (
              <div className='bg-darkbackground-500 rounded-lg p-3'>
                <Image
                  src={writersBlock?.bannerImage}
                  width={360}
                  height={120}
                  className='rounded-lg'
                />
              </div>
            )}
            <div className='flex-1'>
              <FileField
                label='Click here to Drop upload a writers block banner image'
                onChange={onBannerChange}
                showSubmit={false}
              />
            </div>
          </div>
        </div>

        <div>
          <div>Tags</div>
          <InputField
            type='text'
            placeholder='Optional: friends, family'
            value={tags}
            onChange={(e) => setTags(e.currentTarget.value)}
          />
        </div>

        <div className='flex justify-between'>
          <Link to='/writers-block'>
            <Button
              trackingEventName={'cancel_create_writers_block_feed'}
              color='secondary'
              loading={loading}
            >
              Cancel
            </Button>
          </Link>
          <Button
            trackingEventName={'create_writers_block_feed_submit'}
            color='primary'
            loading={loading}
            onClick={CreateBlock}
          >
            {loading ? <Spinner /> : isEditing ? 'Save changes' : 'Create'}
          </Button>
        </div>

        <div className='text-red-600'>{validationError || error}</div>
      </div>
    </div>
  )
}

export default WritersBlockCreateBlockPage
