import { Link } from 'react-router-dom'
import EmberFullLogo from './logos/EmberFullLogo'

function Footer() {
  return (
    <section className="bg-foreground-500 text-steel">
      <div className="container grid grid-cols-3 mx-auto pt-9">
        <div className="max-w-sm">
          <EmberFullLogo />
        </div>
        <div className="">
          <h4 className="text-2xl">Community</h4>
          <ul>
            <li className="mt-3">
              <Link to="/games">
                <span className="">Games</span>
              </Link>
            </li>
            <li className="mt-3">
              <a href="https://rkpknhex.paperform.co/">Live Streams</a>
            </li>
            <li className="mt-3">
              <a href="https://b61cb7bc.paperform.co/">Feedback</a>
            </li>
            <li className="mt-3">
              <Link to="/about">About</Link>
            </li>
            <li className="mt-3">
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li className="mt-3">
              <Link to="/private-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="">
          <p className="text-2xl">Stay Connected</p>
          <ul>
            <li className="mt-3">
              <a href="https://twitter.com/ember_gn?lang=en">Twitter</a>
            </li>
            <li className="mt-3">
              <a href="https://www.facebook.com/EmberGN/">Facebook</a>
            </li>
            <li className="mt-3">
              <a href="https://discord.com/invite/ZMk93b9">Discord</a>
            </li>
            <li className="mt-3">
              <a href="https://www.patreon.com/EmberGN">Patreon</a>
            </li>
            <li className="mt-3">
              <a href="https://www.twitch.tv/embergn">Twitch</a>
            </li>
          </ul>
        </div>
        <div className="col-span-3 mb-3 mt-9 text-center">
          © 2020 Copyright | Ember 11374709 | Ember, Level 3, Kollider, Castle
          House, Sheffield, South Yorkshire, United Kingdom, S3 8LS
        </div>
      </div>
    </section>
  )
}

export default Footer
