function NintendoSwitchLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.624"
      height="23.624"
      viewBox="0 0 23.624 23.624"
    >
      <path
        id="nintendo_switch_icon_136357"
        style={{ fill: '#fff' }}
        d="M11.5,23.734H8.048A4.195,4.195,0,0,1,3.89,19.576V8.048A4.154,4.154,0,0,1,8.048,3.914H11.5V23.734M8.048,2A6.048,6.048,0,0,0,2,8.048V19.576a6.044,6.044,0,0,0,6.048,6.048H13.4V2H8.048M5.674,9.087A2.221,2.221,0,1,0,7.906,6.867,2.221,2.221,0,0,0,5.674,9.087m14.765,3.544a2.363,2.363,0,1,1-2.4,2.362,2.364,2.364,0,0,1,2.4-2.362m-.862,12.993a6.048,6.048,0,0,0,6.048-6.048V8.048A6.044,6.044,0,0,0,19.576,2H15.761V25.624Z"
        transform="translate(-2 -2)"
      />
    </svg>
  )
}

export default NintendoSwitchLogo
