import gql from 'graphql-tag'
import { Feed, FeedTopic, FeedType, Privacy, SocialPlatform } from '../types'
import { PaginatedResult } from '../types/PaginatedResult'

export const GET_FEED = gql`
  query GetFeed($id: String, $name: String, $slug: String) {
    Feed(id: $id, name: $name, slug: $slug) {
      _id
      createdBy
      name
      slug
      description
      website
      category
      coverImage
      iconImage
      bannerImage
      location
      events {
        name
        description
        start
        end
        allDay
        singleDay
        timeszone
        repeat
        repeatEndDate
        repeatEndTimes
        title
        location
        frequency
      }
      socialPlatforms {
        _id
        name
        platform
        value
        pinned
      }
      owner {
        name
        description
        iconImage
      }
      featured
      feedType
      totalViews
      archived
      privacy
      nsfw
      tags
      topics {
        name
        topics {
          name
          topics {
            name
            topics {
              name
            }
          }
        }
      }
      IsOwner
      IsSubscribed
      TotalPosts
      TotalSubscribers
      TotalShards
      TotalViews
    }
  }
`

export interface GetFeedQuery {
  Feed: Feed
}

export interface GetFeedVariables {
  id?: string
  name?: string
  slug?: string
}

export const GET_FEEDS = gql`
  query GetFeeds(
    $type: FeedType!
    $order: String
    $limit: Float
    $searchTags: [String!]
    $categories: [String!]
    $page: Float
    $nsfw: Boolean
    $owned: Boolean
  ) {
    Feeds(
      type: $type
      order: $order
      limit: $limit
      searchTags: $searchTags
      categories: $categories
      page: $page
      nsfw: $nsfw
      owned: $owned
    ) {
      hasMore
      total
      items {
        _id
        name
        slug
        description
        category
        coverImage
        iconImage
        bannerImage
        featured
        feedType
        totalViews
        archived
        privacy
        nsfw
        tags
        TotalShards
      }
    }
    TotalFeeds(feedType: $type)
    TotalPostsByFeedType(feedType: $type)
  }
`

export interface GetFeedsQuery {
  Feeds: PaginatedResult<Feed>
  TotalFeeds: number
  TotalPostsByFeedType: number
}

export interface GetFeedsVariables {
  type?: FeedType
  order?: string
  limit?: number
  searchTags?: string[]
  page?: number
  nsfw?: boolean
  owned?: boolean
  categories?: string[]
}

export const FEED_BY_OWNER = gql`
  query FeedByOwner($username: String, $feedType: FeedType!) {
    FeedByOwner(username: $username, feedType: $feedType) {
      hasMore
      total
      items {
        _id
        name
        description
        category
        coverImage
        iconImage
        bannerImage
        featured
        feedType
        totalViews
        archived
        privacy
        nsfw
        tags
        TotalShards
        slug
      }
    }
  }
`

export interface FeedByOwnerQuery {
  FeedByOwner: PaginatedResult<Feed>
}

export interface FeedByOwnerVariables {
  username: string
  feedType: FeedType
}

export const UPDATE_FEED = gql`
  mutation UpdateFeed(
    $id: String!
    $name: String
    $description: String
    $website: String
    $category: [String!]
    $location: String
    $coverImage: String
    $bannerImage: String
    $iconImage: String
    $archived: Boolean
    $privacy: Int
    $nsfw: Boolean
    $tags: [String!]
    $topics: [FeedTopicInput!]
    $socialPlatforms: [SocialPlatformInput!]
  ) {
    UpdateFeed(
      id: $id
      name: $name
      description: $description
      website: $website
      category: $category
      location: $location
      archived: $archived
      privacy: $privacy
      coverImage: $coverImage
      bannerImage: $bannerImage
      iconImage: $iconImage
      nsfw: $nsfw
      tags: $tags
      topics: $topics
      socialPlatforms: $socialPlatforms
    ) {
      _id
      createdBy
      name
      normalisedName
      description
      website
      category
      coverImage
      iconImage
      bannerImage
      location
      events {
        name
        description
        start
        end
        allDay
        singleDay
        timeszone
        repeat
        repeatEndDate
        repeatEndTimes
        title
        location
        frequency
      }
      socialPlatforms {
        _id
        name
        platform
        value
        pinned
      }
      topics {
        name
        topics {
          name
          topics {
            name
          }
        }
      }
      featured
      feedType
      totalViews
      archived
      privacy
      nsfw
      tags
    }
  }
`

export interface UpdateFeedQuery {
  UpdateFeed: Feed
}

export interface UpdateFeedVariables {
  id: string
  name?: string
  description?: string
  website?: string
  category?: string[]
  location?: string
  coverImage?: string
  bannerImage?: string
  iconImage?: string
  archived?: boolean
  privacy?: Privacy
  nsfw?: boolean
  tags?: string[]
  topics?: FeedTopic[]
  socialPlatforms?: SocialPlatform[]
}

export const CREATE_FEED = gql`
  mutation CreateFeed(
    $name: String!
    $feedType: FeedType!
    $nsfw: Boolean!
    $description: String!
    $website: String!
    $category: [String!]
    $coverImage: String
    $iconImage: String!
    $bannerImage: String!
    $location: String!
    $tags: [String!]
    $privacy: Int
  ) {
    CreateFeed(
      name: $name
      feedType: $feedType
      nsfw: $nsfw
      description: $description
      website: $website
      category: $category
      coverImage: $coverImage
      iconImage: $iconImage
      bannerImage: $bannerImage
      location: $location
      tags: $tags
      privacy: $privacy
    ) {
      _id
    }
  }
`

export interface CreateFeedQuery {
  CreateFeed: Feed
}

export interface CreateFeedVariables {
  name: string
  feedType: string
  nsfw: boolean
  description: string
  website: string
  category: string[]
  coverImage?: string
  iconImage: string
  bannerImage: string
  location: string
  tags: string[]
  privacy: Privacy
}

export const ARCHIVE_FEED = gql`
  mutation CreateFeed($id: String) {
    ArchiveFeed(id: $id)
  }
`

export interface ArchiveFeedQuery {
  ArchiveFeed: boolean
}

export interface ArchiveFeedVariables {
  id: string
}

export const SUBSCRIBE_TO_FEED = gql`
  mutation ToggleFeedSubscription($feed: String!) {
    SubscribeToFeed(feed: $feed)
  }
`

export interface SubscribeToFeedQuery {
  SubscribeToFeed: boolean
}

export interface SubscribeToFeedVariables {
  feed: string
}

export const GET_RANDOM_FEED = gql`
  query GetRandomFeed($type: FeedType!) {
    GetRandomFeed(type: $type) {
      _id
    }
  }
`

export interface GetRandomFeedQuery {
  GetRandomFeed: Feed
}

export interface GetRandomFeedVariables {
  type: FeedType
}
