function ExitIcon() {
  return (
    <svg id="logout_black_24dp-2" xmlns="http://www.w3.org/2000/svg" width="32.206" height="32.206" viewBox="0 0 32.206 32.206">
      <g id="Group_1347" data-name="Group 1347" transform="translate(4.026 4.026)">
        <g id="Group_1346" data-name="Group 1346">
          <path id="Path_725" data-name="Path 725" d="M5.684,5.684h8.052a1.346,1.346,0,0,0,1.342-1.342h0A1.346,1.346,0,0,0,13.735,3H5.684A2.692,2.692,0,0,0,3,5.684V24.471a2.692,2.692,0,0,0,2.684,2.684h8.052a1.346,1.346,0,0,0,1.342-1.342h0a1.346,1.346,0,0,0-1.342-1.342H5.684Z" transform="translate(-3 -3)" fill="currentColor" />
          <path id="Path_726" data-name="Path 726" d="M24.633,12.656,20.889,8.912a.672.672,0,0,0-1.154.47v2.4H10.342A1.346,1.346,0,0,0,9,13.125H9a1.346,1.346,0,0,0,1.342,1.342h9.393v2.4a.664.664,0,0,0,1.141.47L24.62,13.6A.66.66,0,0,0,24.633,12.656Z" transform="translate(-0.948 -1.048)" fill="currentColor" />
        </g>
      </g>
    </svg>

  )
}

export default ExitIcon