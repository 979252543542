import SectionCard from '../../atoms/SectionCard'
import Image from 'src/common/forms/Image'
import FileField from 'src/common/forms/FileField'
import ErrorMessage from '../../molecules/ErrorMessage'
import { useContext, useState } from 'react'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'
import { AspectRatio } from '@chakra-ui/react'

export function ChangeProfileBanner() {
  const {
    user,
    ClearCache,
  } = useContext(CurrentUserContext)

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const {
    updateUser,
  } = useUpdateUser()

  const uploadProfileBanner = async (files: File[]) => {
    setError(false)
    setMessage('')

    if (!files[0]) return

    updateUser({
      bannerImage: files[0],
    })
      .then(() => {
        ClearCache()
        setMessage('Successfully uploaded your new profile banner image.')
      })
      .catch(() => {
        setError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  return (
    <SectionCard title='Change Profile Picture'>
      <div>
        <div className='text-lg font-medium'>Profile Banner</div>
        <div className='space-x-3 items-center'>
          <div className='bg-darkbackground-500 rounded-lg p-3 mx-3 mb-3'>
          <AspectRatio ratio={1140 / 288} >
            <Image
              src={user?.banner}
              className='rounded-lg'
            />
          </AspectRatio>
          </div>
          <div>
            <p className="text-sm text-gray-500 pb-3">
                Your profile banner will be visible to everyone on Ember. For best results, use an image of 1140x288px.
            </p>
          </div>
          <div className='flex-1'>
            <FileField
              label='Click here to Drop upload a profile banner'
              onSubmit={uploadProfileBanner}
            />
          </div>
        </div>
        <div className='text-center'>
          <ErrorMessage
            error={error}
            message={message}
            dataTestId={'profile-banner'}
          />
        </div>
      </div>
    </SectionCard>
  )
}
