import { useMemo } from 'react'
import { CharLimit } from '../app/helpers/string-helpers'

interface OGImage {
  Type: string
  Alt: string
  URL: string // src
  SURL: string
  Height: number
  Width: number
}

interface OGURL {
  ForceQuery: boolean
  Fragment: string
  Host: string
  OmitHost: boolean
  Opaque: string
  Path: string
  RawFragment: string
  RawPath: string
  RawQuery: string
  Scheme: string
  Source: string // destination
  User: any
  Value: string
}

interface Props {
  url: OGURL;
  title: string
  description: string
  favicon: string
  image: OGImage[]
}

export default function OpenGraphPreview({
  url,
  title,
  description,
  favicon,
  image,
}: Props) {
  const imgSrc = useMemo(() => {
    if (image && image.length > 0) {
        return image[0].URL
    }
    return favicon
  }, [favicon, image]);

  return (
    <a href={url.Value} target='_blank' rel='noopener noreferrer'>
      <div className='flex gap-3 rounded-lg border border-primary-200 p-3 mb-3'>
        <div className='aspect-video max-w-[140px]'>
          <img src={imgSrc} alt={title} className={'h-full w-full object-cover object-center overflow-hidden'} />
        </div>

        <div className='flex flex-col gap-3'>
          <div className='text-lg font-bold'>{title}</div>
          <div className='text-sm'>{CharLimit(description, 200)}</div>
        </div>
      </div>
    </a>
  )
}