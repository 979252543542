import DiscordLogo from 'src/common/brand/DiscordLogo'
import EALogo from 'src/common/brand/EALogo'
import EpicLogo from 'src/common/brand/EpicLogo'
import GogLogo from 'src/common/brand/GOGLogo'
import InstagramLogo from 'src/common/brand/InstagramLogo'
import Nintendo3DSLogo from 'src/common/brand/Nintendo3DSLogo'
import NintendoSwitchLogo from 'src/common/brand/NintendoSwitchLogo'
import PokemonGoLogo from 'src/common/brand/PokemonGoLogo'
import PSNLogo from 'src/common/brand/PSNLogo'
import StadiaLogo from 'src/common/brand/StadiaLogo'
import SteamLogo from 'src/common/brand/SteamLogo'
import TwitchLogo from 'src/common/brand/TwitchLogo'
import TwitterLogo from 'src/common/brand/TwitterLogo'
import UbisoftLogo from 'src/common/brand/UbisoftLogo'
import WebsiteLogo from 'src/common/brand/WebsiteLogo'
import XboxLogo from 'src/common/brand/XboxLogo'
import { BaseType } from '../base-type'

export interface SocialPlatform extends BaseType {
  name: string
  platform: SocialBrand
  value: string
  pinned: boolean
}
export enum SocialBrand {
  TWITTER = 'Twitter',
  TWITCH = 'Twitch',
  STEAM = 'Steam',
  STEAMCOMMUNITY = 'SteamCommunity',
  STEAMGAME = 'SteamGame',
  XBOX = 'Xbox',
  PLAYSTATION = 'Playstation',
  NINTENDOSWITCH = 'Nintendo_switch',
  NINTENDO3DS = 'Nintendo_3ds',
  STADIA = 'Stadia',
  // Luna = 'Luna',
  GOG = 'Gog',
  EPIC = 'Epic',
  EA = 'Ea',
  UBISOFT = 'Ubisoft',
  //ORIGIN = "Origin",
  // Apple = "Apple",
  // Android = "Android",
  POKEMONGO = 'PokemonGo',
  INSTAGRAM = 'Instagram',
  WEBSITE = 'Website',
  DISCORD = 'Discord',
}

type SocialBrandAttributes = {
  baseURL: string | false
  settingsTitle: string
  icon: () => JSX.Element
  bgColor: string
}

export const SocialBrandsAttributes: Record<
  SocialBrand,
  SocialBrandAttributes
> = {
  [SocialBrand.TWITTER]: {
    baseURL: 'https://twitter.com/',
    settingsTitle: 'Your Twitter handle',
    icon: TwitterLogo,
    bgColor: '#00a2f4',
  },
  [SocialBrand.TWITCH]: {
    baseURL: 'https://twitch.tv/',
    settingsTitle: 'Your Twitch handle',
    icon: TwitchLogo,
    bgColor: '#6441a5',
  },
  [SocialBrand.STEAM]: {
    baseURL: 'https://steamcommunity.com/id/',
    settingsTitle: 'Your Steam username',
    icon: SteamLogo,
    bgColor: '#00adee',
  },
  [SocialBrand.STEAMCOMMUNITY]: {
    baseURL: 'https://steamcommunity.com/groups/',
    settingsTitle: 'Your Steam community name',
    icon: SteamLogo,
    bgColor: '#00adee',
  },
  [SocialBrand.STEAMGAME]: {
    baseURL: 'https://store.steampowered.com/app/',
    settingsTitle: 'Your Steam game ID',
    icon: SteamLogo,
    bgColor: '#00adee',
  },
  [SocialBrand.XBOX]: {
    baseURL: 'https://account.xbox.com/profile?gamertag=',
    settingsTitle: 'Your Xbox gamertag',
    icon: XboxLogo,
    bgColor: '#107C10',
  },
  [SocialBrand.PLAYSTATION]: {
    baseURL: false,
    settingsTitle: 'Your Playstation username',
    icon: PSNLogo,
    bgColor: '#E4000F',
  },
  [SocialBrand.NINTENDOSWITCH]: {
    baseURL: false,
    settingsTitle: 'Your Switch Username',
    icon: NintendoSwitchLogo,
    bgColor: '#E4000F',
  },
  [SocialBrand.NINTENDO3DS]: {
    baseURL: false,
    settingsTitle: 'Your 3DS code',
    icon: Nintendo3DSLogo,
    bgColor: '#481E6A',
  },
  [SocialBrand.STADIA]: {
    baseURL: false,
    settingsTitle: 'Your Stadia username',
    icon: StadiaLogo,
    bgColor: '#AC0D57',
  },
  [SocialBrand.GOG]: {
    baseURL: false,
    settingsTitle: 'Your Gog username',
    icon: GogLogo,
    bgColor: '#6441A5',
  },
  [SocialBrand.EPIC]: {
    baseURL: false,
    settingsTitle: 'Your Epic username',
    icon: EpicLogo,
    bgColor: '#1E5B94',
  },
  [SocialBrand.EA]: {
    baseURL: false,
    settingsTitle: 'Your EA username',
    icon: EALogo,
    bgColor: '#00A2F4',
  },
  [SocialBrand.UBISOFT]: {
    baseURL: false,
    settingsTitle: 'Your Ubisoft username',
    icon: UbisoftLogo,
    bgColor: '#003087',
  },
  [SocialBrand.POKEMONGO]: {
    baseURL: false,
    settingsTitle: 'Your Trainer Code',
    icon: PokemonGoLogo,
    bgColor: '#FD4747',
  },
  [SocialBrand.INSTAGRAM]: {
    baseURL: 'https://www.instagram.com/',
    settingsTitle: 'Your Instagram username',
    icon: InstagramLogo,
    bgColor: '#E44376',
  },
  [SocialBrand.WEBSITE]: {
    baseURL: 'https://',
    settingsTitle: 'Your Website',
    icon: WebsiteLogo,
    bgColor: '#838383',
  },
  [SocialBrand.DISCORD]: {
    baseURL: 'https://discord.gg/',
    settingsTitle: 'Your discord invite code',
    icon: DiscordLogo,
    bgColor: '#7289da',
  },
}
