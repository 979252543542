import classNames from 'classnames'
import { useState } from 'react'
import ChevronRight from 'src/common/icons/ChevronRight'
import NavigationSideBar from '../../molecules/NavigationSideBar'
/**
 * @params routes - The routes to display in the sidebar
 * @params activeRoute - The route that is currently active
 */
interface Props {
  routes: { name: string; path: string }[]
  activeRoute: string
}
export default function MobileSideNav({ routes, activeRoute }: Props) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <div
        className={classNames('md:w-1/3 lg:w-1/6 h-full', {
          'hidden md:block': !isOpen,
          'md:hidden': isOpen,
        })}
        data-testid="nav-side-bar"
      >
        <NavigationSideBar
          childRoutes={routes}
          activeRoute={activeRoute}
          onChange={() => setIsOpen(!isOpen)}
        />
      </div>

      {/* The expand bar to see the side nav again */}
      <div
        className={classNames(
          'flex-shrink flex flex-col justify-center -mr-3',
          {
            'hidden md:block': isOpen,
            flex: !isOpen,
          },
        )}
        style={{ minHeight: 'calc(100vh - 72px)' }}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="expand-nav-side-bar"
      >
        <div className="p-3">
          <ChevronRight />
        </div>
      </div>
    </>
  )
}
