import ChangePasswordForm from '../organisms/account/ChangePasswordForm'
import ChangePersonalDetailsForm from '../organisms/account/ChangePersonalDataForm'
// import { CreatePasskey } from '../organisms/profile/CreatePasskey'

export default function AccountSettings() {
  return (
    <>
      <ChangePersonalDetailsForm />
      <ChangePasswordForm />
      {/* <CreatePasskey /> // Unsupported on ubuntu  */}
    </>
  )
}
