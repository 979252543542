import { ChangeNSFWConsent } from '../organisms/profile/ChangeNSFWConsent'
import { ChangeNSFWProfileContent } from '../organisms/profile/ChangeNSFWProfileContent'
import { ChangeProfileBanner } from '../organisms/profile/ChangeProfileBanner'
import ChangeProfileModules from '../organisms/profile/ChangeProfileModules'
import { ChangeProfileParts } from '../organisms/profile/ChangeProfileParts'
import { ChangeProfilePicture } from '../organisms/profile/ChangeProfilePicture'
import { ChangeUserPrivacy } from '../organisms/profile/ChangeUserPrivacy'
import SocialLinksSettingsWidget from '../organisms/SocialLinksWidget'

export default function ProfileSettings() {
  return (
    <div>
      <ChangeProfileParts />

      <ChangeProfilePicture />

      <ChangeProfileBanner />

      <ChangeProfileModules />

      <SocialLinksSettingsWidget />

      <ChangeNSFWConsent />

      <ChangeNSFWProfileContent />

      <ChangeUserPrivacy />
    </div>
  )
}
