import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, ApolloClient, InMemoryCache } from '@apollo/client'
import style from './AccountPages.module.css'
import config from '../../config'
import {
  ActivateUserQuery,
  ActivateUserVariables,
  ACTIVATE_USER,
} from '../../api/gql-queries'
import Button from '../../common/forms/Button'
import Spinner from '../../common/Spinner'

function ActivateAccountPage() {
  let [searchParams] = useSearchParams()
  let navigate = useNavigate()

  let [code] = useState(searchParams.get('code'))
  let [error, setError] = useState('')
  let [message, setMessage] = useState('')
  let [loading, setLoading] = useState(false)

  const client = new ApolloClient({
    uri: config.graphqlAPI.url,
    cache: new InMemoryCache(),
  })

  let [ActivateAccount] = useMutation<ActivateUserQuery, ActivateUserVariables>(
    ACTIVATE_USER,
    {
      client,
      onError(error) {
        setError(error.message)
      },
      onCompleted(data) {
        if (data.ActivateUser) {
          setMessage('Your account has been activated!')
          setTimeout(() => {
            navigate('/login')
          }, 3000)
        } else {
          setError(
            'Something went wrong! Please try again or contact us on our discord.',
          )
        }

        setLoading(false)
      },
    },
  )

  useMemo(() => {
    setLoading(true)
    ActivateAccount({
      variables: {
        code: code as string,
      },
    })
  }, [ActivateAccount, code])

  return (
    <div
      className={classNames(
        style.bg,
        'flex justify-center items-center w-screen h-screen',
      )}
    >
      <div
        className={classNames(
          { [style.bgMaskPrimary]: true },
          'absolute w-screen h-screen z-0',
        )}
      />
      <div className="container mx-auto px-4 xl:px-36 z-10">
        <div
          className={classNames(
            'w-full flex bg-background-500 rounded-2xl text-white',
          )}
        >
          {!code ? (
            <div className="flex flex-col w-full justify-center py-6 xl:py-12 text-steel px-5">
              Something went wrong, please click the link in your email again or
              contact our support on our discord.
              <Link to="/login">
                <Button
                  className="mt-5 w-full"
                  color="primary"
                  trackingEventName={
                    'change-password-something-wrong-navigation'
                  }
                >
                  Back to Login
                </Button>
              </Link>
            </div>
          ) : (
            <div className="flex flex-col w-full justify-center py-6 xl:py-12 px-5">
              <p className="text-lg text-white">Activating your account...</p>

              {loading && <Spinner />}

              {error && (
                <div>
                  <div className="flex flex-col w-full justify-center py-3 text-red-500">
                    Error: {error}
                  </div>

                  <Link to="/login">
                    <Button
                      className="mt-5 w-full"
                      color="primary"
                      trackingEventName={
                        'activate-account-something-wrong-navigation'
                      }
                    >
                      Back to Login
                    </Button>
                  </Link>
                </div>
              )}

              {message && (
                <div className="flex flex-col w-full justify-center py-3 text-green-500">
                  Success: {message}
                  <Link to="/login">
                    <Button
                      className="mt-5 w-full"
                      color="primary"
                      trackingEventName={
                        'activate-account-success-activation-navigation'
                      }
                    >
                      Back to Login
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ActivateAccountPage
