import { createContext, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import config from '../config'
import axios, { AxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { Routes } from '../app/helpers/RestApiRoutes'

export const AxiosContext = createContext(axios.create())

export function AxiosProvider({ children }: React.PropsWithChildren<{}>) {
  const [cookies, setCookie, removeCookie] = useCookies([config.cookies.auth_token, config.cookies.refresh_token])
  const toast = useToast()

  const axiosClient = useMemo(() => {
    let instance = axios.create({})
    if (cookies.auth_token && cookies.auth_token !== '') {
      instance = axios.create({
        headers: {
          'Authorization': 'Bearer ' + cookies.auth_token,
        },
      })
    }

    instance.interceptors.response.use(
      (response) => {
        return response
      },
      async (error: AxiosError) => {
        console.log('axios interceptor error', {
          code: error.code,
          data: error.response?.data,
        })
        const payload = error.response?.data as any
        const data = payload || {
          data: '',
          message: '',
          code: 0,
        }

        if (error?.code === '401' && data.message === 'invalid bearer token') {
          try {
            const refreshResp = await instance.get(Routes.GetRefreshTokens(cookies.refresh_token))
            if (refreshResp.status === 200) {
              const {
                accessToken,
                refreshToken,
              } = refreshResp.data
              setCookie(config.cookies.auth_token, accessToken, {
                maxAge: 15 * 60, // 15 minutes
                path: '/',
              })
              setCookie(config.cookies.refresh_token, refreshToken, {
                maxAge: 24 * 60 * 60, // 1 day
                path: '/',
              })

              if (!error.config) {
                throw new Error('no config, unable to retry request')
              }
              error.config.headers.Authorization = 'Bearer ' + accessToken
              console.log('config after successful refresh', { config: error.config })
              return instance.request(error.config)
            }
          } catch (e) {
            console.warn('failure to refresh tokens', e)
          }

          removeCookie(config.cookies.auth_token, {
            path: '/',
          })
          removeCookie(config.cookies.refresh_token, {
            path: '/',
          })
          toast({
            title: 'User session timed out',
            description: 'Your session has timed out. Please log in again.',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
        return error
      },
    )
    return instance
  }, [cookies, removeCookie, setCookie, toast])

  return (
    <AxiosContext.Provider value={axiosClient}>
      {children}
    </AxiosContext.Provider>
  )
}