function TwitchLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.926"
      height="24"
      viewBox="0 0 22.926 24"
    >
      <path
        id="iconmonstr-twitch-1"
        style={{ fill: '#fff' }}
        d="M2.149,0,.537,4.119V20.955H6.268V24H9.492l3.045-3.045h4.657l6.269-6.269V0H2.149ZM21.313,13.612l-3.582,3.582H12L8.955,20.239V17.194H4.119V2.149H21.313ZM17.731,6.269v6.262H15.582V6.269h2.149ZM12,6.269v6.262H9.851V6.269H12Z"
        transform="translate(-0.537)"
      />
    </svg>
  )
}

export default TwitchLogo
