import classNames from 'classnames'
import { useState, useEffect } from 'react'
import './ToggleSwitch.css'

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  label: string
  disabled: boolean
  icons?: {
    checked: React.ReactNode
    unchecked: React.ReactNode
  }
}

function ToggleSwitch(props: Props) {
  const [toggle, setToggle] = useState(false)
  const { defaultChecked, onChange, disabled, className } = props

  const defaultProps = {
    icons: {
      checked: <div>yes</div>, //<CheckedIcon />,
      unchecked: <div>no</div>,
    },
  }

  useEffect(() => {
    if (defaultChecked) {
      setToggle(defaultChecked)
    }
  }, [defaultChecked])

  const triggerToggle = () => {
    if (disabled) {
      return
    }

    setToggle(!toggle)

    if (typeof onChange === 'function') {
      // @ts-ignore
      onChange({ currentTarget: { value: !toggle } })
    }
  }

  const getIcon = (type: 'checked' | 'unchecked') => {
    const { icons } = props
    if (!icons) {
      return null
    }

    return icons[type] === undefined ? defaultProps.icons[type] : icons[type]
  }

  const toggleClasses = classNames(
    'wrg-toggle',
    {
      'wrg-toggle--checked': toggle,
      'wrg-toggle--disabled': disabled,
    },
    className,
  )

  return (
    <div onClick={triggerToggle} className={toggleClasses}>
      <div className="wrg-toggle-container">
        <div className="wrg-toggle-check">
          <span>{getIcon('checked')}</span>
        </div>
        <div className="wrg-toggle-uncheck">
          <span>{getIcon('unchecked')}</span>
        </div>
      </div>
      <div className="wrg-toggle-circle"></div>
      <input
        type="checkbox"
        aria-label="Toggle Button"
        className="wrg-toggle-input"
      />
    </div>
  )
}

export default ToggleSwitch
