import { Navigate, RouteObject } from 'react-router-dom'
import SettingsPage from './pages/SettingsPage'
import ProfileSettings from './pages/ProfileSettings'
import AccountSettings from './pages/AccountSettings'
import WritersBlockSettings from './pages/WritersBlockSettings'
import WritersBlockSettingsWidget from './pages/WritersBlockSettingsWidget'

export const SettingsRoutes: RouteObject = {
  path: '/settings',
  element: <SettingsPage />,
  children: [
    {
      path: 'account',
      caseSensitive: false,
      element: <AccountSettings />,
    },
    {
      path: 'profile',
      caseSensitive: false,
      element: <ProfileSettings />,
    },
    {
      path: 'writers-blocks',
      caseSensitive: false,
      element: <WritersBlockSettings />,
      children: [
        {
          path: ':blockName',
          caseSensitive: false,
          element: <WritersBlockSettingsWidget />,
        },
      ],
    },
    // {
    //   path: 'communities',
    //   caseSensitive: false,
    //   element: <CommunitySettings />,
    //   children: [
    //     {
    //       path: ':blockName',
    //       caseSensitive: false,
    //       element: <CommunitySettingsWidget />,
    //     },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="settings/index" />,
    },
  ],
}

/**
 * Helper function to get a sensible object with names and paths for the settings routes.
 */
export const GetChildRoutesNamesAndPaths = (): {
  name: string
  path: string
}[] => {
  return (
    (SettingsRoutes?.children ?? [])
      .map((route) => {
        return {
          name: route.path as string,
          path: `${SettingsRoutes.path}/${route.path}`,
        }
      })
      // Remove the wildcard route
      .filter((route) => route.name !== '*')
  )
}
