import { useCallback, useContext, useEffect, useState } from 'react'
import SelectField from '../../common/forms/SelectField'
import DashboardAdvert from './DashboardAdvert'
import DashboardStatsCard from './DashboardStatsCard'
import Image from '../../common/forms/Image'
import InfiniteScrollingFeed from 'src/common/containers/InfiniteScrollingFeed'
import TrendingOnEmber from './TrendingOnEmber'
import useCreatePost from 'src/app/hooks/useCreatePost'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { Spinner, useBoolean } from '@chakra-ui/react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { APIPaginatedResponse, Routes } from '../../app/helpers/RestApiRoutes'
import { Post } from '../../api/types'
import { PostCreateEvent } from '../../common/command-palette/ShortPostEditor'

const defaultPaginatedResponse = {
  hasMore: false,
  total: 0,
  payload: [],
  pageSize: 0,
  offset: 0,
}

const availableAdvertisements = [
  {
    image: '/images/dashboard/partners/Barclays.png',
    authorName: 'Barclays',
    link: 'https://labs.uk.barclays/gamestech/resource-hub/how-ember-wants-to-unite-gaming-communities',
  },
  {
    image: '/images/dashboard/partners/ExtraLife.png',
    authorName: 'Extra Life',
    link: 'https://extralifegaminglounge.co.uk/',
  },
  {
    image: '/images/dashboard/partners/kollider.png',
    authorName: 'Kollider',
    link: 'https://www.kollider.co/',
  },
  {
    image: '/images/dashboard/partners/NVM.png',
    authorName: 'NVM',
    link: 'https://thenvm.org/',
  },
  {
    image: '/images/dashboard/partners/SGC.png',
    authorName: 'SCG',
    link: 'https://twitter.com/sheffieldgaming?lang=en',
  },
  {
    image: '/images/dashboard/partners/Treehouse.png',
    authorName: 'Treehouse',
    link: 'https://www.treehousesheffield.com/',
  },
  {
    image: '/images/dashboard/partners/PatriotGames.png',
    authorName: 'Patriot Games',
    link: 'https://patriotgames.uk/',
  },
]

function DashboardPage() {
  const { user } = useContext(CurrentUserContext)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState<'newest' | 'popular'>('popular')
  const [feedFilter, setFeedFilter] = useState<string>('myfeed')
  const pageSize = 12

  const [loading, setLoading] = useBoolean()
  // TODO: error state
  const [, setError] = useState('')
  const [data, setData] = useState<APIPaginatedResponse<Post>>(
    defaultPaginatedResponse,
  )
  const client = useContext(AxiosContext)

  const GetFeed = useCallback(
    async (
      page: number,
      pageSize: number,
      feedFilter: string,
      order: string,
    ) => {
      setLoading.on()
      setError('')
      try {
        const { data: responseData } = await client.get<
          APIPaginatedResponse<Post>
        >(Routes.GetDashboard(page, pageSize, feedFilter === 'myfeed', order))

        setData((prev) => ({
          ...responseData,
          payload: [...(prev.payload ?? []), ...(responseData.payload ?? [])],
        }))
      } catch (e: any) {
        setError(e.message)
      }
      setLoading.off()
    },
    [client, setLoading],
  )

  useEffect(() => {
    void GetFeed(page, pageSize, feedFilter, order)
  }, [GetFeed, feedFilter, order, page])

  const performRefresh = useCallback(async (callback: () => void) => {
    setData(defaultPaginatedResponse)
    setPage(0)
    callback()
  }, [])

  const { create: createPost } = useCreatePost({
    feedId: user?.userProfileFeed?.id!,
  })

  const PostOnLoggedInUserProfileFeed = useCallback(async (e: PostCreateEvent) => {
    await createPost(e)
    setPage(0)
    setData(defaultPaginatedResponse)
    await GetFeed(0, pageSize, feedFilter, order)
  }, [GetFeed, createPost, feedFilter, order])

  const WelcomeToEmber = () => (
    <div className='w-full'>
      <Image
        src='/images/dashboard/welcome.png'
        alt='welcome to ember'
        className='w-full rounded-3xl'
      />
    </div>
  )

  function RandomisedAdverts() {
    return availableAdvertisements.sort(() => Math.random() - 0.5)
  }

  const [advertSelection] = useState(RandomisedAdverts())

  const Adverts = () => {
    // choose a random pair of adverts
    const [firstAdvert, secondAdvert] = advertSelection.slice(0, 2)

    return (
      <div className='flex flex-col space-y-3 w-full pt-5'>
        <DashboardAdvert
          src={firstAdvert.image}
          alt={firstAdvert.authorName + ' partner sponsorship'}
          href={firstAdvert.link}
          text={firstAdvert.authorName}
        />
        <DashboardAdvert
          src={secondAdvert.image}
          alt={secondAdvert.authorName + ' partner sponsorship'}
          href={secondAdvert.link}
          text={secondAdvert.authorName}
        />
      </div>
    )
  }

  const MobileAdverts = () => {
    const [firstAdvert, secondAdvert, thirdAdvert, fourthAdvert] =
      advertSelection.slice(0, 4)

    return (
      <div className='flex flex-row my-3 h-28 space-x-3 overflow-x-scroll'>
        <DashboardAdvert
          src={firstAdvert.image}
          alt={firstAdvert.authorName + ' partner sponsorship'}
          href={firstAdvert.link}
          text={firstAdvert.authorName}
        />
        <DashboardAdvert
          src={secondAdvert.image}
          alt={secondAdvert.authorName + ' partner sponsorship'}
          href={secondAdvert.link}
          text={secondAdvert.authorName}
        />
        <DashboardAdvert
          src={thirdAdvert.image}
          alt={thirdAdvert.authorName + ' partner sponsorship'}
          href={thirdAdvert.link}
          text={thirdAdvert.authorName}
        />
        <DashboardAdvert
          src={fourthAdvert.image}
          alt={fourthAdvert.authorName + ' partner sponsorship'}
          href={fourthAdvert.link}
          text={fourthAdvert.authorName}
        />
      </div>
    )
  }

  function MainFeedPane() {
    return (
      <div>
        <div className='grid grid-cols-2 gap-5 w-full t-60 sticky pb-3'>
          <div className='bg-background-500 p-3 rounded-xl'>
            <SelectField
              className='w-full'
              options={{
                myfeed: 'My Feed',
                global: 'Global',
              }}
              defaultValue={feedFilter}
              onChange={(e) => {
                performRefresh(() => setFeedFilter(e))
              }}
            />
          </div>

          <div className='bg-background-500 p-3 rounded-xl'>
            <SelectField
              className='w-full'
              options={{
                popular: 'Popular',
                newest: 'Newest',
              }}
              defaultValue={order}
              onChange={(e) => {
                performRefresh(() => setOrder(e as any))
              }}
            />
          </div>
        </div>

        {!data && loading && <Spinner />}
        {data && (
          <InfiniteScrollingFeed
            displayShortPostEditor={true}
            hasMore={data?.hasMore ?? false}
            items={data?.payload ?? []}
            loading={loading}
            pageStart={page}
            loadMore={() => {
              setLoading.on()
              setPage((prev) => prev + 1)
            }}
            shortPostEditorOnClick={PostOnLoggedInUserProfileFeed}
          />
        )}
      </div>
    )
  }

  return (
    <div className='w-full bg-darkbackground-500'>
      <div
        id='dashboard-background'
        className='fixed top-0 left-0 h-full w-full opacity-10'
        style={{
          backgroundImage: 'url(./images/dashboard/dashboard-background.png)',
        }}
      />
      <div className='mx-auto container md:grid grid-cols-12 xl:px-0 md:px-5 px-2 relative'>
        <div className='lg:col-span-3 md:col-span-4 md:flex hidden flex-col space-y-3 py-5 w-[90%] md:ml-7'>
          <DashboardStatsCard />

          <WelcomeToEmber />

          <TrendingOnEmber />
        </div>

        <div className='block md:hidden'>
          <MobileAdverts />
        </div>

        <div className='lg:col-span-6 md:col-span-8 md:p-5 md:pr-0 lg:pr-5'>
          <MainFeedPane />
        </div>

        <div className='lg:col-span-3 lg:block hidden'>
          <Adverts />
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
