function FullDiamond() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.172"
      height="32.372"
      viewBox="0 0 20.172 32.372"
    >
      <path
        id="Path_1004"
        data-name="Path 1004"
        d="M526.874,2312.385h0l-10.086,17.126,10.086,15.245,10.086-15.245Z"
        transform="translate(-516.788 -2312.385)"
        fill="currentColor"
      />
    </svg>
  )
}

export default FullDiamond
