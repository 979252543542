import { useCallback, useContext, useEffect } from 'react'
import { useState } from 'react'
import { Game } from '../../api/types'
import useDebounce from '../../app/hooks/useDebounce'
import InputField from '../../common/forms/InputField'
import Spinner from '../../common/Spinner'
import GameCard from './GameCard'
import { AxiosContext } from '../../providers/AxiosProvider'
import { APIPaginatedResponse, Routes } from '../../app/helpers/RestApiRoutes'
import useInfiniteScroll from 'react-infinite-scroll-hook'

function GamesPage() {
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [newSearchTerm, setNewSearchTerm] = useState<string | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [response, setResponse] = useState<APIPaginatedResponse<Game>>()
  const [error, setError] = useState('')
  const pageSize = 24
  const client = useContext(AxiosContext)

  useEffect(() => {
    void loadMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedSearch = useDebounce(search, 500)

  const handleSearch = useCallback(async () => {
    setLoading(true)

    try {
      const res = await client.get<APIPaginatedResponse<Game>>(Routes.GetGames(0, pageSize, debouncedSearch ?? ''))
      setResponse(res.data)
      setPage(0)
      setNewSearchTerm(debouncedSearch)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError('Unknown error')
      }
    }

    setLoading(false)
  }, [client, debouncedSearch])

  useEffect(() => {
    void handleSearch()
  }, [debouncedSearch, client, handleSearch])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }

  const loadMore = useCallback(async () => {
    setError('')
    setLoading(true)
    try {
      const res = await client.get<APIPaginatedResponse<Game>>(Routes.GetGames(page + 1, pageSize, newSearchTerm ?? ''))
      setResponse((prev) => ({
        ...prev,
        payload: [...(prev?.payload ?? []), ...(res?.data?.payload ?? [])],
        hasMore: res.data?.hasMore,
      }) as APIPaginatedResponse<Game>)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError('Unknown error')
      }
    }
    setPage((prev) => prev + 1)
    setLoading(false)
  }, [client, newSearchTerm, page])

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: Boolean(response?.hasMore),
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  return (
    <div className='bg-darkbackground-500 text-white min-h-screen w-full'>
      <div className='container mx-auto px-4 lg:pt-16 pt-4 relative'>
        {/* Games DB Hero */}
        <div
          className='h-100 rounded-xl flex flex-col w-full p-4 bg-no-repeat bg-cover relative'
          style={{ backgroundImage: 'url(/images/games-db-bg.png)' }}
        >
          <div
            data-testid='suggest-a-game'
            className='absolute bottom-3 left-3'
          >
            Can't see what you're looking for?
            <br />
            <a
              href='https://e4mupms3.paperform.co/'
              target='_blank'
              rel='noreferrer'
            >
              Suggest a game
            </a>
          </div>
          <h1 className='lg:text-5xl text-3xl'>Games Database</h1>
          <h2 className='lg:text-3xl text-lg text-steel mb-32'>
            All Games, All Platforms
          </h2>
          <div className='text-lg p-5 max-w-sm bg-background-500 rounded-xl ml-auto'>
            <div className='text-2xl font-medium'>Search Games</div>
            <div className='flex space-x-3'>
              <InputField
                id='search-field'
                type='text'
                onChange={onSearchChange}
                placeholder='Search...'
              />
            </div>
          </div>
        </div>

        {/* Games List */}
        <div className='mb-7'>
          <div className='grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-4 lg:mt-9 mt-5'>
            {error && <div>We failed to load games from the server: {error}</div>}

            {!error && (response?.payload ?? []).length === 0 && <div>No games found</div>}

            {(response?.payload ?? []).map((game, index) => <GameCard key={`${game.id}-${index}`} game={game} />)}
          </div>
          {/* </InfiniteScroll> */}
          {(loading || response?.hasMore) && (
            <div className='w-full text-center py-5' ref={sentryRef}>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GamesPage
