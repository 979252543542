import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Feed, User } from '../api/types'
import { Routes } from '../app/helpers/RestApiRoutes'
import { AxiosContext } from './AxiosProvider'

interface currentLoggedInUserContext {
  loading: boolean
  user: User | undefined
  writersBlocks: Feed[] | undefined
  communities: Feed[] | undefined
  ClearCache: () => void
}

export const CurrentUserContext = createContext<currentLoggedInUserContext>({
  user: undefined,
  ClearCache: () => {
  },
  loading: false,
  communities: [],
  writersBlocks: [],
})

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const axiosClient = useContext(AxiosContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [writersBlocks, setWritersBlocks] = useState<Feed[] | undefined>(undefined)
  const [communities, setCommunities] = useState<Feed[] | undefined>(undefined)

  const getUser = useCallback(async () => {
    setLoading(true)
    try {
      const res = await axiosClient.get<User>(Routes.GetCurrentUser())
      setUser(res.data)
      // setWritersBlocks(getFeeds('writers-block'))
      // setCommunities(getFeeds('community'))
      // setUserProfileFeed(res.data.userProfileFeed)
    } catch (e) {
      console.error('failed to get user from rest api: ', e)
    }
    setLoading(false)
  }, [axiosClient])

  // const getFeeds = useCallback(async (feedType: string) => {
  //   setLoading(true)
  //   try {
  //     const res = await axiosClient.get<Feed[]>(Routes.GetFeeds(0, 99, feedType, ))
  //     setWritersBlocks(res.data.filter((i) => i.type === 'writers-block'))
  //     setCommunities(res.data.filter((i) => i.type === 'community'))
  //   } catch (e) {
  //     console.error('failed to get feeds from rest api: ', e)
  //   }
  //   setLoading(false)
  // }, [axiosClient])

  const ClearCache = useCallback(() => {
    setUser(undefined)
    setWritersBlocks(undefined)
    setCommunities(undefined)
    void getUser()
  }, [getUser])

  useEffect(() => {
    console.log('auth provider trigger')
    void getUser()
  }, [axiosClient, getUser])

  const props = useMemo<currentLoggedInUserContext>(() => {
    return {
      loading,
      user,
      writersBlocks,
      communities,
      ClearCache,
    }
  }, [ClearCache, communities, loading, user, writersBlocks])

  return (
    <CurrentUserContext.Provider value={props}>
      {children}
    </CurrentUserContext.Provider>
  )
}