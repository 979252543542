function UbisoftLogo() {
  return (
    <svg
      id="ubisoft"
      xmlns="http://www.w3.org/2000/svg"
      width="23.9"
      height="23.9"
      viewBox="0 0 23.9 23.9"
    >
      <g id="Group_1391" data-name="Group 1391">
        <path
          id="Path_713"
          data-name="Path 713"
          style={{ fill: '#fff' }}
          d="M20.4,3.5A11.95,11.95,0,0,0,3.5,20.4,11.95,11.95,0,0,0,20.4,3.5ZM3.1,17.683a7.054,7.054,0,0,1,10.64-9.029,5.579,5.579,0,0,0-1.788-.293l-.144,0a7.766,7.766,0,0,0-6.518,11.76A10.635,10.635,0,0,1,3.1,17.683Zm13.053-3.72a4.2,4.2,0,1,1-4.2-4.2A4.206,4.206,0,0,1,16.151,13.964ZM11.95,22.5l-.164,0a6.366,6.366,0,0,1-5.342-9.563,5.6,5.6,0,1,0,11.108,1.029q0-.072,0-.144A8.456,8.456,0,0,0,1.558,10.13,10.55,10.55,0,1,1,11.95,22.5Z"
        />
      </g>
    </svg>
  )
}

export default UbisoftLogo
