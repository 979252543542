import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Conversation } from '../../api/types'
import { Routes } from '../helpers/RestApiRoutes'

export function useGetConversation(conversationId: string) {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [conversation, setConversation] = useState<Conversation>()
  const [error, setError] = useState('')
  const toast = useToast()

  const getConversations = useCallback(async () => {
    setLoading.on()
    setConversation(undefined)
    try {
      const { data } = await client.get<Conversation>(Routes.GetConversation(conversationId!))
      if (data) {
        setConversation(data)
      }
    } catch (e: any) {
      toast({
        title: 'Failed to load conversation',
        description: 'Something went wrong while trying to load the conversation',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, conversationId, setLoading, toast])

  return {
    loading,
    conversation,
    error,
    getConversations,
  }
}