function SteamLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.908"
      height="24"
      viewBox="0 0 23.908 24"
    >
      <path
        id="iconmonstr-steam-4"
        style={{ fill: '#fff' }}
        d="M24,12A12,12,0,0,1,.256,14.463l4.764,2a3.146,3.146,0,0,0,6.231-.595l.007,0,3.6-2.684a4.092,4.092,0,1,0-4.043-4.169l-2.64,3.693a3.1,3.1,0,0,0-1.791.51L.092,10.572A12,12,0,0,1,24,12ZM7.423,17.477l-1.544-.649A2.432,2.432,0,1,0,8.106,13.42a2.412,2.412,0,0,0-.86.16l1.542.648a1.762,1.762,0,0,1-1.365,3.249Zm7.484-5.6a2.782,2.782,0,1,1,2.781-2.782,2.786,2.786,0,0,1-2.781,2.782Zm0-.682a2.1,2.1,0,1,0-2.1-2.1,2.1,2.1,0,0,0,2.1,2.1Z"
        transform="translate(-0.092)"
      />
    </svg>
  )
}

export default SteamLogo
