import gql from 'graphql-tag'
import { FeedType, Reply, Post } from '../types'
import { PaginatedResult } from '../types/PaginatedResult'

export const postFragment = gql`
  fragment PostDetails on Post {
    _id
    content
    tags
    image
    feed {
      _id
      feedType
      name
      iconImage
    }
    replies {
      _id
      author {
        _id
        username
        normalisedUsername
        profilePicture
        verified
      }
      createdAt
      content
      replies {
        _id
        author {
          _id
          username
          normalisedUsername
          profilePicture
          verified
        }
        createdAt
        content
      }
    }
    title
    pinned
    views
    sponsored
    locked
    nsfw
    archived
    timesShared
    sharedPost {
      content
      tags
      image
      replies {
        _id
      }
      title
      pinned
      createdAt
      views
      sponsored
      locked
      nsfw
      archived
      timesShared
    }
    author {
      _id
      username
      normalisedUsername
      profilePicture
      verified
    }
  }
`

const replyFragment = gql`
  fragment replyDetails on Reply {
    _id
    content
    createdAt
    author {
      _id
      username
      profilePicture
      verified
    }
  }
`
const finalReplyFragment = gql`
  fragment finalReplyDetails on FinalReply {
    _id
    content
    createdAt
    author {
      _id
      username
      profilePicture
      verified
    }
  }
`

export const ARCHIVE_POST = gql`
  mutation ArchivePost($postId: String!, $status: Boolean!) {
    ArchivePost(postId: $postId, status: $status)
  }
`

export interface ArchivePostMutation {
  ArchivePost: boolean
}

export interface ArchivePostVariables {
  postId: string
  status: boolean
}

export const UPDATE_POST = gql`
  mutation UpdatePost(
    $id: String!
    $content: String
    $tags: [String!]
    $image: String
    $title: String
    $pinned: Boolean
    $sponsored: Boolean
    $locked: Boolean
    $nsfw: Boolean
    $archived: Boolean
    $sharedPost: String
    $author: String
  ) {
    UpdatePost(
      id: $id
      content: $content
      tags: $tags
      image: $image
      title: $title
      pinned: $pinned
      sponsored: $sponsored
      locked: $locked
      nsfw: $nsfw
      archived: $archived
      sharedPost: $sharedPost
      author: $author
    ) {
      content
      _id
      tags
      image
      replies {
        _id
        author {
          _id
          username
          normalisedUsername
          profilePicture
          verified
        }
        content
        shards {
          giver
        }
      }
      title
      pinned
      shards {
        giver
      }
      views
      sponsored
      locked
      nsfw
      archived
      timesShared
      sharedPost {
        content
        tags
        image
        replies {
          _id
        }
        title
        pinned
        shards {
          giver
        }
        views
        sponsored
        locked
        nsfw
        archived
        timesShared
      }
      author {
        _id
        username
        normalisedUsername
        profilePicture
        verified
      }
    }
  }
`

export interface UpdatePostMutation {
  UpdatePost: Post
}

export interface UpdatePostVariables {
  id: string
  content?: string
  tags?: string[]
  image?: string
  title?: string
  pinned?: boolean
  sponsored?: boolean
  locked?: boolean
  nsfw?: boolean
  archived?: boolean
  sharedPost?: string
}

export const GET_POST = gql`
  query GetPost($id: String, $title: String, $slug: String) {
    Post(id: $id, title: $title, slug: $slug) {
      content
      _id
      tags
      image
      replies {
        _id
        author {
          _id
          username
          normalisedUsername
          profilePicture
          verified
        }
        content
        shards {
          giver
        }
      }
      title
      pinned
      shards {
        giver
      }
      views
      sponsored
      locked
      nsfw
      archived
      timesShared
      sharedPost {
        content
        tags
        image
        replies {
          _id
        }
        title
        pinned
        shards {
          giver
        }
        views
        sponsored
        locked
        nsfw
        archived
        timesShared
      }
      author {
        _id
        username
        normalisedUsername
        profilePicture
        tagline
        verified
      }
    }
  }
`

export interface GetPostQuery {
  Post: Post
}

export interface GetPostVariables {
  id?: string
  title?: string
  slug?: string
}

export const GET_RECENT_POSTS_FOR_FEED = gql`
  query RecentPostsForFeed($id: String!, $quantity: Float!) {
    RecentPostsForFeed(id: $id, quantity: $quantity) {
      hasMore
      total
      items {
        content
        tags
        image
        # replies {
        #   _id
        #   author {
        #     _id
        #     username
        #     normalisedUsername
        #     profilePicture
        #   }
        #   content
        #   timesShared
        #   locked
        #   replies {
        #     _id
        #     author {
        #       _id
        #       username
        #       normalisedUsername
        #       profilePicture
        #     }
        #     content
        #     timesShared
        #     locked
        #   }
        # }
        title
        pinned
        views
        sponsored
        locked
        nsfw
        archived
        timesShared
        # sharedPost {
        #   content
        #   tags
        #   image
        #   replies {
        #     _id
        #   }
        #   title
        #   pinned
        #   views
        #   sponsored
        #   locked
        #   nsfw
        #   archived
        #   timesShared
        # }
        author {
          _id
          username
          normalisedUsername
          profilePicture
          verified
        }
      }
    }
  }
`

export interface GetRecentPostsForFeedQuery {
  RecentPostsForFeed: PaginatedResult<Post>
}

export interface GetRecentPostsForFeedVariables {
  id: string
  quantity: number
}

export const RECENT_POSTS = gql`
  query GetPost($quantity: Float!, $type: String!) {
    RecentPostsForType(quantity: $quantity, type: $type) {
      ...PostDetails
    }
  }
  ${postFragment}
`

export interface RecentPostQuery {
  RecentPostsForType: Post[]
}

export interface RecentPostVariables {
  quantity: number
  type: 'any' | 'writersBlock' | 'community'
}

export const GET_POSTS_FOR_FEED = gql`
  query GetPostForFeed(
    $feedId: String!
    $page: Float!
    $pageSize: Float!
    $pinned: Boolean
    $order: String
    $tags: [String!]
  ) {
    GetPostsForFeed(
      feedId: $feedId
      page: $page
      pageSize: $pageSize
      pinned: $pinned
      order: $order
      tags: $tags
    ) {
      hasMore
      total
      items {
        ...PostDetails
      }
    }
  }
  ${postFragment}
`

export interface GetPostForFeedQuery {
  GetPostsForFeed: PaginatedResult<Post>
}

export interface GetPostForFeedVariables {
  feedId: string
  page: number
  pageSize: number
  pinned: boolean | null
  order: 'newest' | 'popular' | null
  tags: string[] | null
}

export const GET_POSTS = gql`
  query GetPosts(
    $page: Float!
    $order: String!
    $limit: Float!
    $searchTags: [String!]
    $type: String
    $sponsored: Boolean
    $featured: Boolean
    $feed: String
  ) {
    Posts(
      page: $page
      order: $order
      limit: $limit
      searchTags: $searchTags
      type: $type
      sponsored: $sponsored
      featured: $featured
      feed: $feed
    ) {
      hasMore
      total
      items {
        ...PostDetails
      }
    }
  }
  ${postFragment}
`

export interface GetPostsQuery {
  Posts: PaginatedResult<Post>
}

export interface GetPostsVariables {
  page: number
  order: string
  limit: number
  searchTags?: string[]
  type?: FeedType
  sponsored?: boolean
  featured?: boolean
  feed?: string
}

export const GET_DASHBOARD_FEED = gql`
  query GetDashboardFeed(
    $offset: Int
    $limit: Float!
    $subscribed: Boolean
    $order: String
    $feedType: FeedType
  ) {
    DashboardFeed(
      subscribed: $subscribed
      offset: $offset
      limit: $limit
      order: $order
      feedType: $feedType
    ) {
      items {
        ...PostDetails
      }
      total
      hasMore
    }
  }
  ${postFragment}
`

export interface DashboardFeedQuery {
  DashboardFeed: {
    items: Post[]
    total: number
    hasMore: boolean
  }
}

export interface DashboardFeedVariables {
  offset?: number
  limit: number
  subscribed?: boolean
  order?: string
  feedType?: FeedType
}

export const REPLY_TO_POST = gql`
  mutation ReplyToPost($postId: String!, $content: String!, $upload: String) {
    ReplyToPost(postId: $postId, content: $content, image: $upload) {
      ...replyDetails
      replies {
        ...finalReplyDetails
      }
    }
  }
  ${replyFragment}
  ${finalReplyFragment}
`

export interface ReplyToPostMutation {
  ReplyToPost: Reply
}

export interface ReplyToPostVariables {
  postId: string
  content: string
  image?: string
}

export const REPLY_TO_REPLY = gql`
  mutation ReplyToReply($postId: String!, $content: String!, $upload: String) {
    ReplyToReply(postId: $postId, content: $content, image: $upload) {
      ...finalReplyDetails
    }
  }
  ${finalReplyFragment}
`

export interface ReplyToReplyMutation {
  ReplyToReply: Reply
}

export interface ReplyToReplyVariables {
  postId: string
  content: string
  image?: string
}

export const CREATE_POST = gql`
  mutation CreatePost(
    $title: String
    $content: String!
    $tags: [String]!
    $image: String
    $feed: String!
    $nsfw: Boolean
  ) {
    CreatePost(
      title: $title
      content: $content
      tags: $tags
      image: $image
      feed: $feed
      nsfw: $nsfw
    ) {
      _id
    }
  }
`

export interface CreatePostMutation {
  CreatePost: { _id: string }
}

export interface CreatePostVariables {
  feed: string
  content: string
  title?: string
  tags: string[]
  image?: string
  nsfw?: boolean
}

export const REPORT_POST = gql`
  mutation CreateReportForPost($postId: String!, $reason: String!) {
    CreateReportForPost(postId: $postId, reason: $reason)
  }
`

export interface ReportPostMutation {
  CreateReportForPost: boolean
}

export interface ReportPostVariables {
  postId: string
  reason: string
}

export const REMOVE_POST = gql`
  mutation RemovePost($postId: String!) {
    RemovePost(postId: $postId)
  }
`

export interface RemovePostMutation {
  RemovePost: boolean
}

export interface RemovePostVariables {
  postId: string
}
