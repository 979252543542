import { Post } from '../../../api/types'
import { CharLimit, StripHTMLTags } from '../../../app/helpers/string-helpers'
import Image from '../../../common/forms/Image'
import EmptySpeechBubble from '../../../common/icons/EmptySpeechBubble'
import ShareIcon from '../../../common/icons/ShareIcon'
import ClickableStat from '../../../common/posts/ClickableStat'
import { Link } from 'react-router-dom'
import ShardClickableStat from '../../../common/posts/ShardClickableStat'
import { AspectRatio } from '@chakra-ui/react'

interface Props {
  post: Post
}

function WritersBlockSponsoredPost({ post }: Props) {
  return (
    <Link to={`/writers-block/${post.feed?.slug}/posts/${post.slug}`}>
      <div className='grid grid-cols-3 bg-background-500 rounded-xl'>
        <div className='col-span-2'>
          <AspectRatio ratio={16 / 9}>
            <Image
              className='w-full rounded-l-xl object-cover object-center'
              src={post.image}
              alt={post.title + ' sponsored post banner'}
            />
          </AspectRatio>
        </div>

        <div className='flex flex-col p-5'>
          <div className='sponsored-post-heading font-medium text-3xl pb-5 text-white'>
            {CharLimit(post.title, 50)}
          </div>

          <div className='sponsored-post-blurb text-darksteel'>
            {CharLimit(StripHTMLTags(post.content)
              .replace(/&nbsp;/g, ''), 132)}
          </div>

          <div className='sponsored-post-disclaimer text-white font-medium text-xs mt-auto pb-3'>
            sponsored content
          </div>

          <div className='sponsored-post-author flex justify-between text-white'>
            <div className='flex flex-grow-1 items-center'>
              <div className='author-image h-10 w-10'>
                <Image
                  className='rounded-md object-cover object-center'
                  src={post.author?.profilePicture}
                  alt={post.author?.username + ' profile picture'}
                />
              </div>

              <div className='author-name justify-between px-3'>
                {post.author?.username}
              </div>
            </div>

            <ShardClickableStat post={post} />
            <ClickableStat>
              <div
                style={{ maxHeight: 39 }}
                className='p-3 flex justify-center items-center space-x-3'
              >
                <EmptySpeechBubble />
                <div>{(post.replies ?? []).length}</div>
              </div>
            </ClickableStat>
            <ClickableStat>
              <div
                style={{ maxHeight: 39 }}
                className='p-3 flex justify-center items-center space-x-3'
              >
                <ShareIcon />
                <div>0</div>
              </div>
            </ClickableStat>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default WritersBlockSponsoredPost
