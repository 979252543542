import config from '../../config'

const baseURL = config.restAPI.url
export const Routes = {
  admin: {
    BlockUser: (userId: string) =>
      `${baseURL}/admin/user/block?user_id=${userId}`,
    UnblockUser: (userId: string) =>
      `${baseURL}/admin/user/unblock?user_id=${userId}`,
    VerifyUser: (userId: string) =>
      `${baseURL}/admin/user/verify?user_id=${userId}`,
    GetPostReports: (postId: string) =>
      `${baseURL}/admin/post_reports?post_id=${postId}`,
    MarkPostReportAsResolved: (reportId: string) =>
      `${baseURL}/admin/post_reports?report_id=${reportId}`,
    ArchivePost: (postId: any) =>
      `${baseURL}/admin/post/archive?post_id=${postId}`,
    SetPinnedPostStatus: (postId: string, shouldBePinned: boolean) =>
      `${baseURL}/admin/post/pinned?post_id=${postId}&pinned=${
        shouldBePinned ? 'y' : 'n'
      }`,
    SetSponsoredPostStatus: (postId: string, shouldBePinned: boolean) =>
      `${baseURL}/admin/post/sponsored?post_id=${postId}&sponsored=${
        shouldBePinned ? 'y' : 'n'
      }`,
  },

  stripe: {
    ListProducts: () => `${baseURL}/stripe/products`,
    CreateCheckoutSession: () => `${baseURL}/stripe/create-checkout-session`,
  },

  GetRefreshTokens: (refresh_token: string) =>
    `${baseURL}/auth/refresh?refresh_token=${refresh_token}`,
  GetCurrentUser: () => baseURL + '/user',
  GetUserRequiredToProvideMoreDetails: () => baseURL + '/user/query/required-details',
  GetUser: (username: string) => baseURL + '/user/' + username,
  GetUsers: (
    page: number,
    pageSize: number,
    id = '',
    username = '',
    confirmed = 'y',
    blocked = 'n',
  ) =>
    baseURL +
    `/users?page=${page}&page_size=${pageSize}&id=${id}&username=${username}&confirmed=${confirmed}&blocked=${blocked}`,
  GetNotifications: (page: number, pageSize: number) =>
    `${baseURL}/user/notifications?page=${page}&page_size=${pageSize}`,
  FindUsers: (
    id: any,
    username: any,
    scope: any,
    page: number,
    pageSize: number,
  ) =>
    `${baseURL}/users?id=${id}&username=${username}&scope=${scope}&page=${page}&page_size=${pageSize}`,

  GetFeed: (slug: string) => baseURL + '/feed/' + slug,
  UpdateFeed: (slug: string) => baseURL + '/feed/' + slug,
  GetFeeds: (
    page: number,
    pageSize: number,
    feedType: string,
    archived: boolean,
    privacy: string,
    user_id: string,
  ) =>
    `${baseURL}/feeds?page=${page}&page_size=${pageSize}&feed_type=${feedType}&archived=${
      archived ? 'y' : 'n'
    }&privacy=${privacy}&user_id=${user_id}`,
  CreateFeed: () => baseURL + '/feed',
  GetPostsForFeed: (feedId: string, page: number, pageSize: number) =>
    `${baseURL}/posts/${feedId}?page=${page}&page_size=${pageSize}`,
  GetRandomFeed: () => `${baseURL}/feeds/random`,

  GetPost: (id: string) => `${baseURL}/post/${id}`,
  DeletePost: (id: string)  => `${baseURL}/post/${id}`,
  GetPosts: (p : {
    page: number,
    pageSize: number,
    feedType: string,
    feedId: string,
    archived: boolean,
    sponsored: boolean,
    pinned: boolean,
  }) =>
    `${baseURL}/posts?page=${p.page}&page_size=${p.pageSize}&feed_type=${p.feedType}&feed_id=${p.feedId}&archived=${
      p.archived ? 'y' : 'n'
    }&sponsored=${p.sponsored ? 'y' : 'n'}&pinned=${p.pinned ? 'y' : 'n'}`,
  GetPostStats: (id: string) => `${baseURL}/post/stats?post_id=${id}`,
  CreatePost: () => baseURL + `/post`,
  GetRepliesForPost: (id: string, page: number, pageSize: number) =>
    `${baseURL}/post/replies?page=${page}&page_size=${pageSize}&id=${id}`,

  GetGames: (page: number, pageSize: number, name: string) =>
    `${baseURL}/games?page=${page}&page_size=${pageSize}&name=${name}`,
  GetGame: (id: string) => `${baseURL}/game/${id}`,
  ToggleFollowGame: (id: string) => `${baseURL}/game/${id}/follow`,

  GetDashboard: (
    page: number,
    pageSize: number,
    subscribed: boolean,
    order: string,
  ) =>
    `${baseURL}/dashboard?page=${page}&page_size=${pageSize}&order=${order}&subscribed=${
      subscribed ? 'y' : 'n'
    }`,
  GetTrendingPostTags: (scope: string) =>
    `${baseURL}/posts/trending-tags?scope=${scope}`,

  GetConversations: (page: number, pageSize: number) =>
    `${baseURL}/conversations?page=${page}&page_size=${pageSize}`,
  CreateConversations: () => `${baseURL}/conversations`,
  GetConversation: (id: string) => `${baseURL}/conversation/${id}`,
  GetMessages: (id: string, page: number, pageSize: number) =>
    `${baseURL}/conversation/${id}/messages?page=${page}&page_size=${pageSize}`,

  GetAdminDashboard: () => `${baseURL}/admin/stats`,
  UpdateUser: () => `${baseURL}/user/settings`,
  Login: () => `${baseURL}/auth/login`,
  Register: () => `${baseURL}/auth/register`,
  RegisterPasskey: () => `${baseURL}/auth/register-passkey`,

  Search: (search: string) => `${baseURL}/search?term=${search}`,
  FindFollowers: (feedId: string, page: number, pageSize: number) =>
    `${baseURL}/user/followers/total/${feedId}?page=${page}&page_size=${pageSize}`,
  ToggleFollowFeed: (feedId: string) =>
    `${baseURL}/feed/${feedId}/toggle-follow`,
  ForgotPassword: () => `${baseURL}/auth/forgot-password`,
  ResetPassword: () => `${baseURL}/auth/reset-password`,
  AttemptToReclaimFeed: (slug: string) => `${baseURL}/feed/${slug}/reclaim`,
}

export interface APIPaginatedResponse<T> {
  hasMore: boolean
  total: number
  offset: number
  pageSize: number
  payload?: T[]
}
