import { useState, useEffect, useContext } from 'react'
import Button from 'src/common/forms/Button'
import SectionCard from '../../atoms/SectionCard'
import InputFieldWithMessage from '../../molecules/InputFieldWithMessage'
import { CurrentUserContext } from '../../../../providers/AuthProvider'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'

type FormState = {
  taglineError: boolean
  taglineMessage: string
  descriptionError: boolean
  descriptionMessage: string
  countryError: boolean
  countryMessage: string
}

const defaultPasswordState: FormState = {
  taglineError: false,
  taglineMessage: '',
  descriptionError: false,
  descriptionMessage: '',
  countryError: false,
  countryMessage: '',
}

export function ChangeProfileParts() {
  const { user, ClearCache } = useContext(CurrentUserContext)

  const [userDescription, setUserDescription] = useState(user?.username ?? '')
  const [userTagline, setUserTagline] = useState(user?.username ?? '')
  const [userCountry, setUserCountry] = useState<string>(user?.country ?? '')
  const [messages, setMessages] = useState<FormState>(defaultPasswordState)

  useEffect(() => {
    setUserDescription(user?.description ?? '')
    setUserTagline(user?.tagline ?? '')
    setUserCountry(user?.country ?? '')
  }, [user])

  const {
    loading,
    updateUser,
  } = useUpdateUser()

  function onSubmit(e: any) {
    e.preventDefault()

    const trimedDescription =
      userDescription.trim() === '' ? undefined : userDescription.trim()
    const trimmedTagline =
      userTagline.trim() === '' ? undefined : userTagline.trim()
    const timmedCountry =
      userCountry.trim() === '' ? undefined : userCountry.trim()

    // Reset state to default
    setMessages(defaultPasswordState)
    updateUser({
        description: trimedDescription,
        tagline: trimmedTagline,
        country: timmedCountry,
    }).then(() => {
        ClearCache()
    })
  }

  return (
    <SectionCard title={'Change your profile details'}>
      <form onSubmit={onSubmit}>
        <div className="mt-3">
          <div>Tagline</div>
          <InputFieldWithMessage
            type="text"
            placeholder="A little tagline about you..."
            value={userTagline}
            dataTestId="input-text-tagline"
            onChange={(e) => setUserTagline(e.currentTarget.value)}
            error={messages.taglineError}
            message={messages.taglineMessage}
          />
        </div>

        <div className="mt-3">
          <div>Description</div>
          <InputFieldWithMessage
            type="text"
            placeholder="Profile description..."
            value={userDescription}
            dataTestId="input-text-description"
            onChange={(e) => setUserDescription(e.currentTarget.value)}
            error={messages.descriptionError}
            message={messages.descriptionMessage}
          />
        </div>

        <div className="mt-3">
          <div>Country</div>
          <InputFieldWithMessage
            type="text"
            placeholder="Your country..."
            value={userCountry}
            dataTestId="input-text-country"
            onChange={(e) => setUserCountry(e.currentTarget.value)}
            error={messages.countryError}
            message={messages.countryMessage}
          />
        </div>

        <Button
          type="submit"
          trackingEventName={'settings-page-update-user-information'}
          size="md"
          className="p-3 bg-primary-500 text-white mt-3"
          data-testid="update-basic-info-save-button"
          loading={loading}
          // class:disabled={() => !HasChangedBasicInfo()}
        >
          Save Changes
        </Button>
      </form>
    </SectionCard>
  )
}
