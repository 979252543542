import NavSideBarWithOutlet from '../templates/NavSideBarWithOutlet'
import { useLocation } from 'react-router-dom'
import { GetChildRoutesNamesAndPaths } from '../SettingsRoutes'

export default function SettingsPage() {
  const activeRoute = useLocation().pathname
  const SettingsRoutes = GetChildRoutesNamesAndPaths()

  return (
    <div className="container mx-auto h-full">
      <NavSideBarWithOutlet routes={SettingsRoutes} activeRoute={activeRoute} />
    </div>
  )
}
