import classNames from 'classnames'
import Spinner from '../Spinner'
import ReactGA from 'react-ga4'

type ButtonColors = 'primary' | 'secondary' | 'info' | 'danger' | 'dark'
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  trackingEventName: string
  loading?: boolean
  size?: 'sm' | 'md' | 'lg'
  color?: ButtonColors
  type?: 'submit' | 'reset' | 'button'
}

function Button({
  trackingEventName,
  children,
  onClick,
  loading = false,
  size = 'md',
  color = 'primary',
  type = 'submit',
  className,
  ...props
}: ButtonProps) {
  function sizeClass(opt?: 'sm' | 'md' | 'lg'): string {
    switch (opt || size) {
      case 'sm':
        return 'px-2 py-1 text-sm rounded-md'
      case 'md':
        return 'px-4 py-2 text-md rounded-lg'
      case 'lg':
        return 'px-8 py-4 text-lg rounded-xl'
    }
  }

  function ColorClass(opt?: ButtonColors): string {
    switch (opt || color) {
      case 'primary':
        return 'bg-primary-500 hover:bg-primary-600 active:bg-primary-700 disabled:bg-primary-800 text-white hover:text-white focus:ring-primary-300'
      case 'secondary':
        return 'bg-gray-500 hover:bg-gray-600 active:bg-gray-700 disabled:bg-gray-800 text-steel hover:text-steel-700 focus:ring-steel-300'
      case 'info':
        return 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700 disabled:bg-blue-800 text-steel hover:text-steel-700 focus:ring-steel-300'
      case 'danger':
        return 'bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-800 text-red hover:text-red-900 focus:ring-red-300'
      case 'dark':
        return 'bg-darkbackground-500 hover:bg-darkbackground-600 active:bg-darkbackground-700 disabled:bg-darkbackground-800 text-white hover:text-grey-900 focus:ring-darkbackground-300'
    }
  }

  function TrackClick(event: any) {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: trackingEventName,
      nonInteraction: false,
    })
    onClick && onClick(event)
  }

  return (
    <button
      disabled={loading}
      className={classNames(
        'transition ease-in text-white shadow-sm focus:ring-4 focus:outline-none ',
        className,
        sizeClass(),
        ColorClass(),
      )}
      type={type}
      onClick={TrackClick}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </button>
  )
}

export default Button
