import { Link } from 'react-router-dom'
import { FeedType, Post } from '../../api/types'
import { CharLimit, StripHTMLTags } from '../../app/helpers/string-helpers'
import Image from '../../common/forms/Image'
import PostOptions from '../../common/posts/PostOptions'
import ShardClickableStat from '../../common/posts/ShardClickableStat'
import { useMemo } from 'react'

interface Props extends React.HtmlHTMLAttributes<any> {
  post: Post
  slug: string
  showOptions?: boolean
}
function WritersBlockPostCard({
  slug,
  post,
  showOptions = true,
  ...otherProps
}: Props) {
  // TODO Generalise this component to be use communties too
  // This tag is a hidden interaction in communites which will display this content on its own page.
  let urls: { postUrl: string }
  if (post.feed?.feedType === FeedType.Community) {
    urls = {
      postUrl: `/communities/${slug}/news/${post.slug}`,
    }
  } else {
    urls = {
      postUrl: `/writers-block/${slug}/posts/${post.slug}`,
    }
  }

  const authorImage = useMemo(() => {
    return post.author.profilePicture ?? post.feed?.iconImage ?? '#'
  }, [post])

  const postImage = useMemo(() => {
    return post.image ?? '#'
  }, [post])
  
  return (
    <Link to={urls.postUrl} {...otherProps}>
      <div id={'writers-block-post-card'} className="bg-background-500 rounded-lg">
        <Image
          style={{ height: 190 }}
          src={postImage}
          alt="post descriptive"
          className="w-full rounded-t-lg object-cover object-center max-h-60"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/images/games/placeholder.png'
          }}
        />

        <div className="w-100 flex flex-col px-5 pt-5 pb-3">
          <div className="flex items-center mb-3">
            <div className="author-image">
              <Image
                src={authorImage}
                alt="author avatar"
                className="w-12 h-12 rounded-2xl mr-2"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = '/images/games/placeholder.png'
                }}
              />
            </div>
            <div className="author-name text-darksteel">
              by {post.author.username}
            </div>
            {showOptions && (
              <div className="ml-auto">
                <PostOptions post={post} refresh={() => {}} />
              </div>
            )}
          </div>
          <div className="text-xl text-white">{post.title}</div>
          <div className="text-darksteel py-5">
            {CharLimit(StripHTMLTags(post.content), 150)}
          </div>
          <div className="flex justify-end">
            <ShardClickableStat post={post} />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default WritersBlockPostCard
