import './App.css'
import { Navigate, useRoutes } from 'react-router-dom'
import LoginPage from './pages/login/LoginPage'
import MainLayout from './layouts/main-layout'
import AboutPage from './pages/about/AboutPage'
import GamesPage from './pages/games/GamesPage'
import GamesIndex from './pages/games/GamesIndex'
import DashboardPage from './pages/dashboard/DashboardPage'
import LaunchPadPage from './pages/launchpage/LaunchPadPage'
import ProfilePage from './pages/profile/ProfilePage'
import ProfilePageFeed from './pages/profile/ProfilePageFeed'
import ProfilePageGames from './pages/profile/ProfilePageGames'
import ProfilePageWritersBlocks from './pages/profile/ProfilePageWritersBlocks'
// import ProfilePageCommunities from './pages/profile/ProfilePageCommunities'
import ProfilePageAbout from './pages/profile/ProfilePageAbout'
import TermsOfServicePage from './pages/bau/TermsOfServicePage'
import MySubscriptionPage from './pages/subscriptions/MySubscriptionPage'
import SubscribePage from './pages/subscriptions/SubscribePage'
import PrivacyPolicyPage from './pages/bau/PrivacyPolicyPage'
import SearchPage from './pages/search/SearchPage'
import LaunchPadDetailsPage from './pages/launchpage/LaunchPadDetailsPage'
import MessagingPage from './pages/messaging/MessagingPage'
import MessagingConversationPage from './pages/messaging/MessagingConversationPage'
import NotificationsPage from './pages/notifications/NotificationsPage'
import ComposePage from './pages/compose/ComposePage'
import ReactGA from 'react-ga4'
import config from './config'
import RecoverPasswordPage from './pages/account/RecoverPasswordPage'
import ActivateAccountPage from './pages/account/ActivateAccountPage'

import { Routes as SettingsRoutes } from './pages/settings'
import { Routes as AdminRoutes } from './pages/admin'
import { Routes as WritersBlockRoutes } from './pages/writers-blocks'
// import { Routes as CommunityRoutes } from './pages/communities'
import { StoreRoutes } from './pages/store/StoreRoutes'

ReactGA.initialize(config.googleAnalytics.trackingId, {
  gaOptions: {},
})

function App() {
  return useRoutes([
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/terms-of-service',
      element: <TermsOfServicePage />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/account/recover-password',
      element: <RecoverPasswordPage />,
    },
    {
      path: '/account/account-activation',
      element: <ActivateAccountPage />,
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          index: true,
          element: <Navigate to="/about" />,
        },
        // ------------- dashbaord ----------------
        {
          path: '/dashboard',
          element: <DashboardPage />,
        },
        // ------------- search ----------------
        {
          path: '/search',
          element: <SearchPage />,
        },
        // ------------- writers blocks ----------------
        WritersBlockRoutes,
        // CommunityRoutes,
        // ------------- games ----------------
        {
          path: '/games',
          element: <GamesIndex />,
        },
        {
          path: '/games/:id',
          element: <GamesPage />,
        },
        // ------------- launchpad ----------------
        {
          path: '/launchpad',
          element: <LaunchPadPage />,
        },
        {
          path: '/launchpad/details/:code',
          element: <LaunchPadDetailsPage />,
        },
        // ------------- messaging ----------------
        {
          path: 'mobile/messaging/:conversation',
          element: <MessagingConversationPage />,
        },
        {
          path: '/messaging',
          element: <MessagingPage />,
          children: [
            {
              path: 'compose',
              element: <MessagingConversationPage />,
            },
            {
              path: ':conversation',
              element: <MessagingConversationPage />,
            },
          ],
        },
        // ------------- profile ----------------
        {
          path: '/profile',
          element: <Navigate to="/profile/123" />, // TODO: redirect the raw profile page to 'your' profile page
        },
        {
          path: '/profile/:id',
          element: <ProfilePage />, // TODO: redirect to the profile page's feed ie /profile/:id/feed
          children: [
            {
              path: '/profile/:id',
              index: true,
              element: <Navigate to="feed" />,
            },
            {
              path: '/profile/:id/feed',
              element: <ProfilePageFeed />,
            },
            {
              path: '/profile/:id/about',
              element: <ProfilePageAbout />,
            },
            // {
            //   path: '/profile/:id/communities',
            //   element: <ProfilePageCommunities />,
            // },
            {
              path: '/profile/:id/writers-blocks',
              element: <ProfilePageWritersBlocks />,
            },
            {
              path: '/profile/:id/games',
              element: <ProfilePageGames />,
            },
            {
              path: '/profile/:id/*',
              element: <Navigate to={'feed'} />,
            },
          ],
        },
        SettingsRoutes,
        AdminRoutes,
        StoreRoutes,
        {
          path: '/notifications',
          element: <NotificationsPage />,
        },
        {
          path: '/compose',
          element: <ComposePage />,
        },
        {
          path: '/settings/subscribe',
          element: <SubscribePage />,
        },
        {
          path: '/my-subscription',
          element: <MySubscriptionPage />,
        },
        {
          path: '/subscription/success',
          element: <div>Success Page</div>,
        },
        {
          path: '/subscription/cancelled',
          element: <div>Failure Page</div>,
        },
        {
          path: '*',
          element: <Navigate to="/" />,
        },
      ],
    },
  ])
}

export default App
