function GogLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.971"
      height="23.971"
      viewBox="0 0 23.971 23.971"
    >
      <path
        id="icons8-gog-galaxy"
        style={{ fill: '#fff' }}
        d="M13.985,2A11.985,11.985,0,1,0,25.971,13.985,11.993,11.993,0,0,0,13.985,2Zm0,1.042A10.943,10.943,0,1,1,3.042,13.985,10.935,10.935,0,0,1,13.985,3.042ZM7,7.732a.833.833,0,0,0-.834.834v3.023A.834.834,0,0,0,7,12.422H9.035V11.38H7.523a.313.313,0,0,1-.312-.312V9.087a.313.313,0,0,1,.312-.312H9.5a.313.313,0,0,1,.312.312v4.065a.313.313,0,0,1-.312.312H6.169v1.042h3.856a.833.833,0,0,0,.834-.834V8.566a.834.834,0,0,0-.834-.834Zm5.472,0a.835.835,0,0,0-.834.834v3.023a.835.835,0,0,0,.834.834H15.5a.835.835,0,0,0,.834-.834V8.566a.835.835,0,0,0-.834-.834Zm5.472,0a.833.833,0,0,0-.834.834v3.023a.834.834,0,0,0,.834.834h2.033V11.38H18.467a.313.313,0,0,1-.312-.312V9.087a.313.313,0,0,1,.312-.312h1.981a.313.313,0,0,1,.312.312v4.065a.313.313,0,0,1-.312.312H17.112v1.042h3.856a.833.833,0,0,0,.834-.834V8.566a.834.834,0,0,0-.834-.834ZM13,8.774h1.981a.313.313,0,0,1,.312.312v1.981a.313.313,0,0,1-.312.312H13a.313.313,0,0,1-.312-.312V9.087A.313.313,0,0,1,13,8.774ZM7,15.549a.834.834,0,0,0-.834.834v3.023A.834.834,0,0,0,7,20.239h3.075V19.2H7.523a.313.313,0,0,1-.312-.312V16.9a.313.313,0,0,1,.312-.312h2.554V15.549Zm4.69,0a.835.835,0,0,0-.834.834v3.023a.835.835,0,0,0,.834.834h3.023a.835.835,0,0,0,.834-.834V16.382a.835.835,0,0,0-.834-.834Zm5.472,0a.834.834,0,0,0-.834.834v3.856h1.042V16.9a.313.313,0,0,1,.312-.312h.86v3.648h1.042V16.9a.313.313,0,0,1,.312-.312h.86v3.648H21.8v-4.69Zm-4.95,1.042h1.981a.313.313,0,0,1,.312.312v1.981a.313.313,0,0,1-.312.312H12.213a.313.313,0,0,1-.312-.312V16.9A.313.313,0,0,1,12.213,16.591Z"
        transform="translate(-2 -2)"
      />
    </svg>
  )
}

export default GogLogo
