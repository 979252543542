import classnames from 'classnames'
import { useEffect } from 'react'
import { Feed, User } from '../../api/types'
import useDebounce from '../../app/hooks/useDebounce'
import Spinner from '../../common/Spinner'
import SearchResult from './SearchResult'
import { useSearch } from '../../app/hooks/UseSearch'

interface Props {
  searchTerm: string
  fixed?: boolean
  onSelect?: () => void
}
function SearchGrid({ searchTerm, fixed = true, onSelect }: Props) {
  const { search, loading, results } = useSearch()
  
  // Debounce the search term
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  useEffect(
    () => {
      const trimmedSearchTerm = debouncedSearchTerm.trim()
      if (trimmedSearchTerm.length > 0) {
        void search(trimmedSearchTerm)
      }
    },
    [debouncedSearchTerm, search], // Only call effect if debounced search term changes
  )

  if (searchTerm === '') {
    return null
  }

  return (
    <div
      className={classnames(
        'p-4 gap-4 grid lg:grid-cols-3 space-x-2 justify-around bg-background-500 opacity-95',
        {
          'fixed top-16 left-16 right-0 bottom-0': fixed,
        },
      )}
    >
      <div>
        <div className="text-2xl font-medium text-white">Users</div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="overflow-y-scroll">
            {results?.users.total === 0 && !loading ? (
              <div>There are no Users matching your search.</div>
            ) : (
              (results?.users.payload ?? []).map((user: User) => (
                <SearchResult
                  key={user.id}
                  title={user.username}
                  description={user.description}
                  to={`/profile/${user.username}/feed`}
                  image={user.profilePicture}
                  onSelect={onSelect}
                />
              ))
            )}
          </div>
        )}
      </div>

      <div>
        <div className="text-2xl font-medium text-white">Writers Block</div>
        {loading ? (
          <Spinner />
        ) : (
          <div
            className="overflow-y-scroll"
            style={{ maxHeight: 'calc(100% - 80px)' }}
          >
            {results?.writersBlocks.total === 0 && !loading ? (
              <div>There are no WritersBlocks matching your search.</div>
            ) : (
              (results?.writersBlocks.payload ?? []).map((writersblock: Feed) => (
                <SearchResult
                  key={writersblock.id}
                  title={writersblock.name}
                  description={writersblock.description}
                  to={`/writers-block/${writersblock.id}`}
                  image={writersblock.iconImage}
                  onSelect={onSelect}
                />
              ))
            )}
          </div>
        )}
      </div>

      <div>
        <div className="text-2xl font-medium text-white">Games</div>
        {loading ? (
          <Spinner />
        ) : (
          <div
            className="overflow-y-scroll"
            style={{ maxHeight: 'calc(100% - 80px)' }}
          >
            {results?.games.total === 0 && !loading ? (
              <div>There are no WritersBlocks matching your search.</div>
            ) : (
              (results?.games.payload ?? []).map((game) => (
                <SearchResult
                  key={game.id}
                  title={game.title}
                  description={game.description.String}
                  to={`/game/${game.id}`}
                  image={game.artwork.String}
                  onSelect={onSelect}
                />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchGrid
