import { useCallback, useContext, useState } from 'react'
import { Post, Reply } from '../../api/types'
import CrossIcon from '../icons/CrossIcon'
import DeleteIcon from '../icons/DeleteIcon'
import EditIcon from '../icons/EditIcon'
import ElipsisIcon from '../icons/ElipsisIcon'
import FlagIcon from '../icons/FlagIcon'
import { useMutation } from '@apollo/client'
import {
  ReportPostMutation,
  ReportPostVariables,
  REPORT_POST,
  UpdatePostMutation,
  UpdatePostVariables,
  UPDATE_POST,
} from '../../api/gql-queries'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../../app/helpers/RestApiRoutes'

interface Props {
  post: Reply | Post
  refresh: () => void
}

function PostOptions({ refresh, post }: Props) {
  const [showOptions, setShowOptions] = useState(false)
  const { user } = useContext(CurrentUserContext)
  const client = useContext(AxiosContext)

  const invokeRefresh = useCallback(() => {
    setShowOptions(false)
    refresh()
  }, [refresh])

  const deletePost = useCallback(async () => {
    if (!post.id) {
      console.warn('Trying to delete a post but the post has no id')
      return
    }

    try {
      await client.delete(Routes.DeletePost(post.id))
      invokeRefresh()
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.log('Error deleting post', e.message)
      }
    }
  }, [client, invokeRefresh, post.id])

  const onDeletePostClicked = () => {
    if (!post.id) {
      return
    }

    if (!user) {
      return
    }

    if (user.id !== post.author.id) {
      window.alert('You can only delete your own posts')
      return
    }

    if (window.confirm('Are you sure you want to delete this post?')) {
      void deletePost()
    }
  }

  const [reportPost] = useMutation<ReportPostMutation, ReportPostVariables>(
    REPORT_POST,
  )

  const onReportClicked = () => {
    if (!post.id) {
      return
    }

    if (!user) {
      return
    }

    if (user.id === post.author.id) {
      window.alert('You can only report other peoples posts')
      return
    }

    let reason = window.prompt(
      'Please enter a reason for reporting this post',
      '',
    )
    if (reason && reason?.trim().length > 0) {
      reportPost({
        variables: {
          reason: reason.trim(),
          postId: post.id as string,
        },
        onCompleted: invokeRefresh,
      })
    }
  }

  const [editPost] = useMutation<UpdatePostMutation, UpdatePostVariables>(
    UPDATE_POST,
  )

  const onEditClicked = () => {
    if (!post.id) {
      return
    }

    if (!user) {
      return
    }

    if (user.id !== post.author.id) {
      window.alert('You can only edit your own posts')
      return
    }

    let newContent = window.prompt(
      'Please enter a newContent for reporting this post',
      post.content,
    )
    if (newContent && newContent?.trim().length > 0) {
      editPost({
        variables: {
          content: newContent.trim(),
          id: post.id as string,
        },
        onCompleted: invokeRefresh,
      })
    }
  }

  return (
    <div className="relative p-2 transition-all ease-in-out fill-steel">
      {showOptions ? (
        <div className="flex items-center">
          <div
            className="p-1 transition-all ease-in-out hover:fill-slate-700"
            onClick={(e) => {
              e.preventDefault()
              onDeletePostClicked()
            }}
          >
            <DeleteIcon />
          </div>
          <div
            className="p-1 transition-all ease-in-out hover:fill-slate-700"
            onClick={(e) => {
              e.preventDefault()
              onEditClicked()
            }}
          >
            <EditIcon />
          </div>
          <div
            className="p-1 transition-all ease-in-out hover:fill-slate-700"
            onClick={(e) => {
              e.preventDefault()
              onReportClicked()
            }}
          >
            <FlagIcon />
          </div>
          <div
            className="p-1 transition-all ease-in-out hover:fill-slate-700"
            onClick={(e) => {
              e.preventDefault()

              setShowOptions(!showOptions)
            }}
          >
            <CrossIcon />
          </div>
        </div>
      ) : (
        <div
          className="flex items-center fill-steel"
          onClick={(e) => {
            e.preventDefault()
            setShowOptions(!showOptions)
          }}
        >
          <div className="p-1 transition-all ease-in-out hover:fill-slate-700 hover:stroke-slate-700">
            <ElipsisIcon />
          </div>
        </div>
      )}
    </div>
  )
}

export default PostOptions
