import { useApolloClient, gql } from '@apollo/client'
import axios from 'axios'
import { nanoid } from 'nanoid'
import { ImageType } from 'react-images-uploading'

interface CreatePresignedUrlMutation {
  CreatePresignedUrl: string
}

interface CreatePresignedUrlVariables {
  fileName: string
}

export function useUploadImage(): (data: ImageType) => Promise<string> {
  const client = useApolloClient()

  return ({ file }: ImageType) => {
    if (!file) {
      throw new Error('File is required when uploading')
    }
    // Obtain presigned url from api

    const fileName = `${nanoid()}.jpeg`

    return client
      .mutate<CreatePresignedUrlMutation, CreatePresignedUrlVariables>({
        mutation: gql`
          mutation CreatePresignedUrl($fileName: String!) {
            CreatePresignedUrl(fileName: $fileName)
          }
        `,
        variables: {
          fileName: fileName,
        },
      })
      .then(async ({ data }) => {
        if (!data) {
          throw new Error('No data returned from CreatePresignedUrl mutation')
        }

        // upload file to s3
        var options = {
          headers: {
            'Content-Type': file.type,
          },
        }

        const signedURL = data.CreatePresignedUrl
        const putResponse = await axios.put(signedURL, file, options)

        // return url
        console.log('Response of put request', putResponse)
        return signedURL.split('?')[0]
      })
  }
}
