import Image from '../../common/forms/Image'
import ShareIcon from '../../common/icons/ShareIcon'

interface Props {
  src: string
  alt: string
  href: string
  text: string
}

function DashboardAdvert({ src, alt, href, text }: Props) {
  return (
    <a href={href} target="_blank" rel="noreferrer" className="relative w-full">
      <Image src={src} alt={alt} className="w-24 md:w-[90%] h-full rounded-xl" />
      <div className="absolute bottom-3 p-3 md:flex items-center justify-center right-10 bg-background-500 rounded-lg hidden ">
        <ShareIcon />
        <div className="text-steel hover:text-steel">{text}</div>
      </div>
    </a>
  )
}

export default DashboardAdvert
