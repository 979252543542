import style from './LoginPage.module.css'
import React, { useEffect, useMemo, useState } from 'react'
import InputField from '../../common/forms/InputField'
import { useCookies } from 'react-cookie'
import classNames from 'classnames'
import EmberLogo from '../../common/EmberLogo'
import Spinner from '../../common/Spinner'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import config from '../../config'
import Button from '../../common/forms/Button'
import CheckMark from '../../common/icons/CheckMark'
import axios from 'axios'
import { FaDiscord } from 'react-icons/fa'
import { Routes } from '../../app/helpers/RestApiRoutes'

type InputChange = React.ChangeEvent<HTMLInputElement>

enum FormType {
  Login,
  Register,
  ForgottenPassword,
}

interface PageState {
  email: string
  password: string
  username: string
  'date-of-birth': string
  'signup-code': string
  'full-name': string
}

function FormElement(props: {
  label: string
  value: string,
  placeholder: string,
  type: string,
  onChange: (e: InputChange) => void
}) {
  const htmlSafeLabel = useMemo(() => {
    return props.label.toLowerCase().replace(/\W/g, '-')
  }, [props.label])

  return (
    <div className='flex flex-col gap-2'>
      <label htmlFor={`${htmlSafeLabel}-field`} className='font-light text-lg'>
        {props.label}
      </label>
      <InputField
        required
        value={props.value}
        name={htmlSafeLabel}
        id={`${htmlSafeLabel}-field`}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  )
}

function RegistrationForm(props: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  state: PageState,
  onChange: (e: InputChange) => void,
  registrationMessage: string,
  loading: boolean,
  onClick: () => void,
  error: string
}) {
  return <form
    className='flex flex-col md:grid md:grid-cols-2 px-5 gap-8'
    onSubmit={props.onSubmit}
  >
    <FormElement type="type" label={'Username'} value={props.state['username']} placeholder={'Username'} onChange={props.onChange} />
    <FormElement type="date" label={'Date of Birth'} value={props.state['date-of-birth']} placeholder={''} onChange={props.onChange} />
    <FormElement type="password" label={'Password'} value={props.state['password']} placeholder={'password'} onChange={props.onChange} />
    <FormElement type="text" label={'Email'} value={props.state['email']} placeholder={'Joe.Bloggs@email.com'} onChange={props.onChange} />
    <FormElement type="text" label={'Full Name'} value={props.state['full-name']} placeholder={'Joe Bloggs'} onChange={props.onChange} />
    <FormElement type="text" label={'Signup Code'} value={props.state['signup-code']} placeholder={'C6012C9D-762D-4FDE-863A-9549E977ED19'} onChange={props.onChange} />

    {props.registrationMessage !== '' && (
      <div className='flex justify-center col-span-2'>
        {props.registrationMessage}
      </div>
    )}

    <div className='flex justify-center col-span-2'>
      <Button
        trackingEventName={'login-page-register-button'}
        size='lg'
        loading={props.loading}
        disabled={props.loading}
        className='px-4 py-5 rounded-2xl font-medium text-xl bg-primary-500 hover:bg-primary-600 active:bg-primary-700 disabled:bg-background-500 text-white shadow-sm'
        type='submit'
      >
        {props.loading ? <Spinner /> : 'Register me'}
      </Button>
    </div>
    <p className='xl:hidden text-center col-span-full'>
      <u
        className='cursor-pointer font-light'
        onClick={props.onClick}
      >
        Return to login
      </u>
    </p>
    {props.error && FormError(props.error)}
    <p className='col-span-2 text-center text-steel'>
      By registering you accept the{' '}
      <Link to={'/terms-of-use'}>terms and conditions</Link>, agree
      to the <Link to={'/private-policy'}>privacy policy</Link>, and
      acknowledge that Ember is currently under development, and as
      such, is not representative of the final product.
    </p>
  </form>
}

function LoginForm(props: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  state: PageState,
  onChange: (e: InputChange) => void,
  onClick: () => void,
  onClick1: () => void,
  disabled: boolean,
  error: string
}) {
  return <>
    <form
      className='flex flex-col gap-2 mx-auto w-3/4 sm:w-1/2'
      onSubmit={props.onSubmit}
    >
      <h2 className='text-3xl font-light text-center pb-6'>
        Sign in to Ember
      </h2>
      <label htmlFor='email-field' className='font-light text-lg'>
        Email
      </label>
      <InputField
        required
        value={props.state.email}
        name='email'
        id='email-field'
        type='email'
        placeholder='Joe.Blogs@email.com'
        onChange={props.onChange} />
      <label htmlFor='password-field' className='font-light text-lg'>
        Password
      </label>
      <InputField
        required
        value={props.state.password}
        name='password'
        id='password-field'
        type='password'
        placeholder='Password'
        onChange={props.onChange} />
      <p className='text-center py-2'>
        <u
          className='cursor-pointer font-light'
          onClick={props.onClick}
        >
          Forgot your password?
        </u>
      </p>
      <p className='xl:hidden text-center py-2'>
        <u
          className='cursor-pointer font-light'
          onClick={props.onClick1}
        >
          Register for Ember
        </u>
      </p>
      <Button
        trackingEventName={'login-page-sign-in'}
        disabled={props.disabled}
        loading={props.disabled}
        className='px-4 py-3 rounded-2xl font-medium text-xl bg-primary-500 hover:bg-primary-600 active:bg-primary-700 disabled:bg-background-500 text-white shadow-sm'
        type='submit'
      >
        Sign in
      </Button>
      {props.error && FormError(props.error)}
    </form>


    <hr className={'mx-12 my-4 border-foreground-500'} />

    <div className='flex flex-col gap-2 mx-auto w-3/4 sm:w-1/2'>

      <a href={`${config.restAPI.url}/auth/discord`} className={'w-full'}>
        <Button trackingEventName={'login-page-discord-sign-in'}
                disabled={props.disabled}
                style={{ backgroundColor: '#5865F2' }}
                className='px-4 w-full relative py-3 rounded-2xl font-medium text-xl text-white shadow-sm'
                loading={props.disabled}>
          <FaDiscord className={'absolute'} size={30} />Discord
        </Button>
      </a>
    </div>

    {/* <a href={`${config.restAPI.url}/auth/github`} className={'w-full'}> */}
    {/*   <Button trackingEventName={'login-page-github-sign-in'} */}
    {/*           disabled={loading} */}
    {/*           className='px-4 py-5 rounded-2xl font-medium text-xl bg-primary-500 hover:bg-primary-600 active:bg-primary-700 disabled:bg-background-500 text-white shadow-sm' */}
    {/*           loading={loading}> */}
    {/*     Github */}
    {/*   </Button> */}
    {/* </a> */}
  </>
}

function LoginPage() {
  const [, setCookie] = useCookies([config.cookies.auth_token])

  const [currentForm, setCurrentForm] = useState<FormType>(FormType.Login)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [registrationMessage, setRegistrationMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<PageState>({
    email: '',
    password: '',
    username: '',
    'date-of-birth': '',
    'signup-code': '',
    'full-name': '',
  })
  const [error, setError] = useState<string>('')

  // const [createUserMutation] = useMutation<
  //   CreateUserQuery,
  //   CreateUserVariables
  // >(CREATE_USER, { client })

  const navigate = useNavigate()

  const changeFormType = (type: FormType) => {
    setError('')
    setRegistrationMessage('')
    setEmailSent(false)
    setCurrentForm(type)
  }

  const onInputChange = (e: InputChange) => {
    console.log(e.currentTarget.name, e.currentTarget.value)
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')
    setLoading(true)
    attemptLogin()
      .catch((err) => {
        setError(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')
    setLoading(true)
    attemptRegister()
      .then(() => {
        setRegistrationMessage('Registration successful!')
      })
      .catch((err) => {
        setError(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onForgotPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!emailSent) {
      setEmailSent(false)
      setError('')
      setLoading(true)
      attemptForgotPassword()
        .catch((err) => {
          setError(err.message)
        })
        .then(() => {
          setEmailSent(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const attemptForgotPassword = async () => {
    try {
      await axios.post(Routes.ForgotPassword(), {
        email: state.email,
      })
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  const attemptRegister = async () => {
    try {
      const {
        email,
        password,
        username,
        'date-of-birth': dob,
        'signup-code': code,
        'full-name': fullName,
      } = state
      try {
        const resp = await axios.post(Routes.Register(), {
          email,
          password,
          username,
          dob,
          code,
          fullName
        })

        setCookies(resp.data)

        navigate('/dashboard')
      } catch (error: unknown) {
        console.error(error)

      }

    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  function setCookies(data: { accessToken: string; refreshToken: string }) {
    // Set token post login
    setCookie(config.cookies.auth_token, data.accessToken, {
      maxAge: 15 * 60, // 15 minutes
      path: '/',
    })
    setCookie(config.cookies.refresh_token, data.refreshToken, {
      maxAge: 24 * 60 * 60, // 1 day
      path: '/',
    })
  }

  const attemptLogin = async () => {
    try {
      const {
        email,
        password,
      } = state

      const {
        data,
        status,
      } = await axios.post(Routes.Login(), {
        email,
        password,
      })

      if (status > 399) {
        throw new Error(data.message ?? data)
      }

      setCookies(data)

      navigate('/dashboard')
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  const location = useLocation()
  const queryProps = useMemo(() => new URLSearchParams(location.search), [location])
  console.log({
    queryProps,
    location,
    got: queryProps.getAll('code'),
  })

  useEffect(() => {
    const source = queryProps.get('source')
    const code = queryProps.get('code')
    const state = queryProps.get('state')
    if (source && code && state) {
      let url = config.restAPI.url
      switch (source) {
        case 'discord':
          url += '/auth/discord/return'
          break
        case 'github':
          url += '/auth/github/return'
      }
      axios.get(url + location.search)
        .then((res) => {
          setCookie(config.cookies.auth_token, res.data.accessToken, {
            maxAge: 15 * 60, // 15 minutes
            path: '/',
          })
          setCookie(config.cookies.refresh_token, res.data.refreshToken, {
            maxAge: 24 * 60 * 60, // 1 day
            path: '/',
          })
          navigate('/dashboard')
        })
        .catch((err) => {
          setError('failed to authenticate with oauth, please try again')
        })
    }
  }, [location.search, navigate, queryProps, setCookie])

  return (
    <div
      className={classNames(
        style.bg,
        'flex justify-center items-center w-screen h-screen',
      )}
    >
      <div
        className={classNames(
          { [style.bgMaskPrimary]: currentForm === FormType.Login },
          {
            [style.bgMaskSecondary]:
            currentForm === FormType.Register ||
            currentForm === FormType.ForgottenPassword,
          },
          'absolute w-screen h-screen z-0',
        )}
      />
      <div className='container mx-auto px-4 xl:px-36 z-10'>
        <div
          className={classNames(
            'w-full flex bg-background-500 rounded-2xl text-white',
            {
              'flex-row-reverse':
                currentForm === FormType.Register ||
                currentForm === FormType.ForgottenPassword,
            },
          )}
        >
          <div className='flex flex-col w-full xl:w-3/4 justify-center py-6 xl:py-12'>
            {currentForm === FormType.Login && (
              <LoginForm onSubmit={onLogin} state={state} onChange={onInputChange}
                         onClick={() => changeFormType(FormType.ForgottenPassword)} onClick1={() => changeFormType(FormType.Register)}
                         disabled={loading} error={error} />
            )}

            {currentForm === FormType.Register && (
              <RegistrationForm onSubmit={onRegister} state={state} onChange={onInputChange} registrationMessage={registrationMessage}
                                loading={loading} onClick={() => changeFormType(FormType.Login)} error={error} />
            )}

            {currentForm === FormType.ForgottenPassword && (
              <ForgotPasswordForm onSubmit={onForgotPassword} state={state} onChange={onInputChange} emailSent={emailSent}
                                  loading={loading}
                                  onClick={() => changeFormType(FormType.Login)} />
            )}
          </div>
          <LoginSidebar currentForm={currentForm} onRegisterPromptClick={() => changeFormType(FormType.Register)}
                        onSignInPromptClick={() => changeFormType(FormType.Login)} />
        </div>
      </div>
    </div>
  )
}

const FormError = (error: string) => {
  return (
    <small className='text-red-600 text-center col-span-full'>{error}</small>
  )
}

const FormSuccess = (message: string) => {
  return (
    <small className='text-green-600 text-center col-span-full'>
      {message}
    </small>
  )
}

function ForgotPasswordForm(props: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  state: PageState,
  onChange: (e: InputChange) => void,
  emailSent: boolean,
  loading: boolean,
  onClick: () => void
}) {
  return (
    <form
      className='flex flex-col gap-2 mx-auto w-3/4 sm:w-1/2'
      onSubmit={props.onSubmit}
    >
      <h2 className='text-3xl font-light text-center pb-6'>
        Forgot your password?
      </h2>
      <label htmlFor='email-field' className='font-light text-lg'>
        Email
      </label>
      <InputField
        required
        value={props.state.email}
        name='email'
        id='email-field'
        type='email'
        placeholder='Joe.Blogs@email.com'
        onChange={props.onChange}
      />
      {props.emailSent && (
        <div className='flex flex-col items-center'>
          <CheckMark />
          {FormSuccess(
            'If your email is registered with Ember, you will have received a recovery email!',
          )}
        </div>
      )}

      {!props.emailSent && (
        <Button
          trackingEventName={'login-forgot-password'}
          loading={props.loading}
          disabled={props.loading}
          className='px-4 py-5 rounded-2xl font-medium text-xl bg-primary-500 hover:bg-primary-600 active:bg-primary-700 disabled:bg-background-500 text-white shadow-sm'
          type='submit'
        >
          {props.loading ? <Spinner /> : 'Send me an email'}
        </Button>
      )}
      <p className='text-center py-4'>
        <u
          className='cursor-pointer font-light'
          onClick={props.onClick}
        >
          Return to login
        </u>
      </p>
    </form>
  )
}

function LoginSidebar(props: { currentForm: FormType, onRegisterPromptClick: () => void, onSignInPromptClick: () => void }) {
  return <div
    className={classNames(
      'w-1/4 hidden xl:flex flex-col gap-2 bg-primary-500 items-center justify-center',
      {
        'bg-primary-500 rounded-r-2xl': props.currentForm === FormType.Login,
        'bg-secondary rounded-l-2xl':
          props.currentForm === FormType.Register ||
          props.currentForm === FormType.ForgottenPassword,
      },
    )}
  >
    <EmberLogo width={180} />

    {props.currentForm === FormType.Login && (
      <RegisterPrompt onClick={props.onRegisterPromptClick} />
    )}

    {[FormType.Register, FormType.ForgottenPassword].includes(props.currentForm) && (
      <SignInPrompt onClick={props.onSignInPromptClick} />
    )}
  </div>
}

const RegisterPrompt = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <h2 className='font-light text-3xl text-center'>Join Our Family!</h2>
      <p className='text-center font-light text-lg px-4'>
        Enter a few details and become part of our ever growing gaming
        community.
      </p>
      <button
        className='px-4 py-5 mb-4 rounded-2xl font-medium text-xl bg-primary-500 hover:bg-white text-white hover:text-black border-2 active:ring-2 ring-slate-300 border-white shadow-sm'
        onClick={onClick}
      >
        Register
      </button>
    </>
  )
}

const SignInPrompt = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <h2 className='font-light text-3xl'>Remember?</h2>
      <p className='font-light text-lg px-4'>Nice one!</p>
      <p className='text-center font-light text-lg px-4'>
        Just click on the sign in button below and enter your details.
      </p>
      <button
        className='px-4 py-3 mb-4 rounded-2xl font-medium text-xl bg-secondary hover:bg-white text-white hover:text-black border-2 active:ring-2 ring-slate-300 border-white shadow-sm'
        onClick={onClick}
      >
        Sign in
      </button>
    </>
  )
}

export default LoginPage
