import { Outlet } from 'react-router-dom'
import DesktopSideNav from '../organisms/SideNav/DesktopSideNav'
import MobileSideNav from '../organisms/SideNav/MobileSideNav'

/**
 * @params routes - The routes to display in the sidebar
 * @params activeRoute - The route that is currently active
 */
interface Props {
  routes: { name: string; path: string }[]
  activeRoute: string
}
export default function NavSideBarWithOutlet({ routes, activeRoute }: Props) {
  return (
    <div className="flex h-full">
      <div className="hidden md:block">
        <DesktopSideNav routes={routes} activeRoute={activeRoute} />
      </div>

      <div className="md:hidden block">
        <MobileSideNav routes={routes} activeRoute={activeRoute} />
      </div>

      {/* The other half of the page */}
      <div
        className="flex-1 overflow-y-scroll"
        style={{ maxHeight: 'calc(100vh - 72px)' }}
      >
        <Outlet />
        <br className="block lg:hidden" />
        <br className="block lg:hidden" />
        <br className="block lg:hidden" />
      </div>
    </div>
  )
}
