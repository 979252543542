import { useContext, useState } from 'react'
import InputField from '../../../common/forms/InputField'
import Spinner from '../../../common/Spinner'
import { Post } from '../../../api/types'
import { Link, useNavigate } from 'react-router-dom'
import FileField, { FileFieldValue } from '../../../common/forms/FileField'
import Image from '../../../common/forms/Image'
import Button from '../../../common/forms/Button'
import { useEffect } from 'react'
import WYSIWYG from '../../../common/forms/WYSIWYG'
import ToggleSwitch from '../../../common/forms/ToggleSwitch'
import { CurrentWritersBlockContext } from './WritersBlockContext'
import { CurrentWritersBlockPostContext } from './WritersBlockPostContext'
import useCreatePost from '../../../app/hooks/useCreatePost'
import { useToast } from '@chakra-ui/react'

interface Props {
  isEditing?: boolean
}

function WritersBlockCreatePostPage({ isEditing = false }: Props) {
  const { feed } = useContext(CurrentWritersBlockContext)
  const post = useContext(CurrentWritersBlockPostContext)
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [image, setImage] = useState<FileFieldValue>([])
  const [isLoading, setIsLoading] = useState(false)
  const [validationError, setValidationError] = useState('')
  const toast = useToast()

  // Editing mode
  const [existingPost, setExistingPost] = useState<Post | undefined>(undefined)
  const [nsfw, setNSFW] = useState<boolean>(false)
  const { loading, error, create } = useCreatePost({ feedId: feed?.id ?? '' }) // TODO: investigate case where feed can be undefined

  useEffect(() => {
    setExistingPost(post)
  }, [post, setExistingPost])

  if (!feed.isOwner) {
    toast({
      title: 'Search failed',
      description: 'Something went wrong while searching for feeds',
      status: 'error',
      duration: 2000,
      isClosable: true,
    })
    navigate('/dashboard', { replace: true })
  }

  const CreatePost = async () => {
    setValidationError('')

    //validate all fields
    if (!title || title === '') {
      setValidationError('Title is required')
      return
    }

    if (!content || content === '') {
      setValidationError('Content is required')
      return
    }

    if (!isEditing && (!image || image.length === 0)) {
      setValidationError('Image is required')
      return
    }

    setIsLoading(true)
    try {
      let imageBlob = undefined
      if (image[0] && image[0].file) imageBlob = image[0]

      //create block
      if (isEditing) {
        if (!existingPost) throw new Error('Existing Post is undefined')

        const res = await create({
            content,
            title,
            image: imageBlob,
            nsfw: nsfw,
            existingPostId: existingPost.id,
            })
        if (res) {
          navigate(`/writers-block/${feed.slug}/posts/${existingPost.id}`)
        }

      } else {
        // Creating a post
        try {
          const res = await create({
            content,
            title,
            image: imageBlob,
            nsfw: nsfw,
          })
          if (res) {
            navigate(`/writers-block/${feed.slug}/posts/${res.slug}`)
          }
        } catch (e: any) {
          setValidationError(e.message)
        }
      }
    } catch (e: any) {
      setValidationError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onIconChange = (e: FileFieldValue) => {
    setImage(e)
  }

  if (isEditing && !existingPost) {
    return (
      <div className="w-full">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="w-full bg-darkbackground-500 p-3">
      <div className="container flex flex-col mx-auto bg-background-500 p-5 rounded-xl space-y-5 text-white">
        <div className="text-3xl font-alice">
          {isEditing
            ? `Editing; ${existingPost?.title}`
            : 'Creating a new post'}
        </div>

        <div>
          <div>Title</div>
          <InputField
            type="text"
            placeholder="Your awesome title"
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
        </div>

        <div>
          <div>Content</div>
          <WYSIWYG onChange={(e) => setContent(e)} />
        </div>

        <div>
          <div>Image</div>
          <div className="flex space-x-3">
            <div>This post contains adult content </div>
            <ToggleSwitch
              label={''}
              disabled={false}
              defaultChecked={nsfw}
              onChange={(e) => setNSFW(!!e.currentTarget.value)}
            />
          </div>
        </div>

        <div>
          <div>Image</div>
          <div className="flex space-x-3 items-center">
            {isEditing && (
              <div className="bg-darkbackground-500 rounded-lg p-3">
                <Image
                  src={existingPost?.image}
                  width={360}
                  height={120}
                  className="rounded-lg"
                />
              </div>
            )}
            <div className="flex-1">
              <FileField
                label="Click here to Drop upload a writers block icon image"
                onChange={onIconChange}
                showSubmit={false}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <Link to="/writers-block">
            <Button
              trackingEventName={'cancel_creating_writers_block_post'}
              color="secondary"
              loading={isLoading}
            >
              Cancel
            </Button>
          </Link>

          <Button
            trackingEventName={'create_writers_block_post_submit_post'}
            color="primary"
            loading={isLoading}
            onClick={CreatePost}
          >
            {loading ? <Spinner /> : isEditing ? 'Save changes' : 'Post'}
          </Button>
        </div>

        <div className="text-red-600">{validationError || error}</div>
      </div>
    </div>
  )
}

export default WritersBlockCreatePostPage
