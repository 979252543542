import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Conversation } from '../../api/types'
import { Routes } from '../helpers/RestApiRoutes'

export function useCreateConversation() {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [error, setError] = useState('')
  const toast = useToast()

  const createConversation = useCallback(async (participantUsernames: string[]) => {
    setLoading.on()
    try {
      const f = new FormData()
      f.append("participantUsernames", participantUsernames.join(','))

      const {data} = await client.post<Conversation>(Routes.CreateConversations(), f, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      return data
    } catch (e: any) {
      toast({
        title: 'Failed to send messages',
        description: 'Something went wrong while trying to send a message',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, setLoading, toast])

  return {
    loading,
    error,
    createConversation,
  }
}