import { useCallback, useContext, useEffect, useState } from 'react'
import { Post, Reply } from '../../api/types'
import { ShardApi } from '../../api/shards/api'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../helpers/RestApiRoutes'
import { useBoolean } from '@chakra-ui/react'

type ErrorWrapper = {
  errored: boolean
  message: string
}

type ShardHelpers = {
  shardsLoading: boolean
  totalShards: number
  hasGivenShard: boolean
  attemptToShard: () => Promise<void>
  error: ErrorWrapper
}

function useShardHelpers(post: Post | Reply | undefined): ShardHelpers {
  const client = useContext(AxiosContext)
  const { user } = useContext(CurrentUserContext)
  const [loading, setLoading] = useBoolean()
  const [totalShards, setTotalShards] = useState(0)
  const [hasGivenShard, setHasGivenShard] = useState(false)
  const [errorWrapper, setErrorWrapper] = useState<ErrorWrapper>({
    errored: false,
    message: '',
  })

  const FetchShardStats = useCallback(
    async (id: string) => {
      try {
        const { data } = await client.get(Routes.GetPostStats(id))
        if (data) {
          setTotalShards(data.totalShards)
          setHasGivenShard(data.hasAwardedShard)
        }
      } catch (e) {
        console.warn('something went wrong getting total shards for post', e)
        setTotalShards(0)
        setHasGivenShard(false)
      }
      setLoading.off()
    },
    [client, setLoading],
  )

  useEffect(() => {
    setLoading.on()
    if (!post?.id) {
      return
    }
    void FetchShardStats(post.id)
  }, [FetchShardStats, setLoading, post])

  const attemptToShard = async () => {
    if (!post) {
      console.log('No post to shard')
      return
    }

    // Ensure we have a target to award
    if (!post.id) {
      console.log('no post id')
      return
    }

    // You've already given a shard?
    if (hasGivenShard) {
      return
    }

    // Make sure you're not the post author
    if (post.author.username === user?.username) {
      return
    }

    // Enforce required values are present
    if (!post.id || !post.author.id || !user?.id) {
      console.log('Cannot award shard, required field is missing', {
        postId: post.id,
        authorId: post.author.id,
        userId: user?.id,
      })
      return
    }

    setLoading.on()
    setErrorWrapper({
      errored: false,
      message: '',
    })

    try {
      await ShardApi.AwardShard(client, post.id)
      await FetchShardStats(post.id)
      setHasGivenShard(true)
    } catch (e: any) {
      console.log({ e })
      setErrorWrapper({
        errored: true,
        message: e.message,
      })

      // // handle special error case
      try {
        const messageBody = e.response.data.message
        if (
          messageBody === 'failed to award shard: shard was already awarded'
        ) {
          setHasGivenShard(true)
          setErrorWrapper({
            errored: false,
            message: '',
          })
        }
      } catch (e) {}
    }
    setLoading.off()
  }

  return {
    shardsLoading: loading,
    totalShards: totalShards,
    hasGivenShard: hasGivenShard,
    attemptToShard,
    error: errorWrapper,
  }
}

export default useShardHelpers
