import gql from 'graphql-tag'
import { EmberNotification } from '../types'

export const GET_NOTIFICATIONS = gql`
  query GetNotifications(
    $type: String!
    $order: String
    $limit: Float
    $searchTags: [String!]
    $page: Float
  ) {
    Notifications(
      type: $type
      order: $order
      limit: $limit
      searchTags: $searchTags
      page: $page
    ) {
      _id
      createdAt
      notificationType
      read
      details
      actor {
        username
        profilePicture
      }
      recipient {
        _id
        username
      }
    }
  }
`

export interface GetNotificationsQuery {
  Notifications: EmberNotification[]
}

export interface GetNotificationsVariables {
  type: string
  order?: string
  limit?: number
  searchTags?: string[]
  page?: number
}

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($ids: [String!]!) {
    MarkNotificationsAsRead(ids: $ids)
  }
`

export interface MarkNotificationsAsReadQuery {
  MarkNotificationsAsRead: boolean
}

export interface MarkNotificationsAsReadVariables {
  ids: string[]
}
