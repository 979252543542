interface Props
  extends React.PropsWithChildren<{
    title: string
  }> {
  title: string
  sectionId?: string
}

export default function SectionCard({ title, children, sectionId }: Props) {
  return (
    <section className="bg-background-500 rounded-lg p-5 flex-col space-y-5 m-5" data-testid="section-card" id={sectionId ?? title}>
      <h1 className="text-xl text-white py-3">{title}</h1>
      {children}
    </section>
  )
}
