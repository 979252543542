import { useGesture } from '@use-gesture/react'
import classNames from 'classnames'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import useWindowSize from '../app/hooks/useWindowSize'
import SearchNavElement from '../features/search/SearchNavElement'
import EmberLogo from './EmberLogo'
import Image from './forms/Image'
import Controller from './icons/Controller'
import CreateIcon from './icons/CreateIcon'
import ExitIcon from './icons/ExitIcon'
import GearIcon from './icons/GearIcon'
import FullDiamond from './icons/FullDiamond'
import House from './icons/House'
import InboxIcon from './icons/InboxIcon'
import Rocket from './icons/Rocket'
import SpeechBubbleWithExclaimationPoint from './icons/SpeechBubbleWithExclaimationPoint'
import StickyNote from './icons/StickyNote'
import NotificationDropdown from './NotificationDropdown'
import ProfileDropdown from './ProfileDropdown'
import config from '../config'
import Spinner from './Spinner'
import ShardBio from './icons/ShardBio'
import PersonFill from './icons/PersonFill'
import MagnifiyingGlass from './icons/MagnifyingGlass'
import BellFill from './icons/BellFill'
import packageJson from '../../package.json'
import { CurrentUserContext } from '../providers/AuthProvider'

interface Props extends React.PropsWithChildren<any> {}

type NavItem = {
  to: string
  text: string
  icon: (props: Props) => JSX.Element
  show: boolean
  external?: boolean
  onClick?: () => void
}

function NavBar({ children }: Props) {
  const { loading: userLoading, user } = useContext(CurrentUserContext)
  const username = user?.username ?? 'unknown'

  const size = useWindowSize()
  const isMobile = size.width < 1024
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [, , removeCookie] = useCookies()

  const Logout = useCallback(() => {
    removeCookie(config.cookies.auth_token)
  }, [removeCookie])

  const drag = useGesture({
    onDragEnd: (state: any) => {
      if (!isMobile) return
      const movement = { x: state.movement[0], y: state.movement[1] }
      if (movement.x >= 100) {
        /* if we are dragging to the right */
        setShowMobileNav(true)
      }
      if (movement.x <= -100) {
        /* if we are dragging to the left */
        setShowMobileNav(false)
      }
    },
  })

  function NavBarItem(props: any) {
    const onClick = () => {
      if (props.onClick) props.onClick()
      setShowMobileNav(false)
    }
    const classes = classNames(
      'flex flex-col items-center justify-center space-y-2 mt-3 hover:text-gray-700 cursor-pointer text-steel fill-steel hover:fill-gray-700 transition-colors',
      { hidden: !props.show },
    )
    if (props.external) {
      return (
        <a
          href={props.to}
          target="_blank"
          rel="external noopener noreferrer"
          className={classes}
        >
          {props.children}
        </a>
      )
    }

    return (
      <Link to={props.to} className={classes} onClick={() => onClick()}>
        {props.children}
      </Link>
    )
  }

  function NavigationItems() {
    return navBarItems.map((item) => (
      <NavBarItem
        key={item.to}
        to={item.to}
        external={item.external}
        show={item.show}
        onClick={item.onClick}
      >
        <div className="flex gap-3 lg:flex-col lg:items-center">
          {item.icon({})}
          <span className="text-center">{item.text}</span>
        </div>
      </NavBarItem>
    ))
  }

  function LeftNavBar(mobileVersion: boolean) {
    return mobileVersion ? (
      <div
        {...drag()}
        className={classNames(
          { '-left-full': !showMobileNav },
          'left-0 w-screen items-start px-3 min-h-screen flex flex-col bg-background-500  fixed z-50 gap-2 pt-3 transition-left ease-in-out duration-250 backdrop-blur-lg',
        )}
        style={{
          touchAction: 'none',
        }}
      >
        <div className="flex flex-col gap-2 items-center bg-background-500 w-full">
          {userLoading ? (
            <Spinner />
          ) : (
            <div className="grid grid-cols-3 w-full">
              <Image
                src={user?.profilePicture}
                alt="profile"
                style={{ height: 90, width: 90 }}
                className="rounded-lg"
              />
              <div className="flex col-span-2 gap-2 justify-around items-center w-full">
                <div className="flex flex-col gap-1 items-center">
                  <ShardBio />
                  <p className="text-white">{user?.TotalShards}</p>
                  <p>Shards</p>
                </div>
                <div className="flex flex-col gap-1 items-center mb-1">
                  <div className="h-8 w-8 text-primary-500">
                    <PersonFill />
                  </div>
                  <p className="text-white">{user?.TotalFollowers}</p>
                  <p>Followers</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 items-start">
          {NavigationItems()}
        </div>
      </div>
    ) : (
      <div
        className="flex flex-col items-center bg-background-500 sticky z-50 text-xs gap-4 pt-3"
        style={{
          width: 88,
          maxWidth: 88,
          top: 70,
          height: 'calc(100vh - 70px)',
        }}
      >
        {NavigationItems()}
      </div>
    )
  }

  const navBarItems = useMemo<NavItem[]>(() => {
    const items = [
      { to: '/', text: 'Home', icon: House, show: true },
      // { to: '/communities', text: 'Communities', icon: Account, show: true }, {
      { to: '/writers-block', text: 'Writers Blocks', icon: StickyNote, show: true, },
      { to: '/games', text: 'Games', icon: Controller, show: true, },
      { to: 'https://docs.google.com/forms/d/e/1FAIpQLSdc1Y6jWSixqqidHnNb8664bznuv4iXwsVp4A9fCjnMGcFdcQ/viewform', external: true, text: 'Feedback', icon: SpeechBubbleWithExclaimationPoint, show: true, },
      { to: '/launchpad', text: 'Launchpad', icon: Rocket, show: true, },
      { to: '/my-subscription', text: 'Subscriptions', icon: FullDiamond, show: true, },
      { to: '/settings', text: 'Settings', icon: GearIcon, show: isMobile, },
      { to: '', text: 'Logout', icon: ExitIcon, show: isMobile, onClick: Logout, },
    ]

    if (user?.role === 'admin') {
      items.push({ to: '/admin', text: 'Admin', icon: GearIcon, show: true, })
    }

    return items
  }, [Logout, isMobile, user])

  const MessagingIcon = () => {
    return (
      <div>
        <Link
          to="/messaging"
          className="p-3 hover:text-gray-700 cursor-pointer text-darksteel fill-darksteel hover:fill-gray-700 transition-colors"
        >
          <InboxIcon />
        </Link>
      </div>
    )
  }

  return (
    <div className="text-steel w-full bg-darkbackground-500">
      <nav className="w-full px-3 flex bg-background-500 justify-between items-center sticky top-0 z-50 rounded-bl-3xl rounded-br-3xl lg:rounded-none">
        <Link to="/dashboard" className="bar-item hidden lg:block">
          <div className="bar-link logo flex items-center">
            <EmberLogo width={70} />
            <p className="text-steel text-xs">V{packageJson.version}</p>
          </div>
        </Link>

        <div
          className="bar-link logo bar-item lg:hidden flex items-center"
          onClick={() => setShowMobileNav(!showMobileNav)}
        >
          <EmberLogo width={70} />
          <p className="text-steel text-xs">V{packageJson.version}</p>
        </div>

        <div className="hidden lg:flex space-x-3">
          <SearchNavElement />
          <MessagingIcon />
          <NotificationDropdown />
        </div>

        <ProfileDropdown className="hidden lg:block" />
        <Link to={`/profile/${username}/feed`} className="lg:hidden">
          <Image
            src={user?.profilePicture}
            alt="profile"
            className="lg:hidden rounded-lg h-12 w-12"
          />
        </Link>
      </nav>

      <main className="flex">
        {/* sidebar */}
        {LeftNavBar(isMobile)}

        {/* mobile footer nav */}
        <div className="fixed flex justify-between items-center lg:hidden bg-background-500 bottom-0 w-full min-h-[60px] z-50 px-2 sm:px-6 md:px-12 text-steel rounded-tl-3xl rounded-tr-3xl">
          <Link to="/search" className="text-steel hover:text-steel">
            <MagnifiyingGlass />
          </Link>
          <Link to="/messaging" className="text-steel hover:text-steel">
            <InboxIcon />
          </Link>
          <Link to="/dashboard" className="text-steel hover:text-steel">
            <House />
          </Link>
          <Link to="/notifications" className="text-steel hover:text-steel">
            <BellFill />
          </Link>
          <Link to="/compose" className="text-steel hover:text-steel">
            <CreateIcon />
          </Link>
        </div>

        {/* main content */}

        <div
          className="w-full overflow-x-hidden"
          style={{ touchAction: 'pan-y' }}
          {...drag()}
        >
          {children}
        </div>
      </main>
    </div>
  )
}
export default NavBar
