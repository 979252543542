import { Navigate, RouteObject } from 'react-router-dom'
import InventoryPage from './Templates/InventoryPage'
import ProductPage from './Templates/ProductPage'
import StoreFront from './Templates/StoreFront'
import StorePage from './pages/StorePage'

export const StoreRoutes: RouteObject = {
  path: '/store',
  element: <StorePage />,
  children: [
    {
      path: '',
      caseSensitive: false,
      element: <StoreFront />,
    },
    {
      path: 'product',
      caseSensitive: false,
      element: <Navigate to={'/store'} />,
    },
    {
      path: 'product/:id',
      caseSensitive: false,
      element: <ProductPage />,
    },
    {
      path: 'inventory',
      caseSensitive: false,
      element: <InventoryPage />,
    },
    {
      path: '*',
      element: <Navigate to="/store" />,
    },
  ],
}
