function XboxLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.953"
      height="23.956"
      viewBox="0 0 23.953 23.956"
    >
      <g id="xbox-logo" transform="translate(-0.002)">
        <path
          id="Path_706"
          data-name="Path 706"
          style={{ fill: '#fff' }}
          d="M5.337,2.032c-.012.009-.021.02-.033.029a1.448,1.448,0,0,1,.23-.191A11.636,11.636,0,0,1,11.98,0a11.911,11.911,0,0,1,6.152,1.7,3.121,3.121,0,0,1,.669.543h0C17.067.332,11.969,4.435,11.969,4.435A14.845,14.845,0,0,0,7.376,1.82,2.333,2.333,0,0,0,5.337,2.032ZM20.766,3.84c-.053-.059-.111-.114-.164-.174A1.742,1.742,0,0,0,19.138,3.1a13.9,13.9,0,0,0-5,3.253,34.562,34.562,0,0,1,4.571,5.575c1.734,2.818,2.772,5.033,2.134,8.107A11.975,11.975,0,0,0,20.766,3.84Zm-4.327,9.291c-.769-.863-1.913-2.1-3.424-3.6q-.5-.493-1.046-1.026s-.552.552-1.271,1.278h0c-.92.929-2.112,2.141-2.774,2.849-1.179,1.258-4.555,5.208-4.745,7.423,0,0-.751-1.757.9-5.808A31.175,31.175,0,0,1,9.766,6.322a14.414,14.414,0,0,0-2.8-2.311l-.009,0s-.018-.014-.046-.032A5.4,5.4,0,0,0,4.555,3.14a2.083,2.083,0,0,0-1.314.645,11.977,11.977,0,1,0,17.6,16.25,10.249,10.249,0,0,0-1.864-3.848C18.6,15.657,17.214,14,16.438,13.131Z"
        />
      </g>
    </svg>
  )
}

export default XboxLogo
