import { Link } from 'react-router-dom'
import Spinner from '../../../common/Spinner'
import Image from '../../../common/forms/Image'
import ShortPostEditor, {
  PostCreateEvent,
} from '../../../common/command-palette/ShortPostEditor'
import PostReply from '../../../common/posts/PostReply'
import './WritersBlockPostPage.css'
import ShardClickableStat from '../../../common/posts/ShardClickableStat'
import { useContext } from 'react'
import { CurrentWritersBlockContext } from './WritersBlockContext'
import { CurrentWritersBlockPostContext } from './WritersBlockPostContext'
import useGetReplies from '../../../app/hooks/useGetReplies'
import useCreatePost from '../../../app/hooks/useCreatePost'

function WritersBlockPostPage() {
  const { feed } = useContext(CurrentWritersBlockContext)
  const post = useContext(CurrentWritersBlockPostContext)

  const { create: createReply } = useCreatePost({ feedId: feed.id ?? '' })

  const doReply = async ({ content }: PostCreateEvent) => {
    if (!content || !post.id) {
      return
    }

    await createReply({
      nsfw: false,
      to: post.id,
      content: content,
    })

    void GetReplies(post.id, page, pageSize)
  }

  const {
    page,
    pageSize,
    GetReplies,
    items,
    total,
    loading,
  } = useGetReplies(post.id ?? '')

  if (!post) {
    ;<div className="bg-darkbackground-500 text-white">
      Something went wrong getting post
    </div>
  }

  return (
    <div className="bg-darkbackground-500 text-white">
      <div
        className="fixed opacity-20 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${post.image})`,
        }}
      />
      <div className="container mx-auto px-4 py-16 relative">
        <Link to={`/writers-block/${feed.slug}`}>
          <div className="back-link text-purple-500 hover:text-purple-600 transition ease-in mb-5">
            &lt; Go Back
          </div>
        </Link>
        <h1 className="text-4xl mb-3">{post.title}</h1>

        {/* Wysiwyg content, TODO: XSS removal of post content */}
        <div
          id="post-content"
          className="text-lg text-steel pb-9"
          dangerouslySetInnerHTML={{ __html: post.content ?? '' }}
        />

        {/* Shard and reply */}
        <div className="flex justify-end">
          <div className="w-full md:w-40 p-3 bg-background-500 rounded-lg">
            <ShardClickableStat post={post} />
          </div>
        </div>

        <hr className="border-darksteel my-5" />
        <div className="w-full grid md:grid-cols-2 gap-3">
          <div className="bg-background-500 flex space-x-3 text-white p-5 rounded-xl">
            {/* Square image */}
            <div className="h-20 w-20 flex-none">
              <Image
                className=" w-full h-full rounded-xl object-cover object-center"
                height={80}
                width={80}
                alt="feed icon"
                src={post.author.profilePicture ?? '#'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = '/images/games/placeholder.png'
                }}
              />
            </div>

            <div>
              <p className="text-steel">Written by</p>
              <p className="pb-4">{post.author.username}</p>
              <p className="text-steel">{post.author.tagline}</p>
            </div>
          </div>

          <div className="bg-background-500 flex space-x-3 text-white p-5 rounded-xl">
            {/* Square image */}
            <div className="h-20 w-20 flex-none">
              <Image
                className=" w-full h-full rounded-xl object-cover object-center"
                height={80}
                width={80}
                alt="feed icon"
                src={feed.iconImage ?? '#'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = '/images/games/placeholder.png'
                }}
              />
            </div>

            <div>
              <p className="text-steel">Posted to</p>
              <p className="pb-4">{feed.name}</p>
              <p className="text-steel">{feed.description}</p>
            </div>
          </div>
        </div>
        <hr className="border-darksteel my-5" />
        <ShortPostEditor onClick={doReply} buttonContent="Post comment" />
        <div className="bg-background-500 rounded-lg p-3 mt-3 grid">
          {loading && <Spinner />}
          {total === 0 ? (
            <div className="text-center">No comments yet</div>
          ) : (
            <div className="grid gap-3">
              {items.map((comment) => (
                <PostReply
                  key={comment.id ?? ''}
                  comment={comment}
                  allowReplies={false}
                  onReply={() => {}}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>

    // Infinite scrolling to populate this grid
  )
}

export default WritersBlockPostPage
