import Spinner from 'src/common/Spinner'
import SectionCard from '../atoms/SectionCard'

export default function SkeletonCard() {
  return (
    <SectionCard title="">
      <div className="w-full pt-3 pb-12 text-center" data-testid="skeleton-card-inner">
        <Spinner />
      </div>
    </SectionCard>
  )
}
