import NavigationSideBarItem from '../atoms/NavigationSideBarItem'

/**
 * @param activeRoute - The route that is currently active
 * @param childRoutes - A List of routes to render within the list, each with a human friendly name and path
 */
interface Props {
  childRoutes: { name: string; path: string }[]
  activeRoute: string
  onChange: () => void
}

export default function NavigationSideBar({
  childRoutes,
  activeRoute,
  onChange,
}: Props) {
  return (
    <div
      className="flex flex-col h-full  border-background-500 pt-5 pl-3"
      style={{ maxWidth: 250, width: 250 }}
      data-testid="navigation-sidebar"
    >
      {childRoutes.map((route) => (
        <NavigationSideBarItem
          key={route.name}
          activeRoute={activeRoute}
          name={route.name}
          path={route.path}
          onClick={onChange}
        />
      ))}
    </div>
  )
}
