import { useCallback, useEffect, useState } from 'react'
import Spinner from '../../common/Spinner'
import SelectField from '../../common/forms/SelectField'
import Button from '../../common/forms/Button'
import { loadStripe } from '@stripe/stripe-js'
import config from '../../config'
import { useContext } from 'react'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../../app/helpers/RestApiRoutes'

const stripePromise = loadStripe(config.stripe.publicKey)

interface ProductPrice {
  Product: {
    id: string,
    images: string[],
    name: string,
    description: string,
  },
  Price: {
    id: string,
    unit_amount: number,
    type: string,
    recurring: {
      interval: string,
      interval_count: number,
    }
  }[]
}

function SubscribePage() {
  const client = useContext(AxiosContext)
  const { user } = useContext(CurrentUserContext)
  const [chosenPrices, setChosenPrice] = useState<Record<string, string>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [checkoutLoading] = useState<boolean>(false)
  const [error] = useState<string>('')
  const [data, setData] = useState<ProductPrice[]>([])

  const getSubscriptionProducts = useCallback(async () => {
    setLoading(true)
    const { data } = await client.get(Routes.stripe.ListProducts())
    setData(data)
    setLoading(false)
  }, [client])
  
  useEffect(() => {
    void getSubscriptionProducts()
  }, [client, getSubscriptionProducts])

  async function createCheckoutSession(priceId: string) {
    return client.post<{id: string}>(Routes.stripe.CreateCheckoutSession(), {
      priceId
    })
  }

  if (!user) {
    return null
  }

  if (loading) {
    return (
      <div className="w-full py-20 text-center">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <p className="w-full py-20 text-center">
        Something wrong: {error}
      </p>
    )
  }

  const getPricesForTier = (tier: ProductPrice['Price']) => {
    const options = {} as any
    tier.map((price) => {
      options[price.id] = `£${price.unit_amount / 100} / ${
        price.recurring.interval
      }`
      return price
    })
    return options
  }

  const choosePrice = async (tier: ProductPrice) => {
    const selectedPrice = chosenPrices[tier.Product.id] || tier.Price[0].id
    const { data } = await createCheckoutSession(selectedPrice)

    if (!data) {
      throw new Error('No checkout session')
    }

    const stripe = await stripePromise
    if (!stripe) {
      throw new Error('Stripe not loaded')
    }

    const error = await stripe.redirectToCheckout({
      sessionId: data.id,
    })

    if (error) {
      console.log(error)
    }
  }

  return (
    <div
      className="w-full min-h-screen pt-5 text-lg bg-no-repeat bg-cover"
      style={{ backgroundImage: 'url("/images/ember-background-ea.jpg")' }}
    >
      <div className="container mx-auto grid md:grid-cols-3 grid-cols-1 gap-3">
        {data.map((tier) => {
          return (
            <div
              className="bg-background-500 rounded-xl flex flex-col"
              key={tier.Product.id}
            >
              <div className="rounded-t-xl w-full h-[250px] relative">
                <div className={'rounded-t-xl w-full h-[250px] bg-gray-200 absolute'}></div>
                <img
                  src={tier.Product.images[0]}
                  alt={tier.Product.name + ' product image'}
                  className="rounded-t-xl w-full  absolute h-[250px]"
                />
              </div>


              <div className="p-5 flex flex-col flex-1">
                <div data-testid="product-title" className="pb-5">
                  {tier.Product.name}
                </div>
                <div data-testid="product-description" className="pb-5">
                  {tier.Product.description}
                </div>

                <SelectField
                  options={getPricesForTier(tier.Price)}
                  onChange={(e: any) => {
                    setChosenPrice((prev) => ({
                      ...prev,
                      [tier.Product.id]: e,
                    }))
                  }}
                  className="mt-auto"
                />

                <Button
                  trackingEventName={`${tier.Product.name}-subscribe-button`}
                  onClick={() => choosePrice(tier)}
                  className="mt-3"
                  loading={checkoutLoading}
                >
                  Subscribe
                </Button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SubscribePage
