import { useBoolean, useToast } from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'
import { EmberNotification } from '../../api/types'

export function useNotifications() {
  const [loading, setLoading] = useBoolean()
  const [notifications, setNotifications] = useState<APIPaginatedResponse<EmberNotification>>({
    total: 0,
    payload: [],
    offset: 0,
    pageSize: 0,
    hasMore: false
  })
  const client = useContext(AxiosContext)
  const toast = useToast()

  const getNotifications = useCallback(async () => {
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<EmberNotification>>(Routes.GetNotifications(0, 100))
      if (data) {
        setNotifications(data)
      }
    } catch (e) {
      toast({
        title: 'Unable to get notifications',
        description: 'Something went wrong while fetching the latest notifications',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading.off()
  }, [client, setLoading, toast])

  useEffect(() => {
    void getNotifications()
  }, [getNotifications])

  return {
    loading,
    notifications,
    getNotifications,
  }
}