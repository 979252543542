import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { Message } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

export function useGetMessages(conversationId: string) {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [messages, setMessages] = useState<APIPaginatedResponse<Message>>({
    payload: [],
    hasMore: false,
    offset: 0,
    pageSize: 0,
    total: 0
  })

  const [error, setError] = useState('')
  const toast = useToast()

  const getMessages = useCallback(async (conversationId: string) => {
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<Message>>(Routes.GetMessages(conversationId, 0, 100))
      if (data) {
        setMessages(data)
      }
    } catch (e: any) {
      toast({
        title: 'Failed to load messages for conversation',
        description: 'Something went wrong while trying to load the messages for the conversation',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, setLoading, toast])

  useEffect(() => {
    void getMessages(conversationId)
  }, [conversationId, getMessages])

  return {
    loading,
    messages,
    error,
    getMessages,
  }
}