function CreateIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.392" height="23.367" viewBox="0 0 23.392 23.367" fill="currentColor">
      <g id="Group_1318" data-name="Group 1318" transform="translate(-3219.519 -2889.708)">
        <path id="path41" d="M35.551,15.173a2.257,2.257,0,0,0-.493.727l-1.766,3.921A.746.746,0,0,0,34.3,20.834l3.921-1.74a2.767,2.767,0,0,0,.727-.493l9.609-9.427a2.424,2.424,0,0,0-3.428-3.428Z" transform="translate(3193.629 2884.683)" />
        <path id="path43" d="M25.412,22.334a1.452,1.452,0,0,0-1.454,1.454v4.467A1.452,1.452,0,0,1,22.5,29.71H9.337a1.452,1.452,0,0,1-1.454-1.454V15.063a1.452,1.452,0,0,1,1.454-1.454h4.285a1.454,1.454,0,0,0,0-2.909H9.337A4.384,4.384,0,0,0,5,15.063V28.256a4.345,4.345,0,0,0,4.337,4.337H22.53a4.345,4.345,0,0,0,4.337-4.337V23.789A1.452,1.452,0,0,0,25.412,22.334Z" transform="translate(3214.519 2880.482)" />
      </g>
    </svg>

  )
}

export default CreateIcon