import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useUploadImage } from '../../app/hooks/useUploadImage'
import 'react-quill/dist/quill.snow.css'
import './WYSIWYG.css'
import ReactQuill from 'react-quill'

interface Props {
  label?: string
  errored?: boolean
  success?: boolean
  message?: boolean | string
  value?: string
  onChange?: (html: string) => void
}

function WYSIWYG({
  label,
  errored = false,
  success = false,
  message = false,
  onChange,
}: Props) {
  const uploader = useUploadImage()

  const [, setEditorValue] = useState<any>('<div></div>')

  // let editor: Quill

  //   TODO: Set the editor value to the existing post's content
  //   export const setContent = (content) => {
  //     const delta = editor.clipboard.convert(content);
  //     editor.setContents(delta, "silent");
  //     textChangeHandler(delta, [], "silent");
  //   };
  const modules = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }, 'blockquote', 'link', 'image', 'video'],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        [{ align: [] }],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
        video: videoHandler,
      },
    },
  })

  const formats = useRef([
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align',
  ])
  const placeholder = 'Click here to start writing'
  const theme = 'snow'

  let editorRef: ReactQuill | undefined = undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function videoHandler() {
    let url = prompt('Enter a YouTube or Vimeo Video URL: ')
    if (!url) {
      console.log('No URL entered')
      return
    }
    url = getVideoUrl(url)
    const editor = editorRef?.getEditor()
    let range = editor?.getSelection()
    if (!range) {
      console.log('There was no range selected')
      return
    }

    if (url != null) {
      editor?.insertEmbed(range.index, 'video', url)
    }
  }

  function getVideoUrl(url: string) {
    let match =
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/,
      ) ||
      url.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/,
      ) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/)

    if (match && match[2].length === 11) {
      return 'https://www.youtube.com/embed/' + match[2] + '?showinfo=0'
    }
    if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
      return (
        (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/'
      )
    }
    return null
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function imageHandler() {
    await selectLocalImage((url: string) => {
      console.log('url', { url, editorRef })
      const editor = editorRef?.getEditor()

      if (!editor || !editor.getSelection()) {
        console.log('No editor')
        return
      }
      const selection = editor.getSelection()
      if (!selection) {
        console.log('No selection')
        return
      }
      editor.insertEmbed(selection.index, 'image', url)
    })
  }

  async function selectLocalImage(callback: (url: string) => void) {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.click()

    // Listen upload local image and save to server
    input.onchange = async () => {
      if (!input.files) {
        console.log('no files were selected')
        return
      }
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        return await uploader({ data_url: '', file }).then((url) =>
          callback(url),
        )
      } else {
        console.warn('You could only upload images.')
      }
    }
  }

  function handleChange(html: string) {
    setEditorValue(html)
    onChange && onChange(html)
  }

  function bindRef(ref: ReactQuill) {
    if (ref === null) return
    editorRef = ref
  }

  return (
    <div>
      {label && <label className="pt-3">{label}</label>}

      <ReactQuill
        ref={bindRef}
        preserveWhitespace={true}
        theme={theme}
        modules={modules.current}
        formats={formats.current}
        // value={editorValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="bg-background-500 rounded-xl editor"
      />

      {message && (
        <small
          className={classNames({
            'text-red-500': errored,
            'text-green-500': success,
          })}
        >
          {message}
        </small>
      )}
    </div>
  )
}

export default WYSIWYG
