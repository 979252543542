import gql from 'graphql-tag'

export const CREATE_STARTUP = gql`
  mutation CreateStartup($email: String!) {
    CreateStartup(email: $email)
  }
`

export interface CreateStartupQuery {
  CreateStartup: Boolean
}

export interface CreateStartupVariables {
  email: string
}

export const SUBMIT_STARTUP_DETAILS = gql`
  mutation SubmitDetails(
    $code: String!
    $idea: String!
    $funding: Boolean!
    $location: String!
    $stage: String!
  ) {
    SubmitStartupDetails(
      code: $code
      idea: $idea
      funding: $funding
      location: $location
      stage: $stage
    )
  }
`

export interface SubmitStartupDetailsMutation {
  SubmitStartupDetails: Boolean
}

export interface SubmitStartupDetailsVariables {
  code: string
  idea: string
  funding: boolean
  location: string
  stage: string
}
