import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import useLogout from 'src/app/hooks/useLogout'
import Image from './forms/Image'
import Spinner from './Spinner'
import { CurrentUserContext } from '../providers/AuthProvider'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function ProfileDropdown(props: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const Logout = useLogout()

  const { user, writersBlocks, communities } = useContext(CurrentUserContext)

  if (!user?.username) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }
  const username = user?.username ?? 'unknown'

  const downArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-down"
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  )

  const upArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-up"
    >
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  )

  const WritersBlockSection = () => {
    if ((writersBlocks ?? []).length === 0) {
      return null
    }

    return (
      <div>
        <hr className="border-darkbackground-500" />
        <div className="flex flex-col ">
          <Link
            to={`/writers-block`}
            className="px-3 text-steel hover:text-primary-600"
          >
            Writers Blocks
          </Link>
          {(writersBlocks ?? []).map(({ slug, name }, index) => (
            <Link to={`/writers-block/${slug}`} className="px-3" key={index}>
              {name}
            </Link>
          ))}
        </div>
      </div>
    )
  }

  const CommunitiesSection = () => {
    if ((communities ?? []).length === 0) {
      return null
    }

    return (
      <div>
        <hr className="border-darkbackground-500" />
        <div className="flex flex-col ">
          <Link
            to={`/communities`}
            className="text-steel hover:text-gray-700 px-3"
          >
            Communities
          </Link>
          {(communities ?? []).map(({ slug, name }, index) => (
            <Link key={index} to={`/communities/${slug}`} className="px-3">
              {name}
            </Link>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div
      className="hidden lg:flex space-x-3 items-center cursor-pointer select-none text-lg"
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen ? upArrow : downArrow}
      <div className="text-sm">{username}</div>
      <Image
        src={user?.profilePicture}
        alt="profile"
        className="rounded-lg h-12 w-12"
      />

      {isOpen && (
        <div className="dropdown-menu absolute top-20 bg-background-500 rounded-xl flex flex-col right-3 py-5 space-y-3 drop-shadow-lg">
          <Link to={`/profile/${username}/feed`} className="px-3">
            {user?.username}
          </Link>

          <WritersBlockSection />

          <CommunitiesSection />

          <hr className="border-darkbackground-500" />

          <div className="flex flex-col ">
            <Link to={`/settings/account`} className="px-3">
              Settings
            </Link>
          </div>

          <hr className="border-darkbackground-500" />

          <div className="flex flex-col">
            <div
              className="cursor-pointer text-primary-500 hover:text-primary-700 px-3"
              onClick={Logout}
            >
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileDropdown
