import { useState } from 'react'
import {
  CreateStartupQuery,
  CreateStartupVariables,
  CREATE_STARTUP,
} from '../../api/gql-queries/launchpad.queries'
import Button from '../../common/forms/Button'
import InputField from '../../common/forms/InputField'
import { useMutation } from '@apollo/client'
import './LaunchPadPage.css'

function LaunchPadPage() {
  const [email, setEmail] = useState('')
  const [sentForm, setSentForm] = useState(false)
  const [createLaunchpadAccount, { loading, error }] = useMutation<
    CreateStartupQuery,
    CreateStartupVariables
  >(CREATE_STARTUP, {
    onCompleted: () => {
      setSentForm(true)
    },
  })

  return (
    <div className="bg-darkbackground-500 w-full text-center min-h-screen background z-0">
      <div className="max-w-5xl mx-auto p-3 z-10 content">
        <div className="grid md:grid-cols-2 p-4 rounded-xl bg-background-500">
          <img
            src="/images/launchpad/mskgrp.png"
            alt="game developer stock imagagry"
            className="h-full rounded-lg object-cover hidden md:block"
          />
          <div className="px-5 flex-1">
            <img
              src="/images/launchpad/kollider_trans_logo_collat_final.png"
              alt="barclays logo"
            />

            {!sentForm && (
              <div>
                <p className="text-white font-rubik font-medium">
                  Elevate your idea to the next Level
                </p>
                <p>
                  We’ve Partnered with Kollider Eagle Lab to give indie
                  developers the tools and resources to take their idea to the
                  next level. Together we aim to provide you the support you
                  need to realise your full potential.
                  <br />
                  <br />
                  To register your interest, please enter your email below.
                </p>

                <div className="md:px-5 pb-3">
                  <InputField
                    id="email_input"
                    placeholder="joe.blogs@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <Button
                  trackingEventName={'launchpad-page-create-launchpad-account'}
                  color="primary"
                  loading={loading}
                  onClick={() => {
                    createLaunchpadAccount({
                      variables: {
                        email,
                      },
                    })
                  }}
                >
                  Submit
                </Button>
              </div>
            )}

            <div className="pt-3">
              <small className="text-muted">
                *This is limited to UK based businesses only at this time.
              </small>
            </div>

            {sentForm && !error && (
              <div>
                <h3 className="text-white">Thanks for registering</h3>
                <p>
                  Thank you for registering your interest in our launchpad
                  project. Check your emails for next steps and join our discord
                  while you wait.
                </p>
                <strong>
                  <a href="https://discord.com/invite/ZMk93b9">Ember Discord</a>
                </strong>
              </div>
            )}
            {error && (
              <div>
                <h3 className="text-white">Something went wrong</h3>
                <p>
                  We're very sorry but something seems to have gone wrong.
                  Please reach out at our discord or try again later.
                </p>
                <strong>
                  <a href="https://discord.com/invite/ZMk93b9">Ember Discord</a>
                </strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LaunchPadPage
