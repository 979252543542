import { useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {
  UpdateFeedQuery,
  UpdateFeedVariables,
  UPDATE_FEED,
  GetFeedQuery,
  GetFeedVariables,
  GET_FEED,
} from 'src/api/gql-queries'
import Button from 'src/common/forms/Button'
import SectionCard from '../../atoms/SectionCard'
import InputFieldWithMessage from '../../molecules/InputFieldWithMessage'
import SkeletonCard from '../../molecules/SkeletonCard'
import { CurrentUserContext } from '../../../../providers/AuthProvider'

type FormState = {
  websiteError: boolean
  websiteMessage: string
  descriptionError: boolean
  descriptionMessage: string
  categoryError: boolean
  categoryMessage: string
}

const defaultPasswordState: FormState = {
  websiteError: false,
  websiteMessage: '',
  descriptionError: false,
  descriptionMessage: '',
  categoryError: false,
  categoryMessage: '',
}

export default function ChangeWritersBlockDetails() {
  const { ClearCache, writersBlocks } = useContext(CurrentUserContext)
  const { pathname: location } = useLocation()

  const [description, setDescription] = useState('')
  const [website, setWebsite] = useState('')
  const [category, setCategory] = useState('')
  const [messages, setMessages] = useState<FormState>(defaultPasswordState)

  const [updateFeedMuataion] = useMutation<
    UpdateFeedQuery,
    UpdateFeedVariables
  >(UPDATE_FEED)

  const getWritersBlockByName = () => {
    // Get last part of url
    const url = location.split('/')
    const name = url[url.length - 1]

    return (writersBlocks ?? []).find((block) => block.name === decodeURI(name))
  }

  const { data, loading } = useQuery<GetFeedQuery, GetFeedVariables>(GET_FEED, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      slug: getWritersBlockByName()?.id ?? '',
    },
  })

  useEffect(() => {
    setMessages(defaultPasswordState)
    setDescription(data?.Feed.description ?? '')
    setWebsite(data?.Feed.website ?? '')
    setCategory(data?.Feed.category.join(', ') ?? '')
  }, [data])

  function onSubmit(e: any) {
    e.preventDefault()
    setMessages(defaultPasswordState)

    const trimmedDescription =
      description.trim() === '' ? undefined : description.trim()
    const trimmedWebsite = website.trim() === '' ? undefined : website.trim()
    const timmedCategory = category.trim() === '' ? undefined : category.trim()

    // Reset state to default
    setMessages(defaultPasswordState)
    updateFeedMuataion({
      variables: {
        id: data?.Feed.id as string,
        description: trimmedDescription || (data?.Feed.bannerImage as string),
        website: trimmedWebsite || (data?.Feed.bannerImage as string),
        category:
          timmedCategory?.split(',').map((i) => i.trim()) ||
          data?.Feed.category,
      },
    })
      .then((res) => {
        if (res.data?.UpdateFeed) {
          ClearCache()
          setMessages({
            ...messages,
            websiteMessage: trimmedWebsite ?? 'Successfully changed website',
            descriptionMessage:
              trimmedDescription ?? 'Successfully changed description',
            categoryMessage:
              timmedCategory ?? 'Successfully changed categories',
          })
        }
      })
      .catch(() => {
        setMessages({
          ...messages,
          websiteMessage: trimmedWebsite ?? 'Something went wrong',
          websiteError: true,
          descriptionMessage: trimmedDescription ?? 'Something went wrong',
          descriptionError: true,
          categoryMessage: timmedCategory ?? 'Something went wrong',
          categoryError: true,
        })
      })
  }

  // There was no communties, so there should be no form
  if (!writersBlocks) return null

  // We're loading so we should display the intent
  if (loading) return <SkeletonCard />

  // there's no WritersBlock with that name, we should get out of here
  if (!getWritersBlockByName())
    return <Navigate to="/settings/writers-blocks" />

  return (
    <SectionCard title="Change Writers Block details">
      <form onSubmit={onSubmit}>
        <div>
          <div>Description</div>
          <InputFieldWithMessage
            type="text"
            placeholder="Description of my blog"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
            dataTestId="input-text-description"
            error={messages.descriptionError}
            message={messages.descriptionMessage}
          />
        </div>

        <div>
          <div>Website</div>
          <InputFieldWithMessage
            type="text"
            placeholder="Optional: https://my-site.com"
            value={website}
            onChange={(e) => setWebsite(e.currentTarget.value)}
            dataTestId="input-text-website"
            error={messages.websiteError}
            message={messages.websiteMessage}
          />
        </div>

        {/* Categories */}
        <div>
          <div>Categories</div>
          <InputFieldWithMessage
            type="text"
            placeholder="Optional: General, Gaming"
            value={category}
            onChange={(e) => setCategory(e.currentTarget.value)}
            dataTestId="input-text-category"
            error={messages.categoryError}
            message={messages.categoryMessage}
          />
        </div>

        <Button
          type="submit"
          trackingEventName={'settings-page-update-user-information'}
          size="md"
          className="p-3 bg-primary-500 text-white mt-3"
          data-testid="update-basic-info-save-button"
          // class:disabled={() => !HasChangedBasicInfo()}
        >
          Save Changes
        </Button>
      </form>
    </SectionCard>
  )
}
