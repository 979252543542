interface Props extends React.HtmlHTMLAttributes<any> {}

function House(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.101"
      height="25.667"
      viewBox="0 0 23.101 25.667"
      fill="currentColor"
    >
      <path
        id="Path_12"
        data-name="Path 12"
        {...props}
        d="M43.073,8.424,34.7.7a2.7,2.7,0,0,0-3.635,0L22.694,8.424a4.61,4.61,0,0,0-1.361,3.1V23.1A2.569,2.569,0,0,0,23.9,25.667h6.116V19.736h5.79v5.931h6.057A2.566,2.566,0,0,0,44.433,23.1V11.521A4.6,4.6,0,0,0,43.073,8.424ZM41.862,23.1h1.21l-.691-12.789L32.806,1.917,24.935,9.495C21.5,9.5,23.9,23.1,23.9,23.1V11.521a2.121,2.121,0,0,1,.534-1.21l8.372-7.721a.142.142,0,0,1,.155,0l8.372,7.721a2.114,2.114,0,0,1,.534,1.21Q41.866,23.1,41.862,23.1Z"
        transform="translate(-21.333 0)"
      />
    </svg>
  )
}

export default House
