function ClickableStat({ children, onClick }: any) {
  return (
    <div
      className="w-full hover:bg-darkbackground-400 transition rounded-lg ease-in-out cursor-pointer select-none text-steel"
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default ClickableStat
