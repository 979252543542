function PokemonGoLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.058"
      height="24.058"
      viewBox="0 0 24.058 24.058"
    >
      <path
        id="pokeball_icon_136305"
        style={{ fill: '#fff' }}
        d="M14.029,2A12.029,12.029,0,1,1,2,14.029,12.029,12.029,0,0,1,14.029,2m0,2.406a9.621,9.621,0,0,0-9.551,8.42h4.9a4.807,4.807,0,0,1,9.31,0h4.9a9.621,9.621,0,0,0-9.551-8.42m0,19.246a9.621,9.621,0,0,0,9.551-8.42h-4.9a4.807,4.807,0,0,1-9.31,0h-4.9a9.621,9.621,0,0,0,9.551,8.42m0-12.029a2.406,2.406,0,1,0,2.406,2.406A2.406,2.406,0,0,0,14.029,11.623Z"
        transform="translate(-2 -2)"
      />
    </svg>
  )
}

export default PokemonGoLogo
