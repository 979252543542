import React, { useCallback, useContext, useEffect, useState } from 'react'
import { APIPaginatedResponse, Routes } from '../../../app/helpers/RestApiRoutes'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead, Tr, useDisclosure, useToast, Wrap,
} from '@chakra-ui/react'
import { AxiosContext } from '../../../providers/AxiosProvider'
import { Post, PostReport } from '../../../api/types'
import Image from '../../../common/forms/Image'
import Button from '../../../common/forms/Button'
import { CharLimit } from '../../../app/helpers/string-helpers'
import { BsX } from 'react-icons/bs'
import CheckMark from '../../../common/icons/CheckMark'
import { formatDistance } from 'date-fns'
import CrossIcon from '../../../common/icons/CrossIcon'

export default function ManagePostPage() {
  const client = useContext(AxiosContext)
  const toast = useToast()
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure()
  const [page, setPage] = useState<number>(0)
  // TODO: search post by username
  // TODO: set page size
  const [pageSize] = useState<number>(20)
  const [currentPost, setCurrentPost] = useState<Post>()
  const [postReports, setPostReports] = useState<APIPaginatedResponse<PostReport>>()
  const [data, setData] = useState<APIPaginatedResponse<Post>>({
    pageSize: 0,
    payload: [],
    total: 0,
    offset: 0,
    hasMore: false,
  })

  const handleRowClick = useCallback((post: Post) => {
    setCurrentPost(post)
    onOpen()
  }, [onOpen, setCurrentPost])

  const getReports = useCallback(async (currentPost: Post) => {
    try {
      const { data } = await client.get<APIPaginatedResponse<PostReport>>(Routes.admin.GetPostReports(currentPost.id!))
      setPostReports(data)
    } catch (e) {
      console.error(e)
      toast({
        title: 'Failed to load reports for post',
        description: 'Something went wrong while trying to load reports for posts',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, toast])

  const markPostReportAsResolved = useCallback(async (postId: string) => {
    try {
      await client.post(Routes.admin.MarkPostReportAsResolved(postId))
      await getReports(currentPost!)
    } catch (e) {
      console.error(e)
      toast({
        title: 'Failed to mark report as resolved',
        description: 'failed to mark report as resolved',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, currentPost, getReports, toast])

  useEffect(() => {
    if (currentPost) {
      void getReports(currentPost)
    }
  }, [currentPost, getReports])

  const getPosts = useCallback(async (page: number, pageSize: number) => {
    try {
      const res = await client.get<APIPaginatedResponse<Post>>(
        Routes.GetPosts({
          page,
          pageSize,
          feedType: '',
          feedId: '',
          pinned: false,
          archived: false,
          sponsored: false,
        }),
      )
      setData(res.data)
    } catch (e) {
      toast({
        title: 'Failed to get users',
        description: 'Something went wrong trying to get posts',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, toast])

  const handleModalClose = useCallback(async (archivePost: boolean) => {
    if (archivePost) {
      await client.post(Routes.admin.ArchivePost(currentPost!.id))
      toast({
        title: 'Post has been archived',
        description: 'This post has been archived and therefore is no longer available',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }

    setCurrentPost(undefined)
    onClose()
    void getPosts(page, pageSize)
  }, [client, currentPost, getPosts, onClose, page, pageSize, toast])

  useEffect(() => {
    void getPosts(page, pageSize)
  }, [getPosts, page, pageSize])

  function PreviousPage() {
    setPage((prev) => {
      if (prev - 1 < 0) {
        return 0
      }
      return prev - 1
    })
  }

  function NextPage() {
    setPage((prev) => {
      const highestPage = Math.ceil(data.total / pageSize) - 1
      if (prev + 1 > highestPage) {
        return prev
      }
      return prev + 1
    })
  }

  const handleSponsoredButtonClick = useCallback(async (postId: string, shouldBePinned: boolean) => {
    try {
      await client.post(Routes.admin.SetSponsoredPostStatus(postId, shouldBePinned))
      void getPosts(page, pageSize)
    } catch (e) {
      console.error(e)
      toast({
        title: 'Failed to block user your profile',
        description: 'Something went wrong while trying to update your following status',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, getPosts, page, pageSize, toast])

  const handlePinnedButtonClick = useCallback(async (postId: string, shouldBePinned: boolean) => {
    try {
      await client.post(Routes.admin.SetPinnedPostStatus(postId, shouldBePinned))
      void getPosts(page, pageSize)
    } catch (e) {
      console.error(e)
      toast({
        title: 'Failed to block user your profile',
        description: 'Something went wrong while trying to update your following status',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }, [client, getPosts, page, pageSize, toast])

  const UserRows = useCallback(() => {
      return (
        <>
          {data.payload?.map(post => {
            return (
              <Tr onClick={() => handleRowClick(post)}>
                <Td paddingX={0}>
                  <Image
                    src={post.image} alt='author avatar' className='w-12 h-12 rounded-2xl mr-2'
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = '/images/games/placeholder.png'
                    }} />
                </Td>
                <Td>{post.title}</Td>
                <Td>{post.feed?.name}</Td>
                <Td>{CharLimit(post.content, 30)}</Td>
                <Td>{post.sponsored ? <CheckMark /> : <CrossIcon />}</Td>
                <Td>{post.pinned ? <CheckMark /> : <CrossIcon />}</Td>
                <Td>{post.totalReports}</Td>
                <Td>
                  <Wrap gap={2}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        void handleSponsoredButtonClick(post.id!, !post.sponsored)
                      }}
                      trackingEventName={'toggle-admin-sponsor'}>
                      {post.sponsored ? 'Remove Sponsor' : 'Sponsor Post'}
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        void handlePinnedButtonClick(post.id!, !post.pinned)
                      }}
                      trackingEventName={'toggle-admin-pin'}>
                      {post.pinned ? 'Remove pin' : 'Pin Post'}
                    </Button>
                  </Wrap>
                </Td>
              </Tr>
            )
          })}
        </>
      )
    },
    [handlePinnedButtonClick, handleSponsoredButtonClick, data.payload, handleRowClick],
  )

  return (
    <div className={'container mx-auto'}>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href='/admin'>Admin</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'>Manage Posts</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <TableContainer my={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Title</Th>
              <Th>Feed Name</Th>
              <Th>Content</Th>
              <Th>Sponsored</Th>
              <Th>Pinned</Th>
              <Th># of Reports</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <UserRows />
          </Tbody>
          <Tfoot>
            <Tr>
              <Th></Th>
              <Th>Title</Th>
              <Th>Feed Name</Th>
              <Th>Content</Th>
              <Th>Sponsored</Th>
              <Th>Pinned</Th>
              <Th># of Reports</Th>
              <Th>Actions</Th>
            </Tr>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th colSpan={2}>
                <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'}>
                  <Button trackingEventName={'prev-page'} onClick={PreviousPage}>&lt;</Button>
                  {page + 1} / {Math.ceil(data.total / pageSize)}
                  <Button trackingEventName={'next-page'} onClick={NextPage}>&gt;</Button>
                </Flex>
              </Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>

      <Modal isOpen={isOpen} size={'full'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={'70vw'}>
          <ModalHeader>Manage post reports</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxH={'70hv'} h={'50vh'}>
              <TableContainer my={4}>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>Age</Th>
                      <Th>Report reason</Th>
                      <Th>Resolved</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(postReports?.payload ?? []).map((report, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{formatDistance(new Date(report.createdAt), new Date(), { addSuffix: true })}</Td>
                          <Td>{report.message}</Td>
                          <Td>{report.resolved ? <CheckMark /> : <BsX />}</Td>
                          <Td>
                            <Button trackingEventName={'mark-as-resolved'}
                                    disabled={report.resolved}
                                    onClick={() => {
                                      void markPostReportAsResolved(report.id)
                                    }}>
                              {report.resolved ? 'Resolved' : 'Mark as resolved'}
                            </Button>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex gap={4}>
              <Button color={'danger'} trackingEventName={'close-message-report-modal'} onClick={() => handleModalClose(true)}>
                Close and remove post
              </Button>
              <Button trackingEventName={'close-message-report-modal'} onClick={() => handleModalClose(false)}>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}