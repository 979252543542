import { useState } from 'react'
import SectionCard from '../../atoms/SectionCard'
import Button from 'src/common/forms/Button'
import InputFieldWithMessage from '../../molecules/InputFieldWithMessage'
import useLogout from 'src/app/hooks/useLogout'
import { useUpdateUser } from '../../../../app/hooks/UseUpdateUser'

type FormState = {
  passwordError: boolean
  passwordMessage: string
}

const defaultPasswordState: FormState = {
  passwordError: false,
  passwordMessage: '',
}

export default function ChangePasswordForm() {
  const [password, setPassword] = useState('')
  const [messages, setMessages] = useState<FormState>(defaultPasswordState)
  const Logout = useLogout()

  const {
    loading,
    updateUser,
  } = useUpdateUser()

  function submitForm(e: any) {
    console.log(e)
    e.preventDefault()

    // Ensure password strength is 8 long, has a number, and has a special character
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if (!passwordRegex.test(password)) {
      setMessages({
        passwordMessage:
          'Password must be at least 8 characters long, contain an upper-case and lower-case character, a number and a special character',
        passwordError: true,
      })
      return
    } else {
      setMessages({
        passwordMessage: '',
        passwordError: false,
      })
    }

    updateUser({
      password: password,
      changePassword: password,
    }).then(() => {
      Logout()
    })
  }

  return (
    <SectionCard title="Change your password">
      <form onSubmit={submitForm}>
        <div>Password</div>

        <InputFieldWithMessage
          type="password"
          placeholder="Password"
          value={password}
          dataTestId="input-text-password"
          onChange={(e) => setPassword(e.currentTarget.value)}
          message={messages.passwordMessage}
          error={messages.passwordError}
        />

        <Button
          type="submit"
          trackingEventName={'settings-page-update-user-information'}
          size="md"
          className="p-3 bg-primary-500 text-white mt-3"
          data-testid="update-basic-info-save-button"
          loading={loading}
        >
          Save Changes
        </Button>
      </form>
    </SectionCard>
  )
}
