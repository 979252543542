import { Link } from 'react-router-dom'

function PrivacyPolicyPage() {
  const microNavBar = (
    <div className="sticky top-0 right-4 bg-background-500">
      <nav className="p-3 flex justify-end space-x-3">
        <div className="nav-item active">
          <Link
            to="/login"
            className="nav-link text-steel hover:text-darksteel"
          >
            Login/Register
          </Link>
        </div>{' '}
        <div className="nav-item">
          <a
            className="text-steel hover:text-darksteel"
            target={'_blank'}
            rel="noreferrer"
            href="https://discord.com/invite/ZMk93b9"
          >
            Discord
          </a>
        </div>{' '}
        <div className="nav-item dropdown">
          <a
            className="text-steel hover:text-darksteel"
            target={'_blank'}
            rel="noreferrer"
            href="mailto:contact@emberapp.gg"
          >
            Contact Us
          </a>
        </div>
      </nav>
    </div>
  )
  return (
    <div className="bg-darkbackground-500">
      {microNavBar}
      <div className="container mx-auto text-steel pb-5 sm:px-3 px-5">
        <h1 className="text-3xl mt-3">
          <strong>Legal Privacy Policy</strong>
        </h1>{' '}
        <p>
          <span>
            By signing up and accessing this website you accept the below
            privacy policy in full.{' '}
          </span>
          <strong>
            Do not continue to use the Ember website if you do not accept all of
            the terms of our privacy policy stated on this page.
          </strong>
        </p>{' '}
        <p>
          <span>
            The following terminology applies to these Terms and Conditions,
            Privacy Policy and Disclaimer Notice and any or all Agreements:
            “Client”, “You” and “Your” refers to you, the person accessing this
            website and accepting the Company’s terms and conditions. “The
            Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company.
            “Party”, “Parties”, or “Us”, refers to both the Client and
            ourselves, or either the Client or ourselves. The “Service” refers
            to any and all functions and services provided by Ember that you,
            The Client use.
          </span>
        </p>{' '}
        <h2 className="text-2xl mt-3">
          <strong>What information does Ember collect from you?</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              We ask for certain information such as your username, date of
              birth, first and last name and email address when you register for
              an Ember account.
            </span>
          </li>
          <li>
            <span>
              We may also retain any messages you send through the Service, and
              may collect User Content you post to the Service.
            </span>
          </li>
          <li>
            <span>
              We will also allow you to provide us your log-in credentials for
              third-party services (Such as Discord or TWITTER) so that you can
              post information from Ember Service onto the third-party service
              and vice versa.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>How Do We Use the Information We Collect?</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              We use this information to operate, maintain, and provide to you
              the features and functionality of the Service, as well as to
              communicate directly with you, such as to send you email messages
              and push notifications, to allow you to find others and have
              others find you on the Service, and to permit you to share content
              with others on the Service.
            </span>
          </li>
          <li>
            <span>
              When you create a profile on the Service, certain information –
              like your profile picture, name and other biographical information
              – is made available to the public by default. Although you can
              change your permissions for certain types of content, this basic
              biographical information will always be available to the public as
              long as you have an account with Ember.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Third Parties:</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              When you interact with us and our accounts on third-party
              services, we may receive information about you from those third
              parties. You can control the information we receive through the
              settings on those third-party services or by unlinking your Ember
              account from those third-party services, for example: Unlinking
              your Ember account from your TWITTER account.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Third Party Vendors</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Third party vendors, consultants and other service providers that
              perform services on our behalf, in order to carry out their work
              for us which may include providing payment, mailing or email
              services, tax and accounting services, data enhancement services,
              fraud prevention services, web hosting, and/or analytic services,
              email server services (to send messages to users), product
              development services and identity verification services.
            </span>
          </li>
          <li>
            <span>
              We may also share your information with third parties as required
              to (I) satisfy any applicable law, regulation or court order,
              legal process or other government request, (II) enforce our Terms
              of Use, including the investigation of potential violations
              thereof, (III) investigate and defend ourselves against any third
              party claims or allegations, (IV) protect against harm to the
              rights, property or safety of Ember, its users or the public as
              required or permitted by law and (V) detect, prevent or otherwise
              address criminal (including fraud or stalking), security or
              technical issues.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Ember uses the following Services:</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Amazon Web Services: Ember is hosted on AWS servers, see their
              privacy policy here -{' '}
            </span>
            <a href="https://aws.amazon.com/privacy/">
              <span>https://aws.amazon.com/privacy/</span>
            </a>
          </li>
          <li>
            <span>
              Cloudflare: Cloudflare is a web-infrastructure and
              website-security company, providing content-delivery-network
              services, DDoS mitigation, Internet security, and distributed
              domain-name-server services, see their privacy policy here -{' '}
            </span>
            <a href="https://www.cloudflare.com/privacypolicy/">
              <span>https://www.cloudflare.com/privacypolicy/</span>
            </a>
          </li>
          <li>
            <span>
              Google Analytics: Google Analytics is a Web analytics service that
              provides statistics and basic analytical tools for search engine
              optimization (SEO) and marketing purposes, see their privacy
              policy here -{' '}
            </span>
            <a href="https://policies.google.com/privacy">
              <span>https://policies.google.com/privacy</span>
            </a>
          </li>
        </ul>{' '}
        <p>
          <span>
            In the UK &amp; EU, the purposes for which we process your personal
            data are:
          </span>
        </p>{' '}
        <ol>
          <li>
            <span>
              Where we need to perform the contract we are about to enter into
              or have entered into with you for the Service;
            </span>
          </li>
          <li>
            <span>
              Where it is necessary for our legitimate interests and your
              interests and fundamental rights do not override those interests;
              and
            </span>
          </li>
          <li>
            <span>
              Where we need to comply with a legal or regulatory obligation in
              the UK &amp; EU.
            </span>
          </li>
        </ol>{' '}
        <p>
          <span>
            We have set out a description of all the ways we plan to use your
            personal data, and which of the legal bases we rely on to do so.
          </span>
        </p>{' '}
        <p>
          <span>
            Please contact us using the details above if you need information
            about the specific legal basis we are relying on to process your
            personal data where more than one legal basis has been set out.
          </span>
        </p>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Cookies</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Use of Cookies and Other Technology to Collect Information: We
              automatically collect certain types of usage information when you
              visit our website or use our Service, read our emails, or
              otherwise engage with us. When you visit the Service, we may send
              one or more cookies — a small text file containing a string of
              alphanumeric characters — to your computer that uniquely
              identifies your browser and lets us help you log in faster and
              enhance your navigation through the site. A cookie may also convey
              information to us about how you use the Service (e.g., the pages
              you view, the links you click, how frequently you access the
              Service, and other actions you take on the Service), and allow us
              to track your usage of the Service over time. We may collect log
              file information from your browser or mobile device each time you
              access the Service. Log file information may include anonymous
              information such as your web request, Internet Protocol (“IP”)
              address, browser type, information about your mobile device,
              referring / exit pages and URLs, number of clicks and how you
              interact with links on the Service, domain names, landing pages,
              pages viewed, and other such information. We may employ clear gifs
              (also known as web beacons) which are used to anonymously track
              the online usage patterns of our Users. In addition, we may also
              use clear gifs in HTML-based emails sent to our users to track
              which emails are opened and which links are clicked by recipients.
              The information allows for more accurate reporting and improvement
              of the Service. We may also collect analytics data, or use
              third-party analytics tools, to help us measure traffic and usage
              trends for the Service. These tools collect information sent by
              your browser or mobile device, including the pages you visit, your
              use of third party applications, and other information that
              assists us in analyzing and improving the Service. When you access
              our Service by or through a mobile device, we may receive or
              collect and store a unique identification number associated with
              your device (“Device ID”), mobile carrier, device type and
              manufacturer, phone number, and location data (e.g., city and
              state) of your mobile device. We use or may use the data collected
              through cookies, log file, device identifiers, location data and
              clear gifs information to: (a) remember information so that you
              will not have to re-enter it during your visit or the next time
              you visit the site; (b) provide custom, personalized content and
              information; (c) provide and monitor the effectiveness of our
              Service; (d) monitor aggregate metrics such as total number of
              visitors, traffic, usage, and demographic patterns on our website
              and our Service; (e) diagnose or fix technology problems; (f)
              otherwise to plan for and enhance our Service; and (g)
              automatically update the Ember application on your mobile devices.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Personal Information</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Ember allows you to connect with others and share information
              about yourself and provide content with other individuals. Your
              profile information, including your name, photo and other personal
              information you include in your bio, will be available publicly to
              other members of the Service by default. By using the Service, you
              consent to have your profile information displayed to other
              members of the Service. Consider your own privacy and personal
              safety when logging in to our Service and sharing your information
              with anyone. Ember does not share any of your personal information
              such as your email address/telephone number to anybody without
              your consent. If you remove information that you posted to the
              Service, copies may remain viewable in cached and archived pages
              of the Service, or if other Users have copied or saved that
              information.
            </span>
          </li>
          <li>
            <span>
              Any information or content that you voluntarily disclose for
              posting to the Service, such as User Content, becomes available to
              the public, as controlled by any applicable privacy settings. To
              change your privacy settings on the Service, please change your
              profile settings to suit your privacy needs.
            </span>
          </li>
          <li>
            <span>
              We may also share your information with other parties in
              connection with any company transaction, such as a merger, sale of
              company assets or shares, reorganization, financing, change of
              control or acquisition of all or a portion of our business by
              another company or third party or in the event of bankruptcy or
              related or similar proceedings.
            </span>
          </li>
          <li>
            <span>
              You may be “found” on the Service by other members using your User
              ID, or you may be featured on the service. Other members may
              choose to “Follow” content you post on the service.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>How We Store and Process Your Information:</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Storage and Processing: Your information collected through the
              Service may be stored and processed in the United Kingdom,
              European Union, or any other country in which Ember or its
              affiliates or service providers maintain facilities.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>How We Protect Your Information:</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Keeping your information safe: Ember takes security of your
              information very seriously, and uses commercially reasonable
              safeguards to preserve the integrity and security of all
              information collected through the Service. To protect your privacy
              and security, we take reasonable steps (such as requesting a
              unique password) to verify your identity before granting you
              access to your account. You are responsible for maintaining the
              secrecy of your unique password and account information, and for
              controlling access to your email communications from Ember, at all
              times. However, Ember cannot ensure or warrant the security of any
              information you transmit to Ember or guarantee that information on
              the Service may not be accessed, disclosed, altered, or destroyed.
              Your privacy settings may also be affected by changes to the
              functionality of third party sites and services that you add to
              Ember, such as social networks. Ember is not responsible for the
              functionality or security measures of any third party. Compromise
              of information: In the event that any information under our
              control is compromised as a result of a breach of security, Ember
              will take reasonable steps to investigate the situation and where
              appropriate, notify those individuals whose information may have
              been compromised and take other steps, in accordance with any
              applicable laws and regulations.
            </span>
          </li>
        </ul>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Your Information Choices</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              Commercial and marketing communications: We use the information we
              collect or receive to communicate directly with you. We use your
              information to send you Service-related emails (e.g., account
              verification, purchase and billing confirmations and reminders,
              changes/updates to features of the Service, technical and security
              notices). You will also be able to be “found” on Ember based on
              information that you provide (see “Personal Information”) above.
              You control your account information and settings: You may update
              your account information at any time by logging into your account
              and changing your settings.
            </span>
          </li>
          <li>
            <span>
              Opt out: The only way to completely opt out of the collection of
              any information through cookies or other tracking technology is to
              actively manage the settings on your browser or mobile device.
              Please refer to your mobile device or browser’s technical
              information for instructions on how to delete and disable cookies,
              and other tracking/recording tools. (To learn more about cookies,
              clear gifs/web beacons and related technologies, you may wish to
              visit
            </span>
            <a href="http://www.allaboutcookies.org">
              <span>http://www.allaboutcookies.org</span>
            </a>
            <span>
              and/or the Network Advertising Initiative’s online resources,
              located at
            </span>
            <a href="https://www.networkadvertising.org">
              <span>http://www.networkadvertising.org</span>
            </a>
            <span>
              ). Depending on your type of device, it may not be possible to
              delete or disable tracking mechanisms on your mobile device. Note
              that disabling cookies and/or other tracking tools prevents Ember
              from tracking your browser’s activities in relation to the
              Service. However, doing so may disable many of the features
              available through the Service.
            </span>
          </li>
        </ul>{' '}
        <p>
          <strong>
            Your rights in respect of your personal information if you are
            resident in the EU and Switzerland:
          </strong>
        </p>{' '}
        <p>
          <span>
            If you are located in the UK, EU or Switzerland, you have the
            following rights in respect of your personal data that we hold:
          </span>
        </p>{' '}
        <ol>
          <li>
            <span>
              I) Right of access. The right to obtain access to your personal
              data.
            </span>
          </li>
          <li>
            <span>
              II) Right to rectification. The right to obtain rectification of
              your personal data without undue delay where that personal data is
              inaccurate or incomplete.
            </span>
          </li>
        </ol>{' '}
        <p>
          <span>
            III) Right to erasure. The right to obtain the erasure of your
            personal data without undue delay in certain circumstances, such as
            where the personal data is no longer necessary in relation to the
            purposes for which it was collected or processed.
          </span>
        </p>{' '}
        <ol>
          <li>
            <span>
              IV) Right to restriction. The right to obtain the restriction of
              the processing undertaken by us on your personal data in certain
              circumstances, such as where the accuracy of the personal data is
              contested by you, for a period enabling us to verify the accuracy
              of that personal data.
            </span>
          </li>
          <li>
            <span>
              V) Right to portability. The right to portability allows you to
              move, copy or transfer personal data easily from one organization
              to another.
            </span>
          </li>
          <li>
            <span>
              VI) Right to object. You have a right to object to processing
              based on legitimate interests and direct marketing.
            </span>
          </li>
        </ol>{' '}
        <p>
          <span>
            VII) Right to withdraw consent. You should be aware that you are
            entitled to withdraw your consent where that has been given, at any
            time. If you do this and we have no alternative lawful reason to
            process your personal data, this may affect our ability to provide
            you with rights to use the Service.
          </span>
        </p>{' '}
        <p>
          <span>
            If you wish to exercise one of these rights, please contact us at
            privacy@emberapp.gg
          </span>
        </p>{' '}
        <p>
          <span>
            You also have the right to lodge a complaint to your local data
            protection authority. Further information about how to contact your
            local data protection authority is available at:
          </span>
          <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            data protection
          </a>
        </p>{' '}
        <p>
          <a href="https://ico.org.uk/">
            <span>https://ico.org.uk/</span>
          </a>
        </p>{' '}
        <p>
          <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            <span>
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </span>
          </a>
        </p>{' '}
        <h2 className="text-2xl mt-3">
          <strong>How long does Ember keep information about me?</strong>
        </h2>{' '}
        <ul className="list-disc my-5 ml-12">
          <li>
            <span>
              For individuals based in the UK, EU or Switzerland, we will only
              retain your personal data for as long as necessary to fulfil the
              purposes we collected it for, including for the purposes of
              satisfying any legal, accounting, or reporting requirements.
            </span>
          </li>
        </ul>{' '}
        <p>
          <span>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements.
          </span>
        </p>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Children’s Privacy:</strong>
        </h2>{' '}
        <p>
          <span>
            Ember does not knowingly collect or solicit any information from
            anyone under the age of 16 or knowingly allow such persons to
            register as Users. The Service and its content are not directed at
            anybody under the age of 16. In the event that we learn that we have
            collected personal information from a child under age 16 without
            verification of parental consent, we will delete that information as
            quickly as possible. If you believe that we might have any
            information from or about a child under 16, please contact us
            privacy@emberapp.gg
          </span>
        </p>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Integrating Other websites and services:</strong>
        </h2>{' '}
        <p>
          <span>
            Ember is not responsible for the practices employed by websites or
            services linked to or from the Service, including the information or
            content contained therein. Please remember that when you use a link
            to go from the Service to another website, our Privacy Policy does
            not apply to third-party websites or services. Your browsing and
            interaction on any third-party website or service, including those
            that have a link on our website, are subject to that third party’s
            own rules and policies. In addition, you agree that we are not
            responsible for and we do not have any control over any
            third-parties that you authorize to access your User Content. If you
            are using a third-party website or service (like TWITTER) and you
            allow such a third-party access to your User Content you do so at
            your own risk. This Privacy Policy does not apply to information we
            collect by other means (including offline) or from other sources
            other than through the Service.
          </span>
        </p>{' '}
        <h2 className="text-2xl mt-3">
          <strong>Changes to our Privacy Policy + Terms and Conditions</strong>
        </h2>{' '}
        <p>
          <span>
            Ember may modify or update this Privacy Policy from time to time to
            reflect the changes in our business and practices, and so you should
            review this page periodically. When we change the policy in a
            material manner we will inform you and update the ‘last modified’
            date at the bottom of this page.
          </span>
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
