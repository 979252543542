interface Props extends React.HtmlHTMLAttributes<any> {}

function Rocket(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.218"
      height="30.219"
      viewBox="0 0 30.218 30.219"
    >
      <g
        id="Group_1324"
        data-name="Group 1324"
        transform="translate(12521.802 -2495.24)"
      >
        <path
          id="Path_651"
          data-name="Path 651"
          {...props}
          d="M7.686,138.268l-2.618.2a2.336,2.336,0,0,0-1.78,1.047L.269,144.084a1.618,1.618,0,0,0,1.1,2.492l2.4.371A21.465,21.465,0,0,1,7.686,138.268Z"
          transform="translate(-12521.802 2365.133)"
        />
        <path
          id="Path_652"
          data-name="Path 652"
          {...props}
          d="M226.682,385.682l.371,2.4a1.617,1.617,0,0,0,2.492,1.1l4.567-3.019a2.336,2.336,0,0,0,1.047-1.78l.2-2.618A21.464,21.464,0,0,1,226.682,385.682Z"
          transform="translate(-12735.105 2136.008)"
        />
        <path
          id="Path_653"
          data-name="Path 653"
          {...props}
          d="M99.051,246.34a1.526,1.526,0,0,0,.25-.021A17.344,17.344,0,0,0,102.9,245.3l-9.852-9.852a17.342,17.342,0,0,0-1.019,3.595,1.545,1.545,0,0,0,.435,1.344l5.5,5.5A1.55,1.55,0,0,0,99.051,246.34Z"
          transform="translate(-12608.376 2273.688)"
        />
        <path
          id="Path_654"
          data-name="Path 654"
          {...props}
          d="M143.468,13.4a23.869,23.869,0,0,0,2.374-11.9A1.552,1.552,0,0,0,144.376.035C144,.015,143.554,0,143.052,0a23.389,23.389,0,0,0-10.572,2.41,24.05,24.05,0,0,0-9.566,9.777.892.892,0,0,1,.082.072l10.624,10.624a.889.889,0,0,1,.072.082A24.05,24.05,0,0,0,143.468,13.4ZM133.229,6.388a4.426,4.426,0,1,1-1.3,3.13,4.431,4.431,0,0,1,1.3-3.13Z"
          transform="translate(-12637.461 2495.24)"
        />
        <path
          id="Path_655"
          data-name="Path 655"
          {...props}
          d="M306.509,120.827a2.656,2.656,0,1,0-.778-1.878A2.659,2.659,0,0,0,306.509,120.827Z"
          transform="translate(-12809.489 2385.81)"
        />
        <path
          id="Path_656"
          data-name="Path 656"
          {...props}
          d="M1.19,347.625a.882.882,0,0,0,.626-.259l2.89-2.89a.885.885,0,0,0-1.252-1.252l-2.89,2.89a.885.885,0,0,0,.626,1.511Z"
          transform="translate(-12522.089 2172.518)"
        />
        <path
          id="Path_657"
          data-name="Path 657"
          {...props}
          d="M7.072,388.1a.885.885,0,0,0-1.252,0L.263,393.662a.885.885,0,1,0,1.252,1.252l5.557-5.557A.885.885,0,0,0,7.072,388.1Z"
          transform="translate(-12521.806 2130.286)"
        />
        <path
          id="Path_658"
          data-name="Path 658"
          {...props}
          d="M93.216,432.986l-2.89,2.89a.885.885,0,1,0,1.252,1.252l2.89-2.89a.885.885,0,1,0-1.252-1.252Z"
          transform="translate(-12606.553 2088.053)"
        />
      </g>
    </svg>
  )
}

export default Rocket
