import classnames from 'classnames'

interface Props {
  message: string
  error: boolean
  dataTestId: string
}

export default function ErrorMessage({ message, error, dataTestId }: Props) {
  if (!message) {
    return null
  }

  return (
    <div
      className={classnames({
        'text-green-500': !error,
        'text-red-500': error,
      })}
      data-testid={`${dataTestId}-success-message`}
    >
      {message}
    </div>
  )
}
