import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Routes } from '../../../app/helpers/RestApiRoutes'
import { useBoolean } from '@chakra-ui/react'
import { Feed, FeedType, Privacy } from '../../../api/types'
import { AxiosContext } from '../../../providers/AxiosProvider'
import { Outlet, useParams } from 'react-router-dom'
import Spinner from '../../../common/Spinner'

const defaultFeed: Feed = {
  id: '',
  name: '',
  slug: '',
  description: '',
  privacy: Privacy.Open,
  archived: false,
  bannerImage: '',
  category: [],
  coverImage: '',
  events: [],
  featured: false,
  feedSocialPlatforms: [],
  feedType: FeedType.Unknown,
  iconImage: '',
  isFollowing: false,
  isOwner: false,
  location: '',
  metadata: undefined,
  normalisedName: '',
  nsfw: false,
  owner: {
    name: '',
    normalisedName: '',
    slug: '',
    description: '',
    contributors: [],
  },
  socialPlatforms: [],
  tags: '',
  topics: [],
  totalPosts: 0,
  totalShards: 0,
  totalSubscribers: 0,
  totalViews: 0,
  website: '',
}

export const CurrentWritersBlockContext = createContext<{
  feed: Feed
  getFeedData: () => void
}>({ feed: defaultFeed, getFeedData: () => {} })

export default function WritersBlockContext() {
  const { id } = useParams()
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean(false)
  const [feedData, setFeedData] = useState<Feed>(defaultFeed)

  const getFeedData = useCallback(async () => {
    if (!id) {
      console.error('unable to find ID from the path parameter')
      return
    }
    setLoading.on()
    try {
      const { data } = await client.get(Routes.GetFeed(id))
      setFeedData(data)
    } catch (error) {
      console.error('fetching feed for writers block context failed: ', error)
    }
    setLoading.off()
  }, [client, setLoading, id])

  useEffect(() => {
    void getFeedData()
  }, [client, getFeedData, setLoading])

  if (loading) {
    return <Spinner />
  }

  return (
    <CurrentWritersBlockContext.Provider
      value={{ feed: feedData, getFeedData }}
    >
      <Outlet />
    </CurrentWritersBlockContext.Provider>
  )
}
