import ClickableStat from './ClickableStat'
import ShardIcon from '../icons/ShardIcon'
import Spinner from '../Spinner'
import { Post, Reply } from '../../api/types'
import ExclamationCircle from '../icons/ExclamationCircle'
import useShardHelpers from '../../app/hooks/useShardHelpers'

interface Props {
  post: Post | Reply
}

export default function ShardClickableStat(props: Props) {
  const {
    error,
    shardsLoading: loading,
    attemptToShard,
    hasGivenShard,
    totalShards,
  } = useShardHelpers(props.post)

  return (
    <ClickableStat onClick={attemptToShard}>
      <div
        style={{ maxHeight: 39 }}
        className='p-3 flex justify-center items-center space-x-3'
      >
        {/* { awardShardError.errored || error ? <span className="text-red-500"><ExclamationCircle /></span> : <ShardIcon isActive={hasGivenShard} /> } */}
        {error.errored ? <span className='text-red-500'><ExclamationCircle /></span> : <ShardIcon isActive={hasGivenShard} />}
        {(loading) ? <Spinner /> : <div>{totalShards}</div>}
      </div>
    </ClickableStat>
  )
}
