import { useBoolean, useToast } from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../helpers/RestApiRoutes'

export function useTrendingTags(scope: string) {
  const [loading, setLoading] = useBoolean()
  const [tags, setTags] = useState<string[]>([])
  const client = useContext(AxiosContext)
  const toast = useToast()

  const getTags = useCallback(async (scope: string) => {
    setLoading.on()
    try {
      const { data } = await client.get<{payload: { tags: string[] } }>(Routes.GetTrendingPostTags(scope))
      if (data) {
        setTags(data.payload.tags)
      }
    } catch (e) {
      toast({
        title: 'Unable to get trending tags',
        description: 'Something went wrong while fetching the latest trending tags',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    setLoading.off()
  }, [client, setLoading, toast])

  useEffect(() => {
    void getTags(scope)
  }, [getTags, scope])

  return {
    loading,
    tags,
    getTags,
  }
}