import classNames from 'classnames'
import { useState } from 'react'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import Button from './Button'
import './FileField.css'
import {
  compressAccurately,
  filetoDataURL,
  EImageType,
} from 'image-conversion'

interface Props {
  maxNumber?: number
  withPreview?: boolean
  label?: string
  showSubmit?: boolean
  showRemoveAll?: boolean
  onSubmit?: (files: any) => void
  onChange?: (files: any) => void
}

export type FileFieldValue = ImageListType

function FileField({
  maxNumber = 1,
  withPreview = true,
  label,
  showSubmit = true,
  showRemoveAll = true,
  onChange: onChangeProp,
  onSubmit,
}: Props) {
  const [images, setImages] = useState([])

  const onChange = async (imageList: ImageListType, addUpdateIndex: any) => {
    // data for submit
    console.log(imageList, addUpdateIndex, typeof imageList)
    // Compress Images
    const compressedImages = await Promise.all(
      imageList.map(async (image) => {
        const compressedImage = await compressAccurately(image.file!, {
          size: 80,
          type: EImageType.JPEG,
        })
        return {
          data_url: await filetoDataURL(compressedImage),
          file: compressedImage,
        }
      }),
    )

    setImages(compressedImages as any)
    onChangeProp && onChangeProp(compressedImages)
  }

  const onSubmitClicked = () => {
    onSubmit && onSubmit(images)
  }

  const TrashIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-trash-2"
      >
        <polyline points="3 6 5 6 21 6" />
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
      </svg>
    )
  }

  const EditIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-edit-2"
      >
        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
      </svg>
    )
  }

  return (
    <ImageUploading
      // multiple
      value={images}
      onChange={onChange as any}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      acceptType={['jpg', 'png', 'jpeg']}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
        errors,
      }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <button
            className={classNames(
              'w-full bg-darkbackground-500 p-5 rounded-xl mb-3',
              {
                'text-steel': !isDragging,
                'text-white': isDragging,
              },
            )}
            onClick={onImageUpload}
            {...dragProps}
          >
            {isDragging
              ? 'Drop it to lock it!'
              : label ?? 'Click or Drop your image here'}
          </button>

          {errors && (
            <div>
              {errors.maxNumber && (
                <div className="text-red-500">
                  Number of selected images exceed maximum number
                </div>
              )}
              {errors.acceptType && (
                <div className="text-red-500">
                  Your selected file type is not allow
                </div>
              )}
              {errors.maxFileSize && (
                <div className="text-red-500">
                  Selected file size exceeds max file size
                </div>
              )}
              {errors.resolution && (
                <div className="text-red-500">
                  Selected file is not match your desired resolution
                </div>
              )}
            </div>
          )}

          {imageList.length > 0 && showSubmit && (
            <Button
              trackingEventName={'file-field-upload-image'}
              color="primary"
              className="mr-3"
              onClick={onSubmitClicked}
            >
              Upload Images
            </Button>
          )}
          {imageList.length > 0 && showRemoveAll && (
            <Button
              trackingEventName={'file-field-remove-all'}
              color="danger"
              onClick={onImageRemoveAll}
            >
              Clear images
            </Button>
          )}
          <div className="flex">
            {imageList.map((image, index) => (
              <div
                key={index}
                className="image-item mt-3 p-3 bg-darkbackground-500 rounded-lg"
              >
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper flex justify-between p-3">
                  <button onClick={() => onImageUpdate(index)}>
                    <EditIcon />
                  </button>
                  <button onClick={() => onImageRemove(index)}>
                    <TrashIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </ImageUploading>
  )
}

export default FileField
