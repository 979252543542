export default function DiscordLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <defs>
        <style>.cls-1</style>
      </defs>
      <path
        id="iconmonstr-discord-1"
        style={{
          fill: '#fff',
          fillRule: 'evenodd',
        }}
        d="M19.54,0A2.466,2.466,0,0,1,22,2.472V24l-2.58-2.28-1.452-1.344-1.536-1.428.636,2.22H3.46A2.466,2.466,0,0,1,1,18.7V2.472A2.466,2.466,0,0,1,3.46,0H19.54ZM14.908,15.672a4.387,4.387,0,0,0,3.672-1.824,16.07,16.07,0,0,0-1.728-7,5.934,5.934,0,0,0-3.372-1.26l-.168.192A7.986,7.986,0,0,1,16.3,7.308a9.782,9.782,0,0,0-3.612-1.152,10.153,10.153,0,0,0-2.424.024l-.2.024a9.012,9.012,0,0,0-2.724.756c-.444.2-.708.348-.708.348A8.1,8.1,0,0,1,9.784,5.736l-.12-.144a5.934,5.934,0,0,0-3.372,1.26,16.07,16.07,0,0,0-1.728,7,4.351,4.351,0,0,0,3.66,1.824s.444-.54.8-1a3.732,3.732,0,0,1-2.1-1.416l.336.2.048.036.047.027.014.006.047.027a7.032,7.032,0,0,0,.876.408,10.073,10.073,0,0,0,1.764.516,8.406,8.406,0,0,0,3.108.012,7.9,7.9,0,0,0,1.74-.516,6.867,6.867,0,0,0,1.38-.708A3.787,3.787,0,0,1,14.116,14.7c.36.456.792.972.792.972Zm-5.58-5.6a1.337,1.337,0,0,0,0,2.664A1.278,1.278,0,0,0,10.552,11.4,1.271,1.271,0,0,0,9.328,10.068Zm4.38,0A1.337,1.337,0,1,0,14.932,11.4,1.278,1.278,0,0,0,13.708,10.068Z"
        transform="translate(-1)"
      />
    </svg>
  )
}
