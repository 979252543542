interface Props {
  defaultValue?: string
  selected?: string
  options: Record<string, string>
  className?: string
  onChange?: (value: string) => void
}

function SelectField({
  defaultValue,
  options,
  selected,
  className,
  onChange,
  ...props
}: Props) {
  return (
    <div className={`flex justify-center ${className}`}>
      <select
        className="
          form-select 
          block
          w-full
          px-3
          py-1.5
          m-0
          text-lg
          font-normal
          text-steel
          bg-darkbackground-500 bg-clip-padding bg-no-repeat
          border border-solid border-darkbackground
          rounded-lg
          transition
          ease-in-out
          outline-none
          border-darkbackground-500
          focus:text-white
          focus:bg-darkbackground-500 
          focus:border-primary
          focus:outline-none"
        onChange={(e) => {
          console.log(e)
          if (onChange) onChange(e.target.value)
        }}
        value={defaultValue}
        {...props}
      >
        {Object.keys(options).map((key) => (
          <option
            key={key}
            value={key}
            selected={key === selected ? true : undefined}
            className="hover:bg-purple-500 hover:text-white py-3 text-steel bg-transparent"
          >
            {options[key]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectField
