import { useOutletContext } from 'react-router-dom'
import { Feed, FeedType } from '../../api/types'
import WritersBlockFeedCard from '../writers-blocks/WritersBlockFeedCard'
import { ProfilePageContextType } from './ProfilePage'
import { useEffect } from 'react'
import useFindFeeds from '../../app/hooks/useFindFeeds'

function ProfilePageWritersBlocks() {
  const {  userId } = useOutletContext<ProfilePageContextType>()
  const {items, Search} = useFindFeeds(FeedType.WritersBlock)

  useEffect(() => {
    void Search(0, 100, false, '', userId ?? '')
  }, [Search, userId])


  return (
    <div className='p-3'>
      <div className='lg:grid flex flex-col grid-cols-2 gap-3'>

        {items.length === 0 && (
          <div className='w-full text-center col-span-2 text-2xl'>
            This user does not own any writers blocks
          </div>
        )}

        {items.map((block: Feed) => {
          return <WritersBlockFeedCard key={block.id} feed={block} />
        })}
      </div>
    </div>
  )
}

export default ProfilePageWritersBlocks
