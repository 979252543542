import React from 'react'
import { RouteObject } from 'react-router-dom'
import AdminRouteGuard from './templates/AdminRouteGuard'
import AdminDashboard from './pages/AdminDashboard'
import ManageUsersPage from './pages/ManageUsersPage'
import ManagePostPage from './pages/ManagePostReportsPage'

export const adminroutes: RouteObject = {
  path: '/admin',
  caseSensitive: false,
  element: <AdminRouteGuard />,
  children: [
    {
      path: '',
      index: true,
      element: <AdminDashboard />
    },
    {
      path: 'manage/users',
      element: <ManageUsersPage />
    },
    {
      path: 'manage/posts',
      element: <ManagePostPage />
    }
  ]
}