import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { Routes } from '../helpers/RestApiRoutes'
import { useBoolean } from '@chakra-ui/react'

export default function useToggleFollowFeed(feedId: string) {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean(false)
  const [error, setError] = useState('')

  const toggleFollow = useCallback(async () => {
    if (!client) return
    if (!feedId || feedId === '') return

    setLoading.on()
    try {
      await client.post(Routes.ToggleFollowFeed(feedId))
    } catch (e: any) {
      setError(e.message)
    }
    setLoading.off()
  }, [client, feedId, setLoading])

  return {
    toggleFollow,
    loading,
    error,
  }
}
