export default function InstagramLogo() {
  return (
    <svg
      width="23.3"
      height="23.3"
      viewBox="0 0 23.3 23.3"
      color="currentcolor"
      data-testid="instagram-logo"
    >
      <path
        id="Path_722"
        data-name="Path 722"
        style={{ fill: '#fff', fillRule: 'evenodd' }}
        d="M11.65,2.1c3.111,0,3.479.012,4.707.068a6.449,6.449,0,0,1,2.163.4,3.61,3.61,0,0,1,1.339.871,3.61,3.61,0,0,1,.871,1.34,6.447,6.447,0,0,1,.4,2.163c.056,1.228.068,1.6.068,4.708s-.012,3.479-.068,4.708a6.447,6.447,0,0,1-.4,2.163,3.858,3.858,0,0,1-2.211,2.211,6.446,6.446,0,0,1-2.163.4c-1.228.056-1.6.068-4.707.068s-3.479-.012-4.708-.068a6.444,6.444,0,0,1-2.163-.4,3.608,3.608,0,0,1-1.34-.871,3.61,3.61,0,0,1-.871-1.34,6.447,6.447,0,0,1-.4-2.163c-.056-1.228-.068-1.6-.068-4.708s.012-3.479.068-4.708a6.447,6.447,0,0,1,.4-2.163A3.609,3.609,0,0,1,3.44,3.44a3.61,3.61,0,0,1,1.34-.871,6.448,6.448,0,0,1,2.163-.4c1.228-.056,1.6-.068,4.708-.068m0-2.1c-3.164,0-3.561.013-4.8.07A8.551,8.551,0,0,0,4.019.612,5.711,5.711,0,0,0,1.955,1.955,5.711,5.711,0,0,0,.612,4.019,8.551,8.551,0,0,0,.07,6.847C.013,8.089,0,8.486,0,11.65s.013,3.561.07,4.8a8.551,8.551,0,0,0,.542,2.828,5.711,5.711,0,0,0,1.344,2.063,5.71,5.71,0,0,0,2.063,1.344,8.55,8.55,0,0,0,2.828.542c1.243.057,1.639.07,4.8.07s3.561-.013,4.8-.07a8.55,8.55,0,0,0,2.828-.542,5.957,5.957,0,0,0,3.407-3.407,8.552,8.552,0,0,0,.542-2.828c.057-1.243.07-1.639.07-4.8s-.013-3.561-.07-4.8a8.552,8.552,0,0,0-.542-2.828,5.71,5.71,0,0,0-1.344-2.063A5.711,5.711,0,0,0,19.281.612,8.551,8.551,0,0,0,16.453.07C15.211.013,14.814,0,11.65,0Zm0,5.668a5.982,5.982,0,1,0,5.982,5.982A5.982,5.982,0,0,0,11.65,5.668Zm0,9.866a3.883,3.883,0,1,1,3.883-3.883A3.883,3.883,0,0,1,11.65,15.533Zm7.617-10.1a1.4,1.4,0,1,1-1.4-1.4A1.4,1.4,0,0,1,19.267,5.431Z"
        transform="translate(0 0)"
      />
    </svg>
  )
}
