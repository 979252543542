import gql from 'graphql-tag'
import { Conversation } from '../types'

export const GET_CONVERSATIONS = gql`
  query GetConversations(
    $page: Int!
    $searchTags: [String!]
    $limit: Int!
    $order: String!
    $type: String!
  ) {
    Conversations(
      page: $page
      searchTags: $searchTags
      limit: $limit
      order: $order
      type: $type
    ) {
      _id
      name
      recipients {
        user {
          _id
          username
          profilePicture
        }
      }
      messages {
        message
        sender {
          _id
          username
        }
        readAt
        archived
        createdAt
      }
    }
  }
`

export interface GetConversationsQuery {
  Conversations: Conversation[]
}

export interface GetConversationsVariables {
  page: number
  searchTags?: string[]
  limit: number
  order: string
  type: string
}

export const CREATE_NEW_CONVERSATION = gql`
  mutation CreateNewConversation($usernames: [String!]!) {
    CreateNewConversation(usernames: $usernames) {
      _id
      name
      recipients {
        user {
          _id
          username
          profilePicture
        }
      }
      messages {
        message
        sender {
          _id
          username
        }
        readAt
        archived
      }
    }
  }
`

export interface CreateNewConversationMutation {
  CreateNewConversation: Conversation
}

export interface CreateNewConversationVariables {
  usernames: string[]
}

export const GET_CONVERSATION = gql`
  query GetConversation($id: String!) {
    Conversation(id: $id) {
      _id
      name
      recipients {
        user {
          _id
          username
          profilePicture
        }
      }
      messages {
        message
        sender {
          _id
          username
          profilePicture
        }
        readAt
        archived
      }
    }
  }
`

export interface GetConversationQuery {
  Conversation: Conversation
}

export interface GetConversationVariables {
  id: string
}

export const SEND_MESSAGE = gql`
  mutation SendMessage($message: String!, $conversationId: String!) {
    SendMessage(message: $message, conversationId: $conversationId) {
      _id
      name
      recipients {
        user {
          _id
          username
          profilePicture
        }
      }
      messages {
        message
        sender {
          _id
          username
        }
        readAt
        archived
      }
    }
  }
`

export interface SendMessageMutation {
  SendMessage: Conversation
}

export interface SendMessageVariables {
  message: string
  conversationId: string
}
