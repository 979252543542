import { useEffect, useState } from 'react'
import useDebounce from 'src/app/hooks/useDebounce'
import Spinner from '../Spinner'
import CloseCrossButton from './CloseCrossButton'
import InputField from './InputField'

type SearchItem = {
  image?: string
  value: string
}

interface Props {
  items: SearchItem[]
  placeholder: string
  showImages: boolean
  onChange: (value: string) => void
  onConfirm: (value: SearchItem | undefined) => void
  isLoading: boolean
  errorMessage: string
}
export default function SearchAheadField({
  items,
  isLoading,
  errorMessage,
  showImages,
  placeholder,
  onChange,
  onConfirm,
}: Props) {
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const debouncedSearchValue = useDebounce(searchValue, 200)

  useEffect(() => {
    console.log('debouncedSearchValue', debouncedSearchValue)
    onChange && onChange(debouncedSearchValue)
  }, [debouncedSearchValue, onChange])

  useEffect(() => {
    if (searchValue.length > 0) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [searchValue])

  return (
    <div className="relative w-full">
      <InputField
        placeholder={placeholder}
        value={searchValue}
        type="text"
        onChange={(e) => {
          console.log('search ahead input on change')
          setSearchValue(e.target.value)
        }}
      />
      {isOpen && (
        <div className="absolute mt-2 w-full bg-background-500 rounded-lg py-2 px-4 block appearance-none leading-normal">
          {isLoading && (
            <div className="text-center">
              <Spinner />
            </div>
          )}

          {errorMessage && (
            <div className="text-center">
              <div className="text-red-500">{errorMessage}</div>
            </div>
          )}

          {items.length === 0 && !isLoading && (
            <div className="text-center">
              <div className="text-steel">No results found</div>
            </div>
          )}

          {items.length > 0 && !isLoading && (
            <ul>
              {items.map((item) => (
                <li
                  key={item.value}
                  className="flex items-center px-3 py-2 hover:bg-primary-500 hover:text-white transition-colors"
                  onClick={() => {
                    setSearchValue('')
                    onConfirm(item)
                  }}
                >
                  {showImages && item.image && (
                    <img
                      className="w-8 h-8 rounded-full mr-3"
                      src={item.image}
                      alt={item.value}
                    />
                  )}
                  <span className="text-sm">{item.value}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      <div className="absolute right-1 top-2">
        <CloseCrossButton onClick={() => setSearchValue('')} />
      </div>
    </div>
  )
}
