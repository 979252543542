import Image from '../../common/forms/Image'
import ShardBio from '../../common/icons/ShardBio'
import PersonFill from '../../common/icons/PersonFill'
import ShardStat from '../../common/posts/ShardStat'
import { CurrentUserContext } from '../../providers/AuthProvider'
import { useContext, useMemo } from 'react'

function DashboardStatsCard() {
  const { user } = useContext(CurrentUserContext)

  const totalFollowers = useMemo(() => {
    if (!user?.TotalFollowers) {
        return 0
    }

    return Math.max(0, user.TotalFollowers)
  }, [user?.TotalFollowers])

  return (
    <div className="bg-background-500 p-5 rounded-3xl">
      <div className="flex space-x-3 text-lg font-semibold">
        <Image
          src={user?.profilePicture}
          style={{ height: 70, width: 70 }}
          alt="avatar"
          className="rounded-lg lg:block hidden"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/images/games/placeholder.png'
          }}
        />

        <div className="flex-1">
          <div className="text-background-400">Hey,</div>
          <div className="text-white">{user?.username}</div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 place-content-center mt-5 font-light">
        <div className="flex flex-col items-center">
          <div className="mb-2">
            <ShardBio />
          </div>
          <ShardStat userId={user?.id} />
          <div>Shards</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-8 w-8 text-primary-500 mb-1">
            <PersonFill />
          </div>
          <div className={'p-3 max-h-[39px]'}>{totalFollowers}</div>
          <div>Followers</div>
        </div>
      </div>
    </div>
  )
}

export default DashboardStatsCard
