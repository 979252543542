interface Props extends React.HtmlHTMLAttributes<any> {}

function MagnifiyingGlass(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.7"
      height="24.7"
      viewBox="0 0 24.7 24.7"
      className="fill-steel"
    >
      <path
        id="Icon_open-magnifying-glass"
        data-name="Icon open-magnifying-glass"
        {...props}
        d="M10.709-.032a10.709,10.709,0,1,0,0,21.419,10.585,10.585,0,0,0,5.079-1.255,3.06,3.06,0,0,0,.4.4l3.06,3.06a3.121,3.121,0,1,0,4.406-4.406l-3.06-3.06a3.06,3.06,0,0,0-.49-.4,10.565,10.565,0,0,0,1.346-5.079A10.721,10.721,0,0,0,10.74-.062Zm0,3.06a7.613,7.613,0,0,1,7.65,7.65,7.673,7.673,0,0,1-2.019,5.263l-.092.092a3.06,3.06,0,0,0-.4.4,7.657,7.657,0,0,1-5.171,1.928,7.65,7.65,0,1,1,0-15.3Z"
        transform="translate(0 0.063)"
      />
    </svg>
  )
}

export default MagnifiyingGlass
