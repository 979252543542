import { Post, Reply } from '../../api/types'
import { StripHTMLTags } from '../../app/helpers/string-helpers'
import EmptySpeechBubble from '../icons/EmptySpeechBubble'
import ClickableStat from './ClickableStat'
import Image from '../forms/Image'
import ShortPostEditor, { PostCreateEvent } from '../command-palette/ShortPostEditor'
import PostOptions from './PostOptions'
import { formatDistance } from 'date-fns'
import { Link } from 'react-router-dom'
import ShardClickableStat from './ShardClickableStat'
import React, { useMemo, useState } from 'react'
import useGetReplies from '../../app/hooks/useGetReplies'
import Spinner from '../Spinner'
import useCreatePost from '../../app/hooks/useCreatePost'

interface Props {
  comment: Post
  allowReplies: boolean
  onReply?: (reply: Reply) => void
}

function PostReply({
  comment,
  allowReplies,
  onReply,
}: Props) {
  const [expanded, setExpanded] = useState(false)

  const { create: createReply } = useCreatePost({ feedId: comment.feedId })

  const doReply = async ({ content }: PostCreateEvent) => {
    if (!content || !comment.id) {
      return
    }

    await createReply({
      nsfw: false,
      to: comment.id,
      content: content,
    })

    void GetReplies(comment.id, page, pageSize)
  }

  const {
    page,
    pageSize,
    GetReplies,
    items,
    total,
    loading,
  } = useGetReplies(comment.id ?? '')

  const postRelativeAge = useMemo(() => {
    if (!comment?.createdAt) {
      return 'moments ago'
    }

    const date = new Date(comment.createdAt)
    return formatDistance(date, new Date(), { addSuffix: true })
  }, [comment])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <div className='flex flex-col space-y-3 relative'>
      <div className='flex flex-col space-y-1'>
        <div className='absolute left-6 top-3 border-l border-steel w-1 bottom-0 z-0' />
        <div className='flex items-center space-x-3 z-10'>
          <Image
            src={comment.author.profilePicture}
            alt={comment.author.username + ' profile icon'}
            className='rounded-lg h-12 w-12'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/images/games/placeholder.png'
            }}
          />
          <div className='flex-1'>
            <div>
              <Link
                to={`/profile/${comment.author.username}/feed`}
                className='font-semibold text-white hover:text-white'
              >
                {comment.author.username}{' '}
              </Link>
              <span className='text-steel text-sm font-normal'>
                - {postRelativeAge}
              </span>
            </div>
          </div>
          <div className='self-start'>
            <PostOptions
              post={comment}
              refresh={() => window.location.reload()}
            />
          </div>
        </div>

        <div className='ml-12 px-3'>{StripHTMLTags(comment.content)}</div>

        <div className='flex justify-between space-x-3 ml-12'>
          <ShardClickableStat post={comment} />
          {allowReplies && (
            <ClickableStat onClick={toggleExpand}>
              <div
                style={{ maxHeight: 39 }}
                className='p-3 flex justify-center items-center space-x-3'
              >
                <EmptySpeechBubble />
                <div>{loading ? <Spinner /> : total}</div>
              </div>
            </ClickableStat>
          )}
        </div>

        <div className='ml-12'>
          {expanded && allowReplies && (
            <div className='grid grid-cols-1 gap-3'>
              <ShortPostEditor
                placeholder='Type your reply...'
                buttonContent='Reply'
                onClick={doReply}
              />
              {items.map((replyToComment) => (
                <PostReply
                  key={replyToComment.id ?? ''}
                  comment={replyToComment}
                  allowReplies={false}
                  onReply={onReply}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PostReply
