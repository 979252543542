import { useCallback, useContext, useState } from 'react'
import { AxiosContext } from '../../providers/AxiosProvider'
import { useBoolean, useToast } from '@chakra-ui/react'
import { User } from '../../api/types'
import { APIPaginatedResponse, Routes } from '../helpers/RestApiRoutes'

export function useFindUsers() {
  const client = useContext(AxiosContext)
  const [loading, setLoading] = useBoolean()
  const [users, setUsers] = useState<APIPaginatedResponse<User>>()
  const [error, setError] = useState('')
  const toast = useToast()

  const findUsers = useCallback(async (id: string, username: string, scope: string, page = 0, pageSize = 100) => {
    setLoading.on()
    try {
      const { data } = await client.get<APIPaginatedResponse<User>>(Routes.FindUsers(id, username, scope, page, pageSize))
      if (data) {
        setUsers(data)
      }
    } catch (e: any) {
      toast({
        title: 'Failed to load messages for conversation',
        description: 'Something went wrong while trying to load the messages for the conversation',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      setError(e.message)
    }
    setLoading.off()
  }, [client, setLoading, toast])

  return {
    loading,
    findUsers,
    error,
    users,
  }
}