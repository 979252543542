import { Feed, SocialBrand } from '../../../api/types'
import ProfileSocialLink from '../ProfileSocialLink'
import { useMemo } from 'react'

interface Props {
  feedData: Feed
}

function SocialLinks({ feedData }: Props) {
  const pinnedFeeds = useMemo(() => {
    const socialLinks = feedData?.feedSocialPlatforms || []
    if (socialLinks.length === 0) return []

    return socialLinks.filter((i) => i.pinned).slice(0, 6)
  }, [feedData])

  if (pinnedFeeds.length === 0) {
    return (<div className='profile-module px-5 py-3 mt-3 bg-background-500 rounded-xl'>
      <div className='text-white pb-3 text-2xl font-medium'>
        Social Platforms
      </div>
      <div>There's nothing here yet!</div>
    </div>)
  }

  return (
    <div className='profile-module px-5 py-3 mt-3 bg-background-500 rounded-xl'>
      <div className='text-white pb-3 text-2xl font-medium'>
        Social Platforms
      </div>
      {pinnedFeeds.map((pinnedFeed, index) => (
          <ProfileSocialLink platform={pinnedFeed.platform as SocialBrand} value={pinnedFeed.value} key={index} />
        ),
      )}
    </div>
  )
}

export default SocialLinks
