import './CoreImageStyles.css'
import { useState } from 'react'

interface Props extends React.HtmlHTMLAttributes<HTMLImageElement> {
  alt?: string
  src?: string
  height?: number
  width?: number
}

/**
 * A wrapper around the HTML image element that provides a default placeholder image
 * and a skeleton loader.
 * @param props
 * @constructor
 */
function Image(props: Props) {
  const [isLoading, setIsLoading] = useState(true)

  const handleImageLoad = () => {
    setIsLoading(false)
  }

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.onerror = null
    event.currentTarget.src = '/images/games/placeholder.png'
  }

  return (
      <>
        {isLoading && (
          <div className={'animate-pulse bg-foreground-500 rounded-lg w-full h-full'}>
            <div className='h-1/2 w-1/2 m-auto' />
          </div>
        )}
        <img
          alt={props.alt ?? 'general image'}
          onLoad={handleImageLoad}
          onError={handleError}
          {...props}
        />
      </>

  )
}

export default Image
