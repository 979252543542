import { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useNavigate } from 'react-router-dom'
import { Feed, FeedType } from '../../../api/types'
import GradientBackgroundContainer from '../../../common/containers/GradientBackgroundContainer'
import Button from '../../../common/forms/Button'
import LinearPicker from '../../../common/forms/LinearPicker'
import ShardIcon from '../../../common/icons/ShardIcon'
import Spinner from '../../../common/Spinner'
import WritersBlockSponsoredPost from './WriterBlockSponsoredPost'
import WritersBlockFeedCard from '../WritersBlockFeedCard'
import useFindFeeds from '../../../app/hooks/useFindFeeds'
import { AxiosContext } from '../../../providers/AxiosProvider'
import { Routes } from '../../../app/helpers/RestApiRoutes'
import useFindPosts from '../../../app/hooks/useFindPosts'

function WritersBlockIndex() {
  const [order, setOrder] = useState<string>('popular')
  const navigate = useNavigate()
  const client = useContext(AxiosContext)
  const {
    loading,
    items,
    total,
    setPage,
    hasMore,
    page,
  } = useFindFeeds(FeedType.WritersBlock)
  const {
    Search: searchForPosts,
    items: sponsoredPosts,
    loading: sponsorLoading
  } = useFindPosts()
  
  useEffect(() => {
    void searchForPosts({
      page: 0,
      pageSize: 1,
      feedType: 'WritersBlock',
      feedId: '',
      sponsored: false,
      archived: false,
      privacy: false,
      pinned: true,
    })
  }, [searchForPosts])

  const performRefresh = async (callback: () => void) => {
    setPage(0)
    callback()
  }

  const GoToRandomFeed = () => {
    client.get<Feed>(Routes.GetRandomFeed())
      .then(({ data }) => {
        navigate(`/writers-block/${data.slug}`)
      })
  }

  return (
    <GradientBackgroundContainer url='/images/writers-blocks/embergn-bedroom.png'>
      <div className='container mx-auto px-4 py-16 relative'>
        <div className='flex md:flex-row flex-col'>
          <div className='flex flex-col text-white flex-1'>
            <h1 className='bi-type-underline text-3xl pb-3 font-alice'>
              Writers Block
            </h1>
            <div className='border-b border-solid border-red-500 w-28 mb-auto' />
            <p className='text-steel py-10'>
              From short stories to deep narratives, shower thoughts and
              articles helping you find that indie gem...
            </p>
            <div className='flex space-x-3'>
              <Link to='/writers-block/create' className='flex-1'>
                <Button
                  trackingEventName={'create_writers_block_feed'}
                  className='bg-primary-500 rounded-xl px-3 py-2 w-full '
                >
                  Create block
                </Button>
              </Link>
              <Button
                trackingEventName={'go_to_random_writers_block_feed'}
                className='bg-primary-500 rounded-xl px-3 py-2 flex-1'
                onClick={GoToRandomFeed}
              >
                Read something
              </Button>
            </div>
          </div>
          <div className='flex-1 flex flex-row mt-6 md:mt-0'>
            <div className='flex-1 text-center flex flex-col items-center space-y-4 justify-center'>
              <ShardIcon isSolid isActive />
              <div className='blocks-created text-5xl text-primary-500'>
                {total ?? <Spinner />}
              </div>
              <div className='text-sm text-gray-300'>Blocks Created</div>
            </div>
            <div className='flex-1 text-center flex flex-col items-center space-y-4 justify-center'>
              <ShardIcon isSolid isActive />
              <div className='blocks-created text-5xl text-primary-500'>
                {/* TODO: Get total posts for all writers block feeds to display here */}
                {0}
              </div>
              <div className='text-sm text-gray-300'>Articles Posted</div>
            </div>
          </div>
        </div>

        <div className='py-8'>
          {sponsorLoading && <Spinner />}
          {sponsoredPosts && sponsoredPosts.length > 0 && (
            <WritersBlockSponsoredPost post={sponsoredPosts[0]} />
          )}
        </div>

        <div className='grid md:grid-cols-3 grid-cols-1 gap-4 mt-9'>
          <div className='text-3xl md:col-span-3 font-alice'>Featured</div>
          <div className='border-b border-solid border-red-500 w-28 md:col-span-3 mb-9' />

          {/* Minimum and maximum number of feeds is 3, we should consider carousels for mobile */}
          {(items ?? []).slice(0, 2)
            .map((feed: Feed) => (
              <WritersBlockFeedCard key={feed.id} feed={feed} />
            ))}
        </div>

        <div className='text-3xl md:col-span-3 font-alice mt-9'>Creators</div>
        <div className='border-b border-solid border-red-500 w-28 md:col-span-3 mb-9' />

        <LinearPicker
          value={order}
          options={['popular', 'newest']}
          onChange={(e) => {
            performRefresh(() => setOrder(e))
          }}
        />

        <InfiniteScroll
          pageStart={page}
          hasMore={hasMore && !loading}
          loadMore={(proposedPage) => {
            setPage(page + 1)
          }}
          loader={
            <div
              key={'writers-block-index-spinner'}
              className='w-full py-5 text-center'
            >
              <Spinner />
            </div>
          }
        >
          <div className='grid md:grid-cols-3 grid-cols-1 gap-4 mt-9'>
            {(items ?? []).map((feed: Feed) => (
              <WritersBlockFeedCard key={feed.id} feed={feed} />
            ))}
            {(items ?? []).length === 0 && (
              <div className='w-full py-5 text-center col-span-3'>
                <p className='text-gray-500'>No writers block feeds</p>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </GradientBackgroundContainer>
  )
}

export default WritersBlockIndex
