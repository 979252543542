import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { CurrentUserContext } from '../../../providers/AuthProvider'

export default function AdminRouteGuard() {
  const { user } = useContext(CurrentUserContext)
  const navigate = useNavigate()

  if( user?.role !== 'admin') {
    navigate('/dashboard')
  }

  return (
    <Outlet />
  )
}