import { useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { GET_FEED, GetFeedQuery, GetFeedVariables, UPDATE_FEED, UpdateFeedQuery, UpdateFeedVariables } from 'src/api/gql-queries'
import SectionCard from '../../atoms/SectionCard'
import ErrorMessage from '../../molecules/ErrorMessage'
import SelectField from 'src/common/forms/SelectField'
import { Navigate, useLocation } from 'react-router-dom'
import { Privacy } from 'src/api/types'
import SkeletonCard from '../../molecules/SkeletonCard'
import { CurrentUserContext } from '../../../../providers/AuthProvider'

export default function ChangeFeedPrivacyContent() {
  const { writersBlocks, ClearCache } = useContext(CurrentUserContext)
  const { pathname: location } = useLocation()

  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [feedPrivacy, setFeedPrivacy] = useState<string>('Open')

  const [updateFeed] = useMutation<UpdateFeedQuery, UpdateFeedVariables>(
    UPDATE_FEED,
  )

  const getWritersBlockByName = () => {
    // Get last part of url
    const url = location.split('/')
    const name = url[url.length - 1]

    return (writersBlocks ?? []).find((block) => block.name === decodeURI(name))
  }

  const { data, loading } = useQuery<GetFeedQuery, GetFeedVariables>(GET_FEED, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      slug: getWritersBlockByName()?.id ?? '',
    },
  })

  useEffect(() => {
    setFeedPrivacy(data?.Feed?.privacy ?? Privacy.Open)
  }, [data])

  async function submitForm(value: Privacy) {
    setFeedPrivacy(value.toString())
    try {
      await updateFeed({
        variables: {
          id: data?.Feed.id ?? '',
          privacy: value,
        },
      })
      ClearCache()
      setMessage('Your feed content flag has been updated.')
    } catch (e) {
      setError(true)
      setMessage('Something went wrong. Please try again later.')
    }
  }

  // There was no communties, so there should be no form
  if (!writersBlocks) return null

  // We're loading so we should display the intent
  if (loading) return <SkeletonCard />

  // there's no WritersBlock with that name, we should get out of here
  if (!getWritersBlockByName())
    return <Navigate to="/settings/writers-blocks" />

  return (
    <SectionCard title="Change feed privacy setting">
      <div>Change the feed's privacy content:</div>

      <SelectField
        options={{
          [Privacy.Private]: 'Private: This feed will not be shown to anyone',
          [Privacy.Invite]:
            'Followers: This feed will only be shown to people I follow',
          [Privacy.Open]: 'Public: This feed will be shown to everyone',
        }}
        defaultValue={feedPrivacy + ''}
        onChange={(value) => {
          submitForm(value as Privacy)
        }}
      />
      <ErrorMessage
        error={error}
        message={message}
        dataTestId={'change-profile-feed-nsfw'}
      />
    </SectionCard>
  )
}
