export interface ProfileModule {
  module: ProfileModuleType
  position: ProfileModulePosition
}

export enum ProfileModuleType {
  // Achievement = "achievement",
  // GeneralAchievements = "general_achievements",
  Games = 'Games',
  // RecentAward = "recent_award",
  RecentBlock = 'RecentBlock',
  // Gallery = "gallery",
  SocialPlatforms = 'SocialPlatforms',
  Unknown = 'unknown',
}

export enum ProfileModulePosition {
  bottomLeft = 'bottomLeft',
  topRight = 'topRight',
  bottomRight = 'bottomRight',
}
