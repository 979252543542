import { Link } from 'react-router-dom'
import Image from '../../common/forms/Image'

type SearchResultProps = {
  to: string
  title: string
  description: string
  image: string
  onSelect?: () => void
}
function SearchResult({
  to,
  title,
  description,
  image,
  onSelect,
}: SearchResultProps) {
  return (
    <Link
      to={to}
      className="rounded-lg mt-3 bg-darkbackground-500"
      onClick={onSelect}
    >
      <div className="search-result flex p-3 ">
        <div className="search-result-image">
          <Image
            src={image}
            alt={title}
            className="rounded-lg max-h-20 max-w-20 h-20 w-20 object-cover"
          />
        </div>
        <div className="search-result-content w-3/4 p-3 flex flex-col justify-around">
          <div className="search-result-title text-xl text-white">{title}</div>
          <div className="search-result-description text-steel">
            {description?.substring(0, 50)}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SearchResult
